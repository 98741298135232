import GDPR from '_constants';
import { INIT } from '_config/initialState';
const { gdprList: initialState } = INIT;
export default (state = initialState, action = {}) => {
    const { type, result, params, faliure } = action;
    switch (type) {
        case GDPR.GDPR_REQUEST_START:
            return { ...state, loading: true };
        case GDPR.GDPR_REQUEST_END:
            return { ...state, loading: false };
        case GDPR.GDPR_SELECTED_ID: {
            return {
                ...state,
                selectedId: result,
            };
        }
        case GDPR.GDPR_CURRENT_SUCCESS: {
            const activeTab =
                params?.category === 'TERM_CONDITION'
                    ? 'currentTerm'
                    : 'currentPrivacy';
            return {
                ...state,
                [activeTab]: result,
            };
        }
        case GDPR.GDPR_LIST_SUCCESS: {
            const activeTab = params?.category;
            return {
                ...state,
                faliure: null,
                tabs: {
                    ...state.tabs,
                    [activeTab]: {
                        result: params?.pageNumber
                            ? {
                                  ...result,
                                  list: [
                                      ...state.tabs[activeTab].result.list,
                                      ...result.list,
                                  ],
                                  faliure: null,
                              }
                            : {
                                  ...result,
                                  list: [...result.list],
                                  faliure: null,
                              },
                        params,
                    },
                },
            };
        }
        case GDPR.GDPR_CLEAR_FAILURE: {
            const activeTab = params?.category;
            return {
                ...state,
                faliure: null,
                tabs: {
                    ...state.tabs,
                    [activeTab]: { ...state.tabs[activeTab], faliure: null },
                },
            };
        }
        case GDPR.GDPR_ADD_FAILURE:
        case GDPR.GDPR_DEL_FAILURE:
        case GDPR.GDPR_EDIT_FAILURE: {
            const activeTab = params?.category;
            return {
                ...state,
                tabs: {
                    ...state.tabs,
                    [activeTab]: { ...state.tabs[activeTab], faliure },
                },
            };
        }
        case GDPR.GDPR_LIST_FAILURE:
            return { ...state, faliure };
        default:
            return { ...state };
    }
};
export const gdprDetails = (state) => state.gdprList;
