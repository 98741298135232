import ADMIN from '_constants';
import { INIT } from '_config/initialState';
const { adminList: initialState } = INIT;

export default (state = initialState, action = {}) => {
    const { type, params, result, error } = action;
    switch (type) {
        case ADMIN.ADMIN_REQUEST_START:
            return { ...state, loading: true };
        case ADMIN.CENTER_REQUEST_START:
            return {
                ...state,
                centerList: { ...state.centerList, loading: true },
            };
        case ADMIN.ADMIN_REQUEST_END:
            return { ...state, loading: false };
        case ADMIN.CENTER_REQUEST_END:
            return {
                ...state,
                centerList: { ...state.centerList, loading: false },
            };
        case ADMIN.CENTER_LIST_SUCCESS:
            return {
                ...state,
                centerList: { ...state.centerList, result },
            };
        case ADMIN.ADMIN_LIST_SUCCESS:
            return {
                ...state,
                result: {
                    ...result,
                    list: params.pageNumber
                        ? [...state.result.list, ...result.list]
                        : [...result.list],
                },
                faliure: null,
                params: params,
            };
        case ADMIN.ADMIN_RECORD_SUCCESS:
            return { ...state, faliure: null, success: true };
        case ADMIN.ADMIN_CLEAR:
            return { ...state, faliure: null, success: false };
        case ADMIN.ADMIN_ADD_FAILURE:
        case ADMIN.ADMIN_EDIT_FAILURE:
        case ADMIN.ADMIN_DEL_FAILURE:
            return { ...state, faliure: error };
        case ADMIN.ADMIN_SELECTED_ID:
            return { ...state, selectedId: result };
        case ADMIN.ADMIN_LIST_FAILURE:
            return { ...state, ...error, loading: false };
        default:
            return { ...state };
    }
};
export const adminDetails = (state) => state.adminList;
