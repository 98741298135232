import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { PlaceholderImg } from 'images';
import { Image as Img, Dimmer, Loader } from 'semantic-ui-react';

const ImageComp = (props) => {
    const { src } = props;
    const [imgSrc, setImsgSrc] = useState({
        loading: true,
        imgUrl: PlaceholderImg,
    });
    const handleImgLoading = useCallback(() => {
        return new Promise((resolve, reject) => {
            const img = new Image();
            img.onload = (ele) =>
                resolve({ loading: false, imgUrl: ele.currentTarget.src });
            img.onerror = () =>
                reject({ loading: false, imgUrl: PlaceholderImg });
            img.src = src;
        });
    }, [src]);
    useEffect(() => {
        let isSubscribed = true;
        handleImgLoading()
            .then((imgUrl) => (isSubscribed ? setImsgSrc(imgUrl) : null))
            .catch((imgUrl) => (isSubscribed ? setImsgSrc(imgUrl) : null));

        return () => (isSubscribed = false);
    }, [handleImgLoading]);
    const { loading, imgUrl } = imgSrc;
    return (
        <>
            <Dimmer inverted active={loading}>
                <Loader />
            </Dimmer>
            <Img {...props} src={imgUrl} />
        </>
    );
};

ImageComp.propTypes = {
    src: PropTypes.string.isRequired,
    avatar: PropTypes.bool,
    bordered: PropTypes.bool,
    centered: PropTypes.bool,
    fluid: PropTypes.bool,
    hidden: PropTypes.bool,
    href: PropTypes.string,
    inline: PropTypes.bool,
    rounded: PropTypes.bool,
    size: PropTypes.oneOf([
        'mini',
        'tiny',
        'small',
        'medium',
        'large',
        'big',
        'huge',
        'massive',
    ]),
    spaced: PropTypes.oneOfType([
        PropTypes.bool,
        PropTypes.oneOf(['left', 'right']),
    ]),
    ui: PropTypes.bool,
    verticalAlign: PropTypes.oneOf(['bottom', 'middle', 'top']),
};

export { ImageComp };
