import React, { useState, useMemo, useEffect } from 'react';
import { Image, UpgradeCEANModal, StatusHistory } from '_components';
import CONSTANTS from '_constants';
import { Segment, Grid, Dimmer, Loader } from 'semantic-ui-react';
import { edit } from 'images';
import './PatientInfoDetails.scss';

const PatientInfoDetails = (props) => {
    const {
        patientInfo: infoData,
        userDetails,
        history,
        upgradeKetoToCean,
        loading,
        getBoxes,
        boxes,
    } = props?.propsData;
    const { id } = props;
    const { ROLES } = CONSTANTS;
    const role = userDetails?.Role[0];
    const userRole = infoData.roles !== undefined ? infoData.roles[0] : '';
    const { patientInfo, statusHistoryVisible } = props?.details;
    const initials =
        infoData?.firstName !== undefined
            ? infoData?.firstName?.substring(0, 1).toUpperCase() +
              '' +
              infoData?.lastName?.substring(0, 1).toUpperCase()
            : '-';
    const [isModalOpen, setUpgradeModal] = useState(false);
    const gotoPromotionPage = () => {
        history.push(`${infoData.id}/upgrade`);
    };
    useEffect(() => {
        role === ROLES.ADMIN && statusHistoryVisible && getBoxes(id);
    }, [id, getBoxes, role, ROLES, statusHistoryVisible]);
    const memopUpgradeModal = useMemo(() => {
        return (
            <UpgradeCEANModal
                isModalOpen={isModalOpen}
                config={patientInfo.modal}
                handleClose={() => setUpgradeModal(false)}
                infoData={infoData}
                upgradeKetoToCean={upgradeKetoToCean}
                userRole={
                    userRole === ROLES.UNAFFILIATED
                        ? 'unaffiliated'
                        : 'ketosteril'
                }
            />
        );
    }, [
        isModalOpen,
        patientInfo,
        infoData,
        upgradeKetoToCean,
        userRole,
        ROLES,
    ]);
    return (
        <div className="patient-info-details">
            <Segment.Group className="patient-details-section">
                {loading && (
                    <Dimmer inverted active={loading}>
                        <Loader />
                    </Dimmer>
                )}
                <Segment className="section-header">
                    {patientInfo?.title}
                    <Image
                        className="img-link"
                        floated="right"
                        src={edit}
                        onClick={() =>
                            history.push({
                                pathname: `/Patients/${infoData?.id}/Edit`,
                            })
                        }
                    />
                </Segment>
                <Segment className="sub-section">
                    {role === ROLES.ADMIN ? (
                        <Grid columns={3}>
                            <Grid.Column className="pr-0">
                                <Grid columns={2} className="patient-info">
                                    <div className="patient-name">
                                        {initials}
                                    </div>
                                    <div className="title">
                                        <div className="subtitle normal">
                                            id {infoData?.id ?? '-'}
                                        </div>
                                        <div className="subtitle normal black">
                                            {patientInfo.user[userRole]}
                                        </div>
                                    </div>
                                </Grid>
                                <Segment className="patient-info-section">
                                    <div className="subtitle">
                                        {patientInfo?.name}
                                    </div>
                                    <div className="info">
                                        {infoData?.firstName ?? '-'}
                                    </div>
                                    <div className="subtitle mg-top-10">
                                        {patientInfo?.surName}
                                    </div>
                                    <div className="info">
                                        {infoData?.lastName ?? '-'}
                                    </div>
                                </Segment>
                            </Grid.Column>
                            <Grid.Column>
                                <Segment
                                    className="patient-info-section"
                                    style={{
                                        whiteSpace:
                                            role !== ROLES.ADMIN
                                                ? 'nowrap'
                                                : 'none',
                                    }}
                                >
                                    <div className="subtitle ">
                                        {patientInfo?.nutritionsitRecordId}
                                    </div>
                                    <div className="info">
                                        {infoData?.nutritionalRecordId ?? '-'}
                                    </div>
                                    <div className="subtitle mg-top-10">
                                        {patientInfo?.phone}
                                    </div>
                                    <div className="info">
                                        {infoData?.phoneNumber ?? '-'}
                                    </div>
                                    <div className="subtitle mg-top-10">
                                        {patientInfo?.emailIdLabel}
                                    </div>
                                    <div className="info">
                                        {infoData?.email ?? '-'}
                                    </div>
                                </Segment>
                            </Grid.Column>
                            {
                                statusHistoryVisible ? <Grid.Column>
                                    <StatusHistory
                                        isDisabled={userRole === ROLES.UNAFFILIATED}
                                        isRejected={false}
                                        config={patientInfo}
                                        items={{
                                            free: boxes?.totalGiftBoxes,
                                            acceptedtems: boxes?.validBoxes,
                                            pendingItems: boxes?.pendingBoxes,
                                        }}
                                    />
                                </Grid.Column> : ''
                            }
                            <Grid.Row colums={3}>
                                {userRole !== ROLES.CEAN ? 
                                    <Grid.Column>
                                        <button
                                        onClick={gotoPromotionPage}
                                        className="sq-button"
                                        >
                                            {patientInfo?.upgradeKeto}
                                        </button>
                                    </Grid.Column> : ''
                                }
                                <Grid.Column>
                                    {userRole !== ROLES.CEAN ? (
                                        <button
                                            className="outline-button"
                                            onClick={() => {
                                                setUpgradeModal(true);
                                            }}
                                        >
                                            {patientInfo?.upgradeCEAN}
                                        </button>
                                    ) : (
                                        ''
                                    )}
                                </Grid.Column>
                                {
                                    statusHistoryVisible ? <Grid.Column>
                                        {userRole !== '' &&
                                        userRole !== ROLES.UNAFFILIATED ? (
                                            <div
                                                className="link"
                                                onClick={async () => {
                                                    await props.propsData.selectMenuItem(
                                                        'Promotions'
                                                    );
                                                    history.push({
                                                        pathname: `/Promotions/DEFAULT/id/${infoData.id}`,
                                                        state: { record: null },
                                                    });
                                                }}
                                            >
                                                {patientInfo?.gotoPromotion}
                                            </div>
                                        ) : (
                                            ''
                                        )}
                                    </Grid.Column> : ''
                                }
                            </Grid.Row>
                        </Grid>
                    ) : (
                        <Grid columns={3}>
                            <Grid.Column>
                                <Grid columns={2}>
                                    <Grid.Column width={5}>
                                        <div className="patient-name">
                                            {initials}
                                        </div>
                                    </Grid.Column>
                                    <Grid.Column>
                                        <Segment className="patient-info-section">
                                            <div className="subtitle">
                                                {patientInfo?.name}
                                            </div>
                                            <div className="info">
                                                {infoData?.firstName ?? '-'}
                                            </div>
                                            <div className="subtitle mg-top-10">
                                                {patientInfo?.surName}
                                            </div>
                                            <div className="info">
                                                {infoData?.lastName ?? '-'}
                                            </div>
                                        </Segment>
                                    </Grid.Column>
                                </Grid>
                            </Grid.Column>
                            <Grid.Column>
                                <Segment className="patient-info-section">
                                    <div className="subtitle">
                                        {patientInfo?.nutritionsitRecordId}
                                    </div>
                                    <div className="info">
                                        {infoData?.nutritionalRecordId ?? '-'}
                                    </div>
                                    <div className="subtitle mg-top-10">id</div>
                                    <div className="info">
                                        {infoData?.id ?? '-'}
                                    </div>
                                </Segment>
                            </Grid.Column>
                            <Grid.Column>
                                <Segment className="patient-info-section">
                                    <div className="subtitle">
                                        {patientInfo?.phone}
                                    </div>
                                    <div className="info">
                                        {infoData?.phoneNumber ?? '-'}
                                    </div>
                                    <div className="subtitle mg-top-10">
                                        {patientInfo?.emailIdLabel}
                                    </div>
                                    <div className="info">
                                        {infoData?.email ?? '-'}
                                    </div>
                                </Segment>
                            </Grid.Column>
                        </Grid>
                    )}
                </Segment>
            </Segment.Group>
            {memopUpgradeModal}
        </div>
    );
};

export { PatientInfoDetails };
