import React from 'react';
import { Segment, Grid } from 'semantic-ui-react';
import { ButtonGroupComp } from '_components/ButtonGroup/ButtonGroup';
import { LineGraph } from '_components/LineGraph/LineGraph';

const BodySection = (props) => {
    const {
        bodyMeasures,
        bmLoading,
        bodyMesBtns,
        handleBMBtnClick,
        bmData,
        bmUnit,
    } = props;
    return (
        <Segment.Group className="width-100">
            <Segment className="section-header">{bodyMeasures?.title}</Segment>
            <Segment loading={bmLoading} className="sub-section">
                <Grid>
                    <Grid.Column>
                        <div className="analyticsBtngrp">
                            <ButtonGroupComp
                                items={bodyMesBtns}
                                handleBtnClick={handleBMBtnClick}
                            />
                        </div>
                        <LineGraph data={bmData} graphType="BM" unit={bmUnit} />
                    </Grid.Column>
                </Grid>
            </Segment>
        </Segment.Group>
    );
};

export default BodySection;
