import CHAT from '_constants';
import { INIT } from '_config/initialState';
const { chatList: initialState } = INIT;
export default (state = initialState, action = {}) => {
    const { type, result, params, faliure } = action;
    switch (type) {
        case CHAT.CHAT_REQUEST_START:
            return { ...state, loading: true };
        case CHAT.CHAT_NUTRI_START:
            return { ...state, nutri: { ...state.nutri, loading: true } };
        case CHAT.CHAT_NUTRI_END:
            return { ...state, nutri: { ...state.nutri, loading: false } };
        case CHAT.CHAT_NUTRI_SUCCESS:
            return {
                ...state,
                nutri: { ...state.nutri, result, faliure: null },
            };
        case CHAT.CHAT_NUTRI_FAILURE:
            return {
                ...state,
                nutri: { ...state.nutri, result: {}, faliure },
            };
        case CHAT.CHAT_CALLING_START:
            return { ...state, calling: result };
        case CHAT.CHAT_CALLING_STOP:
            return { ...state, calling: result };
        case CHAT.CHAT_CALLING_END:
            return { ...state, calling: result };
        case CHAT.CHAT_REQUEST_END:
            return { ...state, loading: false };
        case CHAT.CHAT_TOKEN_SET:
            return {
                ...state,
                token: result?.chatToken ?? null,
                inviteUsers: result?.nutritionistList ?? [],
            };
        case CHAT.CHAT_SELECTED_ID: {
            const tab = params?.category;
            return {
                ...state,
                tabs: {
                    ...state.tabs,
                    [tab]: {
                        ...state.tabs[tab],
                        selectedId: result.room,
                        chatHistory: result?.msgs,
                    },
                },
            };
        }
        case CHAT.CHAT_RECORDING_START: {
            const tab = params?.category;
            return {
                ...state,
                tabs: {
                    ...state.tabs,
                    [tab]: {
                        ...state.tabs[tab],
                        isAudioMsgRunning: true,
                    },
                },
            };
        }
        case CHAT.CHAT_RECORDING_STOP: {
            const tab = params?.category;
            return {
                ...state,
                tabs: {
                    ...state.tabs,
                    [tab]: {
                        ...state.tabs[tab],
                        isAudioMsgRunning: false,
                    },
                },
            };
        }
        case CHAT.CHAT_UPDATE_MSG_HISTORY: {
            const tab = params?.category;
            return {
                ...state,
                tabs: {
                    ...state.tabs,
                    [tab]: {
                        ...state.tabs[tab],
                        chatHistory: [...state.tabs[tab].chatHistory, result],
                    },
                },
            };
        }
        case CHAT.CHAT_UPDATE_PREV_MSG_HISTORY: {
            const tab = params?.category;
            return {
                ...state,
                tabs: {
                    ...state.tabs,
                    [tab]: {
                        ...state.tabs[tab],
                        chatHistory: [
                            ...result,
                            ...state.tabs[tab].chatHistory,
                        ],
                    },
                },
            };
        }
        case CHAT.CHAT_LIST_SUCCESS: {
            const tab = params?.category;
            return {
                ...state,
                faliure: null,
                tabs: {
                    ...state.tabs,
                    [tab]: {
                        result: params?.pageNumber
                            ? {
                                  ...result,
                                  list: [
                                      ...state.tabs[tab].result.list,
                                      ...result.list,
                                  ],
                                  faliure: null,
                              }
                            : {
                                  ...result,
                                  list: [...result.list],
                                  faliure: null,
                              },
                        params,
                    },
                },
            };
        }
        case CHAT.CHAT_CHANGE_CENTER: {
            const tab = params?.category;
            return {
                ...state,
                tabs: {
                    ...state.tabs,
                    [tab]: {
                        ...state.tabs[tab],
                        selectedId: undefined,
                        chatHistory: [],
                    },
                },
                nutri: { loading: false, result: {}, faliure: null },
            };
        }
        case CHAT.CHAT_CLEAR_FAILURE: {
            const tab = params?.category;
            return {
                ...state,
                faliure: null,
                tabs: {
                    ...state.tabs,
                    [tab]: { ...state.tabs[tab], faliure: null },
                },
            };
        }
        case CHAT.CHAT_ADD_FAILURE:
        case CHAT.CHAT_DEL_FAILURE:
        case CHAT.CHAT_EDIT_FAILURE: {
            const tab = params?.category;
            return {
                ...state,
                tabs: {
                    ...state.tabs,
                    [tab]: { ...state.tabs[tab], faliure },
                },
            };
        }
        case CHAT.CHAT_LIST_FAILURE:
            return { ...state, faliure };
        case CHAT.CHAT_SET_KABICENTER:
            return { ...state, selectedKabiCenter: result };
        default:
            return { ...state };
    }
};
export const chatDetails = (state) => state.chatList;
