import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from 'semantic-ui-react';
const ActionButton = (props) => {
    const { record, details, history, isAdmin, isAfflido } = props;
    return (
        <>
            <Grid.Column width={isAdmin ? 4 : 2}>
                <div
                    className={`text-link ${
                        isAdmin && isAfflido ? 'text-fix' : ''
                    }`}
                >
                    {isAdmin && isAfflido ? (
                        <>
                            <div
                                onClick={() => {
                                    history.push({
                                        pathname: `/Patients/${record?.id}/upgrade`,
                                        state: { main: record },
                                    });
                                }}
                                className="ticket-pending"
                            >
                                {details?.pendingTicket} (
                                {record?.pendingTicket}) &nbsp;&nbsp;&nbsp;
                            </div>
                        </>
                    ) : (
                        ''
                    )}
                    <div
                        onClick={() =>
                            history.push({
                                pathname: `/Patients/${record?.id}`,
                            })
                        }
                    >
                        {details?.actionLable ?? 'Edit'}
                    </div>
                </div>
            </Grid.Column>
        </>
    );
};

ActionButton.propTypes = {
    record: PropTypes.object.isRequired,
    details: PropTypes.object.isRequired,
};

export default ActionButton;
