import React, { useEffect } from 'react';
import { Segment, Container, Button, Form } from 'semantic-ui-react';
import CONST from '_constants';
import { isEmpty } from 'lodash';
import { BreadcrumbComp, Image, Input, TextArea } from '_components';
import { useForm } from 'react-hook-form';
import { validMinMaxInput, validMinMaxTextArea, getRecordId } from '_utils';
import { times } from 'images';
import './GdprEdit.scss';
const GdprEdit = (props) => {
    const {
        activeTab,
        goBack,
        config: {
            details,
            details: { form },
        },
        location: {
            pathname,
            state: { record: selectedRecord },
        },
        actions: { addGdpr, editGdpr },
        state: {
            gdprList: { loading: recordEditing, selectedId, tabs },
        },
    } = props;
    const record = selectedRecord === undefined ? selectedId : selectedRecord;
    const { category } = getRecordId(pathname);
    const isView = category !== undefined;
    if (isEmpty(tabs[category]?.faliure)) {
        // redirect to list page
    }
    // const pageSubHeadingForEdit = edit[activeTab.tab] ?? '';
    const { register, handleSubmit, watch, errors, setValue } = useForm({
        mode: 'onChange',
    });
    const watchAllFields = watch(['title', 'text', 'version']);
    const showErrors = (_, value) => {
        switch (_?.type) {
            case 'required':
                return <div className="err-msg">{value?.required}</div>;
            case 'minLength':
                return <div className="err-msg">{value?.minLength}</div>;
            default:
                break;
        }
    };
    const sections = [
        {
            key: 'myAdminListPage',
            content: activeTab.breadcrumbHeading,
            onClick: goBack,
        },
        {
            key: 'myAdminEditPage',
            content: isView
                ? details?.breadcrumbForView
                : details?.breadcrumbForAdd,
            active: true,
        },
    ];
    const onSubmit = (data) => {
        addGdpr({
            ...record,
            ...selectedRecord,
            ...data,
            content: data?.text,
            type: activeTab?.tab,
            params: tabs[activeTab?.tab]?.params,
        });
        setTimeout(() => goBack(true), CONST.TIMEOUT);
    };
    const validateForm = () => {
        const { title, text, version } = watchAllFields;
        return !validMinMaxInput(title, undefined, form?.title?.limit) &&
            !validMinMaxTextArea(text, undefined, form?.desc?.limit) &&
            !validMinMaxTextArea(version, undefined, form?.version?.limit) &&
            isEmpty(errors)
            ? false
            : true;
    };
    useEffect(() => {
        if (
            pathname !== '/Gdpr/TERM_CONDITION/Add' &&
            activeTab.tab !== 'PRIVACY_POLICY'
        ) {
            editGdpr({
                pathname,
                selectedId: selectedRecord?.termsAndConditionId,
            });
        }
    }, [pathname, selectedRecord, editGdpr, activeTab]);
    let recordEnabled = isView ? !isEmpty(record) : true;
    return (
        <Container fluid id="GdprEdit" className={`private-layout`}>
            <Segment basic loading={recordEditing}>
                <BreadcrumbComp sections={sections} />
                <div className="edit-container">
                    <div className="edit-heading">
                        <div className="edit-heading-text">
                            {activeTab.tab === 'PRIVACY_POLICY'
                                ? details?.pageHeading
                                : details?.pageHeadingTerm}
                        </div>
                        <div className="edit-heading-subtext">
                            {activeTab.tab === 'PRIVACY_POLICY'
                                ? details?.heading?.edit?.PRIVACY_POLICY
                                : details?.heading?.edit?.TERM_CONDITION}
                        </div>
                        <div className="edit-close">
                            <Image src={times} onClick={goBack} />
                        </div>
                    </div>
                    {recordEnabled && (
                        <div className="edit-form">
                            <Form onSubmit={handleSubmit(onSubmit)} unstackable>
                                <Form.Group>
                                    <Form.Field width={10}>
                                        <Input
                                            name="title"
                                            label={form?.title?.label}
                                            placeholder={
                                                form?.title?.placeholder
                                            }
                                            refs={{
                                                register,
                                                validation: {
                                                    maxLength:
                                                        form?.title?.limit,
                                                },
                                            }}
                                            value={
                                                record?.title ??
                                                selectedId?.title
                                            }
                                            disabled={isView}
                                            count={!isView}
                                        />
                                    </Form.Field>
                                    <Form.Field
                                        width={6}
                                        className="error-field"
                                    >
                                        <Input
                                            name="version"
                                            label={form?.version?.label}
                                            placeholder={
                                                form?.version?.placeholder
                                            }
                                            refs={{
                                                register,
                                                validation: {
                                                    minLength: 4,
                                                    maxLength:
                                                        form?.version?.limit,
                                                },
                                            }}
                                            value={record?.version ?? ''}
                                            disabled={isView}
                                            count={!isView}
                                        />
                                        {showErrors(
                                            errors?.version,
                                            form?.version
                                        )}
                                    </Form.Field>
                                </Form.Group>
                                <Form.Field width={16}>
                                    <TextArea
                                        name="text"
                                        label={form?.desc?.label}
                                        rows={6}
                                        placeholder={form?.desc?.placeholder}
                                        refs={{
                                            register,
                                            setValue,
                                            validation: {
                                                maxLength: form?.desc?.limit,
                                            },
                                        }}
                                        value={
                                            record?.content ??
                                            selectedId?.content
                                        }
                                        disabled={isView}
                                        count={!isView}
                                        editor
                                    />
                                </Form.Field>
                                {!isView && (
                                    <Button
                                        className="edit-action-button"
                                        type="submit"
                                        disabled={validateForm()}
                                    >
                                        {form?.actionLableForEdit}
                                    </Button>
                                )}
                            </Form>
                        </div>
                    )}
                </div>
            </Segment>
        </Container>
    );
};

export { GdprEdit };
