import React from 'react';
import { Container } from 'semantic-ui-react';
import { useDispatch, batch } from 'react-redux';
import { isEmpty } from 'lodash';

import { getUserDetails } from '_utils';
import CONST from '_constants';

import './NotFoundPage.scss';
const NotFoundPage = () => {
    const dispatch = useDispatch();
    const isValidUser = () => {
        if (isEmpty(getUserDetails())) {
            setTimeout(
                () =>
                    batch(() => {
                        dispatch({ type: CONST.LOGOUT });
                        dispatch({
                            type: CONST.ERROR,
                            message:
                                'Su sesión expiró. Por favor, ingrese de nuevo',
                        });
                    }),
                CONST.TIMEOUT - 4800
            );
        }
        return null;
    };
    return (
        <Container fluid id="NotFoundPage" className={`private-layout`}>
            {isValidUser()}
        </Container>
    );
};

export { NotFoundPage };
