import FILE_UP from '_constants';
import { INIT } from '_config/initialState';
import { findIndex } from 'lodash';
const { files: initialState } = INIT;
const updateList = (list, file, obj) => {
    const getIndex = findIndex(list, { name: file.name });
    let updateList = [];
    if (getIndex !== -1) {
        list[getIndex] = { ...list[getIndex], ...obj, ...file };
        updateList = list;
    } else updateList = [...list, { ...obj, ...file }];
    return updateList;
};
export default (state = initialState, action = {}) => {
    const { type, faliure, file } = action;
    switch (type) {
        case FILE_UP.FILE_UP_REQUEST_START:
            return {
                list: updateList(state.list, file, { uploading: true }),
            };
        case FILE_UP.FILE_UP_REQUEST_END:
            return {
                list: updateList(state.list, file, { uploading: false }),
            };
        case FILE_UP.FILE_UP_FAILURE:
            return {
                list: updateList(state.list, file, { faliure }),
            };
        case FILE_UP.FILE_UP_SUCCESS:
            return {
                list: updateList(state.list, file, {
                    uploaded: true,
                    faliure: null,
                }),
            };
        case FILE_UP.FILE_UP_INIT:
            return { ...initialState };
        default:
            return { ...state };
    }
};
export const filesDetails = (state) => state.files;
