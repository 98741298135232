import React, { useMemo, useEffect } from 'react';
import { Switch, Route, useRouteMatch, Redirect } from 'react-router-dom';
import { ChatList } from './Chat';
const ChatRoutes = (props) => {
    const { path } = useRouteMatch();
    const {
        config,
        activeTab,
        actions: { getToken },
    } = props;
    const memoChatList = useMemo(() => {
        return <ChatList {...props} config={{ ...config, activeTab }} />;
    }, [props, config, activeTab]);
    useEffect(() => {
        getToken();
    }, [getToken]);
    return (
        <Switch>
            <Route exact path={path}>
                <Redirect to={`${path}/${activeTab.tab}`} />
            </Route>
            <Route exact path={`${path}/:tabID`}>
                {memoChatList}
            </Route>
        </Switch>
    );
};

export default ChatRoutes;
