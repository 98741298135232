import React from 'react';
import { Switch, Route, useRouteMatch, Redirect } from 'react-router-dom';
import {
    TipsList,
    TipsEdit,
    TipsAdd,
    NutritionalTab,
    NutritionalFoodAdd,
} from './Tips';
import { GeneralEdit, GeneralAdd } from './General';
// import { NotFoundPage } from '_pages';
const TipsRoutes = (props) => {
    const { path } = useRouteMatch();
    const { config, activeTab } = props;
    const editRecords = () => {
        switch (activeTab.tab) {
            case 'RECIPES':
                return <TipsEdit {...props} />;
            case 'PHYSICAL':
            case 'GENERAL':
                return <GeneralEdit {...props} />;
            default:
                break;
        }
    };
    const addRecords = () => {
        switch (activeTab.tab) {
            case 'RECIPES':
                return <TipsAdd {...props} />;
            case 'PHYSICAL':
            case 'GENERAL':
                return <GeneralAdd {...props} />;
            default:
                break;
        }
    };
    const listRecord = () => {
        switch (activeTab.tab) {
            case 'RECIPES':
            case 'PHYSICAL':
            case 'GENERAL':
                return (
                    <TipsList {...props} config={{ ...config, activeTab }} />
                );
            case 'NUTRITIONAL':
                return <NutritionalTab {...props} />;
            default:
                break;
        }
    };
    return (
        <Switch>
            <Route exact path={path}>
                <Redirect to={`${path}/${activeTab.tab}`} />
            </Route>
            <Route exact path={`${path}/:tabID`}>
                {listRecord()}
            </Route>
            <Route exact path={`${path}/:tabID/id/:id`}>
                {editRecords()}
            </Route>
            <Route exact path={`${path}/:tabID/Add`}>
                {addRecords()}
            </Route>
            <Route exact path={`${path}/:tabID/AddNutritionalFood`}>
                <NutritionalFoodAdd {...props} />
            </Route>
            <Route exact path={`${path}/:tabID/AddNutritionalFood/:id`}>
                <NutritionalFoodAdd {...props} />
            </Route>
        </Switch>
    );
};

export default TipsRoutes;
