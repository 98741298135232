import {
    isEmpty,
    indexOf,
    filter,
    find,
    isArray,
    map,
    trim,
    lowerCase,
} from 'lodash';
import Cookies from 'js-cookie';
import JwtDecode from 'jwt-decode';
import UTILS from '_constants';
import * as moment from 'moment';

export const removeUserDetails = () => {
    const options = {
        path: '/',
        domain: process.env.REACT_APP_HOST_NAME,
    };
    Cookies.remove('session-token', options);
};
export const setUserDetails = (userDetails) => {
    const { token } = userDetails;
    Cookies.set('session-token', token, {
        expires: 1 / 3.7, // expire in 1 hour
        // secure: true,
        domain: process.env.REACT_APP_HOST_NAME,
        path: '/',
        sameSite: 'lax',
    });
};
export const hasExtensions = (value, exts = ['.csv']) =>
    new RegExp('(' + exts.join('|').replace(/\./g, '\\.') + ')$').test(value);
export const getUserDetailsFromToken = (token) => {
    try {
        return JwtDecode(token);
    } catch (e) {
        return false;
    }
};
export const getUserID = (id) => {
    try {
        return atob(id);
    } catch (e) {
        return false;
    }
};
export const removeTags = (str) => {
    try {
        const x = str.toString();
        return x
            .replace(/(<([^>]+)>)/gi, '')
            .replace(/(?:https?|ftp):\/\/[\n\S]+/g, '');
    } catch (_) {
        return str;
    }
};
export const getCurRefreshToken = () => {
    return Cookies.get('refreshToken', {
        domain: process.env.REACT_APP_HOST_NAME,
    });
};
export const getUserDetails = () => {
    try {
        const token = Cookies.get('session-token', {
            domain: process.env.REACT_APP_HOST_NAME,
        });
        const { Role, ...rest } = JwtDecode(token);
        rest.roles =
            Role[0] === 'ROLE_ADMINISTRATOR'
                ? 'Admin'
                : Role[0] === 'ROLE_NUTRITIONIST'
                ? 'Nutritionists'
                : false;
        return token
            ? {
                  ...rest,
                  Role,
                  token: token,
              }
            : false;
    } catch (e) {
        return false;
    }
};
const validation = (input, min, max) => {
    try {
        return !isEmpty(input) &&
            trim(input).length <= max &&
            trim(input).length >= min
            ? false
            : true;
    } catch (error) {
        return true;
    }
};
const {
    VALIDATION: { MIN, MAXINPUT, MAXTEXTAREA },
} = UTILS;
export const validMinMaxInput = (input, min = MIN, max = MAXINPUT) =>
    validation(input, min, max);

export const validMinMaxTextArea = (input, min = MIN, max = MAXTEXTAREA) =>
    validation(input, min, max);
export const validateEmail = (email) => {
    const re = /\S+@\S+\.\S+/;
    return re.test(String(email).toLowerCase());
};
export const validateContact = (contact) => {
    const re = /^[+-]?\d+$/;
    return re.test(String(contact).toLowerCase());
};
export const getParams = (params) => {
    try {
        const result = {};
        new URLSearchParams(params).forEach(
            (val, key) => (result[key] = val !== '' && val)
        );
        const { id, jwt, ...rest } = result;
        if (!isEmpty(id) && !isEmpty(jwt) && isEmpty(rest))
            return { id, token: jwt };
        return false;
    } catch (e) {
        return false;
    }
};
export const getActiveTabDetails = (pathname, tabs) => {
    const [, , curTab] = pathname.split('/');
    const getCurId = indexOf(tabs, curTab);
    return getCurId !== -1
        ? { curTab, getCurId }
        : { curTab: tabs[0], getCurId: 0 };
};
const isMatch = (fName, lName, searchText) => {
    if (trim(searchText) !== '') {
        return (
            lowerCase(`${fName} ${lName}`).indexOf(lowerCase(searchText)) > -1
        );
    } else return true;
};
export const getNutriInfo = (_, searchText, isAdmin) => {
    try {
        if (_ !== undefined) {
            const { lastMessage, attributes } = _;
            let [fName, lName] = isAdmin
                ? attributes?.name.split(' ')
                : attributes?.mName.split(' ');
            return isMatch(fName, lName, searchText)
                ? {
                      uN: `${fName.charAt(0)}${lName.charAt(0)}`,
                      userId: isAdmin ? attributes?.id : attributes?.mId,
                      status: true,
                      userName: `${fName.toUpperCase()} ${lName.toUpperCase()}`,
                      lastMessage: chatTime(lastMessage?.timestamp),
                  }
                : { status: false };
        } else
            return {
                status: false,
            };
    } catch (e) {
        return {
            status: false,
        };
    }
};
export const getUserInfo = (_, category, searchText, isAdmin) => {
    try {
        const { uniqueName = '', lastMessage, attributes } = _;
        const [section] = uniqueName.split('-');
        if (category === section && attributes?.name !== undefined) {
            if (section === 'NUTRITIONISTS') {
                return getNutriInfo(_, searchText, isAdmin);
            } else {
                const [fName, lName] = attributes?.name.split(' ');

                return isMatch(fName, lName, searchText)
                    ? {
                          uN: `${fName.charAt(0)}${lName.charAt(0)}`,
                          userId: attributes?.id,
                          status: true,
                          userName: `${fName.toUpperCase()} ${lName.toUpperCase()}`,
                          lastMessage: chatTime(lastMessage?.timestamp),
                      }
                    : { status: false };
            }
        } else {
            return { status: false };
        }
    } catch (e) {
        return {
            status: false,
        };
    }
};
export const chatDate = (item, array, index) => {
    try {
        const { dateUpdated } = item;
        let msgDateFlag = '';
        const curFormDate = moment(dateUpdated).fromNow();
        if (index > 1) {
            const prevFormDate = moment(
                array[index - 1]['dateUpdated']
            ).fromNow();
            msgDateFlag = curFormDate !== prevFormDate ? curFormDate : null;
        } else msgDateFlag = curFormDate;
        return msgDateFlag;
    } catch (_) {
        return null;
    }
};
export const chatTime = (input) => {
    try {
        return moment(input).format('LT');
    } catch (e) {
        return '---';
    }
};
export const dateFormat = (input) => {
    try {
        return moment(input.replace(/T|-|\/|:/g, ' '), 'YYYY-MM-DD').format(
            'DD-MM-YYYY'
        );
    } catch (e) {
        return '---';
    }
};
export const getFileDetail = (list, filterData, key = 'url', bool = false) => {
    try {
        const [filterList] = filter(list, { name: filterData });
        return !isEmpty(filterList) ? filterList[key] : bool ? false : '';
    } catch (e) {
        return '';
    }
};
const getRoleLabellName = (role, roleDetails) => {
    try {
        const { label } = find(roleDetails, { key: role });
        return label;
    } catch (e) {
        return '--';
    }
};
export const getUserType = (role) => {
    try {
        const userTypes = [
            { label: 'ALL', key: 'Todos' },
            { label: 'UNAFFILIATED', key: 'No afiliados' },
            { label: 'CEAN', key: 'CEAN' },
            { label: 'KETOSTERIL', key: 'Ketosteril' },
        ];
        const { label } = find(userTypes, { key: role });
        return label;
    } catch (e) {
        return 'Todos';
    }
};
export const getRoles = (roles, roleDetails) => {
    try {
        if (isArray(roles)) {
            return map(roles, (role) => getRoleLabellName(role, roleDetails));
        } else return '--';
    } catch (e) {
        return '--';
    }
};
export const isAfflido = (roles, status) => {
    return trim(roles) !== 'CEAN' && status !== undefined && status;
};
export const isNumberExist = (num) => !isNaN(parseFloat(num)) && isFinite(num);
export const getRecordId = (input) => {
    try {
        const params = input.split('/');
        return (
            params.length === 5 && {
                category: params[2],
                id: isNumberExist(params[4]) ? parseInt(params[4]) : 0,
            }
        );
    } catch (e) {
        return 0;
    }
};
export const getNewsId = (input) => {
    try {
        const params = input.split('/');
        return (
            params.length === 4 && {
                category: params[1],
                id: isNumberExist(params[3]) ? parseInt(params[3]) : 0,
            }
        );
    } catch (e) {
        return 0;
    }
};
export const navigatePage = (
    { history },
    alert,
    page,
    updateList = () => {}
) => {
    try {
        if (
            !isEmpty(alert) &&
            alert?.type === 'alert-success' &&
            alert?.message !== 'force loggedin'
        ) {
            setTimeout(() => {
                history.push({ pathname: page });
                updateList();
            }, UTILS.TIMEOUT);
        }
    } catch (e) {
        return null;
    }
};
export const getQuantity = (quantity) => {
    try {
        let quantityWithDecimal = quantity.toString().split('.');

        if (quantityWithDecimal.length > 0) {
            if (quantityWithDecimal[1] === '5')
                return quantityWithDecimal[0] !== '0'
                    ? `${quantityWithDecimal[0]} 1/2`
                    : `1/2`;
            else if (quantityWithDecimal[1] === '33')
                return quantityWithDecimal[0] !== '0'
                    ? `${quantityWithDecimal[0]} 1/3`
                    : '1/3';
            else if (quantityWithDecimal[1] === '25')
                return quantityWithDecimal[0] !== '0'
                    ? `${quantityWithDecimal[0]} 1/4`
                    : '1/4';
            else if (quantityWithDecimal[1] === '2')
                return quantityWithDecimal[0] !== '0'
                    ? `${quantityWithDecimal[0]} 1/5`
                    : '1/5';
            else if (quantityWithDecimal[1] === '66')
                return quantityWithDecimal[0] !== '0'
                    ? `${quantityWithDecimal[0]} 2/3`
                    : '2/3';
            else if (quantityWithDecimal[1] === '75')
                return quantityWithDecimal[0] !== '0'
                    ? `${quantityWithDecimal[0]} 3/4`
                    : '3/4';
            else return quantityWithDecimal[0];
        } else return quantity;
    } catch (_) {
        return quantity;
    }
};
export const downloadFile = ({ data }, fileName = 'MARKETING_CONSENT.csv') => {
    const downloadUrl = window.URL.createObjectURL(new Blob([data]));
    const link = document.createElement('a');
    link.href = downloadUrl;
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();
    link.remove();
};
export const getErrorMsg = (input, params) => {
    try {
        input.errMsg = params[input?.errNo]?.msg ?? input?.errMsg;
        return input;
    } catch (_) {
        return input;
    }
};

export const getFormattedPhone = (phone) => {
    if (process.env.REACT_APP_LANGUAGE === 'es-EC' && phone.length === 14 && phone[4] === '0') {
        const splittedPhone = phone.split('');
        splittedPhone.splice(4, 1);
        return splittedPhone.join('');
    }
    return phone;
}

export const getPromoList = res => {
    const promoList = {
        loading: false,
        boxLoading: false,
        isModalOpen: false,
        isModalLoad: false,
        modalData:{},
        tabs: {},
    }
    const defaultPromo = {
        result: {
            list: [],
            receipts: [],
        },
        boxes: {},
        userData: {},
        params: {},
        recordParams: {},
        pharmaList: [],
    };
    const customPromo = {
        result: {
            list: [
                {
                    title: 'Promocion 15 + 1',
                    description: 'Compre 15 y le daremos 1 caja.',
                    discount: false,
                    edit: false,
                    id: 0,
                },
            ],
        },
        params: {},
    }
    if (res === 'es-PE' || res === 'es-CO' || res === 'es-CL') {
        promoList.tabs["CUSTOM"] = customPromo;
        promoList.tabs.CUSTOM.result.list = [];
    }
    if (res === 'es-EC') {
        promoList.tabs["DEFAULT"] = defaultPromo;
        promoList.tabs["CUSTOM"] = customPromo;
        promoList.tabs.CUSTOM.result.list = [
            {
                title: 'Promoción 4 = 1',
                description: 'Acumule 4 visitas al CEAN y le daremos 1 caja sin costo.',
                discount: false,
                edit: false,
                id: 0,
            }
        ]
    }
    if (res === 'es-MX' || res === 'en') {
        promoList.tabs["DEFAULT"] = defaultPromo;
        promoList.tabs["CUSTOM"] = customPromo;
        promoList.tabs.CUSTOM.result.list = [
            {
                title: 'Promoción 15 + 1',
                description: 'Compre 15 y le daremos 1 caja de regalo.',
                discount: false,
                edit: false,
                id: 0,
            }
        ]
    }
    return promoList;
}

export const getDefaultPromoAvailable = res => {
    if (res === 'es-PE' || res === 'es-CO' || res === 'es-CL') {
        return false;
    }
    return true;
}

export const getCountryCode = () => {
    switch(process.env.REACT_APP_LANGUAGE) {
        case 'es-MX':
            return 'Mexico';
        case 'es-CO':
            return 'Colombia';
        case 'es-CL':
            return 'Chile';
        case 'es-EC':
            return 'Ecuador';
        case 'es-PE':
            return 'Peru';
        default: 
            return 'English';
    }
}
