import React, { useEffect } from 'react';
import './UserInfo.scss';
import { StatusHistory } from '_components/StatusHistory/StatusHistory';
import { getUserInfo, getNutriInfo } from '_utils';
import { Loader, Dimmer } from 'semantic-ui-react';
import { filter, isEmpty } from 'lodash';
const UserInfo = (props) => {
    const {
        config: { userInfo },
        userDetail,
        isAdmin,
        boxhistory,
        tab,
        getBoxes,
        getNutri,
        boxes,
        searchText,
        nutri,
        inviteUsers,
        statusHistoryVisible,
    } = props;
    const { uN, userName, userId } =
        tab === 'NUTRITIONISTS'
            ? getNutriInfo(userDetail?.roomInfo, searchText, isAdmin)
            : getUserInfo(userDetail?.roomInfo, tab, searchText, isAdmin);
    const heading =
        isAdmin && tab === 'NUTRITIONISTS'
            ? userInfo?.headingNutri
            : userInfo?.heading;
    const abc = filter(inviteUsers, { id: parseInt(userId) })[0];
    useEffect(() => {
        if (tab !== 'NUTRITIONISTS' && userId) {
            if (statusHistoryVisible) {
                getBoxes(userId);
            }
            getNutri(userId);
        }
        if (tab === 'NUTRITIONISTS' && userId && !isEmpty(abc) && isAdmin)
            getNutri(abc?.externalId, true, isAdmin);
        if (tab === 'NUTRITIONISTS' && userId && !isAdmin)
            getNutri(userId, true, isAdmin);
    }, [userId, tab, getBoxes, getNutri, isAdmin, abc, statusHistoryVisible]);
    const items = {
        free: boxes?.totalGiftBoxes,
        acceptedtems: boxes?.validBoxes,
        pendingItems: boxes?.pendingBoxes,
    };
    const { loading, result } = nutri;
    return (
        <div className="user-info">
            {userDetail !== undefined && (
                <>
                    <div className="user-info-heading">
                        {isAdmin ? heading : userInfo?.headingNutri}
                    </div>
                    <div className="user-info-details">
                        <div className="profile-img">{uN?.toUpperCase()}</div>
                        <div className="user-info-details-record">
                            <div className="user-info-details-record-name">
                                {userName}
                            </div>
                            <div className="user-info-details-record-id">
                                ID {userId}
                            </div>
                        </div>
                    </div>
                    {tab !== 'NUTRITIONISTS' ? (
                        <>
                            <div className="user-info-record-container">
                                {loading ? (
                                    <Dimmer inverted active>
                                        <Loader />
                                    </Dimmer>
                                ) : (
                                    <>
                                        <div className="user-info-record">
                                            <div className="user-info-record-label">
                                                {userInfo.nutriHeading}
                                            </div>
                                            <div className="user-info-record-value">
                                                {result?.fullName || '--'}
                                            </div>
                                        </div>
                                        <div className="user-info-record">
                                            <div className="user-info-record-label">
                                                {userInfo.mediHeading}
                                            </div>
                                            <div className="user-info-record-value">
                                                {result?.id || '--'}
                                            </div>
                                        </div>
                                        <div className="user-info-record">
                                            <div className="user-info-record-label">
                                                {userInfo.cellularHeading}
                                            </div>
                                            <div className="user-info-record-value">
                                                {result?.phone || '--'}
                                            </div>
                                        </div>
                                        <div className="user-info-record">
                                            <div className="user-info-record-label">
                                                {userInfo.emailHeading}
                                            </div>
                                            <div className="user-info-record-value">
                                                {result?.email || '--'}
                                            </div>
                                        </div>
                                    </>
                                )}
                            </div>
                            {statusHistoryVisible ? <StatusHistory
                                isRejected={false}
                                config={boxhistory}
                                items={items}
                            /> : ''}
                        </>
                    ) : (
                        <div className="user-info-record-container">
                            <div className="user-info-record">
                                <div className="user-info-record-label">
                                    {userInfo.cean}
                                </div>
                                <div className="user-info-record-value">
                                    {(result?.center?.centerName ??
                                        result?.clinicName) ||
                                        '--'}
                                </div>
                            </div>
                            <div className="user-info-record">
                                <div className="user-info-record-label">
                                    {userInfo.cellularHeading}
                                </div>
                                <div className="user-info-record-value">
                                    {(result?.phoneNumber ?? result?.phone) ||
                                        '--'}
                                </div>
                            </div>
                            <div className="user-info-record">
                                <div className="user-info-record-label">
                                    {userInfo.emailHeading}
                                </div>
                                <div className="user-info-record-value">
                                    {result?.email ?? '--'}
                                </div>
                            </div>
                        </div>
                    )}
                </>
            )}
        </div>
    );
};

export { UserInfo };
