import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { chatTime } from '_utils';
import { Image } from '_components';
import AudioMsg from './AudioMsg';
import { isObject } from 'lodash';
const getMediaType = (media) => {
    try {
        const [mediType] = media?.split('/');
        return mediType;
    } catch (error) {
        return false;
    }
};
const ChatContent = (props) => {
    const {
        item: { type, body, dateUpdated, media, author: AU },
        senderName,
        formatDate,
        isAdmin,
        userName: UN,
    } = props;
    const [, author] = AU.split('_');
    const [mediaSrc, setimgLink] = useState(null);
    const initChatContent = useCallback(() => {
        let isMediaImage = true;
        // GET IMG URL;
        if (type === 'media' && mediaSrc === null && isMediaImage) {
            media.getContentUrl().then((url) => setimgLink(url));
        }
        // return () => (isMediaImage = false);
    }, [media, mediaSrc, type]);
    initChatContent();
    const mediaType = getMediaType(media?.contentType);
    let userName = '';
    if (isObject(senderName)) {
        const { mName, name } = senderName;
        const curName = isAdmin
            ? 'ADMIN' === author
                ? mName
                : name
            : 'ADMIN' !== author
            ? mName
            : name;
        const [fName, lName] = curName.split(' ');
        userName = `${fName.charAt(0)}${lName.charAt(0)}`;
    } else {
        if ('ADMIN' !== author) {
            const [fName, lName] = senderName.split(' ');
            userName = `${fName.charAt(0)}${lName.charAt(0)}`;
        } else {
            userName = UN;
        }
    }
    const getClassName = () => {
        if (isAdmin) {
            return 'ADMIN' === author ? 'chat-msg-out' : 'chat-msg-in';
        } else return 'ADMIN' !== author ? 'chat-msg-out' : 'chat-msg-in';
    };
    return (
        <>
            {formatDate !== null && (
                <div className="chat-msg-date"> {formatDate} </div>
            )}
            <div className={getClassName()}>
                {type === 'text' && <div className="chat-msg-text">{body}</div>}
                {type === 'media' &&
                    mediaType === 'image' &&
                    mediaSrc !== null && (
                        <div className="chat-msg-img">
                            <Image src={mediaSrc} />
                        </div>
                    )}
                {type === 'media' &&
                    mediaType === 'audio' &&
                    mediaSrc !== null && (
                        <AudioMsg audioSrc={mediaSrc} size={media.size} />
                    )}
                <div className="chat-msg-user">
                    <div className="profile-img">{userName.toUpperCase()}</div>
                </div>
                {mediaType !== 'audio' && (
                    <div className="chat-msg-time">{chatTime(dateUpdated)}</div>
                )}
            </div>
        </>
    );
};

ChatContent.propTypes = {
    item: PropTypes.object.isRequired,
    curMember: PropTypes.string.isRequired,
};

export default ChatContent;
