import React from 'react';
import { Modal, Button } from 'semantic-ui-react';
import { Image } from '_components/Image';
import { PhoneView } from 'images';
//import PropTypes from 'prop-types';

const ViewModal = (props) => {
    const { button, handleClose, isModalOpen, content } = props;
    return (
        <Modal
            onClose={handleClose}
            size="mini"
            trigger={button()}
            className="view-modal"
            open={isModalOpen}
            dimmer="blurring"
        >
            <div className="view-modal-container">
                <div className="view-modal-image">
                    <Image src={PhoneView} />
                </div>
                <div className="view-modal-content">
                    <div className="view-modal-content-banner">
                        {content?.imageUrl && <Image src={content?.imageUrl} />}
                    </div>
                    <div className="view-modal-btn">
                        <Button className="btn outline" type="button">
                            Ver video
                        </Button>
                    </div>
                    <div className="view-modal-content-box">
                        <div className="view-modal-content-heading">
                            {content?.title}
                        </div>
                        <div
                            className="view-modal-content-desc"
                            dangerouslySetInnerHTML={{ __html: content?.desc }}
                        />
                    </div>
                </div>
            </div>
        </Modal>
    );
};

ViewModal.propTypes = {};

export default ViewModal;
