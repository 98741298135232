import { batch } from 'react-redux';
import CHAT from '_constants';
import { chatService } from '_services';
import { map, filter, isEmpty } from 'lodash';
import { lang } from '_config';
import { getErrorMsg } from '_utils';
const {
    menuList: {
        error: {
            patient: { nutritionist },
        },
    },
} = lang;
// get token from third party
const getToken = (_) => {
    return (dispatch) => {
        // ON ERROR OCCURED
        const onError = (error) => {
            batch(() => {
                dispatch({
                    type: CHAT.CHAT_TOKEN_FAIL,
                    faliure: error ?? null,
                });
                dispatch({ type: CHAT.ERROR, message: error });
                setTimeout(() => dispatch({ type: CHAT.CLEAR }), CHAT.TIMEOUT);
            });
        };
        // START REQUEST SETUP
        batch(() => {
            dispatch({ type: CHAT.CHAT_REQUEST_START });
            dispatch({ type: CHAT.CLEAR });
        });
        // REQUEST START
        chatService
            .getToken()
            .then(
                (result) => {
                    // REQUEST SUCCESS
                    if (result && result?.status) {
                        if (_ !== undefined) {
                            _.updateToken(result?.chatToken);
                        }
                        dispatch({
                            type: CHAT.CHAT_TOKEN_SET,
                            result,
                        });
                    } else {
                        // REQUEST FAILURE
                        onError(result);
                    }
                },
                // REQUEST FAILURE
                (err) => onError(err)
            )
            // HANDEL ANY WEIRED BEHAVIOUR(LIKE NETWORK OR ANY OTHER)
            .catch(onError)
            .finally(() => {
                // REQUEST ENDED
                dispatch({ type: CHAT.CHAT_REQUEST_END });
            });
    };
};
const setRoomInfo = (selectedUser) => {
    const { roomInfo, curIndex, memberInfo, category } = selectedUser;
    return async (dispatch) => {
        if (roomInfo.status === 'invited') await roomInfo.join();
        const unreadMsgCount = await roomInfo.getUnconsumedMessagesCount();
        if (unreadMsgCount) {
            const msgIndex = await roomInfo.getMessagesCount();
            await roomInfo.updateLastConsumedMessageIndex(msgIndex);
        }
        const myRoom =
            roomInfo?.getMessages === undefined
                ? roomInfo.messagesEntity.channel
                : roomInfo;
        myRoom
            .getMessages(100)
            .then((res) => {
                const { items } = res;
                const params = { category };

                const result = {
                    msgs: map(items, 'state'),
                    room: {
                        curIndex,
                        roomInfo: {
                            roomID: myRoom.sid,
                            ...myRoom?.channelState,
                        },
                        memberInfo,
                    },
                };
                dispatch({ type: CHAT.CHAT_SELECTED_ID, params, result });
            })
            .catch(() => {});
    };
};
const updateMsgHistory = (res) => {
    const { msg, sid, tab: category } = res;
    return (dispatch, getState) => {
        const {
            chatList: {
                tabs: {
                    [category]: {
                        chatHistory,
                        selectedId: {
                            roomInfo: { roomID },
                        },
                    },
                },
            },
        } = getState();
        if (sid === roomID) {
            const existMsg = filter(chatHistory, { index: msg.index });
            if (existMsg.length === 0) {
                const params = { category };
                const result = msg;
                dispatch({
                    type: CHAT.CHAT_UPDATE_MSG_HISTORY,
                    params,
                    result,
                });
            }
        }
    };
};
const updatePrevMsgHistory = (res) => {
    const { items, tab: category } = res;
    return (dispatch) => {
        const msgs = map(items, 'state');
        const params = { category };
        dispatch({
            type: CHAT.CHAT_UPDATE_PREV_MSG_HISTORY,
            params,
            result: msgs,
        });
    };
};
const notificaion = (_) => {
    return (dispatch) => {
        // ON ERROR OCCURED
        const onError = (error) => {
            batch(() => {
                dispatch({
                    type: CHAT.CHAT_NOTIF_FAIL,
                    faliure: error ?? null,
                });
                dispatch({ type: CHAT.ERROR, message: error });
                setTimeout(() => dispatch({ type: CHAT.CLEAR }), CHAT.TIMEOUT);
            });
        };
        // START REQUEST SETUP
        batch(() => {
            dispatch({ type: CHAT.CHAT_NOTIF_START });
            dispatch({ type: CHAT.CLEAR });
        });
        // REQUEST START
        chatService
            .sendNotificaiton(_)
            .then(
                (result) => {
                    // REQUEST SUCCESS
                    if (result && result?.status) {
                        dispatch({
                            type: CHAT.CHAT_NOTIF_SUCCESS,
                            // result,
                        });
                    } else {
                        // REQUEST FAILURE
                        onError(result);
                    }
                },
                // REQUEST FAILURE
                (err) => onError(err)
            )
            // HANDEL ANY WEIRED BEHAVIOUR(LIKE NETWORK OR ANY OTHER)
            .catch(onError)
            .finally(() => {
                // REQUEST ENDED
                dispatch({ type: CHAT.CHAT_NOTIF_END });
            });
    };
};

const calling = (data) => {
    return { type: CHAT.CHAT_CALLING_START, result: data };
};
const endCall = () => {
    return { type: CHAT.CHAT_CALLING_END, result: {} };
};
const startAudioMsg = (tab) => {
    return {
        type: CHAT.CHAT_RECORDING_START,
        params: { category: tab },
    };
};
const stopAudioMsg = (_) => {
    const { tab } = _;
    return {
        type: CHAT.CHAT_RECORDING_STOP,
        params: { category: tab },
    };
};
// GET NUTRI DETAILS
const getNutri = (id, flag, isAdmin) => {
    return (dispatch, getState) => {
        const {
            patientList: { loading },
            alertData,
        } = getState();
        // if allready fetching the record
        if (!loading) {
            const onError = (faliure) => {
                batch(() => {
                    const cMsg = getErrorMsg(faliure, nutritionist?.get);
                    dispatch({
                        type: CHAT.CHAT_NUTRI_FAILURE,
                        faliure: flag && isAdmin ? cMsg : faliure,
                    });
                    dispatch({
                        type: CHAT.ERROR,
                        message: flag && isAdmin ? cMsg : faliure,
                    });

                    setTimeout(
                        () => dispatch({ type: CHAT.CLEAR }),
                        CHAT.TIMEOUT
                    );
                });
            };
            // START REQUEST SETUP
            batch(() => {
                if (!isEmpty(alertData)) dispatch({ type: CHAT.CLEAR });
                dispatch({ type: CHAT.CHAT_NUTRI_START });
            });
            // REQUEST START
            chatService
                .getNutriDetails(id, flag, isAdmin)
                .then(
                    (result) => {
                        // REQUEST SUCCESS
                        if (result && result?.status) {
                            dispatch({
                                type: CHAT.CHAT_NUTRI_SUCCESS,
                                result,
                            });
                        } else {
                            // REQUEST FAILURE WITH BAD PARAMS
                            onError(result);
                        }
                    },
                    (err) => onError(err)
                )
                // HANDEL ANY WEIRED BEHAVIOUR(LIKE NETWORK OR ANY OTHER)
                .catch(onError)
                .finally(() => {
                    // REQUEST ENDED
                    batch(() => {
                        dispatch({ type: CHAT.CHAT_NUTRI_END });
                    });
                });
        }
    };
};
// SET KABICENTER FOR COLOMBIA COUNTRY
const setKabiCenter = (value) => {
    return {
        type: CHAT.CHAT_SET_KABICENTER,
        result: value,
    };
}
const updateChatOnCenterChange = () => {
    return dispatch => dispatch({
        type: CHAT.CHAT_CHANGE_CENTER, params: {category: 'PATIENTS'}
    });
}
export const chatActions = {
    getToken,
    calling,
    notificaion,
    endCall,
    setRoomInfo,
    updatePrevMsgHistory,
    updateMsgHistory,
    startAudioMsg,
    stopAudioMsg,
    getNutri,
    setKabiCenter,
    updateChatOnCenterChange,
};
