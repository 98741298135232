import INFO from '_constants';
import { INIT } from '_config/initialState';
const { infoList: initialState } = INIT;
export default (state = initialState, action = {}) => {
    const { type, result, params, faliure } = action;
    switch (type) {
        case INFO.INFO_REQUEST_START:
            return { ...state, loading: true };
        case INFO.INFO_REQUEST_END:
            return { ...state, loading: false };
        case INFO.INFO_SELECTED_ID: {
            return {
                ...state,
                selectedId: result,
            };
        }
        case INFO.INFO_LIST_SUCCESS: {
            const activeTab = params?.category;
            return {
                ...state,
                faliure: null,
                tabs: {
                    ...state.tabs,
                    [activeTab]: {
                        result: params?.pageNumber
                            ? {
                                  ...result,
                                  list: [
                                      ...state.tabs[activeTab].result.list,
                                      ...result.list,
                                  ],
                                  faliure: null,
                              }
                            : {
                                  ...result,
                                  list: [...result.list],
                                  faliure: null,
                              },
                        params,
                    },
                },
            };
        }
        case INFO.INFO_CLEAR_FAILURE: {
            const activeTab = params?.category;
            return {
                ...state,
                faliure: null,
                tabs: {
                    ...state.tabs,
                    [activeTab]: { ...state.tabs[activeTab], faliure: null },
                },
            };
        }
        case INFO.INFO_ADD_FAILURE:
        case INFO.INFO_DEL_FAILURE:
        case INFO.INFO_EDIT_FAILURE: {
            const activeTab = params?.category;
            return {
                ...state,
                tabs: {
                    ...state.tabs,
                    [activeTab]: { ...state.tabs[activeTab], faliure },
                },
            };
        }
        case INFO.INFO_LIST_FAILURE:
            return { ...state, faliure };
        default:
            return { ...state };
    }
};
export const infoDetails = (state) => state.infoList;
