/* eslint-disable react/no-multi-comp */
import React from 'react';
import { Grid, Icon } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import './GridHeading.scss';
let iconName = 'angle down';
const GridSortHeading = (props) => {
    const { sorting, handelSorting, column, params, loading } = props;
    if (sorting && sorting.direction === 'DESC') {
        iconName = 'angle down';
    } else iconName = 'angle up';
    if (params.orderBy === column) {
        if (params.direction === 'DESC') {
            iconName = 'angle down';
        }
        if (params.direction === 'ASC') {
            iconName = 'angle up';
        }
    }
    const handelArrow = () => {
        if (sorting && !loading) {
            const direction = params.direction === 'ASC' ? 'DESC' : 'ASC';
            handelSorting({ direction, orderBy: column });
        }
    };
    return (
        <div className="filter-heading" onClick={handelArrow}>
            <div className="sorting-icon">
                <Icon name="attach" size="tiny" color="blue" />
                <Icon name={iconName} />
            </div>
        </div>
    );
};
const GridHeading = (props) => {
    const { width, cellText, sorting, handelSorting, column, params } = props;
    iconName = 'angle down';
    if (sorting && sorting.direction === 'DESC') {
        iconName = 'angle down';
    } else iconName = 'angle up';
    if (params.orderBy === column) {
        if (params.direction === 'DESC') {
            iconName = 'angle down';
        }
        if (params.direction === 'ASC') {
            iconName = 'angle up';
        }
    }

    const handelArrow = () => {
        if (sorting && !props?.loading) {
            const direction = params.direction === 'ASC' ? 'DESC' : 'ASC';
            handelSorting({ direction, orderBy: column });
        }
    };

    return (
        <Grid.Column
            width={width ?? 4}
            className={sorting ? 'grid-heading sorting' : 'grid-heading'}
            textAlign={cellText === '' ? 'right' : 'left'}
            onClick={handelArrow}
        >
            {cellText}
            {sorting && (
                <div className="sorting-icon">
                    <Icon name={iconName} />
                </div>
            )}
        </Grid.Column>
    );
};

GridHeading.propTypes = {
    width: PropTypes.number.isRequired,
    cellText: PropTypes.string.isRequired,
    column: PropTypes.string.isRequired,
    params: PropTypes.object.isRequired,
    handelSorting: PropTypes.func.isRequired,
    sorting: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]).isRequired,
};

export { GridHeading, GridSortHeading };
