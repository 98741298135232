import { batch } from 'react-redux';
import PATIENT from '_constants';
import { patientService } from '_services';
import { isEmpty } from 'lodash';
import { lang } from '_config';
import { patientDetailActions } from './patientDetails.action';
const {
    menuList: {
        messages,
        error: {
            patient: { affiliation },
        },
    },
} = lang;
const getAll = (params) => {
    const queryParams = {
        pageNumber: params?.pageNumber ?? 0,
        direction: params?.direction ?? 'DESC',
        orderBy: params?.orderBy ?? 'NAME',
        filter: params?.filter ?? 'ALL',
        activeUserType: params?.activeUserType ?? 'Todos',
        search: params?.search ?? '',
        promotionParticipated: false,
    };
    return (dispatch, getState) => {
        const {
            alertData,
            patientList: { loading },
        } = getState();
        // if allready fetching the record
        if (!loading) {
            const onError = (faliure) => {
                batch(() => {
                    dispatch({
                        type: PATIENT.PATIENT_LIST_FAILURE,
                        faliure,
                    });
                    dispatch({
                        type: PATIENT.ERROR,
                        message: faliure,
                    });
                    setTimeout(
                        () => dispatch({ type: PATIENT.CLEAR }),
                        PATIENT.TIMEOUT
                    );
                });
            };
            // START REQUEST SETUP
            batch(() => {
                dispatch({ type: PATIENT.PATIENT_REQUEST_START });
                if (!isEmpty(alertData)) dispatch({ type: PATIENT.CLEAR });
            });
            // REQUEST START
            patientService
                .getAll(queryParams)
                .then(
                    (result) => {
                        // REQUEST SUCCESS
                        if (result && result?.status) {
                            dispatch({
                                type: PATIENT.PATIENT_LIST_SUCCESS,
                                result,
                                params: queryParams,
                            });
                        } else {
                            // REQUEST FAILURE WITH BAD PARAMS
                            onError(result);
                        }
                    },
                    (err) => onError(err)
                )
                // HANDEL ANY WEIRED BEHAVIOUR(LIKE NETWORK OR ANY OTHER)
                .catch(onError)
                .finally(() => {
                    // REQUEST ENDED
                    dispatch({ type: PATIENT.PATIENT_REQUEST_END });
                });
        }
    };
};
// GET BILL DETAILS
const getTicketDetails = (id) => {
    return (dispatch, getState) => {
        const {
            patientList: { loading, isModalOpen, isModalLoad },
            alertData,
        } = getState();
        // if allready fetching the record
        if (!loading) {
            const onError = (faliure) => {
                batch(() => {
                    dispatch({
                        type: PATIENT.PATIENT_TICKET_FAILURE,
                        faliure,
                    });
                    dispatch({
                        type: PATIENT.ERROR,
                        message: faliure,
                    });
                    setTimeout(
                        () => dispatch({ type: PATIENT.CLEAR }),
                        PATIENT.TIMEOUT
                    );
                });
            };
            // START REQUEST SETUP
            batch(() => {
                if (!isEmpty(alertData)) dispatch({ type: PATIENT.CLEAR });
                if (!isModalOpen)
                    dispatch({ type: PATIENT.PATIENT_MODAL_OPEN });
                if (!isModalLoad)
                    dispatch({ type: PATIENT.PATIENT_MODAL_LOADING });
            });
            // REQUEST START
            patientService
                .getTicketId(id)
                .then(
                    (result) => {
                        // REQUEST SUCCESS
                        if (result && result?.status) {
                            dispatch({
                                type: PATIENT.PATIENT_TICKET_SUCCESS,
                                result,
                                params: id,
                            });
                        } else {
                            // REQUEST FAILURE WITH BAD PARAMS
                            onError(result);
                        }
                    },
                    (err) => onError(err)
                )
                // HANDEL ANY WEIRED BEHAVIOUR(LIKE NETWORK OR ANY OTHER)
                .catch(onError)
                .finally(() => {
                    // REQUEST ENDED
                    batch(() => {
                        dispatch({ type: PATIENT.PATIENT_MODAL_LOADED });
                    });
                });
        }
    };
};
const updateTicket = (_, isValid) => {
    return (dispatch, getState) => {
        const {
            patientList: { loading, isModalOpen, isModalLoad, params },
            alertData,
        } = getState();
        const { upgradeParams, ...data } = _;
        // if allready fetching the record
        if (!loading) {
            const onError = (faliure) => {
                batch(() => {
                    dispatch({
                        type: PATIENT.PATIENT_TICKET_FAILURE,
                        faliure,
                    });
                    dispatch({
                        type: PATIENT.ERROR,
                        message: faliure,
                    });
                    setTimeout(
                        () => dispatch({ type: PATIENT.CLEAR }),
                        PATIENT.TIMEOUT
                    );
                });
            };
            // START REQUEST SETUP
            batch(() => {
                if (!isEmpty(alertData)) dispatch({ type: PATIENT.CLEAR });
                if (!isModalOpen)
                    dispatch({ type: PATIENT.PATIENT_MODAL_OPEN });
                if (!isModalLoad)
                    dispatch({ type: PATIENT.PATIENT_MODAL_LOADING });
            });
            // REQUEST START
            patientService
                .updateTicket(data)
                .then(
                    (result) => {
                        // REQUEST SUCCESS
                        if (result && result?.status) {
                            batch(() => {
                                dispatch({
                                    type: PATIENT.SUCCESS,
                                    message: {
                                        suc:
                                            affiliation[200]?.msg ??
                                            messages?.update,
                                    },
                                });
                                setTimeout(() => {
                                    if (isValid === undefined)
                                        dispatch(getAll(params));
                                    if (isValid === true)
                                        dispatch(
                                            patientDetailActions.upgradeHistory(
                                                upgradeParams
                                            )
                                        );
                                }, PATIENT.TIMEOUT);
                            });
                        } else {
                            // REQUEST FAILURE WITH BAD PARAMS
                            onError(result);
                        }
                    },
                    (err) => onError(err)
                )
                // HANDEL ANY WEIRED BEHAVIOUR(LIKE NETWORK OR ANY OTHER)
                .catch(onError)
                .finally(() => {
                    // REQUEST ENDED
                    batch(() => {
                        dispatch({ type: PATIENT.PATIENT_MODAL_LOADED });
                        dispatch({ type: PATIENT.PATIENT_MODAL_CLOSE });
                    });
                });
        }
    };
};
//OPEN MODAL
const openModal = (modalData) => {
    return (dispatch, getState) => {
        const {
            patientList: { isModalOpen },
        } = getState();
        if (!isModalOpen) dispatch({ type: PATIENT.PATIENT_MODAL_OPEN,modalData });
    };
};
//close MODAL
const closeModal = () => {
    return (dispatch, getState) => {
        const {
            patientList: { isModalOpen },
        } = getState();
        if (isModalOpen) dispatch({ type: PATIENT.PATIENT_MODAL_CLOSE });
    };
};
export const patientActions = {
    getAll,
    getTicketDetails,
    updateTicket,
    closeModal,
    openModal,
};
