import React from 'react';
import PropTypes from 'prop-types';
import { Switch, Route, useRouteMatch, Redirect } from 'react-router-dom';
import { ProList, ProView } from './Pro';
import { CustomList } from './Custom';
import CustomEdit from './Custom/CustomEdit/CustomEdit';
import CustomAdd from './Custom/CustomAdd/CustomAdd';
import { NotFoundPage } from '_pages';
import { getDefaultPromoAvailable } from '_utils';
const ProRoutes = (props) => {
    const { path } = useRouteMatch();
    const { config, activeTab } = props;
    let defaultAvailable = getDefaultPromoAvailable(process.env.REACT_APP_LANGUAGE);
    return (
        <Switch>
            <Route exact path={path}>
                <Redirect from={path} to={`${path}/${activeTab.tab}`} />
            </Route>
            <Route exact path={`${path}/:tabID`}>
                {activeTab.tab === 'DEFAULT' && defaultAvailable && (
                    <ProList {...props} config={{ ...config, activeTab }} />
                )}
                {activeTab.tab === 'CUSTOM' && <CustomList {...props} />}
            </Route>
            <Route exact path={`${path}/:tabID/id/:id`}>
                {activeTab.tab === 'DEFAULT' && <ProView {...props} />}
                {activeTab.tab === 'CUSTOM' && <CustomEdit {...props} />}
            </Route>
            <Route exact path={`${path}/:tabID/Add`}>
                <CustomAdd {...props} />
            </Route>
            <Route from={`${path}/*`} component={NotFoundPage} />
        </Switch>
    );
};

ProRoutes.propTypes = {
    config: PropTypes.object.isRequired,
    activeTab: PropTypes.object.isRequired,
};

export default ProRoutes;
