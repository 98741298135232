import React, { useEffect } from 'react';
import { Button, Form, Grid, Icon } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { isEmpty } from 'lodash';
import {
    PublicLayout as Layout,
    Image,
    Message,
    Notification,
    Input,
} from '_components';
import { LoginBanner, ketoLogo, Logo } from 'images';
import { lang } from '_config';
import {
    getParams,
    getUserDetailsFromToken,
    getUserID,
    validMinMaxInput,
    validMinMaxTextArea,
} from '_utils';
const ResetPwdPage = (props) => {
    const userData = getParams(props?.location?.search);
    let userDetails = {};
    if (!userData) {
        props.history.push('login');
    } else {
        userDetails = getUserID(userData?.id);
        if (!userDetails || !getUserDetailsFromToken(userData?.token)) {
            props.history.push('login');
        }
    }
    const { resetPwd: configLogin } = lang,
        { register, handleSubmit, watch, errors } = useForm(),
        watchAllFields = watch(['pwd', 'rpwd']),
        { pwdField, rpwdField, buttonField, msg } = configLogin,
        frmData = {
            fields: [
                {
                    lable: pwdField.lableName,
                    validation: register,
                    className: 'ui form-password',
                    type: 'password',
                    name: 'pwd',
                },
                {
                    lable: rpwdField.lableName,
                    validation: register,
                    className: 'ui form-password',
                    type: 'password',
                    name: 'rpwd',
                },
            ],
        },
        {
            actions: { msgClear, msgErr, reqResetPwd },
            state: { forgotData, alertData },
        } = props,
        validateForm = () => {
            const { pwd, rpwd } = watchAllFields;
            return !validMinMaxInput(pwd) &&
                !validMinMaxTextArea(rpwd) &&
                rpwd === pwd &&
                isEmpty(errors)
                ? false
                : true;
        },
        onSubmit = (data) => {
            userDetails &&
                reqResetPwd({
                    jwt: userData?.token ?? '',
                    newPassword: data?.pwd ?? '',
                    confirmPassword: data?.rpwd ?? '',
                    id: userData?.id ?? '',
                });
        },
        Notifi = () => {
            if (
                alertData?.type === 'alert-success' &&
                alertData?.message === 'UpdatePwd'
            ) {
                const myMsg = msg.success.replace(/#{email}/g, userDetails);
                return <Notification text={myMsg} className="success" />;
            }
        };
    useEffect(() => {
        return () => {
            msgClear();
        };
    }, [msgClear]);
    if (!userDetails && isEmpty(alertData)) {
        msgErr('INVALID REQUEST');
    }
    return (
        <Layout {...props} className="forgot-page" header="Login Page">
            <Grid className="ForgotPage-grid">
                <Grid.Row>
                    <Grid.Column
                        width={8}
                        className="col-image"
                        only="computer"
                    >
                        <div className="image-container">
                            <Link to="/login">
                                <Icon
                                    circular
                                    name="arrow left"
                                    size="huge"
                                    className="back-icon"
                                />
                            </Link>
                            <Image src={LoginBanner} fluid />
                        </div>
                    </Grid.Column>
                    <Grid.Column width={8} className="col-form">
                        <div className="image-container">
                            <Image src={Logo} fluid />
                        </div>
                        <div className="form-container">
                            <div className="form-notification">{Notifi()} </div>
                            <div className="form-header">
                                <Image
                                    src={ketoLogo}
                                    className="form-header--logo"
                                />
                            </div>
                            <div className="form-content">
                                <h2 className="header">
                                    {configLogin?.text?.headingText}
                                </h2>
                                <div className="form-header-sub">
                                    {configLogin?.text?.subHeadingText.replace(
                                        /#{email}/g,
                                        userDetails
                                    )}
                                </div>
                                <Form onSubmit={handleSubmit(onSubmit)}>
                                    {alertData.message && (
                                        <Message
                                            alertData={alertData}
                                            loginData={forgotData}
                                        />
                                    )}
                                    {frmData.fields.map((field, i) => {
                                        return (
                                            <Form.Field key={i}>
                                                <Input
                                                    type={field.type}
                                                    label={field.lable}
                                                    name={field.name}
                                                    placeholder={
                                                        field.placeHolder
                                                    }
                                                    disabled={!userDetails}
                                                    refs={{
                                                        register:
                                                            field.validation,
                                                    }}
                                                />
                                            </Form.Field>
                                        );
                                    })}
                                    <Button
                                        loading={forgotData.loading}
                                        type="submit"
                                        className="form-button"
                                        disabled={validateForm()}
                                    >
                                        {buttonField.lableName}
                                    </Button>
                                </Form>
                            </div>
                        </div>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </Layout>
    );
};

export { ResetPwdPage };
