import React, { useState } from 'react';
import { Form, Modal, Icon, Button } from 'semantic-ui-react';
import { TextArea } from '_components/Core/TextArea/TextArea';
import { isEmpty } from 'lodash';
import { validMinMaxInput } from '_utils';
import { ConfirmModal } from '_components/ConfirmModal/ConfirmModal';

const AddNote = (props) => {
    const {
        onSubmit,
        handelNote,
        loading,
        config,
        register,
        watchAllFields,
        errors,
        showError,
        notesDefault,
    } = props;
    // IS FORM VALID
    const { openNotes } = showError;
    const { notes } = watchAllFields;
    const validateForm = () => {
        return !validMinMaxInput(notes, undefined, 100) && isEmpty(errors)
            ? false
            : true;
    };
    const [isModalOpen, setModal] = useState(false);
    // Close Modal
    const handleClose = () => {
        setModal(false);
    };
    // Close Modal
    const handleOpen = () => {
        setModal(true);
    };
    return (
        <div className="add-notes">
            <Form autoComplete="off" unstackable>
                <Modal.Header>
                    <Icon
                        size="large"
                        name="arrow left"
                        color="blue"
                        className="icon-link"
                        onClick={handelNote}
                        disabled={loading}
                    />
                    <div className="header-note">
                        {config?.notes?.headingText}
                    </div>
                </Modal.Header>
                <Modal.Content className="ui form">
                    <div className="notes-container">
                        <TextArea
                            name="notes"
                            rows={25}
                            refs={{
                                register,
                                validation: {
                                    maxLength: 100,
                                },
                            }}
                            disabled={openNotes}
                            count
                            placeholder={config?.notes?.notesPlaceholder}
                            value={notesDefault}
                        />
                    </div>
                    <div className="notes-container-button">
                        <ConfirmModal
                            config={config?.modal?.invalid}
                            handleClose={handleClose}
                            button={() => (
                                <Button
                                    disabled={validateForm()}
                                    size="large"
                                    loading={loading}
                                    onClick={handleOpen}
                                >
                                    {config?.notes?.actionLable}
                                </Button>
                            )}
                            actionButton={() => onSubmit({ notes })}
                            isModalOpen={isModalOpen}
                            loading={loading}
                        />
                    </div>
                </Modal.Content>
            </Form>
        </div>
    );
};

export default AddNote;
