import React from 'react';
import { Switch, Route, useRouteMatch, Redirect } from 'react-router-dom';
import { InfoList } from './Info';
import InfoEdit from './Info/InfoEdit/InfoEdit';
import InfoAdd from './Info/InfoAdd/InfoAdd';
// import { NotFoundPage } from '_pages';
const InfoRoutes = (props) => {
    const { path } = useRouteMatch();
    const { config, activeTab } = props;
    return (
        <Switch>
            <Route exact path={path}>
                <Redirect to={`${path}/${activeTab.tab}`} />
            </Route>
            <Route exact path={`${path}/:tabID`}>
                <InfoList {...props} config={{ ...config, activeTab }} />
            </Route>
            <Route exact path={`${path}/:tabID/id/:id`}>
                <InfoEdit {...props} />
            </Route>
            <Route exact path={`${path}/:tabID/Add`}>
                <InfoAdd {...props} />
            </Route>
        </Switch>
    );
};

export default InfoRoutes;
