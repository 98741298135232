import TIPS from '_constants';
import { INIT } from '_config/initialState';
const { tips: initialState } = INIT;
export default (state = initialState, { type, result, params, faliure }) => {
    switch (type) {
        case TIPS.TIPS_REQUEST_START:
            return { ...state, loading: true };
        case TIPS.TIPS_REQUEST_END:
            return { ...state, loading: false };
        case TIPS.TIPS_LIST_SUCCESS: {
            const activeTab = params?.category;
            return {
                ...state,
                faliure: null,
                tabs: {
                    ...state.tabs,
                    [activeTab]: {
                        result: params?.pageNumber
                            ? {
                                  ...result,
                                  list: [
                                      ...state.tabs[activeTab].result.list,
                                      ...result.list,
                                  ],
                              }
                            : {
                                  ...result,
                                  list: [...result.list],
                              },
                        params,
                    },
                },
            };
        }
        case TIPS.TIPS_SELECTED_ID: {
            return {
                ...state,
                selectedId: result,
            };
        }
        case TIPS.TIPS_CLEAR_FAILURE: {
            const activeTab = params?.category;
            return {
                ...state,
                faliure: null,
                tabs: {
                    ...state.tabs,
                    [activeTab]: { ...state.tabs[activeTab], faliure: null },
                },
            };
        }
        case TIPS.TIPS_ADD_FAILURE:
        case TIPS.TIPS_DEL_FAILURE:
        case TIPS.TIPS_EDIT_FAILURE: {
            const activeTab = params?.category;
            return {
                ...state,
                tabs: {
                    ...state.tabs,
                    [activeTab]: { ...state.tabs[activeTab], faliure },
                },
            };
        }
        case TIPS.TIPS_LIST_FAILURE:
            return { ...state, faliure };

        case TIPS.NUTR_FOODLIST_SUCCESS: {
            const { list, ...rest } = result;
            return {
                ...state,
                faliure: null,
                tabs: {
                    ...state.tabs,
                    NUTRITIONAL: {
                        ...state.tabs.NUTRITIONAL,
                        foodList:
                            result?.pageNumber === 0
                                ? result
                                : {
                                      ...rest,
                                      list: [
                                          ...list,
                                          ...state.tabs.NUTRITIONAL.foodList
                                              .list,
                                      ],
                                  },
                    },
                },
            };
        }
        case TIPS.TIPS_RECORD_REQUEST_START: {
            return {
                ...state,
                tabs: {
                    ...state.tabs,
                    NUTRITIONAL: {
                        ...state.tabs.NUTRITIONAL,
                        loading: true,
                    },
                },
            };
        }
        case TIPS.TIPS_RECORD_REQUEST_END: {
            return {
                ...state,
                tabs: {
                    ...state.tabs,
                    NUTRITIONAL: {
                        ...state.tabs.NUTRITIONAL,
                        loading: false,
                    },
                },
            };
        }
        case TIPS.NUTR_FOODCAT_SUCCESS: {
            return {
                ...state,
                faliure: null,
                tabs: {
                    ...state.tabs,
                    NUTRITIONAL: {
                        ...state.tabs.NUTRITIONAL,
                        foodCategory: result,
                    },
                },
            };
        }

        case TIPS.NUTR_FOODUNIT_SUCCESS: {
            return {
                ...state,
                faliure: null,
                tabs: {
                    ...state.tabs,
                    NUTRITIONAL: {
                        ...state.tabs.NUTRITIONAL,
                        unity: result,
                    },
                },
            };
        }
        case TIPS.NUTR_DELETEFOOD_SUCCESS:
        case TIPS.NUTR_UPDATEFOOD_SUCCESS:
        case TIPS.NUTR_FOODCREATE_SUCCESS: {
            return {
                ...state,
                faliure: null,
                tabs: {
                    ...state.tabs,
                    NUTRITIONAL: {
                        ...state.tabs.NUTRITIONAL,
                        status: true,
                    },
                },
            };
        }
        case TIPS.NUTR_REQUEST_START: {
            return {
                ...state,
                faliure: null,
                tabs: {
                    ...state.tabs,
                    NUTRITIONAL: {
                        ...state.tabs.NUTRITIONAL,
                        status: false,
                        loading: true,
                    },
                },
            };
        }

        case TIPS.NUTR_FOODCREATE_FAILURE: {
            return {
                ...state,
                faliure: null,
                tabs: {
                    ...state.tabs,
                    NUTRITIONAL: {
                        ...state.tabs.NUTRITIONAL,
                        status: false,
                    },
                },
            };
        }
        case TIPS.NUTR_GETFOOD_SUCCESS: {
            let arr = [];
            arr.push(result);
            return {
                ...state,
                faliure: null,
                tabs: {
                    ...state.tabs,
                    NUTRITIONAL: {
                        ...state.tabs.NUTRITIONAL,
                        foodList: { list: arr },
                    },
                },
            };
        }

        case TIPS.NUTR_FAILURE:
            return {
                ...state,
                faliure: null,
                tabs: {
                    ...state.tabs,
                    NUTRITIONAL: {
                        ...state.tabs.NUTRITIONAL,
                        faliure,
                    },
                },
            };

        case TIPS.NUTR_REQUEST_END:
            return {
                ...state,
                faliure: null,
                tabs: {
                    ...state.tabs,
                    NUTRITIONAL: {
                        ...state.tabs.NUTRITIONAL,
                        status: false,
                        loading: false,
                    },
                },
            };
        default:
            return { ...state };
    }
};
export const tipsDetails = (state) => state.tips;
