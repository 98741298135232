import React from 'react';
import PropTypes from 'prop-types';
import { Popup as PopupSemantic } from 'semantic-ui-react';
const Popup = (props) => {
    const { content, button, position, className, on, size, inverted } = props;
    return (
        <PopupSemantic
            className={className}
            trigger={button}
            content={content}
            position={position}
            on={on}
            size={size}
            hideOnScroll
            inverted={inverted}
            flowing
        />
    );
};
Popup.defaultProps = {
    position: 'top center',
    className: '',
    on: 'click',
    inverted: false,
    size: 'mini',
};
Popup.propTypes = {
    content: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    position: PropTypes.string,
    on: PropTypes.string,
    size: PropTypes.string,
    inverted: PropTypes.bool,
    className: PropTypes.string,
};

export { Popup };
