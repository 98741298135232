import React, { useEffect, useState } from 'react';
import { Segment, Container, Button, Form } from 'semantic-ui-react';
import { useForm } from 'react-hook-form';
import { isEmpty, includes } from 'lodash';
import {
    BreadcrumbComp,
    Image,
    Input,
    TextArea,
    FileUpload,
    DateTimePicker,
    Select,
} from '_components';
import { validMinMaxInput, validMinMaxTextArea, getFileDetail } from '_utils';
import { times } from 'images';
import CONST from '_constants';
import './TipsEdit.scss';
const TipsEdit = (props) => {
    const {
        activeTab,
        goBack,
        config: {
            assignedList,
            details,
            details: { form, heading, subHeading, catList },
        },
        location: {
            pathname,
            state: { record: selectedRecord },
        },
        actions: {
            editRecipies,
            updateRecipies,
            delRecipies,
            clearFileDetails,
            fileTextUpdate,
        },
        state: {
            filesList,
            tips: { loading: tipsEditing, selectedId },
        },
    } = props;
    const record = selectedRecord === undefined ? selectedId : selectedRecord;
    const pageHeading = heading[activeTab.tab] ?? '';
    const pageSubHeadingForAdd = subHeading?.edit[activeTab.tab] ?? '';
    const sections = [
        {
            key: 'myAdminListPage',
            content: activeTab.breadcrumbHeading,
            onClick: goBack,
        },
        {
            key: 'myAdminEditPage',
            content: details?.breadcrumbForEdit ?? 'Edit',
            active: true,
        },
    ];
    const tipsEditVidUrl = getFileDetail(filesList, 'tipsEditVid', 'url');
    const tipsEditImgUrl = getFileDetail(filesList, 'tipsEditImg', 'url');
    const tipsUploadVid = getFileDetail(
        filesList,
        'tipsEditVid',
        'uploading',
        true
    );
    const tipsUploadImg = getFileDetail(
        filesList,
        'tipsEditImg',
        'uploading',
        true
    );
    const [isCat, setCat] = useState(record?.category);
    const [isAssign, setAssign] = useState(record?.assignedRoles);
    if (!isEmpty(record?.assignedRoles) && isEmpty(isAssign))
        setAssign(() => record?.assignedRoles);
    const { register, handleSubmit, watch, errors, setValue } = useForm(),
        watchAllFields = watch(['title', 'desc', 'from']),
        validation = {
            AVAILABE: {
                required: true,
                maxLength: 100,
                minLength: 4,
            },
        },
        onChange = (_, data) => {
            setAssign(data.value);
        },
        onChangeCat = (_, data) => {
            setCat(data.value);
        },
        { title, desc, from } = watchAllFields,
        validateForm = () => {
            return !validMinMaxInput(title) &&
                !validMinMaxTextArea(desc) &&
                !tipsUploadImg &&
                !tipsUploadVid &&
                !isEmpty(from) &&
                !isEmpty(isAssign) &&
                !isEmpty(isCat) &&
                isEmpty(errors)
                ? false
                : true;
        },
        handelDelTips = () => {
            delRecipies({
                id: record?.id,
            });
            setTimeout(() => goBack(true), CONST.TIMEOUT);
        },
        onSubmit = (data) => {
            const assignedRoles = includes(isAssign, 'TODO')
                ? ['ROLE_UNAFFILIATED', 'ROLE_CEAN', 'ROLE_KETOSTERIL']
                : isAssign;
            updateRecipies({
                id: record?.id,
                assignedRoles,
                availableFrom: data?.from,
                category: isCat,
                description: data?.desc,
                foodImageUrl: tipsEditImgUrl,
                title: data?.title,
                imageContent: [''],
                videoUrl: tipsEditVidUrl,
            });
            setTimeout(() => goBack(true), CONST.TIMEOUT);
        };
    useEffect(() => {
        clearFileDetails();
        if (!isEmpty(record)) {
            if (record?.foodImageUrl)
                fileTextUpdate({
                    name: 'tipsEditImg',
                    url: record?.foodImageUrl,
                });
            if (record?.videoUrl)
                fileTextUpdate({ name: 'tipsEditVid', url: record?.videoUrl });
        } else {
            editRecipies(pathname);
        }
    }, [clearFileDetails, fileTextUpdate, record, pathname, editRecipies]);
    return (
        <Container fluid id="TipsEdit" className={`private-layout`}>
            <Segment basic loading={tipsEditing}>
                <BreadcrumbComp sections={sections} />
                <div className="edit-container">
                    <div className="edit-heading">
                        <div className="edit-heading-text">{pageHeading}</div>
                        <div className="edit-heading-subtext">
                            {pageSubHeadingForAdd}
                        </div>
                        <div className="edit-close">
                            <Image src={times} onClick={goBack} />
                        </div>
                    </div>
                    {!isEmpty(record) && (
                        <div className="edit-form">
                            <Form
                                autoComplete="off"
                                onSubmit={handleSubmit(onSubmit)}
                                unstackable
                            >
                                <Form.Group>
                                    <Form.Field width={10}>
                                        <Input
                                            name="title"
                                            label={form?.title?.label}
                                            placeholder={
                                                form?.title?.placeholder
                                            }
                                            widths={12}
                                            count
                                            refs={{ register }}
                                            value={record?.title ?? ''}
                                        />
                                    </Form.Field>
                                    <Form.Field width={5}>
                                        <Select
                                            name="categories"
                                            label={form?.category?.label}
                                            placeholder={
                                                form?.category?.placeholder
                                            }
                                            options={catList ?? []}
                                            value={record?.category}
                                            search
                                            onChange={onChangeCat}
                                            fluid
                                        />
                                    </Form.Field>
                                </Form.Group>
                                <Form.Group>
                                    <Form.Field width={5}>
                                        <FileUpload
                                            label={form?.imgTable?.label}
                                            limit={form?.imgTable?.limit}
                                            placeholder={
                                                form?.imgTable?.placeholder
                                            }
                                            name="tipsEditImg"
                                            optional={details.optional}
                                        />
                                    </Form.Field>
                                    <Form.Field width={5}>
                                        <FileUpload
                                            label={form?.video?.label}
                                            limit={form?.video?.limit}
                                            placeholder={
                                                form?.video?.placeholder
                                            }
                                            name="tipsEditVid"
                                            optional={details.optional}
                                            video
                                        />
                                    </Form.Field>
                                    <Form.Field width={5}>
                                        <DateTimePicker
                                            label={form?.available?.label}
                                            placeholder={
                                                form?.available?.placeholder
                                            }
                                            refs={register({
                                                ...validation.AVAILABE,
                                            })}
                                            value={[
                                                record?.availableFrom,
                                                record?.availableTo,
                                            ]}
                                        />
                                        <Select
                                            name="assign"
                                            label={
                                                form?.assign?.label ?? 'Assign'
                                            }
                                            placeholder={
                                                form?.assign?.placeholder
                                            }
                                            options={assignedList}
                                            onChange={onChange}
                                            value={isAssign}
                                            multiple
                                            fluid
                                        />
                                    </Form.Field>
                                </Form.Group>
                                <Form.Group>
                                    <Form.Field width={15}>
                                        <TextArea
                                            name="desc"
                                            label={form?.desc?.label}
                                            rows={6}
                                            placeholder={
                                                form?.desc?.placeholder
                                            }
                                            refs={{ register, setValue }}
                                            count
                                            preview
                                            content={{
                                                isAssign,
                                                availableFrom: from,
                                                category: isCat,
                                                desc:
                                                    desc ?? record?.description,
                                                title: title ?? record?.title,
                                                imageUrl: tipsEditImgUrl,
                                                videoUrl: tipsEditVidUrl,
                                            }}
                                            editor
                                            value={record?.description ?? ''}
                                        />
                                    </Form.Field>
                                </Form.Group>
                                <Button
                                    disabled={validateForm()}
                                    className="add-action-button"
                                    type="submit"
                                >
                                    {form?.actionLableForEdit}
                                </Button>
                                <Button
                                    className="add-action-button outline"
                                    type="button"
                                    onClick={handelDelTips}
                                >
                                    {form?.actionLableForDelete}
                                </Button>
                            </Form>
                        </div>
                    )}
                </div>
            </Segment>
        </Container>
    );
};

export { TipsEdit };
