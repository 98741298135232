import { Apis, handleResponse, handelError } from './services';

const getPatientList = ({ id }) => {
    const requestOptions = {
        method: 'GET',
    };
    return Apis()(`/user/nutritionist/${id}`, requestOptions)
        .then(handleResponse)
        .then((data) => ({ status: true, data }))
        .catch(handelError);
};
const getAll = ({ pageNumber, orderBy, direction, search, category }) => {
    const requestOptions = {
        method: 'GET',
        params: {
            pageNumber,
            orderBy,
            direction,
            search,
            category,
        },
    };
    return Apis()(`/user/nutritionist/list`, requestOptions)
        .then(handleResponse)
        .then((data) => ({ status: true, ...data }))
        .catch(handelError);
};
const addAdmin = (data) => {
    const requestOptions = {
        method: 'POST',
        data,
    };
    return Apis()(`/user/admin/`, requestOptions)
        .then(handleResponse)
        .then(() => ({ status: true }))
        .catch(handelError);
};
const editAdmin = (data) => {
    const requestOptions = {
        method: 'GET',
    };
    const { id } = data;
    return Apis()(`/user/admin/${id}`, requestOptions)
        .then(handleResponse)
        .then((res) => ({ status: true, ...res }))
        .catch(handelError);
};
const updateNutri = (data) => {
    const { id, ...rest } = data;
    const requestOptions = {
        method: 'POST',
        data: { password: rest?.password },
    };

    return Apis()(`/user/nutritionist/${id}`, requestOptions)
        .then(handleResponse)
        .then(() => ({ status: true }))
        .catch(handelError);
};
const deleteAdmin = (data) => {
    const requestOptions = {
        method: 'DELETE',
    };
    const { id } = data;
    return Apis()(`/user/admin/${id}`, requestOptions)
        .then(handleResponse)
        .then(() => ({ status: true }))
        .catch(handelError);
};

const nutriUpload = (file) => {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'multipart/form-data' },
        data: file,
    };
    return Apis()(`/user/nutritionist/upload`, requestOptions)
        .then(handleResponse)
        .then(() => ({ status: true }))
        .catch(handelError);
};

export const nutriService = {
    getPatientList,
    getAll,
    editAdmin,
    addAdmin,
    updateNutri,
    deleteAdmin,
    nutriUpload
};
