import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Icon, Transition, Dimmer, Loader } from 'semantic-ui-react';
import './PatientsAssigned.scss';
import { map } from 'lodash';
const PatientsAssigned = (props) => {
    const { active, setActive, loading, result } = props;
    useEffect(() => {
        return () => {
            if (!loading && active) setActive(!active);
        };
    }, [loading, active, setActive]);
    return (
        <Transition visible={active} animation="vertical flip" duration={500}>
            <div className="patient-assigned">
                <h3 className="patient--name-heading">
                    {props?.config?.moreInfoHeading ?? 'Patients assigned'}
                </h3>
                {loading ? (
                    <Dimmer active inverted>
                        <Loader />
                    </Dimmer>
                ) : (
                    <ul className="patient--name-list">
                        {map(result?.patients, ({ fullName }, index) => (
                            <li key={index} className="patient--name-text">
                                {fullName}
                            </li>
                        ))}
                    </ul>
                )}
                <div className="close-button">
                    <Icon name="angle up" onClick={() => setActive(!active)} />
                </div>
            </div>
        </Transition>
    );
};

PatientsAssigned.propTypes = {
    config: PropTypes.object.isRequired,
    active: PropTypes.bool.isRequired,
};

export { PatientsAssigned };
