import React, { useRef, useEffect, useMemo } from 'react';
import { Grid } from 'semantic-ui-react';
import { GridList, StickyButton } from '_components';
import { isEmpty, map } from 'lodash';
import { dateFormat, getRoles } from '_utils';
import { Search } from '_components/Search/Search';
const GdprList = (props) => {
    const {
        activeTab: { tab },
        actions: { getMarketList, getTermList, downloadGdpr },
        state: {
            gdprList: { loading, tabs },
            userDetails: { roles },
        },
        config,
        config: {
            details,
            details: { form },
        },
        history,
    } = props;
    const {
        params,
        result: { list, ...filters },
    } = tabs[tab];
    const cref = useRef('Gdpr-Page');
    const getListData = tab === 'TERM_CONDITION' ? getTermList : getMarketList;
    const filterList = useMemo(
        () =>
            map(list, (record) => {
                let userType = '--';
                if (record.roles) {
                    const currRole = [record.roles[0].roleName];
                    userType = `${getRoles(currRole, details?.roleDetails)}`
                }
                return {
                    ...record,
                    nutritionalRecordId: record?.nutritionalRecordId || '--',
                    userType,
                    NAME: record?.fullName || '--',
                    CONSENT: record?.mpAccepted ? 'Sí' : 'No',
                    DATE: dateFormat(
                        record?.tcAcceptedDate ?? record?.mpAcceptedDate
                    ),

                };
            }),
        [list, details]
    );
    const listData = {
        sorting: tab === 'TERM_CONDITION' ? 
        [
            { direction: 'DESC' },
            { direction: 'DESC' },
            { direction: 'DESC' },
            false,
            false,
        ] : [
            { direction: 'DESC' },
            false,
            false,
            { direction: 'DESC' },
            { direction: 'DESC' },
            false,
            false,
            false,
        ],
        actionList: getListData,
        isDisable: filters.totalElements <= list.length,
        columns:
            tab === 'TERM_CONDITION'
                ? ['NAME', 'version', 'DATE', 'phoneNumber', 'email']
                : ['NAME', 'nutritionalRecordId', 'userType', 'CONSENT', 'DATE', 'phoneNumber', 'email'],
        isHtml: [false, true],
        cellWidth: tab === 'MARKET' ? [2, 2, 2, 3, 2, 2, 2] : [3, 3, 2, 3, 3],
        pageData: filterList,
        loading,
        filters,
        params,
        cref,
        roles,
    };
    useEffect(() => {
        if (isEmpty(params)) {
            if (tab === 'TERM_CONDITION')
                getListData({ ...params, category: tab });
            if (tab === 'MARKET') getListData({ ...params, category: tab });
        }
    }, [getListData, params, tab]);
    return (
        <>
            <div className="actions">
                <Search
                    actionList={getListData}
                    params={params}
                    config={config}
                    width="full"
                />
            </div>
            <GridList config={config} history={history} listData={listData}>
                {(id, record) => {
                    return (
                        <Grid.Column key={id} width={2}>
                            {tab === 'TERM_CONDITION' && (
                                <div
                                    className="text-link"
                                    onClick={() => {
                                        props.history.push({
                                            pathname: `/Gdpr/${tab}/id/${record?.termsAndConditionId}`,
                                            state: {
                                                record: { ...record, id },
                                            },
                                        });
                                    }}
                                >
                                    {form?.actionLableForView}
                                </div>
                            )}
                        </Grid.Column>
                    );
                }}
            </GridList>
            {tab === 'MARKET' && (
                <StickyButton icon="cloud download" onClick={downloadGdpr} />
            )}
        </>
    );
};

export { GdprList };
