import { connect } from 'react-redux';
import { alertActions, userActions, AdminActions } from '_actions';
import { AdminPage } from '_pages';
import { getNewsId } from '_utils';
import history from '_utils/history';
function mapState(state) {
    const {
        alert,
        adminList,
        loginData: { userDetails },
    } = state;
    return { state: { alert, userDetails, adminList } };
}

const actionCreators = (dispatch) => {
    return {
        actions: {
            clearAlerts: () => dispatch(alertActions.clear()),
            clearFailure: () => dispatch(AdminActions.clear()),
            getList: (params) => dispatch(AdminActions.getAll(params)),
            createAdmin: (record) => dispatch(AdminActions.addAdmin(record)),
            getAdminRecord: (input) => {
                const _ = getNewsId(input);
                _.id
                    ? dispatch(AdminActions.editAdmin(getNewsId(input)))
                    : history.push({ pathname: '/Admins' });
            },
            updateAdmin: (record) => dispatch(AdminActions.updateAdmin(record)),
            deleteAdmin: (record) => dispatch(AdminActions.deleteAdmin(record)),
            getCenterList: () => dispatch(AdminActions.getCenterList()),
            logOut: () => dispatch(userActions.logout()),
            loginSuccess: () => dispatch(userActions.loginSuccess()),
        },
    };
};

export const AdminContainer = connect(mapState, actionCreators)(AdminPage);
