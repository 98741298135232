import { connect } from 'react-redux';
import { userActions, alertActions } from '_actions';
import { forgotDetails, alertDetails } from '_reducers';
import { ResetPwdPage } from '_pages';
function mapState(state) {
    return {
        state: {
            forgotData: forgotDetails(state),
            alertData: alertDetails(state),
        },
    };
}

const actionCreators = (dispatch) => {
    return {
        actions: {
            reqResetPwd: (input) => {
                dispatch(userActions.reset(input));
            },
            msgClear: () => dispatch(alertActions.clear()),
            msgErr: (input) => dispatch(alertActions.error(input)),
        },
    };
};

export const ResetPwdContainer = connect(
    mapState,
    actionCreators
)(ResetPwdPage);
