import { connect } from 'react-redux';
import { PatientRoutes } from '../_pages';
import {
    patientDetailActions as PDA,
    promoActions as PA,
    patientActions as PAA,
    userActions,
} from '_actions';
const mapStateToProps = (state) => {
    return {
        userDetails: state.loginData?.userDetails,
        patientInfo: state.patientMedicalRecords.patientInfo,
        BMIRecord: state.patientMedicalRecords.BMIRecord,
        bodyMeasures: state.patientMedicalRecords.bodyMeasures.result,
        bmLoading: state.patientMedicalRecords.bodyMeasures.loading,
        analytics: state.patientMedicalRecords.analytics.result,
        aLoading: state.patientMedicalRecords.analytics.loading,
        upgrade: state.patientMedicalRecords.upgrade,
        loading: state.patientMedicalRecords.loading,
        health: state.patientMedicalRecords.health,
        symptoms: state.patientMedicalRecords.symptoms,
        boxes: state.promoList.tabs?.DEFAULT?.boxes??{},
        pharmaList: state.promoList.tabs?.DEFAULT?.pharmaList??[],
        isModalOpen: state.patientList.isModalOpen,
        isModalLoad: state.patientList.isModalLoad,
        modalData: state.patientList.modalData,
        csvLoading: state.patientMedicalRecords.csvLoading,
        csvParams: state.patientMedicalRecords.csvParams,
        uploaded: state.patientMedicalRecords.uploaded,
        uploading: state.patientMedicalRecords.uploading,
        uploadStatus: state.patientMedicalRecords.uploadStatus,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getPatientDetail: (id) => {
            dispatch(PDA.getPatientInfo(id));
        },
        getMedicalRecords: (id, type, sectionType) => {
            dispatch(PDA.getMedicalRecords(id, type, sectionType));
        },
        upgradeHistory: (params) => {
            dispatch(PDA.upgradeHistory(params));
        },
        upgradeKetoToCean: (data) => {
            dispatch(PDA.upgradeKetoToCean(data));
        },
        getPharmacyList: () => dispatch(PA.getPharmacyList()),
        updateTicket: (data, isValid) =>
            dispatch(PAA.updateTicket(data, isValid)),
        getBoxes: (userId) => dispatch(PA.getBoxes(userId)),
        getHealthRec: (data) => dispatch(PDA.getHealthRec(data)),
        getTicketDetails: (id) => dispatch(PAA.getTicketDetails(id)),
        openModal: (mData) => dispatch(PAA.openModal(mData)),
        closeModal: () => dispatch(PAA.closeModal()),
        selectMenuItem: (e) => dispatch(userActions.selectMenuItem(e)),
        initModal: () => dispatch(PDA.isUpload()),
        patientDetailsUpload: (file, id) => {
            dispatch(PDA.uploadPatientDetails(file, id))
        },
    };
};

export const PatientDetailsContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(PatientRoutes);
