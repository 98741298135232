import React from 'react';
import PropTypes from 'prop-types';
import { Route } from 'react-router-dom';
import { PrivateRoute } from '_components';
import {
    PatientsListContainer,
    NutritionistContainer,
    PromotionsContainer,
    KetomapContainer,
    AdminContainer,
    InfoContainer,
    GdprContainer,
    ChatContainer,
    PatientDetailsContainer,
    UpgradeContainer,
    TipsContainer,
    NoticesContainer,
    EditPatientInfoContainer,
} from '_containers';
import { NotFoundPage, ErrorPage } from '_pages';

const Routes = (props) => {
    const { roles } = props;
    const allRoutes = [
        {
            key: 1,
            component: AdminContainer,
            path: '/Admins',
            exact: false,
        },
        {
            key: 2,
            component: NutritionistContainer,
            path: '/Nutritionists',
            exact: false,
        },
        {
            key: 3,
            component: PromotionsContainer,
            path: '/Promotions',
            exact: false,
        },
        { key: 4, component: KetomapContainer, path: '/Map', exact: true },
        {
            key: 5,
            component: PatientsListContainer,
            path: '/Patients',
            exact: true,
        },
        { key: 8, component: InfoContainer, path: '/Info', exact: false },
        { key: 14, component: ChatContainer, path: '/Chat', exact: false },
        { key: 15, component: GdprContainer, path: '/Gdpr', exact: false },
        {
            key: 9,
            component: EditPatientInfoContainer,
            path: '/Patients/:id/Edit',
            exact: true,
        },
        {
            key: 10,
            component: PatientDetailsContainer,
            path: '/Patients/:id',
            exact: false,
        },
        {
            key: 11,
            component: UpgradeContainer,
            path: '/Activar',
            exact: true,
        },
        { key: 12, component: TipsContainer, path: '/Tips', exact: false },
        {
            key: 13,
            component: NoticesContainer,
            path: '/News',
            exact: false,
        },
    ];
    const validRoutes =
        roles === 'Admin'
            ? allRoutes
            : [
                  {
                      key: 1,
                      component: PatientsListContainer,
                      path: '/Patients',
                      exact: true,
                  },
                  {
                      key: 2,
                      component: PatientDetailsContainer,
                      path: '/Patients/:id',
                      exact: true,
                  },
                  {
                      key: 3,
                      component: EditPatientInfoContainer,
                      path: '/Patients/:id/Edit',
                      exact: true,
                  },
                  {
                      key: 4,
                      component: ChatContainer,
                      path: '/Chat',
                      exact: false,
                  },
              ];
    const loadAllRoutes = () =>
        validRoutes.map(({ path, component, key, exact }) => (
            <PrivateRoute
                exact={exact}
                path={path}
                component={component}
                key={key}
                {...props}
            />
        ));
    return (
        <ErrorPage>
            {loadAllRoutes()}
            <Route
                to={{
                    pathname: '*',
                    state: {
                        userType: roles,
                        activeTab: roles === 'Admin' ? 'Admins' : roles,
                    },
                }}
                component={NotFoundPage}
            />
        </ErrorPage>
    );
};

Routes.propTypes = {
    loggedIn: PropTypes.bool.isRequired,
    token: PropTypes.string.isRequired,
};

export default Routes;
