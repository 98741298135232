import ALERT from '_constants';
import { INIT } from '_config/initialState';
const { alertData } = INIT;

export default (state = alertData, action = {}) => {
    switch (action.type) {
        case ALERT.SUCCESS:
            return {
                ...state,
                type: 'alert-success',
                message: action.message,
            };
        case ALERT.ERROR:
            return {
                ...state,
                type: 'alert-danger',
                message: action.message,
            };
        case ALERT.CLEAR:
            return {};
        default:
            return state;
    }
};
export const alertDetails = (state) => state.alertData;
