import React, { useCallback, useState, useEffect } from 'react';
import { Grid, Dimmer, Loader } from 'semantic-ui-react';
import {
    UserList,
    ChatHistory,
    ChatActions,
    UserInfo,
    ChatRinging,
} from '_components';
import { bootChatApp, createRoom, recordAudioMsg } from '_utils/chat';
import './ChatList.scss';
import { isEmpty } from 'lodash';
const ChatList = (props) => {
    const {
        config: {
            details,
            details: { boxhistory, userAction, chatHistory: chatHistoryConfig },
        },
        state: {
            chatList,
            boxes,
            userDetails: { token: _, ...restUserDetails },
        },
        actions: {
            setRoomInfo,
            updateMsgHistory,
            updatePrevMsgHistory,
            getToken,
            startAudioMsg,
            stopAudioMsg,
            callingAction,
            endCallAction,
            notifAction,
            getBoxes,
            getNutri,
            errorAlerts,
            updateChatOnCenterChange,
            setKabiCenter,
        },
        activeTab,
        isAdmin,
        statusHistoryVisible,
    } = props;
    const { token, tabs, inviteUsers, calling, nutri, selectedKabiCenter } = chatList;
    const { chatHistory: roomHistory, isAudioMsgRunning, selectedId } = tabs[
        activeTab?.tab
    ];
    const [chatData, setChatData] = useState({ chatLoading: true });
    // INITIALIZE CAHT
    const chatApp = useCallback(async () => {
        if (!isEmpty(token) && chatData.chatLoading) {
            setChatData({ chatLoading: false });
            const result = await bootChatApp(token, restUserDetails).then(
                (res) => {
                    try {
                        const {
                            chatInfo: { chatClient },
                        } = res;
                        chatClient.on('tokenAboutToExpire', () => {
                            getToken(chatClient);
                        });
                        return res;
                    } catch (error) {
                        console.log(error.name, error.message);
                    }
                }
            );
            setChatData({ chatLoading: false, ...result });
        } else return null;
    }, [token, restUserDetails, chatData, getToken]);
    chatApp();
    useEffect(() => {
        setChatData({ chatLoading: true });
        updateChatOnCenterChange();
    }, [selectedKabiCenter]);
    
    const { chatLoading } = chatData;
    return (
        <>
            {isEmpty(calling) ? (
                <Grid relaxed id="chat-list">
                    <Grid.Row>
                        {chatLoading ? (
                            <Dimmer inverted active>
                                <Loader />
                            </Dimmer>
                        ) : (
                            <>
                                <Grid.Column width={4}>
                                    <UserList
                                        createRoom={createRoom}
                                        inviteUsers={inviteUsers}
                                        chatData={chatData}
                                        setRoomInfo={setRoomInfo}
                                        selectedId={selectedId}
                                        tab={activeTab?.tab}
                                        searchText={activeTab?.search}
                                        isDisable={isAudioMsgRunning}
                                        isAdmin={isAdmin}
                                        errorAlerts={errorAlerts}
                                        setKabiCenter={setKabiCenter}
                                        selectedKabiCenter={selectedKabiCenter}
                                    />
                                </Grid.Column>
                                <Grid.Column className="main-section" width={8}>
                                    <ChatHistory
                                        chatClient={
                                            chatData?.chatInfo?.chatClient
                                        }
                                        userName={restUserDetails?.Initials}
                                        roomHistory={roomHistory}
                                        isAdmin={isAdmin}
                                        selectedId={selectedId}
                                        updateMsgHistory={updateMsgHistory}
                                        updatePrevMsgHistory={
                                            updatePrevMsgHistory
                                        }
                                        tab={activeTab?.tab}
                                        config={chatHistoryConfig}
                                    />
                                    <ChatActions
                                        chatClient={
                                            chatData?.chatInfo?.chatClient
                                        }
                                        roomID={selectedId?.roomInfo?.roomID}
                                        isAdmin={isAdmin}
                                        isAudioMsgRunning={isAudioMsgRunning}
                                        startAudioMsg={startAudioMsg}
                                        stopAudioMsg={stopAudioMsg}
                                        recordAudioMsg={recordAudioMsg}
                                        notifAction={notifAction}
                                        tab={activeTab?.tab}
                                        config={userAction}
                                        calling={callingAction}
                                    />
                                </Grid.Column>
                                <Grid.Column width={4}>
                                    <UserInfo
                                        userDetail={selectedId}
                                        isAdmin={isAdmin}
                                        tab={activeTab?.tab}
                                        config={details}
                                        boxhistory={boxhistory}
                                        getBoxes={getBoxes}
                                        getNutri={getNutri}
                                        boxes={boxes}
                                        searchText={activeTab?.search}
                                        nutri={nutri}
                                        inviteUsers={inviteUsers}
                                        statusHistoryVisible={statusHistoryVisible}
                                    />
                                </Grid.Column>
                            </>
                        )}
                    </Grid.Row>
                </Grid>
            ) : (
                <ChatRinging
                    roomInfo={selectedId?.roomInfo}
                    endCallAction={endCallAction}
                    calling={calling}
                    notifAction={notifAction}
                    tab={activeTab?.tab}
                />
            )}
        </>
    );
};

export { ChatList };
