import React from 'react';
import { Chart } from 'react-google-charts';
import './LineGraph.scss';
import { lang } from '_config';
import { has, indexOf, map, isEmpty } from 'lodash';
import moment from 'moment';
import 'moment/locale/es';
const { details } = lang.medicalRecord;
const LineGraph = (props) => {
    let unit = props?.unit;
    let updatedData = [];
    const { appData, mrData } = props?.data;
    if (Object.keys(props?.data).length) {
        delete props.data['status'];
        let formatedAppData = appData.map((item) => {
            item.date = item.createdDate;
            item['type'] = 'USER';
            return item;
        });
        let formatedmrData = mrData?.map((item) => {
            item['type'] = 'CEAN';
            item.date = item.createdDate;
            return item;
        });
        if (Object.keys(props?.data).length === 1) {
            if (
                props?.graphType === 'Analytics' &&
                indexOf(['mmHg', 'kg'], props?.unit) !== -1
            ) {
                updatedData.push([
                    { type: 'date', label: '' },
                    { label: `${details?.common?.user} presión sistólica` },
                    { label: `${details?.common?.user} presión diastólica` },
                ]);
            } else {
                updatedData.push([
                    { type: 'date', label: '' },
                    details?.common?.user,
                ]);
            }
            props.data.appData.forEach((item) => {
                if (
                    props?.graphType === 'Analytics' &&
                    indexOf(['mmHg', 'kg'], props?.unit) !== -1
                ) {
                    updatedData.push([
                        new Date(item.createdDate),
                        item.value,
                        item.secondValue,
                    ]);
                } else
                    updatedData.push([new Date(item.createdDate), item.value]);
            });
        } else if (appData.length && mrData.length) {
            let mergedObj = formatedAppData
                .concat(formatedmrData)
                .reduce((r, o) => {
                    let obj = {};
                    obj['date'] = o.date;
                    if (r[o.date]?.date === o.date) obj = r[o.date];
                    if (o.type === 'CEAN') {
                        obj['CEAN'] = o.value;
                        if (
                            props?.graphType === 'Analytics' &&
                            indexOf(['mmHg', 'kg'], props?.unit) !== -1
                        )
                            obj['CEANHIGH'] = o?.secondValue ?? null;
                    } else {
                        obj['USER'] = o.value;
                        if (
                            props?.graphType === 'Analytics' &&
                            indexOf(['mmHg', 'kg'], props?.unit) !== -1
                        )
                            obj['USERHIGH'] = o?.secondValue ?? null;
                    }
                    r[o.date] = Object.assign({}, r[o.date] || obj);
                    return r;
                }, {});
            let mergedArr = [];
            for (let i in mergedObj) {
                mergedArr.push(mergedObj[i]);
            }
            let sortedArr = mergedArr.sort((a, b) => {
                return new Date(a.date) - new Date(b.date);
            });
            if (
                props?.graphType === 'Analytics' &&
                indexOf(['mmHg', 'kg'], props?.unit) !== -1
            ) {
                updatedData.push([
                    { type: 'date', label: '' },
                    { label: `${details?.common?.user} presión sistólica` },
                    { label: `${details?.common?.user} presión diastólica` },
                    { label: `${details?.common?.common} presión sistólica` },
                    { label: `${details?.common?.common} presión diastólica` },
                ]);
            } else {
                updatedData.push([
                    { type: 'date', label: '' },
                    details?.common?.user,
                    details?.common?.common,
                ]);
            }
            sortedArr.forEach((item) => {
                let arr = [];
                arr.push(new Date(item.date));
                let CEANVal = has(item, 'CEAN') ? item.CEAN : null;
                let USERVal = has(item, 'USER') ? item.USER : null;
                arr.push(CEANVal);
                if (
                    props?.graphType === 'Analytics' &&
                    indexOf(['mmHg', 'kg'], props?.unit) !== -1
                )
                    arr.push(item?.CEANHIGH);
                arr.push(USERVal);
                if (
                    props?.graphType === 'Analytics' &&
                    indexOf(['mmHg', 'kg'], props?.unit) !== -1
                )
                    arr.push(item?.USERHIGH ?? null);
                updatedData.push(arr);
            });
        } else if (appData.length || mrData.length) {
            let label = appData.length ? details?.common?.common : details?.common?.user;
            let arr = appData.length ? formatedAppData : formatedmrData;
            if (
                props?.graphType === 'Analytics' &&
                indexOf(['mmHg', 'kg'], props?.unit) !== -1
            ) {                
                updatedData.push([
                    { type: 'date', label: '' },
                    { label: `${label} presión sistólica` },
                    { label: `${label} presión diastólica` },
                ]);
            } else {
                updatedData.push([{ type: 'date', label: '' }, label]);
            }
            arr.forEach((item) => {
                if (
                    props?.graphType === 'Analytics' &&
                    indexOf(['mmHg', 'kg'], props?.unit) !== -1
                ) {
                    updatedData.push([
                        new Date(item.date),
                        item.value,
                        item.secondValue,
                    ]);
                } else {
                    updatedData.push([new Date(item.date), item.value]);
                }
            });
        }
    }
    updatedData = map(updatedData, (item, index) => {
        const [, ...items] = item;
        if (index >= 1) {
            const x = moment(item[0], 'dd-mm-yyyy', 'es');
            return [x.format('MMM DD'), ...items];
        } else return [{ label: 'date' }, ...items];
    });
    let chartColor = ['#2E5BFF', '#F7A900'];

    if (!isEmpty(appData) && appData.length && isEmpty(mrData)) {
        chartColor[0] = chartColor[1];
    } else {
        if (
            !isEmpty(appData) &&
            appData.length &&
            !isEmpty(mrData) &&
            mrData.length
        ) {
            if (
                props?.graphType === 'Analytics' &&
                indexOf(['mmHg', 'kg'], props?.unit) !== -1
            ) {
                chartColor[2] = chartColor[1];
                chartColor[3] = chartColor[1];
                chartColor[1] = chartColor[0];
            } else {
                chartColor[2] = chartColor[1];
            }
        }
        if (
            isEmpty(appData) &&
            !isEmpty(mrData) &&
            mrData.length &&
            props?.graphType === 'Analytics' &&
            indexOf(['mmHg', 'kg'], props?.unit) !== -1
        ) {
            chartColor[1] = chartColor[0];
        }
    }
    
    return (
        <div className="graph">
            {updatedData?.length > 1 ? (
                <div className="chart-container">
                    <Chart
                        height={'400px'}
                        chartType="LineChart"
                        graphID={props?.graphType}
                        loader={<div>Loading Chart</div>}
                        data={updatedData.length ? updatedData : []}
                        options={{
                            explorer: { axis: 'horizontal' },
                            interpolateNulls: true,
                            hAxis: {
                                format: 'dd MMM yyyy',
                                title: '',
                                textStyle: { color: '#C1C9D5' },
                                titleTextStyle: { color: '#9D9D9C' },
                            },
                            vAxis: {
                                title: unit,
                                minValue: 0,
                                maxValue: 80,
                                titleTextStyle: { color: '#C1C9D5' },
                                textStyle: { color: '#C1C9D5' },
                            },
                            legend: {
                                position: 'bottom',
                                textStyle: { color: '#9D9D9C', fontSize: 8 },
                                alignment: 'end',
                            },
                            chartArea: {
                                top: '5',
                                position: 'relative',
                                color: '#9D9D9C',
                            },
                            colors: chartColor,
                            series: {
                                0: { pointShape: 'circle', pointSize: 5 },
                                1: { pointShape: 'circle', pointSize: 5 },
                            },
                        }}
                        rootProps={{ 'data-testid': '1' }}
                        getChartEditor={({
                            chartEditor,
                            chartWrapper,
                            google,
                        }) => {}}
                        chartPackages={['corechart', 'controls', 'charteditor']}
                    />
                    <div className="chart-label">Medidas tomadas por:</div>
                </div>
            ) : (
                <div className="empty-msg">{details?.common?.emptyMsg}</div>
            )}
        </div>
    );
};
export { LineGraph };
