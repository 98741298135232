import NUTRI from '_constants';
import { INIT } from '_config/initialState';
const { nutriList: initialState } = INIT;

export default (state = initialState, action = {}) => {
    const { type, params, result, error, uploadStatus } = action;
    switch (type) {
        case NUTRI.NUTRI_REQUEST_START:
            return { ...state, loading: true };
        case NUTRI.NUTRI_REQUEST_END:
            return { ...state, loading: false };
        case NUTRI.NUTRI_PWD_REQ_START:
            return { ...state, resetLoading: true };
        case NUTRI.NUTRI_PWD_REQ_END:
            return { ...state, resetLoading: false };
        case NUTRI.NUTRI_PATIENT_REQUEST_START:
            return {
                ...state,
                assignePatientList: {
                    ...state.assignePatientList,
                    loading: true,
                },
            };
        case NUTRI.NUTRI_PATIENT_REQUEST_END:
            return {
                ...state,
                assignePatientList: {
                    ...state.assignePatientList,
                    loading: false,
                },
            };
        case NUTRI.NUTRI_PATIENT_SUCCESS:
            return {
                ...state,
                assignePatientList: {
                    ...state.assignePatientList,
                    result,
                },
            };
        case NUTRI.NUTRI_UPLOAD_REQUEST_START:
            return { ...state, uploading: true };
        case NUTRI.NUTRI_UPLOAD_REQUEST_END:
            return { ...state, uploading: false };
        case NUTRI.NUTRI_UPLOAD_INIT:
            return {
                ...state,
                uploading: false,
                uploaded: false,
                uploadStatus: false,
            };
        case NUTRI.NUTRI_PATIENT_FAILURE:
            return {
                ...state,
                assignePatientList: {
                    ...state.assignePatientList,
                    faliure: error,
                },
            };
        case NUTRI.NUTRI_LIST_SUCCESS:
            return {
                ...state,
                result: {
                    ...result,
                    list: params.pageNumber
                        ? [...state.result.list, ...result.list]
                        : [...result.list],
                },
                faliure: null,
                params: params,
            };
        case NUTRI.NUTRI_RECORD_SUCCESS:
            return { ...state, faliure: null, success: true };
        case NUTRI.NUTRI_CLEAR:
            return { ...state, faliure: null, success: false };
        case NUTRI.NUTRI_EDIT_FAILURE:
            return { ...state, faliure: error };
        case NUTRI.NUTRI_SELECTED_ID:
            return { ...state, selectedId: result };
        case NUTRI.NUTRI_LIST_FAILURE:
            return { ...state, ...error, loading: false };
        case NUTRI.NUTRI_UPLOAD_FAILURE:
            return { ...state, uploaded: false, uploadStatus };
        case NUTRI.NUTRI_UPLOAD_SUCCESS:
            return { ...state, uploaded: true, uploadStatus: false };
        default:
            return { ...state };
    }
};
export const nutriDetails = (state) => state.nutriList;
