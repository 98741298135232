import React, { Component } from 'react';
import { Segment, Grid } from 'semantic-ui-react';
import { BreadcrumbComp, BMIChart, PatientInfoDetails, UploadCsvModal } from '_components';
import './PatientDetails.scss';
import { lang } from '_config';
import SectionHealth from './Sections/SectionHealth';
import AnalyticSection from './Sections/AnalyticSection';
import BodySection from './Sections/BodySection';
import { orderBy, isEmpty, filter } from 'lodash';

const { details } = lang.medicalRecord;
const sections = [
    {
        key: 'myPatients',
        content: details?.pageheading ?? 'Patients',
        active: true,
        link: true,
        path: '/Patients',
    },
    {
        key: 'patientId',
        content: '',
        active: false,
    },
];

const { btnDetails } = details?.analytics;

const buttonGroup = btnDetails;

const bodyMesBtn = [
    {
        value: details?.bodyMeasures?.weight,
        active: true,
        text: 'WEIGHT',
        unit: 'kg',
    },
    {
        value: details?.bodyMeasures?.height,
        active: false,
        text: 'HEIGHT',
        unit: 'cm',
    },
];

class PatientDetails extends Component {
    constructor(props) {
        super(props);
        const {
            match: {
                params: { id },
            },
        } = this.props;
        this.state = {
            id: id,
            analyticsBtns: buttonGroup,
            bodyMesBtns: bodyMesBtn,
            BMUnit: 'kg',
            anyUnit: 'kg',
        };
    }

    // eslint-disable-next-line react/sort-comp
    handleClick = (index) => {
        const { analyticsBtns, id } = this.state;
        let selectedItem = '';
        let unit = '';
        analyticsBtns.map((obj, i) => {
            if (index === i) {
                obj.active = true;
                selectedItem = obj.text;
                unit = obj.unit;
            } else {
                obj.active = false;
            }
            return obj;
        });
        this.props.getMedicalRecords(id, selectedItem, 'analytics');
        this.setState({ analyticsBtns, anyUnit: unit });
    };

    handleBMBtnClick = (index) => {
        const { bodyMesBtns, id } = this.state;
        let selectedItem = '';
        let unit = '';
        bodyMesBtns.map((obj, i) => {
            if (index === i) {
                obj.active = true;
                selectedItem = obj.text;
                unit = obj.unit;
            } else {
                obj.active = false;
            }
            return obj;
        });
        this.props.getMedicalRecords(id, selectedItem, 'BM');
        this.setState({ bodyMesBtns, BMUnit: unit });
    };

    componentDidMount() {
        const { id } = this.state;
        const { getPatientDetail, getMedicalRecords } = this.props;
        getPatientDetail(id);
        getMedicalRecords(id, 'IMC', 'BMI');
        getMedicalRecords(id, 'WEIGHT', 'BM');
        getMedicalRecords(id, 'BLOOD_PREASURE', 'analytics');
    }

    handleLink = (event) => {
        const pathname = event.target.getAttribute('path');
        this.props.history.push({ pathname });
    };
    render() {
        const {
            loading,
            patientInfo: { firstName, lastName },
            BMIRecord,
            bodyMeasures: bmData,
            analytics: analyticData,
            getHealthRec,
            health,
            bmLoading,
            aLoading,
            symptoms,
            csvLoading,
            csvParams,
            uploaded,
            uploading,
            uploadStatus,
            initModal,
            patientDetailsUpload,
        } = this.props;
        const userType = this.props?.patientInfo?.roles;
        if (loading) sections[1].content = '';
        else sections[1].content = firstName + ' ' + lastName;
        const { userDetails } = this.props;
        const role = userDetails?.Role[0];
        const { bodyMesBtns, BMUnit, analyticsBtns, anyUnit, id } = this.state;
        const {
            BMI,
            bodyMeasures,
            analytics,
            healthDiary,
            symptoms: symp,
            symptomLabel,
        } = details;
        let recordValues = !isEmpty(BMIRecord?.appData)
            ? [...BMIRecord?.appData]
            : [];
        recordValues = !isEmpty(BMIRecord?.mrData)
            ? [...BMIRecord?.mrData, ...recordValues]
            : recordValues;
        recordValues = filter(
            orderBy(recordValues, ['createdDate'], 'desc'),
            'value'
        );
        const BMIValue = recordValues.length ? recordValues[0].value : null;
        return (
            <div id="PatientDetailPage" className="patient-detail-container">
                <Segment basic>
                    <BreadcrumbComp
                        sections={sections}
                        handleLink={this.handleLink}
                    />
                    <Grid className="ui-section ">
                        <Grid.Row columns={2}>
                            <Grid.Column width={10}>
                                <PatientInfoDetails
                                    propsData={this.props}
                                    details={details}
                                    id={this?.state?.id}
                                />
                            </Grid.Column>

                            <Grid.Column width={6}>
                                <Segment.Group className="BMI-section">
                                    <Segment
                                        className="section-header"
                                        style={{ textAlign: 'center' }}
                                    >
                                        {BMI?.title}
                                    </Segment>
                                    <Segment className="sub-section">
                                        <BMIChart data={BMIValue} role={role} />
                                    </Segment>
                                </Segment.Group>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row columns={1}>
                            <BodySection
                                bodyMeasures={bodyMeasures}
                                bmLoading={bmLoading}
                                bodyMesBtns={bodyMesBtns}
                                handleBMBtnClick={this.handleBMBtnClick}
                                bmData={bmData}
                                bmUnit={BMUnit}
                            />
                        </Grid.Row>
                        <Grid.Row columns={1}>
                            <AnalyticSection
                                analytics={analytics}
                                aLoading={aLoading}
                                analyticsBtns={analyticsBtns}
                                analyticData={analyticData}
                                anyUnit={anyUnit}
                                handleClick={this.handleClick}
                            />
                        </Grid.Row>
                        <Grid.Row>
                            <SectionHealth
                                getHealthRec={getHealthRec}
                                healthDiary={healthDiary}
                                id={id}
                                health={health}
                                symptoms={symptoms}
                                symp={symp}
                                symptomLabel={symptomLabel}
                            />
                        </Grid.Row>
                    </Grid>
                    {details?.uploadRequired && userType && userType[0] === 'ROLE_CEAN' ? <UploadCsvModal
                        lang={details.uploadModal}
                        uploaded={uploaded}
                        uploading={uploading}
                        uploadFile={patientDetailsUpload}
                        initModal={initModal}
                        loading={csvLoading}
                        actionList={() => this.props.getPatientDetail(this.state.id)}
                        params={csvParams}
                        uploadStatus={uploadStatus}
                        withInfoIcon
                        buttonInfoText={details?.uploadButtonInfoText}
                        patientId={this.props?.patientInfo?.id}
                    /> : ''}
                </Segment>
            </div>
        );
    }
}

export { PatientDetails };
