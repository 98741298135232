import React, { createRef } from 'react';
import PropTypes from 'prop-types';
import { debounce } from 'lodash';
import './Search.scss';
const Search = (props) => {
    const { config, params, actionList } = props;
    const searchText = { value: params?.search ?? '' };
    const inputRef = createRef();
    const handleSearch = (event) => {
        try {
            event.preventDefault();
            const search = inputRef.current.value ?? '';
            actionList({ ...params, pageNumber: 0, search });
            // eslint-disable-next-line no-empty
        } catch (e) {}
    };
    const searchList = () => {
        actionList({ ...params, pageNumber: 0, search: searchText.value });
    };
    const handelTypeText = debounce(searchList, 500);
    return (
        <div id="search-container" className={props?.width ?? ''}>
            <form onSubmit={handleSearch}>
                <div className="ui icon input">
                    <input
                        ref={inputRef}
                        onChange={({ target: { value } }) => {
                            searchText.value = value;
                            handelTypeText(value);
                        }}
                        placeholder={config?.placeholder}
                        defaultValue={searchText.value}
                        type="text"
                    />
                    <i
                        onClick={handleSearch}
                        aria-hidden="true"
                        className="search link icon"
                    />
                </div>
            </form>
        </div>
    );
};

Search.propTypes = {
    config: PropTypes.object.isRequired,
    width: PropTypes.string.isRequired,
};

export { Search };
