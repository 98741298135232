import { connect } from 'react-redux';
import {
    alertActions,
    patientActions as PA,
    promoActions as PO,
    userActions,
} from '_actions';
import { PatientsListPage } from '_pages';
function mapState(state) {
    const {
        alertData,
        patientList,
        loginData: { userDetails },
    } = state;
    const pharmaList = state.promoList.tabs?.DEFAULT?.pharmaList??[];
    const pharmaListSuccess = state.promoList.tabs?.DEFAULT?.pharmaList??[];
    return { state: { alertData, patientList, userDetails, pharmaList, pharmaListSuccess } };
}

const actionCreators = (dispatch) => {
    return {
        actions: {
            clearAlerts: () => dispatch(alertActions.clear()),
            logOut: () => dispatch(userActions.logout()),
            getList: (queryParams) => dispatch(PA.getAll(queryParams)),
            getPharmacyList: () => dispatch(PO.getPharmacyList()),
            getTicketDetails: (id) => dispatch(PA.getTicketDetails(id)),
            updateTicket: (data) => dispatch(PA.updateTicket(data)),
            closeModal: () => dispatch(PA.closeModal()),
        },
    };
};

export const PatientsListContainer = connect(
    mapState,
    actionCreators
)(PatientsListPage);
