import React, { createRef, useState } from 'react';
import PropTypes from 'prop-types';
import CONSTANTS from '_constants';
import './TextArea.scss';
import { CkEditor } from '_components/CkEditor/CkEditor';
import { MobilePreview } from './MobilePreview';
const TextArea = (props) => {
    const {
        label,
        name,
        refs,
        disabled,
        placeholder,
        onChange,
        rows,
        value,
        count,
        editor,
        link,
        preview,
        content,
    } = props;
    const {
        VALIDATION: { MIN, MAXTEXTAREA },
    } = CONSTANTS;
    const validation = {
        required: true,
        maxLength: MAXTEXTAREA,
        minLength: MIN,
    };
    const { maxLength, ...curValidataion } = {
        ...validation,
        ...refs.validation,
    };
    const curRef =
        refs?.register !== undefined
            ? refs.register({ ...curValidataion, maxLength })
            : refs;
    const [inputCount, setCount] = useState(0);
    const getValue = (result) => {
        refs.setValue(name, result);
        setCount(result.length);
    };
    const currentRef = async (e) => {
        if (count) {
            await setCount(() => e?.value.length);
            curRef(e);
        } else curRef(e);
    };
    return (
        <div className="dmi-textarea">
            <label>{label}</label>
            {editor ? (
                <div className="ck-editor">
                    <CkEditor
                        disabled={disabled}
                        rows={rows}
                        getValue={getValue}
                        dataValue={value}
                        link={link}
                    />
                    {preview && (
                        <div className="preview-btn">
                            <label>
                                Las imágenes de 500Kb son de calidad suficiente
                                para la KetoApp
                            </label>
                            <MobilePreview content={content} />
                        </div>
                    )}
                    <textarea
                        ref={currentRef}
                        className="text-area-hidden"
                        name={name}
                        defaultValue={value}
                    />
                </div>
            ) : (
                <textarea
                    disabled={disabled}
                    className={`text-area ${disabled ? 'disabled' : ''}`}
                    ref={currentRef}
                    name={name}
                    rows={rows}
                    onChange={onChange}
                    placeholder={placeholder}
                    defaultValue={value}
                    maxLength={maxLength}
                />
            )}
            {count && (
                <label className="count">
                    {inputCount}/{maxLength}
                </label>
            )}
        </div>
    );
};
TextArea.defaultProps = {
    value: '',
    name: '',
    label: '',
    refs: createRef(),
    type: 'text',
    preview: false,
    editor: false,
    disabled: false,
    count: false,
    link: true,
    rows: 5,
    countNum: 0,
};
TextArea.propTypes = {
    value: PropTypes.string,
    label: PropTypes.string,
    editor: PropTypes.bool,
    link: PropTypes.bool,
    preview: PropTypes.bool,
    content: PropTypes.object,
    name: PropTypes.string,
    rows: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    placeholder: PropTypes.string,
    onChange: PropTypes.func,
    disabled: PropTypes.bool,
    count: PropTypes.bool,
    countNum: PropTypes.number,
    refs: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
};

export { TextArea };
