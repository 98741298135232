/* eslint-disable react/no-multi-comp */
/* eslint-disable react/no-danger */
import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Placeholder } from 'semantic-ui-react';
import { useRouteMatch } from 'react-router-dom';
import { map, isEmpty } from 'lodash';
import './GridRow.scss';
import { Popup } from '_components/Core/Popup/Popup';
const GridRowForAdmin = (props) => {
    const {
        listData: { cellWidth, columns, loading, isHtml },
        cellData,
        rowIndex,
    } = props;
    const { path } = useRouteMatch();
    const loadingText = () => (
        <Placeholder>
            <Placeholder.Line />
        </Placeholder>
    );
    const isLoading = loading ?? false;
    const createEmptyColums = () => (
        <Grid.Column>Ningún record fue encontrado</Grid.Column>
    );
    const getContent = (_) => (
        <span dangerouslySetInnerHTML={{ __html: _ ?? '' }} />
    );
    const createColumns = () => {
        const { id, ...cellTexts } = cellData;
        const classKey = path === '/Nutritionists' ? 'cell-data-open' : '';
        const rows = map(columns, (column, index) => {
            return (
                <Grid.Column
                    className={classKey}
                    key={`${rowIndex}-${index}`}
                    width={cellWidth[index] ?? 3}
                >
                    {isLoading ? (
                        loadingText()
                    ) : (
                        <div className="text-data">
                            <Popup
                                className="view-grid-popup"
                                button={
                                    <label className="expire-input center-list">
                                        {isHtml[index]
                                            ? getContent(cellTexts[column])
                                            : cellTexts[column]}
                                    </label>
                                }
                                content={
                                    isHtml[index]
                                        ? getContent(cellTexts[column])
                                        : cellTexts[column]
                                }
                                position="bottom center"
                                size="small"
                                hideOnScroll={false}
                                inverted
                                on="hover"
                            />
                        </div>
                    )}
                </Grid.Column>
            );
        });
        if (props.children !== undefined && loading === false)
            rows.push(props.children(id, cellTexts));
        if (loading)
            rows.push(
                <Grid.Column key={`${rowIndex}`}>{loadingText()}</Grid.Column>
            );
        return rows;
    };
    if (cellWidth !== undefined) {
        return (
            <Grid.Row className={isLoading ? 'cell-data loading' : 'cell-data'}>
                {isEmpty(cellData) && loading === false
                    ? createEmptyColums()
                    : createColumns()}
            </Grid.Row>
        );
    } else return null;
};

GridRowForAdmin.propTypes = {
    cellData: PropTypes.object.isRequired,
    roles: PropTypes.string.isRequired,
    config: PropTypes.object.isRequired,
};

export { GridRowForAdmin };
