import React from 'react';
import { Segment, Grid } from 'semantic-ui-react';
import { ButtonGroupComp } from '_components/ButtonGroup/ButtonGroup';
import { LineGraph } from '_components/LineGraph/LineGraph';

const AnalyticSection = (props) => {
    const {
        analytics,
        aLoading,
        analyticsBtns,
        analyticData,
        anyUnit,
        handleClick,
    } = props;
    return (
        <Segment.Group className="width-100">
            <Segment className="section-header">{analytics?.title}</Segment>
            <Segment loading={aLoading} className="sub-section">
                <Grid>
                    <Grid.Row>
                        <Grid.Column>
                            <div className="analyticsBtngrp">
                                <ButtonGroupComp
                                    items={analyticsBtns}
                                    handleBtnClick={handleClick}
                                />
                            </div>
                            <LineGraph
                                data={analyticData}
                                graphType="Analytics"
                                unit={anyUnit}
                            />
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Segment>
        </Segment.Group>
    );
};

export default AnalyticSection;
