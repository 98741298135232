import { batch } from 'react-redux';
import TIPS from '_constants';
import { tipsService } from '_services';
import { lang } from '_config';
import moment from 'moment';
import { getErrorMsg } from '_utils';
const {
    menuList: {
        messages,
        error: {
            tips: { generaladvices, physical, recipes, food },
        },
    },
} = lang;
const getGeranal = (params) => {
    const queryParams = {
        pageNumber: params?.pageNumber ?? 0,
        direction: params?.direction ?? 'DESC',
        orderBy: params?.orderBy ?? 'TITLE',
        search: params?.search ?? '',
        category: params?.category ?? 'GENERAL',
    };
    return (dispatch) => {
        const onError = (error) => {
            batch(() => {
                dispatch({
                    type: TIPS.TIPS_LIST_FAILURE,
                    faliure: error ?? null,
                });
                dispatch({
                    type: TIPS.ERROR,
                    message: error ?? null,
                });
                setTimeout(() => dispatch({ type: TIPS.CLEAR }), TIPS.TIMEOUT);
            });
        };
        batch(() => {
            dispatch({ type: TIPS.TIPS_REQUEST_START });
            dispatch({ type: TIPS.CLEAR });
        });
        tipsService
            .generalaAvicesList(queryParams)
            .then((result) => {
                if (result && result?.status) {
                    dispatch({
                        type: TIPS.TIPS_LIST_SUCCESS,
                        result,
                        params: queryParams,
                    });
                } else {
                    onError(result);
                }
            })
            .catch(onError)
            .finally(() => {
                dispatch({ type: TIPS.TIPS_REQUEST_END });
            });
    };
};
const getPhysical = (params) => {
    const queryParams = {
        pageNumber: params?.pageNumber ?? 0,
        direction: params?.direction ?? 'DESC',
        orderBy: params?.orderBy ?? 'TITLE',
        search: params?.search ?? '',
        category: params?.category ?? 'PHYSICAL',
    };
    return (dispatch) => {
        const onError = (error) => {
            batch(() => {
                dispatch({
                    type: TIPS.TIPS_LIST_FAILURE,
                    faliure: error ?? null,
                });
                dispatch({
                    type: TIPS.ERROR,
                    message: error ?? null,
                });
                setTimeout(() => dispatch({ type: TIPS.CLEAR }), TIPS.TIMEOUT);
            });
        };
        batch(() => {
            dispatch({ type: TIPS.TIPS_REQUEST_START });
            dispatch({ type: TIPS.CLEAR });
        });
        tipsService
            .physicalActivityList(queryParams)
            .then((result) => {
                if (result && result?.status) {
                    dispatch({
                        type: TIPS.TIPS_LIST_SUCCESS,
                        result,
                        params: queryParams,
                    });
                } else {
                    onError(result);
                }
            })
            .catch(onError)
            .finally(() => {
                dispatch({ type: TIPS.TIPS_REQUEST_END });
            });
    };
};
const getRecipes = (params) => {
    const queryParams = {
        pageNumber: params?.pageNumber ?? 0,
        direction: params?.direction ?? 'DESC',
        orderBy: params?.orderBy ?? 'TITLE',
        search: params?.search ?? '',
        category: params?.category ?? 'RECIPES',
    };
    return (dispatch) => {
        const onError = (error) => {
            batch(() => {
                dispatch({
                    type: TIPS.TIPS_LIST_FAILURE,
                    faliure: error ?? null,
                });
                dispatch({
                    type: TIPS.ERROR,
                    message: error ?? null,
                });
                setTimeout(() => dispatch({ type: TIPS.CLEAR }), TIPS.TIMEOUT);
            });
        };
        batch(() => {
            dispatch({ type: TIPS.TIPS_REQUEST_START });
            dispatch({ type: TIPS.CLEAR });
        });
        tipsService
            .recipesList(queryParams)
            .then((result) => {
                if (result && result?.status) {
                    dispatch({
                        type: TIPS.TIPS_LIST_SUCCESS,
                        result,
                        params: queryParams,
                    });
                } else {
                    onError(result);
                }
            })
            .catch(onError)
            .finally(() => {
                dispatch({ type: TIPS.TIPS_REQUEST_END });
            });
    };
};
const addGen = (newRecord) => {
    return (dispatch) => {
        const onError = (error) => {
            batch(() => {
                dispatch({
                    type: TIPS.TIPS_ADD_FAILURE,
                    faliure: getErrorMsg(error, generaladvices?.create) ?? null,
                    params: newRecord,
                });
                dispatch({
                    type: TIPS.ERROR,
                    message: getErrorMsg(error, generaladvices?.create) ?? null,
                });
                setTimeout(() => dispatch({ type: TIPS.CLEAR }), TIPS.TIMEOUT);
            });
        };
        batch(() => {
            dispatch({ type: TIPS.TIPS_REQUEST_START });
            dispatch({ type: TIPS.CLEAR });
        });
        tipsService
            .addGen(newRecord)
            .then((res) => {
                res.status
                    ? dispatch({
                          type: TIPS.SUCCESS,
                          message: {
                              suc:
                                  generaladvices?.create[200]?.msg ??
                                  messages?.add,
                          },
                      })
                    : onError(res);
            })
            .catch(onError)
            .finally(() => {
                batch(() => {
                    dispatch({ type: TIPS.TIPS_REQUEST_END });
                    setTimeout(
                        () => dispatch(getGeranal({ category: 'GENERAL' })),
                        TIPS.TIMEOUT
                    );
                });
            });
    };
};
const editGen = (selectedRecord) => {
    return (dispatch) => {
        const onError = (error) => {
            batch(() => {
                dispatch({
                    type: TIPS.TIPS_ADD_FAILURE,
                    faliure: error ?? null,
                    params: selectedRecord,
                });
                dispatch({ type: TIPS.ERROR, message: error ?? null });
            });
            setTimeout(() => dispatch({ type: TIPS.CLEAR }), TIPS.TIMEOUT);
        };
        batch(() => {
            dispatch({ type: TIPS.TIPS_REQUEST_START });
            dispatch({ type: TIPS.CLEAR });
        });
        tipsService
            .editGen(selectedRecord)
            .then((res) => {
                res.status
                    ? dispatch({
                          type: TIPS.TIPS_SELECTED_ID,
                          result: res,
                          params: selectedRecord,
                      })
                    : onError(res);
            })
            .catch(onError)
            .finally(() => {
                batch(() => {
                    dispatch({ type: TIPS.TIPS_REQUEST_END });
                });
            });
    };
};
const updateGen = (selectedRecord) => {
    return (dispatch) => {
        const onError = (error) => {
            batch(() => {
                dispatch({
                    type: TIPS.TIPS_ADD_FAILURE,
                    faliure: getErrorMsg(error, generaladvices?.put) ?? null,
                    params: selectedRecord,
                });
                dispatch({
                    type: TIPS.ERROR,
                    message: getErrorMsg(error, generaladvices?.put) ?? null,
                });
                setTimeout(() => dispatch({ type: TIPS.CLEAR }), TIPS.TIMEOUT);
            });
        };
        batch(() => {
            dispatch({ type: TIPS.TIPS_REQUEST_START });
            dispatch({ type: TIPS.CLEAR });
        });
        tipsService
            .updateGen(selectedRecord)
            .then((res) => {
                res.status
                    ? dispatch({
                          type: TIPS.SUCCESS,
                          message: {
                              suc:
                                  generaladvices?.put[200]?.msg ??
                                  messages?.update,
                          },
                      })
                    : onError(res);
            })
            .catch(onError)
            .finally(() => {
                batch(() => {
                    dispatch({ type: TIPS.TIPS_REQUEST_END });
                    setTimeout(
                        () => dispatch(getGeranal({ category: 'GENERAL' })),
                        TIPS.TIMEOUT
                    );
                });
            });
    };
};
const addRecipies = (newRecord) => {
    const { availableFrom } = newRecord;
    newRecord.availableFrom = moment(availableFrom, 'DD-MM-YYYY').format(
        'YYYY-MM-DD'
    );
    return (dispatch) => {
        const onError = (error) => {
            batch(() => {
                dispatch({
                    type: TIPS.TIPS_ADD_FAILURE,
                    faliure: getErrorMsg(error, recipes?.create) ?? null,
                    params: newRecord,
                });
                dispatch({
                    type: TIPS.ERROR,
                    message: getErrorMsg(error, recipes?.create) ?? null,
                });
                setTimeout(() => dispatch({ type: TIPS.CLEAR }), TIPS.TIMEOUT);
            });
        };
        batch(() => {
            dispatch({ type: TIPS.TIPS_REQUEST_START });
            dispatch({ type: TIPS.CLEAR });
        });
        tipsService
            .recipesAdd(newRecord)
            .then((res) => {
                res.status
                    ? dispatch({
                          type: TIPS.SUCCESS,
                          message: {
                              suc: recipes?.create[200]?.msg ?? messages?.add,
                          },
                      })
                    : onError(res);
            })
            .catch(onError)
            .finally(() => {
                batch(() => {
                    dispatch({ type: TIPS.TIPS_REQUEST_END });
                    setTimeout(() => dispatch(getRecipes()), TIPS.TIMEOUT);
                });
            });
    };
};
const updateRecipies = (selectedRecord) => {
    const { availableFrom } = selectedRecord;
    selectedRecord.availableFrom = moment(availableFrom, 'DD-MM-YYYY').format(
        'YYYY-MM-DD'
    );
    return (dispatch) => {
        const onError = (error) => {
            batch(() => {
                dispatch({
                    type: TIPS.TIPS_ADD_FAILURE,
                    faliure: getErrorMsg(error, recipes?.update) ?? null,
                    params: selectedRecord,
                });
                dispatch({
                    type: TIPS.ERROR,
                    message: getErrorMsg(error, recipes?.update) ?? null,
                });
                setTimeout(() => dispatch({ type: TIPS.CLEAR }), TIPS.TIMEOUT);
            });
        };
        batch(() => {
            dispatch({ type: TIPS.TIPS_REQUEST_START });
            dispatch({ type: TIPS.CLEAR });
        });
        tipsService
            .recipesUpdate(selectedRecord)
            .then((res) => {
                res.status
                    ? dispatch({
                          type: TIPS.SUCCESS,
                          message: {
                              suc:
                                  recipes?.update[200]?.msg ?? messages?.update,
                          },
                      })
                    : onError(res);
            })
            .catch(onError)
            .finally(() => {
                batch(() => {
                    dispatch({ type: TIPS.TIPS_REQUEST_END });
                    setTimeout(() => dispatch(getRecipes()), TIPS.TIMEOUT);
                });
            });
    };
};
const delRecipies = (selectedRecord) => {
    return (dispatch) => {
        const onError = (error) => {
            batch(() => {
                dispatch({
                    type: TIPS.TIPS_ADD_FAILURE,
                    faliure: getErrorMsg(error, recipes?.delete) ?? null,
                    params: selectedRecord,
                });
                dispatch({
                    type: TIPS.ERROR,
                    message: getErrorMsg(error, recipes?.delete) ?? null,
                });
                setTimeout(() => dispatch({ type: TIPS.CLEAR }), TIPS.TIMEOUT);
            });
        };
        batch(() => {
            dispatch({ type: TIPS.TIPS_REQUEST_START });
            dispatch({ type: TIPS.CLEAR });
        });
        tipsService
            .recipesDeleted(selectedRecord)
            .then((res) => {
                res.status
                    ? dispatch({
                          type: TIPS.SUCCESS,
                          message: {
                              suc:
                                  recipes?.delete[200]?.msg ?? messages?.delete,
                          },
                      })
                    : onError(res);
            })
            .catch(onError)
            .finally(() => {
                batch(() => {
                    dispatch({ type: TIPS.TIPS_REQUEST_END });
                    setTimeout(() => dispatch(getRecipes()), TIPS.TIMEOUT);
                });
            });
    };
};
const delGen = (selectedRecord) => {
    return (dispatch) => {
        const onError = (error) => {
            batch(() => {
                dispatch({
                    type: TIPS.TIPS_ADD_FAILURE,
                    faliure: getErrorMsg(error, generaladvices?.delete) ?? null,
                    params: selectedRecord,
                });
                dispatch({
                    type: TIPS.ERROR,
                    message: getErrorMsg(error, generaladvices?.delete) ?? null,
                });
                setTimeout(() => dispatch({ type: TIPS.CLEAR }), TIPS.TIMEOUT);
            });
        };
        batch(() => {
            dispatch({ type: TIPS.TIPS_REQUEST_START });
            dispatch({ type: TIPS.CLEAR });
        });
        tipsService
            .delGen(selectedRecord)
            .then((res) => {
                res.status
                    ? dispatch({
                          type: TIPS.SUCCESS,
                          message: {
                              suc:
                                  generaladvices?.delete[200]?.msg ??
                                  messages?.delete,
                          },
                      })
                    : onError(res);
            })
            .catch(onError)
            .finally(() => {
                batch(() => {
                    dispatch({ type: TIPS.TIPS_REQUEST_END });
                    setTimeout(
                        () => dispatch(getGeranal({ category: 'GENERAL' })),
                        TIPS.TIMEOUT
                    );
                });
            });
    };
};
const editRecipies = (selectedRecord) => {
    return (dispatch) => {
        const onError = (error) => {
            batch(() => {
                dispatch({
                    type: TIPS.TIPS_ADD_FAILURE,
                    faliure: error ?? null,
                    params: selectedRecord,
                });
                dispatch({ type: TIPS.ERROR, message: error ?? null });
                setTimeout(() => dispatch({ type: TIPS.CLEAR }), TIPS.TIMEOUT);
            });
        };
        batch(() => {
            dispatch({ type: TIPS.TIPS_REQUEST_START });
            dispatch({ type: TIPS.CLEAR });
        });
        tipsService
            .recipesEdit(selectedRecord)
            .then((res) => {
                res.status
                    ? dispatch({
                          type: TIPS.TIPS_SELECTED_ID,
                          result: res,
                          params: selectedRecord,
                      })
                    : onError(res);
            })
            .catch(onError)
            .finally(() => {
                batch(() => {
                    dispatch({ type: TIPS.TIPS_REQUEST_END });
                });
            });
    };
};
const addPyhsical = (newRecord) => {
    return (dispatch) => {
        const onError = (error) => {
            batch(() => {
                dispatch({
                    type: TIPS.TIPS_ADD_FAILURE,
                    faliure: getErrorMsg(error, physical?.create) ?? null,
                    params: newRecord,
                });
                dispatch({
                    type: TIPS.ERROR,
                    message: getErrorMsg(error, physical?.create) ?? null,
                });
                setTimeout(() => dispatch({ type: TIPS.CLEAR }), TIPS.TIMEOUT);
            });
        };
        batch(() => {
            dispatch({ type: TIPS.TIPS_REQUEST_START });
            dispatch({ type: TIPS.CLEAR });
        });
        tipsService
            .addPyhsical(newRecord)
            .then((res) => {
                res.status
                    ? dispatch({
                          type: TIPS.SUCCESS,
                          message: {
                              suc: physical?.create[200]?.msg ?? messages?.add,
                          },
                      })
                    : onError(res);
            })
            .catch(onError)
            .finally(() => {
                batch(() => {
                    dispatch({ type: TIPS.TIPS_REQUEST_END });
                    setTimeout(
                        () => dispatch(getPhysical({ category: 'PHYSICAL' })),
                        TIPS.TIMEOUT
                    );
                });
            });
    };
};
const editPyhsical = (selectedRecord) => {
    return (dispatch) => {
        const onError = (error) => {
            batch(() => {
                dispatch({
                    type: TIPS.TIPS_ADD_FAILURE,
                    faliure: error ?? null,
                    params: selectedRecord,
                });
                dispatch({ type: TIPS.ERROR, message: error ?? null });
                setTimeout(() => dispatch({ type: TIPS.CLEAR }), TIPS.TIMEOUT);
            });
        };
        batch(() => {
            dispatch({ type: TIPS.TIPS_REQUEST_START });
            dispatch({ type: TIPS.CLEAR });
        });
        tipsService
            .editPhysicalactivity(selectedRecord)
            .then((res) => {
                res.status
                    ? dispatch({
                          type: TIPS.TIPS_SELECTED_ID,
                          result: res,
                          params: selectedRecord,
                      })
                    : onError(res);
            })
            .catch(onError)
            .finally(() => {
                batch(() => {
                    dispatch({ type: TIPS.TIPS_REQUEST_END });
                });
            });
    };
};
const updatePyhsical = (selectedRecord) => {
    return (dispatch) => {
        const onError = (error) => {
            batch(() => {
                dispatch({
                    type: TIPS.TIPS_ADD_FAILURE,
                    faliure: getErrorMsg(error, physical?.update) ?? null,
                    params: selectedRecord,
                });
                dispatch({
                    type: TIPS.ERROR,
                    message: getErrorMsg(error, physical?.update) ?? null,
                });
                setTimeout(() => dispatch({ type: TIPS.CLEAR }), TIPS.TIMEOUT);
            });
        };
        batch(() => {
            dispatch({ type: TIPS.TIPS_REQUEST_START });
            dispatch({ type: TIPS.CLEAR });
        });
        tipsService
            .updatePyhsical(selectedRecord)
            .then((res) => {
                res.status
                    ? dispatch({
                          type: TIPS.SUCCESS,
                          message: {
                              suc:
                                  physical?.update[200]?.msg ??
                                  messages?.update,
                          },
                      })
                    : onError(res);
            })
            .catch(onError)
            .finally(() => {
                batch(() => {
                    dispatch({ type: TIPS.TIPS_REQUEST_END });
                    setTimeout(
                        () => dispatch(getPhysical({ category: 'PHYSICAL' })),
                        TIPS.TIMEOUT
                    );
                });
            });
    };
};
const delPyhsical = (selectedRecord) => {
    return (dispatch) => {
        const onError = (error) => {
            batch(() => {
                dispatch({
                    type: TIPS.TIPS_ADD_FAILURE,
                    faliure: getErrorMsg(error, physical?.delete) ?? null,
                    params: selectedRecord,
                });
                dispatch({
                    type: TIPS.ERROR,
                    message: getErrorMsg(error, physical?.delete) ?? null,
                });
                setTimeout(() => dispatch({ type: TIPS.CLEAR }), TIPS.TIMEOUT);
            });
        };
        batch(() => {
            dispatch({ type: TIPS.TIPS_REQUEST_START });
            dispatch({ type: TIPS.CLEAR });
        });
        tipsService
            .delPyhsical(selectedRecord)
            .then((res) => {
                res.status
                    ? dispatch({
                          type: TIPS.SUCCESS,
                          message: {
                              suc:
                                  physical?.delete[200]?.msg ??
                                  messages?.delete,
                          },
                      })
                    : onError(res);
            })
            .catch(onError)
            .finally(() => {
                batch(() => {
                    dispatch({ type: TIPS.TIPS_REQUEST_END });
                    setTimeout(
                        () => dispatch(getPhysical({ category: 'PHYSICAL' })),
                        TIPS.TIMEOUT
                    );
                });
            });
    };
};

const getFoodCategories = () => {
    return (dispatch) => {
        const onError = (error) => {
            batch(() => {
                dispatch({
                    type: TIPS.NUTR_FAILURE,
                    faliure: error ?? null,
                });
                dispatch({
                    type: TIPS.NUTR_ERROR,
                    message: error ?? null,
                });
                setTimeout(() => dispatch({ type: TIPS.CLEAR }), TIPS.TIMEOUT);
            });
        };
        batch(() => {
            dispatch({ type: TIPS.NUTR_REQUEST_START });
            // dispatch({ type: TIPS.NUTR_CLEAR });
        });
        tipsService
            .getFoodCategories()
            .then((result) => {
                if (result && result?.status) {
                    dispatch({
                        type: TIPS.NUTR_FOODCAT_SUCCESS,
                        result,
                    });
                } else {
                    onError(result);
                }
            })
            .catch(onError)
            .finally(() => {
                dispatch({ type: TIPS.NUTR_REQUEST_END });
            });
    };
};

const getFoodList = (pageNumber) => {
    return (dispatch) => {
        const onError = (error) => {
            batch(() => {
                dispatch({
                    type: TIPS.NUTR_FAILURE,
                    faliure: error ?? null,
                });
                dispatch({
                    type: TIPS.NUTR_ERROR,
                    message: error ?? null,
                });
                setTimeout(() => dispatch({ type: TIPS.CLEAR }), TIPS.TIMEOUT);
            });
        };
        batch(() => {
            dispatch({ type: TIPS.NUTR_REQUEST_START });
            // dispatch({ type: TIPS.NUTR_CLEAR });
        });
        tipsService
            .getFoodList(pageNumber)
            .then((result) => {
                if (result && result?.status) {
                    dispatch({
                        type: TIPS.NUTR_FOODLIST_SUCCESS,
                        result,
                    });
                } else {
                    onError(result);
                }
            })
            .catch(onError)
            .finally(() => {
                dispatch({ type: TIPS.NUTR_REQUEST_END });
            });
    };
};

const getFoodUnit = () => {
    return (dispatch) => {
        const onError = (error) => {
            batch(() => {
                dispatch({
                    type: TIPS.NUTR_FAILURE,
                    faliure: error ?? null,
                });
                dispatch({
                    type: TIPS.NUTR_ERROR,
                    message: error ?? null,
                });
                setTimeout(() => dispatch({ type: TIPS.CLEAR }), TIPS.TIMEOUT);
            });
        };
        batch(() => {
            dispatch({ type: TIPS.NUTR_REQUEST_START });
            // dispatch({ type: TIPS.NUTR_CLEAR });
        });
        tipsService
            .getFoodUnit()
            .then((result) => {
                if (result && result?.status) {
                    dispatch({
                        type: TIPS.NUTR_FOODUNIT_SUCCESS,
                        result,
                    });
                } else {
                    onError(result);
                }
            })
            .catch(onError)
            .finally(() => {
                dispatch({ type: TIPS.NUTR_REQUEST_END });
            });
    };
};

const createFood = (foodObj) => {
    return (dispatch) => {
        const onError = (error) => {
            batch(() => {
                dispatch({
                    type: TIPS.NUTR_FAILURE,
                    faliure: getErrorMsg(error, food?.create) ?? null,
                });
                dispatch({
                    type: TIPS.NUTR_ERROR,
                    message: getErrorMsg(error, food?.create) ?? null,
                });
                setTimeout(() => dispatch({ type: TIPS.CLEAR }), TIPS.TIMEOUT);
            });
        };
        batch(() => {
            dispatch({ type: TIPS.NUTR_REQUEST_START });
            // dispatch({ type: TIPS.NUTR_CLEAR });
        });
        tipsService
            .createFood(foodObj)
            .then((result) => {
                if (result && result?.status) {
                    batch(() => {
                        dispatch({
                            type: TIPS.NUTR_FOODCREATE_SUCCESS,
                            result,
                        });
                        dispatch({
                            type: TIPS.SUCCESS,
                            message: {
                                suc: food?.create[200]?.msg ?? messages?.add,
                            },
                        });
                        setTimeout(
                            () => dispatch({ type: TIPS.CLEAR }),
                            TIPS.TIMEOUT
                        );
                    });
                } else {
                    onError(result);
                }
            })
            .catch(onError)
            .finally(() => {
                dispatch({ type: TIPS.NUTR_REQUEST_END });
            });
    };
};

const updateFood = (foodObj) => {
    return (dispatch) => {
        const onError = (error) => {
            batch(() => {
                dispatch({
                    type: TIPS.NUTR_FAILURE,
                    faliure: getErrorMsg(error, food?.update) ?? null,
                });
                dispatch({
                    type: TIPS.NUTR_ERROR,
                    message: getErrorMsg(error, food?.update) ?? null,
                });
                setTimeout(() => dispatch({ type: TIPS.CLEAR }), TIPS.TIMEOUT);
            });
        };
        batch(() => {
            dispatch({ type: TIPS.NUTR_REQUEST_START });
            // dispatch({ type: TIPS.NUTR_CLEAR });
        });
        tipsService
            .updateFood(foodObj)
            .then((result) => {
                if (result && result?.status) {
                    batch(() => {
                        dispatch({
                            type: TIPS.NUTR_UPDATEFOOD_SUCCESS,
                            result,
                        });
                        dispatch({
                            type: TIPS.SUCCESS,
                            message: {
                                suc: food?.update[200]?.msg ?? messages?.update,
                            },
                        });
                        setTimeout(
                            () => dispatch({ type: TIPS.CLEAR }),
                            TIPS.TIMEOUT
                        );
                    });
                } else {
                    onError(result);
                }
            })
            .catch(onError)
            .finally(() => {
                dispatch({ type: TIPS.NUTR_REQUEST_END });
            });
    };
};

const deleteFood = (id) => {
    return (dispatch) => {
        const onError = (error) => {
            batch(() => {
                dispatch({
                    type: TIPS.NUTR_FAILURE,
                    faliure: getErrorMsg(error, food?.delete) ?? null,
                });
                dispatch({
                    type: TIPS.NUTR_ERROR,
                    message: getErrorMsg(error, food?.delete) ?? null,
                });
                setTimeout(() => dispatch({ type: TIPS.CLEAR }), TIPS.TIMEOUT);
            });
        };
        batch(() => {
            dispatch({ type: TIPS.NUTR_REQUEST_START });
            // dispatch({ type: TIPS.NUTR_CLEAR });
        });
        tipsService
            .deleteFood(id)
            .then((result) => {
                if (result && result?.status) {
                    batch(() => {
                        dispatch({
                            type: TIPS.NUTR_DELETEFOOD_SUCCESS,
                            result,
                        });
                        dispatch({
                            type: TIPS.SUCCESS,
                            message: {
                                suc: food?.delete[200]?.msg ?? messages?.delete,
                            },
                        });
                        setTimeout(
                            () => dispatch({ type: TIPS.CLEAR }),
                            TIPS.TIMEOUT
                        );
                    });
                } else {
                    onError(result);
                }
            })
            .catch(onError)
            .finally(() => {
                dispatch({ type: TIPS.NUTR_REQUEST_END });
            });
    };
};

const getFoodById = (id) => {
    return (dispatch) => {
        const onError = (error) => {
            batch(() => {
                dispatch({
                    type: TIPS.NUTR_FAILURE,
                    faliure: error ?? null,
                });
                dispatch({
                    type: TIPS.NUTR_ERROR,
                    message: error ?? null,
                });
                setTimeout(() => dispatch({ type: TIPS.CLEAR }), TIPS.TIMEOUT);
            });
        };
        batch(() => {
            dispatch({ type: TIPS.NUTR_REQUEST_START });
            // dispatch({ type: TIPS.NUTR_CLEAR });
        });
        tipsService
            .getFoodById(id)
            .then((result) => {
                if (result && result?.status) {
                    dispatch({
                        type: TIPS.NUTR_GETFOOD_SUCCESS,
                        result,
                    });
                } else {
                    onError(result);
                }
            })
            .catch(onError)
            .finally(() => {
                dispatch({ type: TIPS.NUTR_REQUEST_END });
            });
    };
};

export const tipsActions = {
    getPhysical,
    addPyhsical,
    editPyhsical,
    updatePyhsical,
    delPyhsical,

    getGeranal,
    addGen,
    editGen,
    updateGen,
    delGen,

    getRecipes,
    addRecipies,
    editRecipies,
    updateRecipies,
    delRecipies,

    getFoodCategories,
    getFoodList,
    getFoodUnit,
    createFood,
    updateFood,
    deleteFood,
    getFoodById,
};
