const initialState = {
    videoData: {},
    previewTracks: null,
    hasJoinedRoom: false,
};
const reducer = (state, action) => {
    const { type, payload } = action;
    switch (type) {
        case 'roomData':
            return { ...state, videoData: payload };
        default:
            throw new Error();
    }
};
export { initialState, reducer };
