import React, { useState } from 'react';
import { Modal, Button, Form } from 'semantic-ui-react';
import { useForm } from 'react-hook-form';
import { Input } from '_components';
import './UpgradeCEANModal.scss';

export const UpgradeCEANModal = (props) => {
    const {
        config,
        infoData,
        isModalOpen,
        handleClose,
        onSubmit,
        upgradeKetoToCean,
        userRole,
    } = props;
    const { register, handleSubmit, formState } = useForm({
        mode: 'onChange',
    });
    const [nutritionalId, setNutrId] = useState('');
    const [linkform, setlinkID] = useState(false);
    const handleUpgrade = () => {
        let data = {
            id: infoData.id,
            reqObj: { nutritionalRecordId: nutritionalId, userRole },
        };
        setlinkID(false);
        upgradeKetoToCean(data);
        handleClose();
    };
    const modalClose = () => {
        setNutrId('');
        setlinkID(false);
        handleClose();
    };
    return (
        <Modal
            size="large"
            open={isModalOpen}
            onClose={modalClose}
            className="upgrade-cean-modal"
        >
            <div className="upgrade-cean-heading">
                {linkform ? (
                    <label>
                        {config?.preText}
                        <span className="hielight-text">
                            {infoData?.firstName} {infoData?.lastName}
                        </span>
                        {config?.postText}
                        <span className="hielight-text">{nutritionalId}</span>?
                    </label>
                ) : (
                    <label>{config?.header}</label>
                )}
            </div>
            {linkform ? (
                <div className="upgrade-cean-footer-button">
                    <Button
                        className="action-upgrade"
                        onClick={handleUpgrade}
                        size="large"
                    >
                        {config?.yes ?? 'Yes'}
                    </Button>
                    <Button
                        className="action-cancel"
                        onClick={() => setlinkID(false)}
                        size="large"
                    >
                        {config?.cancel ?? 'Cancel'}
                    </Button>
                </div>
            ) : (
                <Form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
                    <Form.Field>
                        <Input
                            name="nutritionalId"
                            label={config?.nutritionalId}
                            value={nutritionalId}
                            onChange={(e) => setNutrId(e.target.value)}
                            refs={register({
                                required: true,
                            })}
                        />
                    </Form.Field>
                    <div className="upgrade-cean-footer-button">
                        <Button
                            disabled={!formState.isValid}
                            className="action-upgrade"
                            onClick={() => setlinkID(true)}
                            size="large"
                        >
                            {config?.upgrade ?? 'Upgrade'}
                        </Button>
                        <Button
                            className="action-cancel"
                            onClick={modalClose}
                            size="large"
                        >
                            {config?.cancel ?? 'Cancel'}
                        </Button>
                    </div>
                </Form>
            )}
        </Modal>
    );
};
