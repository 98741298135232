import { Apis, handleResponse, handelError } from './services';

const getAll = ({ pageNumber, orderBy, direction, search, category }) => {
    const requestOptions = {
        method: 'GET',
        params: {
            pageNumber,
            orderBy,
            direction,
            search,
            category,
        },
    };
    return Apis()(`/information/list`, requestOptions)
        .then(handleResponse)
        .then((data) => ({ status: true, ...data }))
        .catch(handelError);
};
const addInfo = (data) => {
    const requestOptions = {
        method: 'POST',
        data,
    };
    return Apis()(`/information`, requestOptions)
        .then(handleResponse)
        .then(() => ({ status: true }))
        .catch(handelError);
};
const editInfo = (data) => {
    const requestOptions = {
        method: 'GET',
    };
    const { id } = data;
    return Apis()(`/information/${id}`, requestOptions)
        .then(handleResponse)
        .then((res) => ({ status: true, ...res }))
        .catch(handelError);
};
const updateInfo = (data) => {
    const { id, ...rest } = data;
    const requestOptions = {
        method: 'PUT',
        data: rest,
    };

    return Apis()(`/information/${id}`, requestOptions)
        .then(handleResponse)
        .then(() => ({ status: true }))
        .catch(handelError);
};
const deleteInfo = (data) => {
    const requestOptions = {
        method: 'DELETE',
    };
    const { id } = data;
    return Apis()(`/information/${id}`, requestOptions)
        .then(handleResponse)
        .then(() => ({ status: true }))
        .catch(handelError);
};

export const infoService = {
    getAll,
    editInfo,
    addInfo,
    updateInfo,
    deleteInfo,
};
