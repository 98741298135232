import React, { useState, createRef } from 'react';
import PropTypes from 'prop-types';
import DatePicker, { registerLocale } from 'react-datepicker';
import { es } from 'date-fns/locale';
import 'react-datepicker/dist/react-datepicker.css';
import './DateTimePicker.scss';
import * as moment from 'moment';
const DateTimePicker = (props) => {
    const {
        showTime,
        fromTo,
        dateFormat,
        label,
        refs,
        placeholder,
        value,
        maxDate,
        fluid,
    } = props;
    registerLocale('es', es);

    const startDate =
        value[0] && value[0] !== '' ? moment(value[0], 'YYYY-MM-DD')._d : null;
    const endDate =
        value[1] && value[1] !== '' ? moment(value[1], 'YYYY-MM-DD')._d : null;
    const [date, setDate] = useState({ Start: startDate, End: endDate });
    const validTime = (valid) => {
        switch (valid) {
            case 'min':
                return moment(date.Start)._d;
            case 'max':
                return moment(date.Start).endOf('day')._d;
            default:
                return date.Start;
        }
    };
    const validDate = (valid, cur) => {
        switch (valid) {
            case 'min':
                return cur === 'from' ? moment()._d : moment(date.Start)._d;
            case 'max':
                return cur === 'from'
                    ? moment().add(3, 'month')._d
                    : moment(date.Start).add(3, 'month')._d;
            default:
                return date.Start;
        }
    };
    const format = showTime ? 'h:mm aa' : dateFormat;
    return (
        <div className="dmi-date-time-picker">
            <label>{label}</label>
            <div
                className={`date-picker ${fromTo ? 'from-to' : ''} ${
                    fluid ? 'fluid' : ''
                }`}
            >
                <DatePicker
                    name="from"
                    placeholderText={placeholder[0]}
                    locale="es"
                    selected={date.Start}
                    onChange={(res) => setDate({ Start: res, End: res })}
                    minDate={validDate('min', 'from')}
                    maxDate={maxDate ? validDate('max', 'from') : maxDate}
                    showTimeSelect={showTime}
                    showTimeSelectOnly={showTime}
                    timeIntervals={15}
                    timeCaption="Time"
                    className={fromTo ? 'from' : ''}
                    dateFormat={format}
                    ref={(e) => refs(e?.input ?? null)}
                />
                {fromTo && (
                    <DatePicker
                        placeholderText={placeholder[1]}
                        name="to"
                        locale="es"
                        selected={date.End}
                        minDate={validDate('min')}
                        maxDate={maxDate ? validDate('max') : maxDate}
                        minTime={validTime('min')}
                        maxTime={validTime('max')}
                        onChange={(d) => setDate({ ...date, End: d })}
                        disabled={date.End === null ? true : false}
                        showTimeSelect={showTime}
                        showTimeSelectOnly={showTime}
                        timeIntervals={15}
                        timeCaption="Time"
                        className={fromTo ? 'to' : ''}
                        dateFormat={format}
                        ref={(e) => refs(e?.input)}
                    />
                )}
            </div>
        </div>
    );
};
DateTimePicker.defaultProps = {
    fluid: false,
    showTime: false,
    fromTo: false,
    maxDate: false,
    value: ['', ''],
    label: '',
    dateFormat: 'dd-MM-yyyy',
    refs: createRef(),
    placeholder: ['From', 'To'],
};
DateTimePicker.propTypes = {
    fluid: PropTypes.bool.isRequired,
    showTime: PropTypes.bool.isRequired,
    fromTo: PropTypes.bool.isRequired,
    refs: PropTypes.func,
    dateFormat: PropTypes.string.isRequired,
    label: PropTypes.string,
    placeholder: PropTypes.array,
    value: PropTypes.array,
    maxDate: PropTypes.bool,
};

export { DateTimePicker };
