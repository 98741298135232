import { batch } from 'react-redux';
import ADMIN from '_constants';
import { adminService } from '_services';
import { isEmpty } from 'lodash';
import { lang } from '_config';
import { getErrorMsg } from '_utils';
// FOR CENTER LIST
const {
    menuList: {
        messages,
        error: { admin },
    },
} = lang;
const getCenterList = () => {
    return (dispatch, getState) => {
        const { alertData } = getState();
        const onError = (error) =>
            batch(() => {
                dispatch({
                    type: ADMIN.CENTER_LIST_FAILURE,
                    faliure: error ?? null,
                });
                dispatch({ type: ADMIN.ERROR, message: error });
                setTimeout(
                    () => dispatch({ type: ADMIN.CLEAR }),
                    ADMIN.TIMEOUT
                );
            });
        // START REQUEST SETUP
        batch(() => {
            dispatch({ type: ADMIN.CENTER_REQUEST_START });
            if (!isEmpty(alertData)) dispatch({ type: ADMIN.CLEAR });
        });
        // REQUEST START
        adminService
            .getCenterList()
            .then((result) => {
                // REQUEST SUCCESS
                if (result && result?.status) {
                    dispatch({
                        type: ADMIN.CENTER_LIST_SUCCESS,
                        result: result.data,
                    });
                } else {
                    // REQUEST FAILURE WITH BAD PARAMS
                    onError(result);
                }
            })
            // HANDEL ANY WEIRED BEHAVIOUR(LIKE NETWORK OR ANY OTHER)
            .catch(onError)
            .finally(() => {
                // REQUEST ENDED
                dispatch({ type: ADMIN.CENTER_REQUEST_END });
            });
    };
};
// FOR ADMIN LIST
const getAll = (params) => {
    const queryParams = {
        pageNumber: params?.pageNumber ?? 0,
        direction: params?.direction ?? 'DESC',
        orderBy: params?.orderBy ?? 'NAME',
        search: params?.search ?? '',
    };
    return (dispatch, getState) => {
        const { alertData } = getState();
        const onError = (error) =>
            batch(() => {
                dispatch({
                    type: ADMIN.ADMIN_LIST_FAILURE,
                    faliure: error ?? null,
                });
                dispatch({ type: ADMIN.ERROR, message: error });
                setTimeout(
                    () => dispatch({ type: ADMIN.CLEAR }),
                    ADMIN.TIMEOUT
                );
            });
        batch(() => {
            dispatch({ type: ADMIN.ADMIN_REQUEST_START });
            if (!isEmpty(alertData)) dispatch({ type: ADMIN.CLEAR });
        });
        adminService
            .getAll(queryParams)
            .then((result) => {
                if (result && result?.status) {
                    dispatch({
                        type: ADMIN.ADMIN_LIST_SUCCESS,
                        result,
                        params: queryParams,
                    });
                } else {
                    onError(result);
                }
            })
            .catch(onError)
            .finally(() => {
                dispatch({ type: ADMIN.ADMIN_REQUEST_END });
            });
    };
};
// FOR ADD A NEW ADMIN
const addAdmin = (addAdminData) => {
    return (dispatch, getState) => {
        const { alertData } = getState();
        const onError = (error) => {
            batch(() => {
                dispatch({
                    type: ADMIN.ADMIN_ADD_FAILURE,
                    error: getErrorMsg(error, admin?.create),
                });
                dispatch({
                    type: ADMIN.ERROR,
                    message: getErrorMsg(error, admin?.create) ?? null,
                });
                setTimeout(
                    () => dispatch({ type: ADMIN.CLEAR }),
                    ADMIN.TIMEOUT
                );
            });
        };
        // START REQUEST SETUP
        batch(() => {
            dispatch({ type: ADMIN.ADMIN_REQUEST_START });
            if (!isEmpty(alertData)) dispatch({ type: ADMIN.CLEAR });
        });
        // REQUEST START
        adminService
            .addAdmin(addAdminData)
            .then((res) => {
                // REQUEST SUCCESS
                if (res.status) {
                    batch(() => {
                        dispatch({
                            type: ADMIN.SUCCESS,
                            message: {
                                suc: admin?.create['200']?.msg ?? messages?.add,
                            },
                        });
                        dispatch({ type: ADMIN.ADMIN_RECORD_SUCCESS });
                        setTimeout(
                            () => dispatch(getAll({ pageNumber: 0 })),
                            ADMIN.TIMEOUT
                        );
                    });
                }
                // REQUEST FAILURE WITH BAD PARAMS
                else onError(res);
            })
            // HANDEL ANY WEIRED BEHAVIOUR(LIKE NETWORK OR ANY OTHER)
            .catch(onError)
            .finally(() => {
                // REQUEST ENDED
                dispatch({ type: ADMIN.ADMIN_REQUEST_END });
            });
    };
};
// FOR VIEW SELECTED ADMIN RECORD
const editAdmin = (params) => {
    return (dispatch, getState) => {
        const { alertData, adminList } = getState();
        const onError = (error) =>
            batch(() => {
                dispatch({
                    type: ADMIN.ADMIN_EDIT_FAILURE,
                    error,
                });
                dispatch({ type: ADMIN.ERROR, message: error ?? null });
                setTimeout(
                    () => dispatch({ type: ADMIN.CLEAR }),
                    ADMIN.TIMEOUT
                );
            });
        if (!adminList.loading) {
            // REQUEST SETUP
            batch(() => {
                dispatch({ type: ADMIN.ADMIN_REQUEST_START });
                if (!isEmpty(alertData)) dispatch({ type: ADMIN.CLEAR });
            });
            // REQUEST START
            adminService
                .editAdmin(params)
                .then((result) => {
                    // REQUEST SUCCESS
                    if (result && result?.status) {
                        dispatch({
                            type: ADMIN.ADMIN_SELECTED_ID,
                            result,
                        });
                    }
                    // REQUEST FAILURE WITH BAD PARAMS
                    else onError(result);
                })
                // HANDEL ANY WEIRED BEHAVIOUR(LIKE NETWORK OR ANY OTHER)
                .catch(onError)
                // REQUEST END
                .finally(() => dispatch({ type: ADMIN.ADMIN_REQUEST_END }));
        }
    };
};
// FOR UPDATE ADMIN RECORD
const updateAdmin = (updateAdminData) => {
    return (dispatch, getState) => {
        const { alertData } = getState();
        const onError = (error) =>
            batch(() => {
                dispatch({
                    type: ADMIN.ADMIN_EDIT_FAILURE,
                    error: getErrorMsg(error, admin?.update),
                });
                dispatch({
                    type: ADMIN.ERROR,
                    message: getErrorMsg(error, admin?.update) ?? null,
                });
                setTimeout(
                    () => dispatch({ type: ADMIN.CLEAR }),
                    ADMIN.TIMEOUT
                );
            });
        // START REQUEST SETUP
        batch(() => {
            dispatch({ type: ADMIN.ADMIN_REQUEST_START });
            if (!isEmpty(alertData)) dispatch({ type: ADMIN.CLEAR });
        });
        // REQUEST START
        adminService
            .updateAdmin(updateAdminData)
            .then((res) => {
                // REQUEST SUCCESS
                if (res?.status) {
                    batch(() => {
                        dispatch({
                            type: ADMIN.SUCCESS,
                            message: {
                                suc:
                                    admin?.update['200']?.msg ??
                                    messages?.update,
                            },
                        });
                        dispatch({ type: ADMIN.ADMIN_RECORD_SUCCESS });
                        setTimeout(
                            () => dispatch(getAll({ pageNumber: 0 })),
                            ADMIN.TIMEOUT
                        );
                    });
                }
                // REQUEST FAILURE WITH BAD PARAMS
                else onError(res);
            })
            // HANDEL ANY WEIRED BEHAVIOUR(LIKE NETWORK OR ANY OTHER)
            .catch(onError)
            .finally(() => {
                // REQUEST ENDED
                dispatch({ type: ADMIN.ADMIN_REQUEST_END });
            });
    };
};
// FOR DELETE ADMIN RECORD
const deleteAdmin = (deleteAdminData) => {
    return (dispatch, getState) => {
        const { alertData } = getState();
        const onError = (error) =>
            batch(() => {
                dispatch({
                    type: ADMIN.ADMIN_DEL_FAILURE,
                    error: getErrorMsg(error, admin?.delete),
                });
                dispatch({
                    type: ADMIN.ERROR,
                    message: getErrorMsg(error, admin?.delete) ?? null,
                });
                setTimeout(
                    () => dispatch({ type: ADMIN.CLEAR }),
                    ADMIN.TIMEOUT
                );
            });
        // START REQUEST SETUP
        batch(() => {
            dispatch({ type: ADMIN.ADMIN_REQUEST_START });
            if (!isEmpty(alertData)) dispatch({ type: ADMIN.CLEAR });
        });
        // REQUEST START
        adminService
            .deleteAdmin(deleteAdminData)
            .then((res) => {
                // REQUEST SUCCESS
                if (res?.status) {
                    batch(() => {
                        dispatch({
                            type: ADMIN.SUCCESS,
                            message: {
                                suc:
                                    admin?.delete['200']?.msg ??
                                    messages?.delete,
                            },
                        });
                        dispatch({ type: ADMIN.ADMIN_RECORD_SUCCESS });
                        setTimeout(
                            () => dispatch(getAll({ pageNumber: 0 })),
                            ADMIN.TIMEOUT
                        );
                    });
                }
                // REQUEST FAILURE WITH BAD PARAMS
                else onError(res);
            })
            // HANDEL ANY WEIRED BEHAVIOUR(LIKE NETWORK OR ANY OTHER)
            .catch(onError)
            .finally(() => {
                // REQUEST ENDED
                dispatch({ type: ADMIN.ADMIN_REQUEST_END });
            });
    };
};
// FOR CLEAR MESSAGE IF ANY ERROR OR SUCCES IS THERE
const clear = (input) => {
    return (dispatch, getState) => {
        const {
            adminList: { faliure, success },
        } = getState();
        if (!isEmpty(faliure)) dispatch({ type: ADMIN.ADMIN_CLEAR });
        if (success) dispatch({ type: ADMIN.ADMIN_CLEAR });
        if (input === true) dispatch({ type: ADMIN.ADMIN_CLEAR });
    };
};
export const AdminActions = {
    getAll,
    getCenterList,
    addAdmin,
    editAdmin,
    updateAdmin,
    deleteAdmin,
    clear,
};
