import { useSelector, useDispatch } from 'react-redux';
import { filesDetails } from '_reducers';
import { fileUploadAction } from '_actions';
const useFileUpload = () => {
    const dispatch = useDispatch();
    const getFileDetails = useSelector((state) => filesDetails(state));
    return {
        state: {
            getFileDetails,
        },
        actions: {
            isFileUpload: (file) => dispatch(fileUploadAction.uploadFile(file)),
        },
    };
};
export { useFileUpload };
