import React from 'react';
import './BMIChart.scss';
import { lang } from '_config';
import { BMICircle, Union } from 'images';
import { Image } from '_components';
import CONSTANTS from '_constants';

const { details } = lang.medicalRecord;
const { ROLES } = CONSTANTS;

const BMIChart = (props) => {
    let BMIValue = props?.data;
    const { state } = details?.BMI;
    let BMIState = '';
    let customStyle = {};
    let deg = 0;
    BMIValue =
        BMIValue !== null ? parseFloat(BMIValue).toFixed(2) ?? '' : BMIValue;
    const BMICategories = {
        underWeight: 18.5,
        healthyWeight: 25,
        overWeight: 30,
        obese: 60,
    };
    // function to calculate degree for BMI chart
    if (props?.data) {
        if (BMIValue <= BMICategories.underWeight) {
            deg = (45 / BMICategories.underWeight) * BMIValue;
            customStyle = {
                margin: '10px 0px 0px 5px',
                transform: `rotate(${deg}deg)`,
            };
            BMIState = state.underweight;
        } else if (
            BMIValue > BMICategories.underWeight &&
            BMIValue <= BMICategories.healthyWeight
        ) {
            let secDivDeg = BMIValue - BMICategories.underWeight;
            let secDivDiff =
                BMICategories.healthyWeight - BMICategories.underWeight;
            deg = 45 + (45 / secDivDiff) * secDivDeg;
            customStyle = {
                margin: '15px 0px 0px 15px',
                transform: `rotate(${deg}deg)`,
            };
            BMIState = state.normal;
        } else if (
            BMIValue > BMICategories.healthyWeight &&
            BMIValue <= BMICategories.overWeight
        ) {
            let secDivDeg = BMIValue - BMICategories.healthyWeight;
            let secDivDiff =
                BMICategories.overWeight - BMICategories.healthyWeight;
            deg = 90 + (45 / secDivDiff) * secDivDeg;
            customStyle = {
                margin: '15px 0px 0px 25px',
                transform: `rotate(${deg}deg)`,
            };
            BMIState = state.overweight;
        } else if (
            BMIValue > BMICategories.overWeight &&
            BMIValue <= BMICategories.obese
        ) {
            let secDivDeg = BMIValue - BMICategories.overWeight;
            let secDivDiff = BMICategories.obese - BMICategories.overWeight;
            deg = 135 + (45 / secDivDiff) * secDivDeg;
            customStyle = {
                margin: '17px 0px 0px 25px',
                transform: `rotate(${deg}deg)`,
            };
            BMIState = state.obese;
        } else if (BMIValue > BMICategories.obese) {
            customStyle = {
                margin: '25px 0px 0px 25px',
                transform: `rotate(180deg)`,
            };
            BMIState = state.obese;
        }
    }
    return (
        <div className="BMI-Chart">
            <div
                className={
                    props?.role === ROLES.ADMIN ? 'adminBMIChart' : 'BMIChart'
                }
            >
                <div className="multi-graph">
                    <div className="outer-div">
                        <div className="centered">
                            <span className="num">
                                {props?.data ? BMIValue || '--' : '--'}
                            </span>
                            <br />
                            <span className="state">{BMIState}</span>
                        </div>
                        {BMIValue ? (
                            <div className="BMICircle">
                                <Image style={customStyle} src={BMICircle} />
                            </div>
                        ) : (
                            <div className="default-circle">
                                <Image src={Union} />
                            </div>
                        )}
                    </div>
                    <div className="graph1" />
                    <div className="graph2" />
                    <div className="graph3" />
                    <div className="graph4" />
                    <div className="graph5" />
                </div>
            </div>
        </div>
    );
};

export { BMIChart };
