import React, { useCallback, useEffect, useState } from 'react';
import { Label } from 'semantic-ui-react';
import { getUserInfo } from '_utils';
const User = (props) => {
    const {
        roomInfo,
        curIndex,
        setRoomInfo,
        memberInfo,
        selectedIndex,
        category,
        createRoom,
        inviteUsers,
        userId,
        isDisable,
        searchText,
        isAdmin,
        chatClient,
        userDetails,
        msgCount,
    } = props;
    const getUserData = () => {
        const { id, firstName, lastName } = inviteUsers;
        return {
            userId: id,
            userName: `${firstName.toUpperCase()} ${lastName.toUpperCase()}`,
            uN: `${firstName.charAt(0)}${lastName.charAt(0)}`,
            status: true,
        };
    };
    let userData =
        roomInfo !== undefined
            ? getUserInfo(roomInfo, category, searchText, isAdmin)
            : getUserData();

    const [unReadMsg, setunReadMsg] = useState(0);
    const getMsg = useCallback(async () => {
        if (roomInfo !== undefined)
            roomInfo.getUnconsumedMessagesCount().then((res) => {
                setunReadMsg(res ?? msgCount);
            });
    }, [roomInfo, msgCount]);
    useEffect(() => {
        getMsg();
    }, [getMsg]);
    const { uN, userName, lastMessage, status } = userData;
    // select room info
    const selectRoom = () => {
        if (!isDisable) {
            if (category === 'NUTRITIONISTS') {
                if (isAdmin) {
                    // const [memberId] = memberInfo.split('_');
                    const uniqueName = `${category}-${userId}-${userDetails?.sub}`;
                    chatClient
                        .getChannelByUniqueName(uniqueName)
                        .then((response) => {
                            response
                                .updateAttributes({
                                    id: userId,
                                    name: userName,
                                    mName: userDetails?.Name,
                                    mId: userDetails?.sub,
                                })
                                .then(async (res) => {
                                    const members = await res.getMembers();
                                    if (members.length < 2) {
                                        try {
                                            await res.invite(
                                                `${userId}_NUTRITIONIST`
                                            );
                                        } catch (e) {}
                                    }
                                    return res;
                                })
                                .then((res) => {
                                    setRoomInfo({
                                        roomInfo: res,
                                        curIndex,
                                        memberInfo,
                                        category,
                                    });
                                });
                        })
                        .catch(() => {
                            createRoom(
                                category,
                                userId,
                                userName,
                                [`${userId}_NUTRITIONIST`],
                                userDetails?.sub
                            ).then(({ status: stat, room, err }) => {
                                if (stat)
                                    setRoomInfo({
                                        roomInfo: room,
                                        curIndex,
                                        memberInfo,
                                        category,
                                    });
                                if (err) {
                                    console.log(err);
                                }
                            });
                        });
                } else {
                    setRoomInfo({
                        roomInfo,
                        curIndex,
                        memberInfo,
                        category,
                    });
                }
            }
            // if Memeber invited
            else {
                createRoom(category, userId, userName, []).then(() => {
                    setRoomInfo({ roomInfo, curIndex, memberInfo, category });
                });
            }
            setunReadMsg(0);
        }
    };
    return (
        <>
            {status ? (
                <div
                    className={
                        selectedIndex === curIndex ? 'list active' : 'list'
                    }
                    onClick={selectRoom}
                >
                    <div className="list-profilePic">
                        <div className="profile-img">{uN?.toUpperCase()}</div>
                        {unReadMsg ? (
                            <Label color="yellow" circular floating size="tiny">
                                {unReadMsg}
                            </Label>
                        ) : (
                            ''
                        )}
                    </div>
                    <div className="list-heading">
                        <div className="list-heading-text">{userName}</div>
                        <div className="list-heading-sub-text">{userId}</div>
                    </div>
                    <div className="list-time">
                        {msgCount ? lastMessage : false}
                    </div>
                </div>
            ) : null}
        </>
    );
};

export default User;
