import { Apis, handelError, handleResponse } from './services';

const getAll = ({
    pageNumber,
    orderBy,
    direction,
    search,
    filter,
    promotionParticipated,
}) => {
    const requestOptions = {
        method: 'GET',
        params: {
            pageNumber,
            orderBy,
            direction,
            search,
            filter,
            promotionParticipated,
        },
    };
    return Apis()(`/patient/list`, requestOptions)
        .then(handleResponse)
        .then((data) => ({ status: true, ...data }))
        .catch(handelError);
};
const getPharmacyList = ({ type }) => {
    const requestOptions = {
        method: 'GET',
    };
    return Apis()(`/ketomap/${type}/list`, requestOptions)
        .then(handleResponse)
        .then((data) => ({ status: true, ...data }))
        .catch(handelError);
};
const getSymptoms = (params) => {
    const requestOptions = {
        method: 'GET',
        params,
    };
    return Apis()(`/diarysymptoms/list`, requestOptions)
        .then(handleResponse)
        .then((data) => ({ status: true, ...data }))
        .catch(handelError);
};
const getHealthRec = (params) => {
    const requestOptions = {
        method: 'GET',
        params,
    };
    return Apis()(`/food/diary/list`, requestOptions)
        .then(handleResponse)
        .then(async (res) => {
            const { status, ...rest } = await getSymptoms(params);
            return status ? { ...res, symptoms: rest?.list } : res;
        })
        .then((data) => ({ status: true, ...data }))
        .catch(handelError);
};

const editPatient = (data) => {
    const requestOptions = {
        method: 'PUT',
        data,
    };
    const { id } = data;
    return Apis()(`/patient/${id}`, requestOptions)
        .then(handleResponse)
        .then(() => ({ status: true }))
        .catch(handelError);
};

const getPatientInfo = (id) => {
    const requestOptions = {
        method: 'GET',
    };
    return Apis()(`/patient/${id}`, requestOptions)
        .then(handleResponse)
        .then((data) => ({ status: true, ...data }))
        .catch(handelError);
};

const updatePatientInfo = (data) => {
    const requestOptions = {
        method: 'PUT',
        data: data.userdata,
    };
    return Apis()(`/patient/${data.id}`, requestOptions)
        .then(handleResponse)
        .then((res) => ({ status: true, ...res }))
        .catch(handelError);
};

const getMedicalRecords = (id, type) => {
    const requestOptions = {
        method: 'GET',
    };
    return Apis()(`/medicalrecords/${id}/${type}`, requestOptions)
        .then(handleResponse)
        .then((data) => ({ status: true, ...data }))
        .catch(handelError);
};
const profileDel = (id) => {
    const requestOptions = {
        method: 'DELETE',
    };
    return Apis()(`/profile/delete/${id}`, requestOptions)
        .then(handleResponse)
        .then(() => ({ status: true }))
        .catch(handelError);
};
const getTicketId = (id) => {
    const requestOptions = {
        method: 'GET',
    };
    return Apis()(`/affiliation/ketosteril/${id}`, requestOptions)
        .then(handleResponse)
        .then((data) => ({ status: true, ...data }))
        .catch(handelError);
};
const updateTicket = (data) => {
    const { id, ...rest } = data;
    const requestOptions = {
        method: 'PUT',
        data: rest,
    };
    return Apis()(`/affiliation/update/ticket/${id}`, requestOptions)
        .then(handleResponse)
        .then(() => ({ status: true }))
        .catch(handelError);
};
const upgradeHistory = ({ pageNumber, orderBy, direction, userId }) => {
    const requestOptions = {
        method: 'GET',
        params: {
            pageNumber,
            orderBy,
            direction,
            userId,
        },
    };
    return Apis()(`affiliation/upgrade/ketosteril/list`, requestOptions)
        .then(handleResponse)
        .then((data) => ({ status: true, ...data }))
        .catch(handelError);
};
const upgradeKetoToCean = (data) => {
    const {
        reqObj: { userRole, ...rest },
    } = data;
    const requestOptions = {
        method: 'POST',
        data: rest,
    };
    return Apis()(`/affiliation/upgrade/${userRole}/${data.id}`, requestOptions)
        .then(handleResponse)
        .then((res) => ({ status: true, ...res }))
        .catch(handelError);
};
const patientDetailsUpload = (file, id) => {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'multipart/form-data' },
        data: file,
    };
    return Apis()(`/medicalrecords/${id}/upload`, requestOptions)
        .then(handleResponse)
        .then(() => ({ status: true }))
        .catch(handelError);
};
export const patientService = {
    getAll,
    getHealthRec,
    getPharmacyList,
    editPatient,
    getPatientInfo,
    getMedicalRecords,
    updatePatientInfo,
    profileDel,
    getTicketId,
    updateTicket,
    upgradeHistory,
    upgradeKetoToCean,
    patientDetailsUpload
};
