import React from 'react';
import PropTypes from 'prop-types';
import { Select } from 'semantic-ui-react';
import './RoundedSelect.scss';
const RoundedSelect = (props) => {
    const {
        placeholder,
        options,
        label,
        onChange,
        defaultValue,
    } = props;
    return (
        <div className="dmi-rounded-select">
            <div className="r-select-label">
                <div className="r-select-label-text">{label}</div>
            </div>
            <Select
                className="rounded-select"
                placeholder={placeholder} options={options}
                defaultValue={defaultValue}
                onChange={onChange}
            />
      </div>
    );
};
RoundedSelect.defaultProps = {
    options: [],
    placeholder: '',
    label: '',
    onChange: () => {},
};
RoundedSelect.propTypes = {
    options: PropTypes.array,
    onChange: PropTypes.func,
    placeholder: PropTypes.string,
    label: PropTypes.string,
};

export { RoundedSelect };
