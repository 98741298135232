import React, { useEffect } from 'react';
import { Button, Grid, Form } from 'semantic-ui-react';
import { useForm } from 'react-hook-form';
import { FileUpload, Input, Select } from '_components';
import './NutritionalFood.scss';
import { lang } from '_config';
import { isArray } from 'lodash';
// import { getFileDetail } from '_utils';

const { nutritionFoodForm, common } = lang.nutritionalTips;
const optionLevels = nutritionFoodForm?.level?.filter((item, index) => index !== 2 ? item : false);
const units = nutritionFoodForm?.units.map((it, index) => {
    return { key: index, value: it, text: it };
});
const NutritionalFood = (props) => {
    const {
        data: {
            actions: { getFoodById },
            state: { tips },
        },
        state: {
            title,
            orgTitle,
            foodValues,
            inputCount,
            formatedCategoryList,
            titleCount,
            id,
            unityTransList,
            errors,
            category,
            foodList,
        },
    } = props;
    const foodListItem = tips?.tabs?.NUTRITIONAL?.foodList?.list ?? false;
    let foodItem = [];
    if (id === null) foodItem = ['NA', 'NA', 'NA', 'NA'];
    else if (isArray(foodListItem)) {
        foodItem =
            foodListItem[0]?.id === id
                ? [
                      foodListItem[0]?.calciumLevel,
                      foodListItem[0]?.phosphorusLevel,
                      foodListItem[0]?.potassiumLevel,
                      foodListItem[0]?.sodiumLevel,
                  ]
                : [];
    }
    useEffect(() => {
        if (id !== null) getFoodById(id);
    }, [getFoodById, id]);
    const isValidTitle =
        (title.length < 2) ^ (title.length > 24) ? true : false;
    const submitFlag = () => {
        return title === orgTitle
            ? isValidTitle
            : !foodList.length > 0
            ? props?.state.category === ''
                ? true
                : props?.state.subCategory === ''
                ? true
                : props?.state?.unit === ''
                ? true
                : false
            : true;
    };

    const { register } = useForm();
    const checkListExists = (type, index) => {
        if (id) {
            if (type === 'category')
                return formatedCategoryList.length > 0 && category;
            if (type === 'subCategory') {
                return props?.state.subFoodCategoryArr.length > 0;
            }
            if (type === 'unit')
                return unityTransList.length > 0 && props?.state.unit;
            if (type === 'level')
                return (
                    props?.state.levels[`dpVal${index}`] &&
                    (nutritionFoodForm?.level || optionLevels)
                );
        } else {
            return true;
        }
    };
    const handelInvalidChar = (e, index) => {
        props.setfoodValue(e, index);
    };
    return (
        <div id="Nutritional-food-form">
            <Form>
                <Grid>
                    <Grid.Row columns={3}>
                        <Grid.Column width={5}>
                            <Form.Field>
                                <Input
                                    {...{
                                        name: 'title',
                                        label: nutritionFoodForm?.title,
                                        placeholder: common?.foodTable?.food1,
                                        onBlur: (e) => props.handleBlur(e),
                                        onChange: (e) => props.handleChange(e),
                                        refs: {
                                            register,
                                            validation: {
                                                maxLength: 24,
                                            },
                                        },
                                        currentValue:
                                            id !== null ? title : undefined,
                                        autoComplete: 'off',
                                    }}
                                />
                                <div className="margin-top-minus">
                                    <div className="count">
                                        {' '}
                                        {inputCount}/{titleCount}
                                    </div>
                                    {errors?.titleErr !== '' && (
                                        <div className="err-msg">
                                            {errors?.titleErr}
                                        </div>
                                    )}
                                    {!id &&
                                        foodList.length > 0 &&
                                        inputCount > 0 && (
                                            <div className="err-msg">
                                                {
                                                    nutritionFoodForm?.FoodExistErr
                                                }
                                            </div>
                                        )}
                                    {id &&
                                        title !== orgTitle &&
                                        foodList.length > 0 &&
                                        inputCount > 0 && (
                                            <div className="err-msg">
                                                {
                                                    nutritionFoodForm?.FoodExistErr
                                                }
                                            </div>
                                        )}
                                </div>
                            </Form.Field>
                            <Form.Field>
                                {checkListExists('category') && (
                                    <Select
                                        name="category"
                                        label={nutritionFoodForm?.category}
                                        placeholder={
                                            nutritionFoodForm?.categaoryPlaceholder
                                        }
                                        options={formatedCategoryList}
                                        loading={false}
                                        onChange={props.getSelectedCategory}
                                        value={props?.state.category}
                                        fluid
                                        search
                                    />
                                )}
                            </Form.Field>
                        </Grid.Column>

                        <Grid.Column width={6}>
                            <Grid>
                                <Grid.Row columns={2}>
                                    <Grid.Column width={8}>
                                        <Form.Field>
                                            <Input
                                                name="quantity"
                                                label={
                                                    nutritionFoodForm?.quantity
                                                }
                                                currentValue={
                                                    props?.state.quantity
                                                }
                                                placeholder={
                                                    common?.foodTable?.food1
                                                }
                                                onChange={(e) =>
                                                    props.handleChange(e)
                                                }
                                                refs={{ register }}
                                                autoComplete="off"
                                                optional={
                                                    nutritionFoodForm?.optional
                                                }
                                            />
                                        </Form.Field>
                                    </Grid.Column>
                                    <Grid.Column width={8}>
                                        <Form.Field>
                                            {checkListExists('unit') && (
                                                <Select
                                                    name="units"
                                                    label={
                                                        nutritionFoodForm?.unit
                                                    }
                                                    placeholder={units[0]?.desc}
                                                    options={unityTransList}
                                                    loading={false}
                                                    onChange={
                                                        props.getSelectedunits
                                                    }
                                                    value={props?.state.unit}
                                                    defaultValue={
                                                        props?.state.unit
                                                    }
                                                    fluid
                                                    search
                                                />
                                            )}
                                        </Form.Field>
                                    </Grid.Column>
                                </Grid.Row>

                                <Grid.Row>
                                    <Grid.Column>
                                        <Form.Field className="sub-category">
                                            {checkListExists('subCategory') && (
                                                <Select
                                                    name="subCategory"
                                                    label={
                                                        nutritionFoodForm?.subCategory
                                                    }
                                                    placeholder={
                                                        nutritionFoodForm?.subCategoryPlaceholder
                                                    }
                                                    options={
                                                        props?.state
                                                            .subFoodCategoryArr
                                                    }
                                                    loading={false}
                                                    onChange={
                                                        props.getSelectedSubCategory
                                                    }
                                                    value={
                                                        props?.state.subCategory
                                                    }
                                                    fluid
                                                    search
                                                />
                                            )}
                                        </Form.Field>
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>
                        </Grid.Column>
                        <Grid.Column width={5}>
                            <FileUpload
                                label={nutritionFoodForm?.image}
                                limit={nutritionFoodForm?.limit}
                                placeholder={nutritionFoodForm?.imgplaceholder}
                                optional={nutritionFoodForm?.optional}
                                name="tipsNutrImg"
                            />
                        </Grid.Column>
                    </Grid.Row>

                    <Grid.Row>
                        <Grid.Column>
                            <label className="food-value-label">
                                {nutritionFoodForm?.foodValue}{' '}
                                <span className="food-value-subLabel">
                                    ({nutritionFoodForm?.optional})
                                </span>
                            </label>
                            <div className="food-form">
                                {foodValues.map((item, index) => {
                                    let customStyle =
                                        index === 0 ||
                                        index === 3 ||
                                        index === 6 ||
                                        index === 9
                                            ? { margin: '10px 0px 0px 0px' }
                                            : { margin: '10px 0px 0px 22px' };
                                    return (
                                        <div
                                            key={index}
                                            style={customStyle}
                                            className="food-form-item"
                                        >
                                            <div className="food-form-label">
                                                {item.name}
                                            </div>
                                            <div className="food-form-text-input">
                                                <input
                                                    autoComplete="off"
                                                    className="food-form-text-input-text"
                                                    name={item?.name}
                                                    value={
                                                        item?.foodVal ===
                                                        undefined
                                                            ? ''
                                                            : item?.foodVal
                                                    }
                                                    onChange={(e) => {
                                                        handelInvalidChar(
                                                            e,
                                                            index
                                                        );
                                                    }}
                                                />
                                            </div>
                                            <div className="food-form-unit">
                                                {item.unit}
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row columns={4}>
                        {foodItem.length
                            ? nutritionFoodForm?.contentlevels.map(
                                  (item, index) => {
                                      return (
                                          <Grid.Column width={4} key={index}>
                                              <Form.Field>
                                                  <Select
                                                      label={item}
                                                      value={foodItem[index]}
                                                      placeholder={
                                                          nutritionFoodForm
                                                              ?.level[0].text
                                                      }
                                                      options={
                                                          index === 2
                                                              ? nutritionFoodForm?.level
                                                              : optionLevels
                                                      }
                                                      onChange={(e, data) =>
                                                          props.selectlevels(
                                                              e,
                                                              data,
                                                              index
                                                          )
                                                      }
                                                      optional={
                                                          nutritionFoodForm?.optional
                                                      }
                                                      fluid
                                                  />
                                              </Form.Field>
                                          </Grid.Column>
                                      );
                                  }
                              )
                            : null}
                    </Grid.Row>
                    <Grid.Row columns={2}>
                        <Grid.Column width={3}>
                            <Button
                                disabled={submitFlag()}
                                onClick={(e) => props.handleSubmit(e)}
                            >
                                {id
                                    ? nutritionFoodForm?.save
                                    : nutritionFoodForm?.add}
                            </Button>
                        </Grid.Column>
                        <Grid.Column width={4}>
                            {id !== null ? (
                                <Button
                                    className="outline"
                                    type="button"
                                    onClick={(e) => props.deleteFood(e)}
                                >
                                    {nutritionFoodForm?.delete}
                                </Button>
                            ) : (
                                ''
                            )}
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Form>
        </div>
    );
};

export { NutritionalFood };
