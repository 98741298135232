import { batch } from 'react-redux';
import USERS from '_constants';
import { userService } from '_services';
import { alertActions } from '_actions';
import { setUserDetails, getUserDetails, getErrorMsg } from '_utils';
import { lang } from '_config';
const {
    menuList: {
        error: {
            sign: { forgot: resetMsgs, login: loginMsgs },
        },
    },
} = lang;
export const userActions = {
    login,
    logout,
    forgot,
    reset,
    loginSuccess,
    updateLang,
    selectMenuItem,
};
function reset(userDetails) {
    return (dispatch) => {
        batch(() => {
            dispatch({ type: USERS.RESET_REQUEST_START });
            dispatch(alertActions.clear());
        });
        userService
            .reset(userDetails)
            .then((res) => {
                if (res && res?.status) {
                    dispatch(alertActions.success('UpdatePwd'));
                } else {
                    dispatch(alertActions.error({ ...res }));
                }
            })
            .catch((e) => {
                dispatch(alertActions.error({ ...e }));
            })
            .finally(() => {
                dispatch({ type: USERS.RESET_REQUEST_END });
            });
    };
}
function forgot(userDetails) {
    const { userName } = userDetails;
    return (dispatch) => {
        batch(() => {
            dispatch({ type: USERS.FORGOT_REQUEST, userName });
            dispatch(alertActions.clear());
        });
        userService
            .forgot(userDetails)
            .then(
                (user) => {
                    if (user && user?.status) {
                        dispatch(
                            alertActions.success(resetMsgs[200]?.msg ?? user)
                        );
                    } else {
                        user.msg.errNo = user?.msg?.code;
                        dispatch(
                            alertActions.error(
                                getErrorMsg(user?.msg, resetMsgs) ??
                                    user?.msg?.errMsg
                            )
                        );
                    }
                },
                (error) => {
                    dispatch(
                        alertActions.error(
                            getErrorMsg(error, resetMsgs) ?? error?.msg
                        )
                    );
                }
            )
            .catch((e) =>
                dispatch(
                    alertActions.error(getErrorMsg(e, resetMsgs) ?? e?.msg)
                )
            )
            .finally(() => {
                dispatch({ type: USERS.FORGOT_FAILURE });
            });
    };
}
function login(userDetails) {
    const { userName } = userDetails;
    const msg = { success: 'Login Successfully', error: 'No user found' };
    return (dispatch) => {
        batch(() => {
            dispatch({ type: USERS.LOGIN_REQUEST, userName });
            dispatch(alertActions.clear(msg.success));
        });
        userService.login(userDetails).then(
            (user) => {
                if (user && user?.status) {
                    setUserDetails({ token: user?.token });
                    loginSuccess(dispatch, user, msg);
                } else {
                    batch(() => {
                        dispatch({
                            type: USERS.LOGIN_FAILURE,
                            error: getErrorMsg(user, loginMsgs) ?? msg.error,
                        });
                        dispatch(
                            alertActions.error(
                                getErrorMsg(user, loginMsgs) ?? msg.error
                            )
                        );
                    });
                }
            },
            (error) => {
                dispatch({ type: USERS.LOGIN_FAILURE, error });
                dispatch(alertActions.error(getErrorMsg(error, loginMsgs)));
            }
        );
    };
}
function loginSuccess(dispatch, user, msg) {
    batch(() => {
        dispatch({
            type: USERS.LOGIN_SUCCESS,
            user: { ...user, ...getUserDetails() },
        });
        dispatch(alertActions.success(msg.success));
    });
}
function logout() {
    const msg = {
        success: 'Logout Successfully',
        error: 'Something went wrong',
    };
    return (dispatch) => {
        userService
            .logout()
            .then(() => {
                batch(() => {
                    dispatch({ type: USERS.LOGOUT });
                    dispatch(alertActions.success(msg.success));
                });
            })
            .catch(() => {
                batch(() => {
                    dispatch({ type: USERS.LOGOUT_FAILURE });
                    dispatch(alertActions.error(msg.error));
                });
            });
    };
}
function updateLang(e) {
    return { type: USERS.UPDATE_LANG, lang: e };
}
function selectMenuItem(selected) {
    return (dispatch, getState) => {
        const {
            loginData: {
                userDetails: { roles: logRoles },
            },
        } = getState();
        const { roles, ...rest } = getUserDetails();
        if (roles !== logRoles) {
            batch(() => {
                dispatch({
                    type: USERS.LOGIN_SUCCESS,
                    user: { roles, ...rest },
                });
                dispatch({
                    type: USERS.MENU_ITEM_SELECTED,
                    selected,
                    isAdmin: roles === 'Admin',
                });
            });
        } else
            dispatch({
                type: USERS.MENU_ITEM_SELECTED,
                selected,
                isAdmin: roles === 'Admin',
            });
    };
}
