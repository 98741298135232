import { connect } from 'react-redux';
import { userActions, alertActions } from '_actions';
import { userDetails, alertDetails } from '_reducers';
import { LoginPage } from '_pages';
function mapState(state) {
    return {
        state: {
            loginData: userDetails(state),
            alertData: alertDetails(state),
        },
    };
}

const actionCreators = (dispatch) => {
    return {
        actions: {
            checkLogin: (input) => {
                dispatch(userActions.login(input));
            },
            msgClear: () => dispatch(alertActions.clear()),
        },
    };
};

export const LoginContainer = connect(mapState, actionCreators)(LoginPage);
