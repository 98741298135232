import React from 'react';
import PropTypes from 'prop-types';
import { Image } from '_components';
import { approve, pending, freeBox, wrong } from 'images';
import './StatusHistory.scss';
function StatusHistory(props) {
    const {
        config: SH,
        items,
        isRejected = true,
        isPending = true,
        isDisabled = false,
    } = props;
    return (
        <div id="view-history-container">
            <div className="subtitle">{SH?.heading}</div>
            <div
                className={`box-history-container ${
                    isDisabled ? 'disabled' : ''
                }`}
            >
                {isPending && (
                    <div className="status status-pending">
                        <Image className="status-img" src={pending} />
                        <div className="status-txt">
                            {items?.pendingItems ?? 0} {SH?.pending}
                        </div>
                    </div>
                )}
                <div className="status status-approve">
                    <Image className="status-img" src={approve} />
                    <div className="status-txt">
                        {items?.acceptedtems ?? 0} {SH?.accepted}
                    </div>
                </div>
                {isRejected && (
                    <div className="status status-rejected">
                        <Image className="status-img" src={wrong} />
                        <div className="status-txt">
                            {items?.rejectedItems ?? 0} {SH?.rejected}
                        </div>
                    </div>
                )}
                <div className="status status-free">
                    <Image className="status-img" src={freeBox} />
                    <div className="status-txt">
                        {items?.free ?? 0} {SH?.free}
                    </div>
                </div>
            </div>
        </div>
    );
}

StatusHistory.propTypes = {
    config: PropTypes.object.isRequired,
};

export { StatusHistory };
