import React, { useEffect } from 'react';
import './ChatActions.scss';
import { isEmpty, trim, includes } from 'lodash';
import DefaultAction from './DefaultAction';
import AudioMsgAction from './AudioMsgAction';
import { isMobileUserOnine } from '_utils/chat';
const ChatActions = (props) => {
    const {
        chatClient,
        roomID,
        isAdmin,
        isAudioMsgRunning,
        startAudioMsg,
        stopAudioMsg,
        recordAudioMsg,
        tab,
        notifAction,
        config,
        calling,
    } = props;
    //START RECORDING MSG
    const startRecordMsg = async () => {
        const recordMsg = await recordAudioMsg();
        window.recordMsg = recordMsg;
        await recordMsg.start();
        startAudioMsg(tab);
        window.checkHangOut = setTimeout(hangAudioMsg, 5000);
    };
    //STOP AUDIO MSG
    const hangAudioMsg = async () => {
        if (window.recordMsg !== undefined) {
            const audio = await window.recordMsg.stop();
            const roomActions = await chatClient.getChannelBySid(roomID);
            await roomActions.sendMessage({
                contentType: audio?.audioBlob?.type,
                media: audio.audioBlob,
            });
            await stopAudioMsg({ tab });
            clearTimeout(window.checkHangOut);
            window.recordMsg = undefined;
            window.checkHangOut = undefined;
        }
    };
    // SEND MESSAGE
    const handleMsg = async (event) => {
        const { shiftKey, target: inputEle, key } = event;
        const textMsg = inputEle?.value;
        if (!isEmpty(trim(textMsg)) && key === 'Enter' && !shiftKey) {
            const roomActions = await chatClient.getChannelBySid(roomID);
            inputEle.value = '';
            const msgRes = await roomActions.sendMessage(trim(textMsg));
            roomActions.updateLastConsumedMessageIndex(msgRes);
            if (tab !== 'NUTRITIONISTS')
                isMobileUserOnine(roomActions, tab).then((res) => {
                    if (!isEmpty(res)) {
                        notifAction(res);
                    }
                });
        }
    };
    // SEND MEDIA MESSAGE
    const uploadImage = async (event) => {
        const [file] = event.target.files;
        const validImageTypes = ['image/gif', 'image/jpeg', 'image/png'];
        if (includes(validImageTypes, file.type)) {
            const roomActions = await chatClient.getChannelBySid(roomID);
            const msgRes = await roomActions.sendMessage({
                contentType: file.type,
                media: file,
            });
            await roomActions.updateLastConsumedMessageIndex(msgRes);
            if (tab !== 'NUTRITIONISTS')
                isMobileUserOnine(roomActions, tab).then((res) => {
                    if (!isEmpty(res)) {
                        notifAction(res);
                    }
                });
        }
    };
    useEffect(() => {
        let isChatAcionCalled = true;
        return () => {
            if (isAudioMsgRunning && isChatAcionCalled) {
                if (window.recordMsg !== undefined) window.recordMsg.stop();
                if (window.checkHangOut !== undefined)
                    clearTimeout(window.checkHangOut);
                stopAudioMsg({ tab });
                window.recordMsg = undefined;
                window.checkHangOut = undefined;
                isChatAcionCalled = false;
            }
        };
    }, [isAudioMsgRunning, stopAudioMsg, tab]);
    const isDisable = (chatClient === undefined) ^ (roomID === undefined);
    return (
        <div
            id="chat-actions"
            className={isDisable ? 'chat-actions disable' : 'chat-actions'}
        >
            {!isAudioMsgRunning ? (
                <DefaultAction
                    isAdmin={isAdmin}
                    tab={tab}
                    config={config}
                    handleMsg={handleMsg}
                    uploadImage={uploadImage}
                    startRecordMsg={startRecordMsg}
                    calling={calling}
                />
            ) : (
                <AudioMsgAction />
            )}
        </div>
    );
};

export { ChatActions };
