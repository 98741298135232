import React from 'react';
import PropTypes from 'prop-types';
import { Button, Icon } from 'semantic-ui-react';
import { Popup } from '..';
import './StickyButton.scss';
const StickyButton = (props) => {
    const { circular, size, icon, className, onClick, disabled, withInfoIcon, buttonInfoText } = props;
    return (
        <div className="dmi-button">
            <Button
                circular={circular}
                size={size}
                icon={icon}
                className={className}
                onClick={onClick}
                disabled={disabled}
            />
            {withInfoIcon ? 
                (
                <Popup
                    className="button-info-popup"
                    button={<Icon className='sticky-icon fitted grey' name="question circle" />}
                    content={buttonInfoText}
                    on="hover"
                    position="left center"
                    inverted />
                ) : 
                (
                    ''
                )
            }
        </div>
    );
};
StickyButton.defaultProps = {
    size: 'massive',
    icon: 'plus',
    className: 'sticky',
    circular: true,
    disabled: false,
};
StickyButton.propTypes = {
    onClick: PropTypes.func,
    size: PropTypes.string,
    icon: PropTypes.string,
    className: PropTypes.string,
    circular: PropTypes.bool,
    disabled: PropTypes.bool,
};

export { StickyButton };
