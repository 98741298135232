import React, { useEffect } from 'react';
import { isEmpty } from 'lodash';
import { Card, StickyButton } from '_components';
import PropTypes from 'prop-types';
import CONST from '_constants';
import './CustomList.scss';
const CustomList = (props) => {
    const {
        history,
        location: { pathname, state },
        loading,
        tabs: {
            params,
            result: { list },
        },
        config: {
            details: { cardEditText, cardDeleteText },
        },
        actions: { getCustomList, delCustomPromo },
    } = props;
    const cards = [...list];
    if (!isEmpty(state?.promo)) {
        const { promo } = state;
        cards.push({
            heading: promo.title,
            subHeading: promo.desc,
            discount: true,
            edit: true,
            id: cards.length + 1,
        });
    }
    const editPromo = ({ id, ...record }) => {
        history.push({
            pathname: `${pathname}/id/${id}`,
            state: { record: { ...record, id } },
        });
    };
    const addNewPromo = () => {
        history.push({ pathname: `${pathname}/add` });
    };
    const delPromo = (id) => {
        delCustomPromo({ id, tabName: 'CUSTOM' });
        setTimeout(() => {
            history.push({ pathname: `/Promotions/` });
            getCustomList();
        }, CONST.TIMEOUT);
    };
    useEffect(() => {
        if (isEmpty(params) && !loading) getCustomList();
    }, [params, loading, getCustomList]);
    return (
        <div id="CustomList">
            <div className="cards">
                {cards.map((card, i) => {
                    return (
                        <Card
                            key={i}
                            card={card}
                            editPromo={editPromo}
                            delPromo={delPromo}
                            more={{ cardEditText, cardDeleteText }}
                        />
                    );
                })}
            </div>
            <StickyButton onClick={addNewPromo} />
        </div>
    );
};

CustomList.propTypes = {
    tabs: PropTypes.object.isRequired,
};

export { CustomList };
