import React, { useState, createRef } from 'react';
import PropTypes from 'prop-types';
import { Icon } from 'semantic-ui-react';
import { eye } from 'images';
import { Image } from '_components';
import CONSTANTS from '_constants';
import './Input.scss';
const Input = (props) => {
    const {
        label,
        name,
        refs,
        type,
        disabled,
        placeholder,
        onChange,
        onBlur,
        value,
        count,
        optional,
        autoComplete,
        currentValue,
    } = props;
    const {
        VALIDATION: { MIN, MAXINPUT },
    } = CONSTANTS;
    const [showPassword, setPassword] = useState(type === 'password');
    const required = optional.trim() === '';
    const [inputCount, setCount] = useState(0);
    const validation = { required, maxLength: MAXINPUT, minLength: MIN };
    const { maxLength, ...curValidataion } = {
        ...validation,
        ...refs.validation,
    };
    const curRef =
        refs?.register !== undefined
            ? refs.register({ ...curValidataion, maxLength })
            : disabled
            ? () => {}
            : refs;
    const currenRef = async (e) => {
        if (count) {
            await setCount(() => e?.value.length);
            curRef(e);
        } else curRef(e);
    };
    const pwd = type === 'password' && showPassword ? 'password' : 'text';
    return (
        <>
            <div className="dmi-input">
                <label>{label}</label>
                <input
                    disabled={disabled}
                    className={`input-text ${disabled ? 'disabled' : ''}`}
                    type={type === 'password' ? pwd : type}
                    ref={currenRef}
                    name={name}
                    onChange={onChange}
                    onBlur={onBlur}
                    placeholder={placeholder}
                    defaultValue={value}
                    value={currentValue}
                    maxLength={maxLength}
                    autoComplete={autoComplete}
                />
                {count && (
                    <label className="count">
                        {inputCount}/{maxLength}
                    </label>
                )}
                {optional !== '' && (
                    <label className="optinal">{optional}</label>
                )}
                {type === 'password' && (
                    <span
                        className="dmi-input-eye"
                        onClick={() => !disabled && setPassword(!showPassword)}
                    >
                        {showPassword ? (
                            <Icon name="eye slash" />
                        ) : (
                            <Image src={eye} />
                        )}
                    </span>
                )}
            </div>
        </>
    );
};
Input.defaultProps = {
    value: undefined,
    name: '',
    label: '',
    refs: createRef(),
    type: 'text',
    optional: '',
    disabled: false,
    count: false,
    currentValue: undefined,
};
Input.propTypes = {
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    label: PropTypes.string,
    name: PropTypes.string,
    placeholder: PropTypes.string,
    type: PropTypes.string,
    onChange: PropTypes.func,
    disabled: PropTypes.bool,
    count: PropTypes.bool,
    optional: PropTypes.string,
    autoComplete: PropTypes.string,
    refs: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
};

export { Input };
