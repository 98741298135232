import React, { useState } from 'react';
import { Segment, Container } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import { keys } from 'lodash';
import { getActiveTabDetails } from '_utils';
import { lang } from '_config';

import GdprRoutes from '../Gdpr.routes';
import './LandingPage.scss';
const { gdpr: Gdpr } = lang;
const {
    placeholder,
    tableHeading,
    breadcrumbHeading,
    tabs: tabHeading,
    ...rest
} = Gdpr;
const tabs = keys(tableHeading);

const GdprPage = (props) => {
    const {
        location: { pathname },
    } = props;
    const options = getActiveTabDetails(pathname, tabs);
    const [activeTab, setActiveTab] = useState({
        tab: options?.curTab,
        heading: tabHeading[options?.getCurId],
        breadcrumbHeading: breadcrumbHeading[options?.getCurId],
    });
    const config = {
        tableHeading: tableHeading[activeTab.tab],
        placeholder: placeholder[activeTab.tab],
        tabs: tabHeading,
        ...rest,
    };
    const goBack = () => props.history.push(`/Gdpr/${activeTab.tab}`);
    const tabsHeading = config?.tabs ?? [];
    return (
        <Container fluid id="Gdpr-Page" className={`private-layout`}>
            <Segment basic>
                <div className="tabs">
                    {tabsHeading.map((tab, i) => (
                        <Link
                            to={`/Gdpr/${tabs[i]}`}
                            key={i}
                            onClick={() => {
                                setActiveTab({
                                    tab: tabs[i],
                                    breadcrumbHeading: breadcrumbHeading[i],
                                    heading: tab,
                                });
                            }}
                        >
                            <div
                                className={
                                    tabs[i] === activeTab.tab
                                        ? 'tabs-item active'
                                        : 'tabs-item'
                                }
                            >
                                {tab}
                            </div>
                        </Link>
                    ))}
                </div>

                <div className="main">
                    <GdprRoutes
                        config={config}
                        activeTab={activeTab}
                        goBack={goBack}
                        {...props}
                    />
                </div>
            </Segment>
        </Container>
    );
};

export { GdprPage };
