import React from 'react';
import PropTypes from 'prop-types';
import { Modal, Button } from 'semantic-ui-react';
const ConfirmModal = (props) => {
    const {
        config: popup,
        button,
        actionButton,
        handleClose,
        isModalOpen,
        loading,
    } = props;
    return (
        <Modal
            onClose={handleClose}
            size="large"
            trigger={button()}
            className="log-out-modal"
            open={isModalOpen}
        >
            <div className="log-out-main">
                <label>{popup?.heading}</label>
            </div>
            <div className="log-out-footer-button">
                <Button
                    className="action-logout"
                    onClick={actionButton}
                    size="large"
                    loading={loading}
                >
                    {popup?.actionOk ?? 'Yes'}
                </Button>
                <Button
                    className="action-cancel"
                    onClick={handleClose}
                    size="large"
                >
                    {popup?.actionCancel ?? 'Cancel'}
                </Button>
            </div>
        </Modal>
    );
};

ConfirmModal.propTypes = {
    config: PropTypes.object.isRequired,
    actionButton: PropTypes.func.isRequired,
    handleClose: PropTypes.func.isRequired,
    isModalOpen: PropTypes.bool.isRequired,
    loading: PropTypes.bool.isRequired,
};

export { ConfirmModal };
