import PATIENT from '_constants';
import { INIT } from '_config/initialState';
const { patientList: initialState } = INIT;

export default (state = initialState, action = {}) => {
    const { type, result, params, faliure,modalData } = action;
    switch (type) {
        case PATIENT.PATIENT_REQUEST_START:
            return { ...state, loading: true };
        case PATIENT.PATIENT_MODAL_OPEN:
            return { ...state, isModalOpen: true,modalData };
        case PATIENT.PATIENT_MODAL_CLOSE:
            return { ...state, isModalOpen: false,modalData:{} };
        case PATIENT.PATIENT_MODAL_LOADED:
            return { ...state, isModalLoad: false };
        case PATIENT.PATIENT_MODAL_LOADING:
            return { ...state, isModalLoad: true };
        case PATIENT.PATIENT_TICKET_SUCCESS:
            return {
                ...state,
                ticketDetails: { recordDetails: params, ...result },
            };
        case PATIENT.PATIENT_LIST_SUCCESS:
            return {
                ...state,
                result: {
                    ...result,
                    list: params.pageNumber
                        ? [...state.result.list, ...result.list]
                        : [...result.list],
                },
                faliure: null,
                params: params,
            };
        case PATIENT.PATIENT_TICKET_FAILURE:
        case PATIENT.PATIENT_LIST_FAILURE:
            return { ...state, faliure };
        case PATIENT.PATIENT_REQUEST_END:
            return { ...state, loading: false };
        default:
            return { ...state };
    }
};
export const patientDetails = (state) => state.patientList;
