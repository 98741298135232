/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-filename-extension */
import React, { useEffect } from 'react';
import { Sidebar } from 'semantic-ui-react';
import { Redirect } from 'react-router-dom';

import { lang } from '_config';
import { Header, Sidebar as Aside } from '_components';
import './PrivateLayout.scss';

const PrivateLayout = (props) => {
    useEffect(() => {
        document.title = props?.title ?? 'Welcome to fresnious';
    }, [props]);
    const {
        history: {
            location: { state, pathname },
        },
        state: { localUserData },
        loggedIn,
    } = props;
    const LANDINGPAGE =
        localUserData?.roles === 'Nutritionists'
            ? {
                  pathname: '/Patients',
                  userType: 'Nutritionists',
                  activeTab: 'Patients',
              }
            : {
                  pathname: '/Admins',
                  userType: 'Admin',
                  activeTab: 'Admins',
              };
    const rediretTo = (_) => (
        <Redirect to={{ pathname: _.pathname, state: _.state }} />
    );
    const menuList =
        localUserData?.roles === 'Admin'
            ? lang?.menuList?.Admin
            : lang?.menuList?.Nutritionists;
    const kabiCenterOptions = lang.chat.details?.kabiCenterOptions;
    const kabiCareCenterPlaceholder = lang.chat.details?.kabiCareCenterPlaceholder;
    const kabiCareCenterLabel = lang.chat.details?.kabiCareCenterLabel;
    const defaultKabiCareCenter = lang.chat.details?.defaultKabiCareCenter;
    return state === undefined && !localUserData ? (
        rediretTo({ pathname: '/login' })
    ) : pathname === '/' ? (
        rediretTo(LANDINGPAGE)
    ) : (
        <div className={`private-layout`}>
            {!loggedIn && rediretTo({ pathname: '/login' })}
            <Sidebar.Pushable>
                <Header
                    {...props}
                    kabiCenterOptions={kabiCenterOptions}
                    kabiCareCenterPlaceholder={kabiCareCenterPlaceholder}
                    kabiCareCenterLabel={kabiCareCenterLabel}
                    defaultKabiCareCenter={defaultKabiCareCenter}
                />
                <Aside {...props} {...menuList} />
                <Sidebar.Pusher>{props.children}</Sidebar.Pusher>
            </Sidebar.Pushable>
        </div>
    );
};

export { PrivateLayout };
