import { connect } from 'react-redux';
import { userActions, alertActions } from '_actions';
import { forgotDetails, alertDetails } from '_reducers';
import { ForgotPage } from '_pages';
function mapState(state) {
    return {
        state: {
            forgotData: forgotDetails(state),
            alertData: alertDetails(state),
        },
    };
}

const actionCreators = (dispatch) => {
    return {
        actions: {
            reqForgotPwd: (input) => {
                dispatch(userActions.forgot(input));
            },
            msgClear: () => dispatch(alertActions.clear()),
        },
    };
};

export const ForgotContainer = connect(mapState, actionCreators)(ForgotPage);
