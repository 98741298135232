import { connect } from 'react-redux';
import {
    alertActions,
    promoActions as PA,
    userActions,
    fileUploadAction,
} from '_actions';
import { PromotionsPage } from '_pages';
function mapState(state) {
    const {
        alertData,
        promoList,
        filesDetails,
        loginData: { userDetails },
        files: { list: filesList },
    } = state;
    return {
        state: { alertData, filesDetails, filesList, promoList, userDetails },
    };
}

const actionCreators = (dispatch) => {
    return {
        actions: {
            clearAlerts: () => dispatch(alertActions.clear()),
            logOut: () => dispatch(userActions.logout()),
            getList: (params) => dispatch(PA.getList(params)),
            getPharmacyList: (params) => dispatch(PA.getPharmacyList(params)),
            getPatientRecord: (params) => dispatch(PA.getPatientRecord(params)),
            updateTicket: (params) => dispatch(PA.updateTicket(params)),
            getCustomList: (params) => dispatch(PA.customListPromo(params)),
            addPromo: (newPromo) => dispatch(PA.addPromo(newPromo)),
            getBoxes: (userId) => dispatch(PA.getBoxes(userId)),
            viewPromo: (newPromo) => dispatch(PA.customRecordPromo(newPromo)),
            delCustomPromo: (promoId) => dispatch(PA.delCustomPromo(promoId)),
            updateCustomPromo: (updatePromo) =>
                dispatch(PA.updateCustomPromo(updatePromo)),
            clearFileDetails: () => dispatch(fileUploadAction.clearFileData()),
            fileTextUpdate: (file) =>
                dispatch(fileUploadAction.fileTextUpdate(file)),
            openModal: (mData) => dispatch(PA.openModal(mData)),
            closeModal: () => dispatch(PA.closeModal()),
        },
    };
};

export const PromotionsContainer = connect(
    mapState,
    actionCreators
)(PromotionsPage);
