/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import { Menu, Grid } from 'semantic-ui-react';
import { Image } from '_components';
import { iconChatActive, ellipse, ketoLogo } from 'images';
import { isEmpty } from 'lodash';
import './Header.scss';
import { Redirect } from 'react-router-dom';
import { RoundedSelect } from '_components/Core/RoundedSelect';
import { getCountryCode } from '_utils';
const Header = (props) => {
    const {
        state: {
            alert: { type, message: msg },
            activeLang,
            userDetails: { Name: userName, roles },
        },
        history,
        actions: { updateLangAction, selectMenuItem, setKabiCenter },
        kabiCenterOptions,
        kabiCareCenterPlaceholder,
        kabiCareCenterLabel,
        defaultKabiCareCenter
    } = props;
    const ifColombiaChatModule = history.location.pathname.split('/')[1] === 'Chat' && getCountryCode() === 'Colombia';
    const getUN = () => {
        try {
            const [fName, lName] = userName.split(' ');
            return `${fName?.charAt(0)}${lName?.charAt(0)}`;
        } catch (error) {
            return '--';
        }
    };
    const handelLang = async (e) => {
        await updateLangAction(e.currentTarget.value);
    };
    const goToChat = async () => {
        await selectMenuItem('Chat');
        history.push({ pathname: '/Chat', state: { userType: roles } });
    };
    return (
        <Menu className="HeaderConatiner">
            {msg?.errCode === 'Token expired' && <Redirect to="/login" />}
            <div className="header-left-section">
                <Image className="keto-logo" src={ketoLogo} />
            </div>
            <div className="header-middle-i-section">
                {!isEmpty(msg) && (
                    <div className={`${type ?? ''}`}>
                        {msg?.errMsg ?? msg?.suc ?? msg?.errCode}
                    </div>
                )}
                <select value={activeLang} onChange={handelLang}>
                    <option value="es">Spanish</option>
                    <option value="en">English</option>
                </select>
            </div>
            {ifColombiaChatModule ? <div className="header-middle-ii-section">
                <RoundedSelect
                    options={kabiCenterOptions}
                    label={kabiCareCenterLabel}
                    placeholder={kabiCareCenterPlaceholder}
                    defaultValue={defaultKabiCareCenter}
                    onChange={(_, data) => setKabiCenter(data.value)}
                />
            </div> : ''}
            <div className="header-right-section">
                <Grid columns={2}>
                    <Grid.Column className="section-click">
                        <Image className="ellipse" src={ellipse} />
                        <Image onClick={goToChat} src={iconChatActive} />
                    </Grid.Column>
                    <Grid.Column>
                        <div className="profile-img">
                            {getUN()?.toUpperCase()}
                        </div>
                    </Grid.Column>
                </Grid>
            </div>
        </Menu>
    );
};

export { Header };
