import React, { useMemo, useCallback } from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import { AdminEdit, AdminAdd, AdminList } from './Admin';
const AdminRoutes = (props) => {
    const { path } = useRouteMatch();
    const { history } = props;
    const goBack = useCallback(() => {
        history.push(`/Admins`);
    }, [history]);
    const List = useMemo(() => <AdminList {...props} />, [props]);
    const Edit = useMemo(() => <AdminEdit goBack={goBack} {...props} />, [
        goBack,
        props,
    ]);
    const Add = useMemo(() => <AdminAdd goBack={goBack} {...props} />, [
        goBack,
        props,
    ]);
    return (
        <Switch>
            <Route exact path={path}>
                {List}
            </Route>
            <Route exact path={`${path}/edit/:id`}>
                {Edit}
            </Route>
            <Route exact path={`${path}/Add`}>
                {Add}
            </Route>
        </Switch>
    );
};

export default AdminRoutes;
