import React from 'react';
import { Grid } from 'semantic-ui-react';

const ActionButton = (props) => {
    const { config, record, openModal } = props;
    const handelModal = () => {
        openModal(record);
    };
    const getImage = () => {
        switch (record?.statuscol) {
            case 'Accepted':
                return { isDisable: true, isReject: false };
            case 'Rejected':
                return { isDisable: true, isReject: true };
            case 'Pending':
                return { isDisable: false };
            case 'Free':
            default:
                break;
        }
    };
    getImage();
    return (
        <Grid.Column width={2}>
            <div onClick={handelModal} className="text-link">
                {config.viewLable}
            </div>
        </Grid.Column>
    );
};

export default ActionButton;
