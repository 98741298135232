import axios from 'axios';
import {
    getUserDetails,
    setUserDetails,
    removeUserDetails,
    getErrorMsg,
} from '_utils';
import { lang } from '_config';
const {
    menuList: {
        error: { genric },
    },
} = lang;
export const fetchApi = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    timeout: 30000,
    responseType: 'json',
    responseEncoding: 'utf8',
    headers: { 'Content-type': 'Application/json' },
    validateStatus: function (status) {
        return status >= 200 && status < 500; // default
    },
});
export const Apis = (_) => {
    const { token } = getUserDetails();
    _ === true && removeUserDetails();
    return axios.create({
        baseURL: process.env.REACT_APP_API_URL,
        timeout: 30000,
        responseType: 'json',
        responseEncoding: 'utf8',
        headers: {
            Authorization: `Bearer ${token}`,
            'Content-type': 'Application/json',
        },
        validateStatus: function (status) {
            return status >= 200 && status < 500; // default
        },
    });
};
const getNewToken = async (_) => {
    const error = (code = null) => {
        if (code === 400) removeUserDetails();
        return Promise.reject({
            message: 'Su sesión expiró. Por favor, ingrese de nuevo',
            errMsg: 'Su sesión expiró. Por favor, ingrese de nuevo',
            code,
        });
    };
    return Apis()('session/refresh/bo')
        .then((response) => {
            const { status: code, data } = response;
            return code !== 200
                ? error(code)
                : setUserDetails({ token: data?.token, _ });
        })
        .catch(error);
};
export const handleResponse = (response) => {
    const {
        status: code,
        data,
        config: { url, data: postData },
    } = response;
    if (code === 401 && url !== '/session/login/bo')
        return getNewToken({ url, postData });
    else
        switch (code) {
            case 200:
                return response.data;
            default:
                return Promise.reject({
                    ...data,
                    message: data?.error,
                    errMsg: data?.message,
                    code,
                });
        }
};
export const handelError = (e) => {
    const obj = {
        status: false,
        errMsg: e?.errMsg ?? '',
        errNo: e?.code ?? '',
        errCode: e?.message,
        errors: e?.errors,
    };
    switch (e?.message) {
        case 'Network Error':
        case 'timeout of 30000ms exceeded':
            obj.errMsg = genric[500]?.msg ?? 'Network Error, Please try again.';
            break;
        default:
            obj.errMsg =
                getErrorMsg({ errNo: e?.code, errMsg: e?.errMsg }, genric)
                    ?.errMsg ??
                e?.errMsg ??
                genric[500]?.msg;
            break;
    }
    return obj;
};
