import { batch } from 'react-redux';
import GDPR from '_constants';
import { gdprService } from '_services';
import { downloadFile, getRecordId, getErrorMsg } from '_utils';
import { lang } from '_config';
import { isEmpty } from 'lodash';
const {
    menuList: {
        messages,
        error: {
            gdpr: { dataprocessconsent, terms },
        },
    },
} = lang;
// import { getRecordId } from '_utils';
const getAll = (params) => {
    const queryParams = {
        pageNumber: params?.pageNumber ?? 0,
        direction: params?.direction ?? 'DESC',
        orderBy: params?.orderBy ?? 'DATE',
        search: params?.search ?? '',
        category: params?.category ?? 'TERM_CONDITION',
    };
    const serviceCall = params?.category === 'MARKET' ? 'marketList' : 'getAll';
    return (dispatch, getState) => {
        const { alertData } = getState();
        const onError = (error) =>
            batch(() => {
                dispatch({
                    type: GDPR.GDPR_LIST_FAILURE,
                    faliure: { [queryParams?.category]: error } ?? null,
                });
                dispatch({ type: GDPR.ERROR, message: error });
                setTimeout(() => dispatch({ type: GDPR.CLEAR }), GDPR.TIMEOUT);
            });
        batch(() => {
            dispatch({ type: GDPR.GDPR_REQUEST_START });
            if (!isEmpty(alertData)) dispatch({ type: GDPR.CLEAR });
        });
        gdprService[serviceCall](queryParams)
            .then((result) => {
                if (result && result?.status) {
                    dispatch({
                        type: GDPR.GDPR_LIST_SUCCESS,
                        result,
                        params: queryParams,
                    });
                } else {
                    onError(result);
                }
            })
            .catch(onError)
            .finally(() => {
                dispatch({ type: GDPR.GDPR_REQUEST_END });
            });
    };
};
const currentGdpr = (category) => {
    return (dispatch, getState) => {
        const { alertData } = getState();
        const onError = (error) =>
            batch(() => {
                dispatch({
                    type: GDPR.GDPR_LIST_FAILURE,
                    faliure: { current: error } ?? null,
                });
                dispatch({ type: GDPR.ERROR, message: error });
                setTimeout(() => dispatch({ type: GDPR.CLEAR }), GDPR.TIMEOUT);
            });
        batch(() => {
            dispatch({ type: GDPR.GDPR_REQUEST_START });
            if (!isEmpty(alertData)) dispatch({ type: GDPR.CLEAR });
        });
        gdprService
            .currentGdpr()
            .then((result) => {
                if (result && result?.status) {
                    dispatch({
                        type: GDPR.GDPR_CURRENT_SUCCESS,
                        result,
                        params: { category: category ?? 'TERM_CONDITION' },
                    });
                } else {
                    onError(result);
                }
            })
            .catch(onError)
            .finally(() => {
                dispatch({ type: GDPR.GDPR_REQUEST_END });
            });
    };
};
const currentPP = () => {
    return (dispatch, getState) => {
        const { alertData } = getState();
        const onError = (error) =>
            batch(() => {
                dispatch({
                    type: GDPR.GDPR_LIST_FAILURE,
                    faliure: { current: error } ?? null,
                });
                dispatch({ type: GDPR.ERROR, message: error });
                setTimeout(() => dispatch({ type: GDPR.CLEAR }), GDPR.TIMEOUT);
            });
        batch(() => {
            dispatch({ type: GDPR.GDPR_REQUEST_START });
            if (!isEmpty(alertData)) dispatch({ type: GDPR.CLEAR });
        });
        gdprService
            .currentPP()
            .then((result) => {
                if (result && result?.status) {
                    dispatch({
                        type: GDPR.GDPR_CURRENT_SUCCESS,
                        result,
                        params: { category: 'PRIVACY_POLICY' },
                    });
                } else {
                    onError(result);
                }
            })
            .catch(onError)
            .finally(() => {
                dispatch({ type: GDPR.GDPR_REQUEST_END });
            });
    };
};
const addGDPR = (params) => {
    const addGDPRData = {
        content: params?.text,
        title: params?.title,
        version: params?.version,
    };
    const serviceCall = params?.type === 'PRIVACY_POLICY' ? 'addPP' : 'addGDPR';
    const customErr =
        params?.type === 'PRIVACY_POLICY' ? dataprocessconsent : terms;
    return (dispatch, getState) => {
        const { alertData } = getState();
        const onError = (error) => {
            batch(() => {
                dispatch({
                    type: GDPR.GDPR_ADD_FAILURE,
                    faliure: getErrorMsg(error, customErr?.create) ?? null,
                    params: addGDPRData,
                });
                dispatch({
                    type: GDPR.ERROR,
                    message: getErrorMsg(error, customErr?.create) ?? null,
                });
                setTimeout(() => dispatch({ type: GDPR.CLEAR }), GDPR.TIMEOUT);
            });
        };
        batch(() => {
            dispatch({ type: GDPR.GDPR_REQUEST_START });
            if (!isEmpty(alertData)) dispatch({ type: GDPR.CLEAR });
        });
        gdprService[serviceCall](addGDPRData)
            .then((res) => {
                if (res.status)
                    dispatch({
                        type: GDPR.SUCCESS,
                        message: {
                            suc: customErr?.create['200']?.msg ?? messages?.add,
                        },
                    });
                else onError(res);
            })
            .catch(onError)
            .finally(() => {
                batch(() => {
                    dispatch({ type: GDPR.GDPR_REQUEST_END });
                });
            });
    };
};
const editGDPR = (params) => {
    const editRecord =
        params?.selectedId === undefined
            ? getRecordId(params.pathname)
            : { id: params?.selectedId };
    return (dispatch) => {
        const onError = (error) =>
            batch(() => {
                dispatch({
                    type: GDPR.GDPR_EDIT_FAILURE,
                    faliure: error ?? null,
                    params,
                });
                dispatch({ type: GDPR.ERROR, message: error ?? null });
                setTimeout(() => dispatch({ type: GDPR.CLEAR }), GDPR.TIMEOUT);
            });
        batch(() => {
            dispatch({ type: GDPR.GDPR_REQUEST_START });
            dispatch({ type: GDPR.CLEAR });
        });
        gdprService
            .editGdpr(editRecord)
            .then((result) => {
                if (result && result?.status) {
                    dispatch({
                        type: GDPR.GDPR_SELECTED_ID,
                        result,
                        params,
                    });
                } else {
                    onError(result);
                }
            })
            .catch(onError)
            .finally(() => dispatch({ type: GDPR.GDPR_REQUEST_END }));
    };
};

const downloadGdpr = () => {
    return (dispatch) => {
        dispatch({ type: GDPR.GDPR_REQUEST_START });
        gdprService
            .downloadList()
            .then(downloadFile)
            .finally(() => dispatch({ type: GDPR.GDPR_REQUEST_END }));
    };
};
export const GdprActions = {
    getAll,
    addGDPR,
    editGDPR,
    currentGdpr,
    currentPP,
    downloadGdpr,
};
