import React, { useState } from 'react';
import { Segment, Container } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import { keys, isEmpty } from 'lodash';
import { getActiveTabDetails } from '_utils';
import CHATCONST from '_constants';
import { lang } from '_config';
// import { Search } from '_components';
import ChatRoutes from '../Chat.Routes';
import './LandingPage.scss';
import { Search } from '_components/Search/Search';
const { chat } = lang;
const {
    placeholder,
    tableHeading,
    breadcrumbHeading,
    tabs: tabHeading,
    ...rest
} = chat;
const { statusHistoryVisible } = lang.medicalRecord.details;
const tabs = keys(tableHeading);

const ChatPage = (props) => {
    const {
        location: { pathname },
        history,
        state: {
            userDetails: { Role },
            chatList: { calling },
        },
    } = props;
    const {
        ROLES: { ADMIN },
    } = CHATCONST;
    const isAdmin = ADMIN === Role[0];
    const options = isAdmin
        ? getActiveTabDetails(pathname, tabs)
        : { curTab: tabs[1], getCurId: 1 };
    const [activeTab, setActiveTab] = useState({
        tab: options?.curTab,
        heading: tabHeading[options?.getCurId],
        breadcrumbHeading: breadcrumbHeading[options?.getCurId],
        search: '',
    });
    const config = {
        tableHeading: tableHeading[activeTab.tab],
        placeholder: placeholder[activeTab.tab],
        tabs: tabHeading,
        ...rest,
    };
    const goBack = () => history.push(`/Chat/${activeTab.tab}`);
    const tabsHeading = config?.tabs ?? [];
    const findUser = (data) => {
        setActiveTab({ ...activeTab, search: data.search });
    };
    return (
        <Container fluid id="ChatPage" className={`private-layout`}>
            <Segment basic>
                {isAdmin && isEmpty(calling) && (
                    <div className="tab-search-container">
                        <div className="tabs">
                            {tabsHeading.map((tab, i) => (
                                <Link
                                    to={`/Chat/${tabs[i]}`}
                                    key={i}
                                    onClick={() => {
                                        setActiveTab({
                                            tab: tabs[i],
                                            breadcrumbHeading: breadcrumbHeading[i],
                                            heading: tab,
                                            search: '',
                                        });
                                    }}
                                >
                                    <div
                                        className={
                                            tabs[i] === activeTab.tab
                                                ? 'tabs-item active'
                                                : 'tabs-item'
                                        }
                                    >
                                        {tab}
                                    </div>
                                </Link>
                            ))}
                        </div>
                        <div className="actions">
                            <Search
                                actionList={findUser}
                                params={{ search: activeTab?.search ?? '' }}
                                config={config}
                                width="full"
                            />
                        </div>
                    </div>
                )}
                <div className="main">
                    <ChatRoutes
                        config={config}
                        activeTab={activeTab}
                        setActiveTab={setActiveTab}
                        goBack={goBack}
                        isAdmin={isAdmin}
                        statusHistoryVisible={statusHistoryVisible}
                        {...props}
                    />
                </div>
            </Segment>
        </Container>
    );
};

export { ChatPage };
