import { connect } from 'react-redux';
import {
    alertActions,
    userActions,
    fileUploadAction,
    newsActions,
} from '_actions';
import { NoticesPage } from '_pages';
import { getNewsId } from '_utils';
function mapState(state) {
    const {
        newsList,
        loginData: { userDetails },
        files: { list: filesList },
    } = state;
    return { state: { userDetails, filesList, newsList } };
}

const actionCreators = (dispatch) => {
    return {
        actions: {
            clearAlerts: () => dispatch(alertActions.clear()),
            logOut: () => dispatch(userActions.logout()),
            loginSuccess: () => dispatch(userActions.loginSuccess()),
            getList: (input) => {
                dispatch(newsActions.getList(input));
            },
            addNews: (input) => {
                dispatch(newsActions.addNews(input));
            },
            editNews: (id) => {
                getNewsId(id);
            },
            updateNews: (input) => {
                dispatch(newsActions.updateNews(input));
            },
            delNews: (id) => {
                dispatch(newsActions.delNews(id));
            },
            clearFileDetails: () => dispatch(fileUploadAction.clearFileData()),
            fileTextUpdate: (file) =>
                dispatch(fileUploadAction.fileTextUpdate(file)),
        },
    };
};

export const NoticesContainer = connect(mapState, actionCreators)(NoticesPage);
