import React, { useRef, useEffect } from 'react';
import { Grid } from 'semantic-ui-react';
import { GridList, StickyButton, Search } from '_components';
import { isEmpty, map } from 'lodash';
import { removeTags } from '_utils';
const InfoList = (props) => {
    const {
        activeTab: { tab },
        actions: { getList },
        state: {
            infoList: { loading, tabs },
            userDetails: { roles },
        },
        config,
        history,
    } = props;
    const {
        params,
        result: { list, ...filters },
    } = tabs[tab];
    const cref = useRef('GernalAdvicesPage');
    const result = map(list, (data) => ({
        mytxt: removeTags(data.text),
        ...data,
    }));
    const listData = {
        sorting: [{ direction: 'DESC' }, false, false],
        actionList: getList,
        isDisable: filters.totalElements <= list.length,
        columns: ['title', 'mytxt'],
        isHtml: [false, true],
        cellWidth: [3, 10, 2],
        pageData: result,
        loading,
        filters,
        params,
        cref,
        roles,
    };
    useEffect(() => {
        if (isEmpty(params)) {
            getList({ ...params, category: tab });
        }
    }, [getList, params, tab]);
    return (
        <>
            <div className="actions">
                <Search
                    actionList={getList}
                    params={params}
                    config={config}
                    width="full"
                />
            </div>
            <GridList config={config} history={history} listData={listData}>
                {(id, record) => {
                    return (
                        <Grid.Column key={id} width={2}>
                            <div
                                className="text-link"
                                onClick={() => {
                                    props.history.push({
                                        pathname: `/Info/${tab}/id/${id}`,
                                        state: { record: { ...record, id } },
                                    });
                                }}
                            >
                                {config.actionLable}
                            </div>
                        </Grid.Column>
                    );
                }}
            </GridList>
            <StickyButton
                onClick={() =>
                    props.history.push({
                        pathname: `/Info/${tab}/Add`,
                    })
                }
            />
        </>
    );
};

export { InfoList };
