import RESET from '_constants';
import { INIT } from '_config/initialState';
const { resetData: initialState } = INIT;
export default (state = initialState, action = {}) => {
    switch (action.type) {
        case RESET.RESET_REQUEST_START:
            return {
                ...state,
                loading: true,
            };
        case RESET.RESET_SUCCESS:
        case RESET.RESET_REQUEST_END:
            return {
                ...state,
                loading: false,
            };
        case RESET.RESET_FAILURE:
            return {
                ...state,
            };
        default:
            return state;
    }
};
export const resetDetails = (state) => state.resetData;
