import { connect } from 'react-redux';
import { EditPatientInfo } from '../_pages';
import { patientDetailActions } from '_actions';
import { isNumberExist } from '_utils';
import history from '_utils/history';
const mapStateToProps = (state) => {
    return {
        loading: state.patientMedicalRecords.loading,
        patientInfo: state.patientMedicalRecords.patientInfo,
        updatedInfo: state.patientMedicalRecords.updatedInfo,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getPatientDetail: (id) => {
            isNumberExist(id)
                ? dispatch(patientDetailActions.getPatientInfo(id))
                : history.push({ pathname: '/Patients' });
        },
        updatePatientInfo: (data) => {
            dispatch(patientDetailActions.updatePatientInfo(data));
        },
        profileDel: (data) => dispatch(patientDetailActions.profileDel(data)),
    };
};

export const EditPatientInfoContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(EditPatientInfo);
