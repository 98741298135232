import React, { useCallback, useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import CKEditor from 'ckeditor4-react';
import { isEmpty } from 'lodash';
import { hasExtensions } from '_utils';

const CkEditor = (props) => {
    const { dataValue, disabled, getValue } = props;
    // const ckEdit = useRef(null);
    const URI = `https://api.cloudinary.com/v1_1/fresenius-keto/upload`;
    const [obj, setObj] = useState({});
    const mEdit = useRef();
    useEffect(() => {
        if (isEmpty(obj) && !isEmpty(dataValue)) {
            setTimeout(() => {
                const { editor } = mEdit.current;
                editor.setData(editor.getData() + '<p>hi</p>');
            }, 500);
            setTimeout(() => {
                const { editor } = mEdit.current;
                editor.setData(editor.getData().slice(0, -11));
            }, 1000);
        }
    }, [obj, mEdit, dataValue]);
    const editorLogs = useCallback(
        (editor, res) => {
            if (isEmpty(res)) {
                setObj({ isCalled: true });
                editor.on('fileUploadRequest', (evt) => {
                    if (
                        hasExtensions(evt.data.fileLoader.file.name, ['.png'])
                    ) {
                        // Prepare request to Cloudinary
                        let xhr = evt.data.fileLoader.xhr;
                        xhr.open('POST', URI, true);
                        xhr.setRequestHeader(
                            'X-Requested-With',
                            'XMLHttpRequest'
                        );

                        // Fill all necessary fields for Cloudinary
                        let fd = new FormData();
                        fd.append('upload_preset', 'ibamuccq');
                        // fd.append('folder', 'content/articles'); // Optional - place image at specific folder admin in Cloudinary
                        // fd.append('tags', 'browser_upload'); // Optional - add tag for image admin in Cloudinary
                        fd.append('file', evt.data.fileLoader.file);

                        // Send the request to Cloudinary server
                        xhr.send(fd);

                        // Prevented the default behavior.
                        evt.stop();
                    }
                });
                // Change response to handle Cloudinary response
                editor.on('fileUploadResponse', (evt) => {
                    // Prevent the default response handler.
                    evt.stop();

                    // Get XHR and response.
                    let data = evt.data,
                        xhr = data.fileLoader.xhr,
                        response = JSON.parse(xhr.responseText);

                    // Transfer the response to Ckeditor format
                    data.uploaded = 1;
                    data.fileName = response.public_id + '.' + response.format;
                    data.url = response.secure_url;
                });
            }
        },
        [URI]
    );

    const handelLogs = ({ editor }) => {
        editorLogs(editor, obj);
    };
    const sendVal = (evt) => {
        const result = evt?.editor?.getData().trim();
        let div = document.createElement('div');
        div.innerHTML = result;
        div.querySelectorAll('img').forEach((item) => {
            item.removeAttribute('style');
        });
        getValue(div.innerHTML);
    };
    return (
        <div>
            <CKEditor
                type="basic"
                ref={mEdit}
                data={dataValue}
                readOnly={disabled}
                onFocus={handelLogs}
                onChange={sendVal}
                onBlur={sendVal}
                config={{
                    uiColor: '#0063be4d',
                    removeButtons: 'Strike,Subscript,Superscript',
                    toolbar: [
                        // ['Font', 'FontSize'],
                        ['Format'],
                        ['Bold', 'Italic', 'Underline'],
                        ['BulletedList', 'NumberedList'],
                        ['Image'],
                        ['Link'],
                    ],
                    filebrowserUploadUrl: URI,
                    resize_enabled: false,
                    removePlugins: 'elementspath',
                    language: 'es',
                }}
            />
        </div>
    );
};

CkEditor.propTypes = {
    dataValue: PropTypes.string,
    link: PropTypes.bool,
};

export { CkEditor };
