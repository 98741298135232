import React from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import { NoticesEdit, NoticesAdd, NoticesList } from './Notices';
const NoticesRoutes = (props) => {
    const { path } = useRouteMatch();
    const { config, history } = props;
    const goBack = () => history.push(`/News`);
    return (
        <Switch>
            <Route exact path={path}>
                <NoticesList {...props} config={{ ...config }} />
            </Route>
            <Route exact path={`${path}/edit/:id`}>
                <NoticesEdit {...props} goBack={goBack} />
            </Route>
            <Route exact path={`${path}/Add`}>
                <NoticesAdd {...props} goBack={goBack} />
            </Route>
        </Switch>
    );
};

export default NoticesRoutes;
