import { connect } from 'react-redux';
import {
    alertActions,
    infoActions,
    userActions,
    fileUploadAction,
    GdprActions,
} from '_actions';
import { GdprPage } from '_pages';
function mapState(state) {
    const {
        alert,
        gdprList,
        loginData: { userDetails },
        files: { list: filesList },
    } = state;
    return { state: { alert, gdprList, filesList, userDetails } };
}

const actionCreators = (dispatch) => {
    return {
        actions: {
            clearAlerts: () => dispatch(alertActions.clear()),
            logOut: () => dispatch(userActions.logout()),
            loginSuccess: () => dispatch(userActions.loginSuccess()),
            getTermList: (_) => dispatch(GdprActions.getAll(_)),
            currentGdpr: (_) => dispatch(GdprActions.currentGdpr(_)),
            currentPP: (_) => dispatch(GdprActions.currentPP(_)),
            viewPP: (_) => dispatch(GdprActions.viewPP(_)),
            getMarketList: (_) => dispatch(GdprActions.getAll(_)),
            addGdpr: (_) => dispatch(GdprActions.addGDPR(_)),
            editGdpr: (_) => dispatch(GdprActions.editGDPR(_)),
            downloadGdpr: () => dispatch(GdprActions.downloadGdpr()),
            delInfo: (queryParams) => {
                dispatch(infoActions.delInfo(queryParams));
            },
            fileTextUpdate: (file) =>
                dispatch(fileUploadAction.fileTextUpdate(file)),
            clearFileDetails: () => dispatch(fileUploadAction.clearFileData()),
        },
    };
};

export const GdprContainer = connect(mapState, actionCreators)(GdprPage);
