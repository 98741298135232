import ALERT from '_constants';

const success = (message) => {
    return { type: ALERT.SUCCESS, message };
};

const error = (message) => {
    return { type: ALERT.ERROR, message };
};

const clear = () => {
    return { type: ALERT.CLEAR };
};
export const alertActions = {
    success,
    error,
    clear,
};
