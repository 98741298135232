import React from 'react';
import PropTypes from 'prop-types';
import { Message as Msg } from 'semantic-ui-react';
import { isEmpty } from 'lodash';
import './Message.scss';
let msg = '';
const Message = (props) => {
    const {
        alertData,
        alertData: { type, message },
    } = props;
    // SET MESSAGE
    if (!isEmpty(message?.errMsg)) msg = message?.errMsg;
    else if (typeof message === 'string') msg = message;
    else if (!isEmpty(message?.errCode)) msg = message?.errCode;
    else msg = 'Something went wrong';
    return isEmpty(alertData) ? null : (
        <Msg
            icon
            negative={type === 'alert-danger'}
            success={type === 'alert-success'}
            className="messageContainer"
        >
            <Msg.Content>
                <Msg.Header>{msg}</Msg.Header>
            </Msg.Content>
        </Msg>
    );
};
Message.defaultProps = { alertData: { type: '', message: '' } };
Message.propTypes = {
    alertData: PropTypes.object.isRequired,
};

export { Message };
