import React, { useRef, useEffect, useMemo } from 'react';
import { isEmpty, map } from 'lodash';
// import ProList from 'prop-types';
import { useRouteMatch } from 'react-router-dom';
import { Grid } from 'semantic-ui-react';
import { GridList, Search } from '_components';
import { pending } from 'images';
import './ProList.scss';
const listData = {
    sorting: [
        { direction: 'DESC' },
        { direction: 'DESC' },
        false,
        { direction: 'DESC' },
        false,
    ],
    cellWidth: [4, 3, 3, 3, 1],
    columns: ['Name', 'nutritionalRecordId', 'Contact', 'TICKET_PENDING'],
    isHtml: [true, false, false, true],
    filters: {},
};
const ProList = (props) => {
    const cref = useRef('Promotions');
    const { url } = useRouteMatch();
    const {
        actions: { getList },
        tabs: {
            result: { list, ...filters },
            params,
        },
        roles,
        config,
        loading,
        history,
    } = props;
    const getImageData = (col) => {
        return col
            ? `<div class='status'><img class='status-img' src='${pending}' /> ${col} Pendientes</div>`
            : '--';
    };
    const filterList = useMemo(() => {
        return map(list, (record) => ({
            ...record,
            Name: `${record?.firstName} ${record?.lastName}<p>${record?.id}</p>`,
            id: record?.id,
            nutritionalRecordId: record?.nutritionalRecordId ?? '--',
            Contact: record?.phoneNumber,
            Email: record?.email || '--',
            TICKET_PENDING: getImageData(record?.pendingTicket),
        }));
    }, [list]);
    const data = {
        ...listData,
        cref,
        roles,
        params,
        loading,
        filters,
        actionList: getList,
        pageData: filterList,
        isDisable: filters.totalElements <= filterList.length,
    };
    useEffect(() => {
        if (isEmpty(params)) getList();
    }, [getList, params]);
    return (
        <>
            <div className="actions">
                <Search
                    actionList={data?.actionList}
                    filters={filters}
                    params={data?.params}
                    config={config}
                    width="full"
                />
            </div>
            <GridList
                config={{ ...config, ...config?.details }}
                history={history}
                listData={data}
            >
                {(id, rest) => {
                    return (
                        <Grid.Column key={id} width={2}>
                            <div
                                className="text-link"
                                onClick={() =>
                                    props?.history.push({
                                        pathname: `${url}/id/${id}`,
                                        state: { record: { id, ...rest } },
                                    })
                                }
                            >
                                {config.viewLable}
                            </div>
                        </Grid.Column>
                    );
                }}
            </GridList>
        </>
    );
};

// ProList.propTypes = {};

export { ProList };
