import React from 'react';
import PropTypes from 'prop-types';
import './Pagination.scss';
import { Button } from 'semantic-ui-react';
const Pagination = (props) => {
    const {
        onLoadMore,
        filters,
        cref,
        isLoading,
        isDisable,
        records,
        config: { pagination },
    } = props;
    const { totalElements } = filters;

    const goToTop = () => {
        try {
            const ele = document.getElementById(cref.current);
            if (ele !== null) ele.scrollTo({ top: 0, behavior: 'smooth' });
            // eslint-disable-next-line no-empty
        } catch (e) {}
    };
    return (
        <div className="dmi-pagination">
            <div className="seen-records">
                {pagination?.paging ?? 'Seeing'} {records} / {totalElements}
            </div>
            <Button
                loading={isLoading}
                disabled={isDisable}
                className="load-more"
                onClick={onLoadMore}
            >
                {pagination?.loadMore ?? 'Load More'}
            </Button>
            <Button className="link-text" onClick={goToTop}>
                {pagination?.topLink ?? 'Go to the top'}
            </Button>
        </div>
    );
};
Pagination.propTypes = {
    onLoadMore: PropTypes.func.isRequired,
};

export { Pagination };
