import { Apis, handleResponse, handelError } from './services';
// ENROLL PATIENT
const enrollPatient = (data) => {
    const requestOptions = {
        method: 'POST',
    };
    const { id } = data;
    return Apis()(`/enroll/${id}`, requestOptions)
        .then(handleResponse)
        .then(() => ({ status: true }))
        .catch(handelError);
};
// GET ENROLL LIST
const getAll = ({ pageNumber, orderBy, direction, search, category }) => {
    const requestOptions = {
        method: 'GET',
        params: {
            pageNo: pageNumber,
            orderBy,
            direction,
            search,
            category,
        },
    };
    return Apis()(`/enroll/list`, requestOptions)
        .then(handleResponse)
        .then((data) => ({ status: true, ...data }))
        .catch(handelError);
};

const upgradeUpload = (file) => {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'multipart/form-data' },
        data: file,
    };
    return Apis()(`/patient/uploadPatientList`, requestOptions)
        .then(handleResponse)
        .then(() => ({ status: true }))
        .catch(handelError);
};

export const upgradeService = {
    enrollPatient,
    getAll,
    upgradeUpload,
};
