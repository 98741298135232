/* eslint-disable no-unused-vars */
/* eslint-disable react/sort-comp */
/* eslint-disable react/destructuring-assignment */
import React, { Component } from 'react';
import { Segment, Container } from 'semantic-ui-react';
import { BreadcrumbComp, Image } from '_components';
import { NutritionalFood } from '../NutritionalFood';
import { imgPlaceholder, times } from 'images';
import { lang } from '_config';
import { getFileDetail } from '_utils';
import './NutritionalFoodAdd.scss';
import { trim } from 'lodash';
import _constants from '_constants';

const { nutritionFoodForm, common } = lang.nutritionalTips;
const { categories } = common;

class NutritionalFoodAdd extends Component {
    constructor(props) {
        super(props);
        let id = this.props.location.pathname.split('/')[4];
        let parsedId = id !== undefined ? parseInt(id) : null;
        this.state = {
            id: parsedId,
            title: '',
            orgTitle: '',
            titleCount: 24,
            inputCount: 0,
            category: '',
            quantity: '',
            unit: '',
            subCategory: '',
            subFoodCategoryArr: [],
            apifoodCategoryList: [],
            formatedCategoryList: [],
            unityTransList: [],
            foodList: [],
            selectedUnitObj: {},
            selectedSubCategory: {},
            levels: {
                dpVal0: 'NA',
                dpVal1: 'NA',
                dpVal2: 'NA',
                dpVal3: 'NA',
            },
            img: {
                url: imgPlaceholder,
                err: '',
                imgInfo: '',
            },
            foodValues: common.foodTable,
            submitFlg: true,
            errors: {
                titleErr: '',
            },
            updatedFlg: false,
        };
    }
    getMatchedCategories = (apiList) => {
        let finalArr = [];
        apiList.forEach((item, index) => {
            categories.forEach((it) => {
                if (it.name === item.name) {
                    let subCategory = this.getmatchedSubcategory(
                        item.foodSubCategory,
                        it.subCategory
                    );
                    finalArr.push({
                        key: index,
                        value: it.name,
                        text: it.desc,
                        catobj: item,
                        subcategory: subCategory,
                    });
                }
            });
        });
        this.setState({ ...this.state, formatedCategoryList: finalArr });
    };

    getmatchedSubcategory = (apiSub, trSub) => {
        let subCategory = [];
        apiSub.forEach((item, index) => {
            trSub.forEach((it) => {
                if (item.name === it.name) {
                    subCategory.push({
                        key: index,
                        value: it.name,
                        text: it.desc,
                        subcatobj: item,
                    });
                }
            });
        });
        return subCategory;
    };

    getUpdatedTransUnity = (unity) => {
        let unityTransList = [];
        if (unity.length) {
            unity.forEach((item, index) => {
                nutritionFoodForm.units.forEach((it) => {
                    if (item.name === it.name) {
                        unityTransList.push({
                            key: index,
                            value: it.name,
                            text: it.desc,
                            unitobj: item,
                        });
                    }
                });
            });
            this.setState({ ...this.state, unityTransList: unityTransList });
        }
    };

    componentDidMount() {
        const { foodCategory, unity } = this.props.state.tips.tabs?.NUTRITIONAL;
        const { id, foodValues } = this.state;
        if (id === null) {
            let arr = foodValues.map((item, index) => {
                item.foodVal = '';
                return item;
            });
            this.setState({ ...this.state, foodValues: arr });
        }
        this.props.actions.clearFileDetails();
        if (!unity?.list?.length) {
            this.props.actions.getFoodUnit();
        }
        if (!foodCategory?.list?.length) {
            this.props.actions.getFoodCategories();
        }
    }

    patchformValues = () => {
        const { foodList } = this.props.state.tips.tabs?.NUTRITIONAL;
        let {
            id,
            formatedCategoryList,
            category,
            subCategory,
            levels,
            unit,
            selectedUnitObj,
            unityTransList,
            subFoodCategoryArr,
            foodValues,
            inputCount,
        } = this.state;
        if (id && foodList?.list?.length) {
            const foodListItem = foodList?.list.filter((x) => x.id === id);
            let listfoodItem = foodListItem[0];
            let selectedSubCategory = {};
            formatedCategoryList.forEach((item) => {
                item.subcategory.forEach((it) => {
                    if (it.value === listfoodItem.foodSubCategory?.name) {
                        category = item.value;
                        subCategory = it.value;
                        selectedSubCategory = it.subcatobj;
                        subFoodCategoryArr = item.subcategory;
                    }
                });
            });
            unityTransList.forEach((item) => {
                if (item.value === listfoodItem.foodUnit.name) {
                    unit = item.value;
                    selectedUnitObj = item.unitobj;
                }
            });
            levels.dpVal0 = listfoodItem.calciumLevel;
            levels.dpVal1 = listfoodItem.phosphorusLevel;
            levels.dpVal2 = listfoodItem.potassiumLevel;
            levels.dpVal3 = listfoodItem.sodiumLevel;

            foodValues['0'].foodVal = listfoodItem.energy;
            foodValues['1'].foodVal = listfoodItem.protein;
            foodValues['2'].foodVal = listfoodItem.lipids;
            foodValues['3'].foodVal = listfoodItem.carbohydrates;
            foodValues['4'].foodVal = listfoodItem.fiber;
            foodValues['5'].foodVal = listfoodItem.water;
            foodValues['6'].foodVal = listfoodItem.calcium;
            foodValues['7'].foodVal = listfoodItem.phosphorus;
            foodValues['8'].foodVal = listfoodItem.potassium;
            foodValues['9'].foodVal = listfoodItem.sodium;
            this.setState({
                ...this.state,
                title: listfoodItem.name,
                inputCount: listfoodItem?.name?.length,
                orgTitle: listfoodItem.name,
                category,
                subCategory,
                selectedSubCategory,
                levels,
                quantity: listfoodItem.quantity,
                unit,
                selectedUnitObj,
                subFoodCategoryArr,
                foodValues,
                updatedFlg: true,
                items: [
                    listfoodItem?.calciumLevel,
                    listfoodItem?.phosphorusLevel,
                    listfoodItem?.potassiumLevel,
                    listfoodItem?.sodiumLevel,
                ],
            });
        }
    };

    componentDidUpdate() {
        const {
            unity,
            foodList,
            foodCategory,
        } = this.props?.state?.tips?.tabs?.NUTRITIONAL;
        const {
            id,
            updatedFlg,
            unityTransList,
            formatedCategoryList,
        } = this.state;

        if (unityTransList?.length === 0 && unity?.list) {
            this.getUpdatedTransUnity(unity.list);
        }
        if (foodCategory?.list?.length && formatedCategoryList?.length === 0) {
            this.getMatchedCategories(foodCategory?.list);
        }
        if (
            id &&
            unityTransList?.length &&
            formatedCategoryList?.length &&
            !updatedFlg
        ) {
            this.patchformValues();
        }
        if (foodList?.list && this.state.foodList !== foodList?.list) {
            let { submitFlg, inputCount, title, orgTitle } = this.state;
            if (id) {
                submitFlg =
                    title !== orgTitle &&
                    inputCount > 0 &&
                    foodList?.list.length
                        ? true
                        : false;
            } else {
                submitFlg =
                    inputCount > 0 && foodList?.list.length ? true : submitFlg;
            }
            this.setState({
                ...this.state,
                foodList: foodList?.list,
                submitFlg,
            });
        }

        if (
            this.state.id &&
            this.props.state.filesList.length === 0 &&
            foodList?.list?.length
        ) {
            const foodListItem = foodList?.list?.filter((x) => x.id === id);
            if (foodListItem[0]?.image) {
                this.props.actions.fileTextUpdate({
                    name: 'tipsNutrImg',
                    url: foodListItem[0]?.image,
                });
            }
        }
    }

    getSelectedCategory = (e, data) => {
        const { value } = data;
        const { formatedCategoryList } = this.state;
        let subFoodCategoryArr = [];
        let selectedCategory = {};
        formatedCategoryList.forEach((item) => {
            if (value === item.value) {
                selectedCategory = item.catobj;
                subFoodCategoryArr = item.subcategory;
            }
        });
        this.setState(
            {
                ...this.state,
                category: value,
                selectedCategory: selectedCategory,
                subFoodCategoryArr,
                subCategory: '',
            },
            () => {
                this.checkValidation();
            }
        );
    };

    getSelectedSubCategory = (e, data) => {
        const { subFoodCategoryArr } = this.state;
        let selectedSubCategory = {};
        subFoodCategoryArr.forEach((item) => {
            if (data.value === item.value) {
                selectedSubCategory = item.subcatobj;
            }
        });
        this.setState(
            {
                ...this.state,
                subCategory: data.value,
                selectedSubCategory: selectedSubCategory,
            },
            () => {
                this.checkValidation();
            }
        );
    };

    handleChange = (e) => {
        const { name, value } = e.target;
        let { inputCount } = this.state;
        if (name === 'title') {
            inputCount = value.length;
            if (value.length === 0) this.checkfoodExists(' ');
        }
        if (name === 'quantity') {
            const numbers = /^(\s*|\d+(\.\d*)?|\.\d)$/;
            const isNumber = numbers.test(String(value).toLowerCase());
            if (isNumber && (parseInt(value) < 2001) ^ (value === '')) {
                this.setState(
                    { ...this.state, inputCount, [name]: value },
                    () => {
                        this.checkValidation();
                    }
                );
            }
        } else
            this.setState({ ...this.state, inputCount, [name]: value }, () => {
                this.checkValidation();
            });
    };

    setfoodValue = (e, index) => {
        const { value } = e.target;
        const { foodValues } = this.state;
        const numbers = /^(\s*|\d+(\.\d*)?|\.\d)$/;
        const isNumber = numbers.test(String(value).toLowerCase());
        if (isNumber && (parseInt(value) < 2001) ^ (value === '')) {
            foodValues[index]['foodVal'] = trim(value);
            this.setState({ ...this.state, foodValues }, () => {
                this.checkValidation();
            });
        }
    };

    selectlevels = (e, data, index) => {
        const { levels } = this.state;
        levels[`dpVal${index}`] = data.value;
        this.setState({ ...this.state, levels }, () => {
            this.checkValidation();
        });
    };

    getSelectedunits = (e, data) => {
        const { unityTransList } = this.state;
        let selectedUnitObj = {};
        unityTransList.forEach((item) => {
            if (data.value === item.value) {
                selectedUnitObj = item.unitobj;
            }
        });
        this.setState(
            { ...this.state, unit: data.value, selectedUnitObj },
            () => {
                this.checkValidation();
            }
        );
    };

    checkValidation = () => {
        const { title, inputCount, levels, foodList } = this.state;
        let nameExist = foodList?.filter((it) => it.name === title);
        let submitFlg = true;
        if (
            title === '' ||
            levels.dpVal0 === '' ||
            levels.dpVal1 === '' ||
            levels.dpVal2 === '' ||
            levels.dpVal3 === '' ||
            (inputCount > 0 && nameExist.length) ||
            this.checkMinMaxVal()
        ) {
            submitFlg = true;
        } else {
            submitFlg = false;
        }
        if (title.length > 24) {
            // errors.titleErr = 'Maxlength is 24';
            submitFlg = true;
        }
        this.setState({ ...this.state, submitFlg });
    };

    checkMinMaxVal = () => {
        const { foodValues, quantity } = this.state;
        let invalidFlg;
        let qinvalidFlg;
        for (let i = 0; i < 9; i++) {
            invalidFlg = foodValues[i]?.foodVal
                ? foodValues[i].foodVal > 0 && foodValues[i].foodVal <= 2000
                    ? false
                    : true
                : false;
            if (invalidFlg) break;
        }
        qinvalidFlg = quantity
            ? quantity > 0 && quantity <= 2000
                ? false
                : true
            : false;
        if (qinvalidFlg || invalidFlg) {
            return true;
        }
        return false;
    };

    handleLink = (event) => {
        this.props.history.goBack();
    };

    handleSubmit = (e) => {
        const { filesList } = this.props.state;
        const {
            id,
            title,
            levels,
            selectedSubCategory,
            selectedUnitObj,
            quantity,
            foodValues,
        } = this.state;
        const tipsAddImgUrl = getFileDetail(filesList, 'tipsNutrImg', 'url');
        if (selectedSubCategory) {
            delete selectedSubCategory['createdDate'];
            delete selectedSubCategory['id'];
        }
        if (selectedUnitObj) {
            delete selectedUnitObj['createdDate'];
            delete selectedUnitObj['id'];
        }
        let foodObj = {
            calcium: foodValues[6].foodVal ? foodValues[6].foodVal : null,
            calciumLevel: levels.dpVal0,
            carbohydrates: foodValues[3].foodVal ? foodValues[3].foodVal : null,
            energy: foodValues[0].foodVal ? foodValues[0].foodVal : null,
            fiber: foodValues[4].foodVal ? foodValues[4].foodVal : null,
            foodSubCategory: selectedSubCategory,
            foodUnit: selectedUnitObj,
            image: tipsAddImgUrl,
            lipids: foodValues[2].foodVal ? foodValues[2].foodVal : null,
            name: title,
            phosphorus: foodValues[7].foodVal ? foodValues[7].foodVal : null,
            phosphorusLevel: levels.dpVal1,
            potassium: foodValues[8].foodVal ? foodValues[8].foodVal : null,
            potassiumLevel: levels.dpVal2,
            protein: foodValues[1].foodVal ? foodValues[1].foodVal : null,
            quantity: quantity,
            sodium: foodValues[9].foodVal ? foodValues[9].foodVal : null,
            sodiumLevel: levels.dpVal3,
            water: foodValues[5].foodVal ? foodValues[5].foodVal : null,
        };
        if (id) {
            this.props.actions.updateFood({ id: id, reqObj: foodObj });
        } else {
            this.props.actions.createFood(foodObj);
        }

        e.preventDefault();
    };

    deleteFood = (e) => {
        e.preventDefault();
        this.props.actions.deleteFood(this.state.id);
    };

    handleBlur = (e) => {
        e.preventDefault();
        if (e.target.value.length) {
            this.checkfoodExists(e.target.value);
        } else {
            this.setState({ ...this.state, foodList: [] });
        }
    };

    checkfoodExists = (value) => {
        let params = {
            category: [],
            pageNumber: 0,
            direction: '',
            orderBy: '',
            search: value,
            exactSearch: true,
        };
        this.props.actions.getFoodList(params);
    };
    getSections() {
        const {
            state: { id },
        } = this;
        return [
            {
                key: 'nutritionFoods',
                content:
                    nutritionFoodForm?.pageHeading1 ?? 'Nutritional food value',
                active: true,
                link: true,
                path: 'Tips/NUTRITIONAL',
            },
            {
                key: 'NutriFoodForm',
                content:
                    id === null
                        ? nutritionFoodForm?.pageHeading2
                        : nutritionFoodForm?.pageHeading3,
                active: false,
            },
        ];
    }
    render() {
        const { status } = this.props?.state?.tips?.tabs?.NUTRITIONAL;
        if (status) {
            setTimeout(() => {
                this.props.history.push({ pathname: `/Tips/NUTRITIONAL` });
            }, _constants.TIMEOUT);
        }
        return (
            <Container
                fluid
                id="nutrition-tips-add"
                className={`private-layout`}
            >
                <Segment basic>
                    <BreadcrumbComp
                        sections={this.getSections()}
                        handleLink={this.handleLink}
                    />
                    <div className="food-container">
                        <div className="food-heading">
                            <div className="food-heading-text">
                                {nutritionFoodForm?.header}
                            </div>
                            <div className="food-heading-subtext">
                                {this.state.id === null
                                    ? nutritionFoodForm?.subHeader
                                    : nutritionFoodForm?.subHeaderEdit}
                            </div>
                            <div className="food-close">
                                <Image
                                    src={times}
                                    onClick={() => this.props.history.goBack()}
                                />
                            </div>
                        </div>

                        <NutritionalFood
                            state={this.state}
                            data={this.props}
                            handleChange={this.handleChange}
                            handleBlur={this.handleBlur}
                            getSelectedCategory={this.getSelectedCategory}
                            getSelectedunits={this.getSelectedunits}
                            getSelectedSubCategory={this.getSelectedSubCategory}
                            setfoodValue={this.setfoodValue}
                            selectlevels={this.selectlevels}
                            handleSubmit={this.handleSubmit}
                            deleteFood={this.deleteFood}
                        />
                    </div>
                </Segment>
            </Container>
        );
    }
}

export { NutritionalFoodAdd };
