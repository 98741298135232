import React, { useEffect } from 'react';
import { Segment, Container, Button, Form } from 'semantic-ui-react';
import { useForm } from 'react-hook-form';
import { isEmpty } from 'lodash';
import {
    BreadcrumbComp,
    Image,
    Input,
    TextArea,
    FileUpload,
} from '_components';
import CONST from '_constants';
import { getFileDetail, validMinMaxInput, validMinMaxTextArea } from '_utils';
import { times } from 'images';
import './GeneralAdd.scss';
const GeneralAdd = (props) => {
    const {
        activeTab,
        goBack,
        config: {
            details,
            details: { form, heading, subHeading },
        },
        actions: { clearFileDetails, addGen, addPyhsical },
        state: {
            filesList,
            tips: { loading: genAdding },
        },
    } = props;
    const pageHeading = heading[activeTab.tab] ?? '';
    const pageSubHeadingForAdd = subHeading?.add[activeTab.tab] ?? '';
    const sections = [
        {
            key: 'myAdminListPage',
            content: activeTab.breadcrumbHeading,
            onClick: goBack,
        },
        {
            key: 'myAdminEditPage',
            content: details?.breadcrumbForAdd ?? 'Add',
            active: true,
        },
    ];
    const genAddImageUrl = getFileDetail(filesList, 'genAddImage', 'url'),
        genUploadImg = getFileDetail(
            filesList,
            'genAddImage',
            'uploading',
            true
        ),
        genAddVidUrl = getFileDetail(filesList, 'genAddVid', 'url'),
        genUploadVid = getFileDetail(filesList, 'genAddVid', 'uploading', true);
    const { register, handleSubmit, watch, errors, setValue } = useForm(),
        watchAllFields = watch(['title', 'desc']),
        { title, desc } = watchAllFields,
        validateForm = () => {
            return !validMinMaxInput(title) &&
                !validMinMaxTextArea(desc) &&
                !genUploadImg &&
                !genUploadVid &&
                isEmpty(errors)
                ? false
                : true;
        },
        onSubmit = (data) => {
            const record = {
                title: data?.title,
                description: data?.desc,
                image: genAddImageUrl,
                video: genAddVidUrl,
            };
            if (activeTab.tab === 'GENERAL') addGen(record);
            if (activeTab.tab === 'PHYSICAL') addPyhsical(record);
            setTimeout(() => goBack(true), CONST.TIMEOUT);
        };
    useEffect(() => {
        clearFileDetails();
    }, [clearFileDetails]);
    return (
        <Container fluid id="GeneralAdd" className={`private-layout`}>
            <Segment basic loading={genAdding}>
                <BreadcrumbComp sections={sections} />
                <div className="add-container">
                    <div className="add-heading">
                        <div className="add-heading-text">{pageHeading}</div>
                        <div className="add-heading-subtext">
                            {pageSubHeadingForAdd}
                        </div>
                        <div className="add-close">
                            <Image src={times} onClick={goBack} />
                        </div>
                    </div>
                    <div className="add-form">
                        <Form
                            autoComplete="off"
                            onSubmit={handleSubmit(onSubmit)}
                            unstackable
                        >
                            <Form.Group>
                                <Form.Field width={16}>
                                    <Input
                                        name="title"
                                        label={form?.title?.label}
                                        placeholder={form?.title?.placeholder}
                                        refs={{ register }}
                                        count
                                    />
                                </Form.Field>
                            </Form.Group>
                            <Form.Group>
                                <Form.Field width={5}>
                                    <FileUpload
                                        label={form?.imgGenral?.label}
                                        limit={form?.imgGenral?.limit}
                                        name="genAddImage"
                                        placeholder={
                                            form?.imgGenral?.placeholder
                                        }
                                        optional={details.optional}
                                    />
                                </Form.Field>
                                <Form.Field width={5}>
                                    <FileUpload
                                        label={form?.videoGenral?.label}
                                        limit={form?.videoGenral?.limit}
                                        name="genAddVid"
                                        placeholder={
                                            form?.videoGenral?.placeholder
                                        }
                                        optional={details.optional}
                                        video
                                    />
                                </Form.Field>
                            </Form.Group>
                            <Form.Group>
                                <Form.Field width={16}>
                                    <TextArea
                                        name="desc"
                                        label={form?.desc?.label}
                                        rows={6}
                                        placeholder={form?.desc?.placeholder}
                                        refs={{ register, setValue }}
                                        editor
                                        preview
                                        content={{
                                            desc,
                                            title,
                                            imageUrl: genAddImageUrl,
                                            videoUrl: genAddVidUrl,
                                        }}
                                        count
                                    />
                                </Form.Field>
                            </Form.Group>
                            <Button
                                disabled={validateForm()}
                                className="add-action-button"
                                type="submit"
                            >
                                {form?.actionLableForAdd}
                            </Button>
                        </Form>
                    </div>
                </div>
            </Segment>
        </Container>
    );
};

export { GeneralAdd };
