import { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';

const ChatHistoryHooks = (props) => {
    const {
        selectedId,
        chatClient,
        tab,
        updateMsgHistory,
        roomHistory,
    } = props;
    const chatMsgContentRef = useRef();
    const [msgActions, setMsgActions] = useState({});
    const roomID = selectedId?.roomInfo?.roomID;
    // FOR NEW MSGS UPDATE HISTORY
    useEffect(() => {
        let isChatHistory = true;
        if (isChatHistory)
            if (
                roomID !== undefined &&
                chatClient !== undefined &&
                isChatHistory
            ) {
                chatClient.getChannelBySid(roomID).then(async (roomActions) => {
                    roomActions.on('messageAdded', (msg) => {
                        updateMsgHistory({
                            msg: msg?.state,
                            sid: msg?.channel?.sid,
                            tab,
                        });
                    });
                });
            }
        return () => {
            isChatHistory = false;
            if (roomID !== undefined && chatClient !== undefined) {
                chatClient.removeAllListeners();
            }
        };
    }, [chatClient, roomID, tab, updateMsgHistory, msgActions]);
    const handelScrollHook = async (updatePrevMsgHistory) => {
        try {
            let myActions = {};
            if (isEmpty(msgActions)) {
                const rActions = await chatClient.getChannelBySid(roomID);
                myActions = await rActions.getMessages(100);
            }
            const hasPrevPage =
                msgActions?.hasPrevPage ?? myActions?.hasPrevPage;
            const prevPage = msgActions?.prevPage ?? myActions?.prevPage;

            const ele = chatMsgContentRef.current;
            let scrollPos = ele.scrollY || ele.scrollTop;
            if (scrollPos < 10 && hasPrevPage) {
                const { items, ...others } = await prevPage();
                updatePrevMsgHistory({ items, tab });
                setMsgActions(others);
            }
        } catch (e) {
            return false;
        }
    };
    useEffect(() => {
        setTimeout(() => {
            if (roomHistory.length && chatMsgContentRef?.current)
                chatMsgContentRef.current.scrollTo({
                    top:
                        roomHistory.length > 100
                            ? 100
                            : chatMsgContentRef.current.scrollHeight,
                    behavior: 'smooth',
                });
        }, 500);
    }, [roomHistory]);
    return { handelScrollHook, chatMsgContentRef };
};

ChatHistoryHooks.propTypes = {
    selectedId: PropTypes.object.isRequired,
};

export { ChatHistoryHooks };
