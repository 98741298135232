/* eslint-disable react/no-multi-comp */
import React, { useState, useEffect } from 'react';
import { Icon, Button, Form, Segment } from 'semantic-ui-react';
import { BreadcrumbComp, Select, Input } from '_components';
import { useForm } from 'react-hook-form';
import { validMinMaxInput, validateEmail, validateContact } from '_utils';
import { isEmpty, map, find } from 'lodash';
import ConfirmModal from './ConfirmModal';
const AdminEdit = (props) => {
    // GET ALL VALUE FROM PROPS
    const {
        config: {
            pageHeading,
            details,
            details: { form },
        },
        state: {
            adminList: { centerList, loading, success, selectedId },
        },
        actions: {
            getCenterList,
            clearFailure,
            updateAdmin,
            deleteAdmin,
            getAdminRecord,
        },
        goBack,
        location: {
            pathname,
            state: { record },
        },
    } = props;
    const curRecord = record === undefined ? selectedId : record;
    // GET ALL VALUE FROM CENTERLIST
    const { result: centers, loading: centerLoading } = centerList;
    // update centerList format
    const centerData = map(centers, ({ id, centerName }) => ({
        key: id,
        value: id,
        text: centerName,
    }));
    // for breadcrumb
    const sections = [
        {
            key: 'myAdminListPage',
            content: pageHeading,
            onClick: goBack,
        },
        {
            key: 'myAdminEditPage',
            content: details?.breadcrumbForEdit ?? 'Edit',
            active: true,
        },
    ];
    // get values form useForm
    const { register, handleSubmit, watch, errors } = useForm({
        mode: 'onChange',
    });
    // validating fields
    const watchAllFields = watch([
        'email',
        'phoneNumber',
        'firstName',
        'lastName',
        'password',
        'confirmPassword',
    ]);
    // Get label of center list
    const getLabel = () => {
        try {
            const { text } = find(centerData, { value: isAssign });
            return text;
        } catch (e) {
            return undefined;
        }
    };
    // delete record
    const handleDelRecord = () => {
        deleteAdmin({ id: curRecord?.id });
    };
    // submition the form
    const onSubmit = (data) => {
        updateAdmin({
            id: curRecord?.id,
            centerName: getLabel(),
            confirmPassword: data?.confirmPassword || undefined,
            email: data?.email,
            firstName: data?.firstName,
            lastName: data?.lastName,
            password: data?.password || undefined,
            phoneNumber: data?.phoneNumber || undefined,
        });
    };
    // update center value
    const onChange = (_, data) => {
        setAssign(data.value);
    };
    const {
        email,
        phoneNumber,
        firstName,
        lastName,
        password,
        confirmPassword,
    } = watchAllFields;
    // enable/disable action button
    const validateForm = () => {
        return !validMinMaxInput(email) &&
            validateEmail(email) &&
            !validMinMaxInput(firstName, 3, 30) &&
            !validMinMaxInput(lastName, 3, 30) &&
            (phoneNumber.length
                ? !validMinMaxInput(phoneNumber, 8, 15) &&
                  validateContact(phoneNumber)
                : true) &&
            (password.length ? password === confirmPassword : true) &&
            isEmpty(errors)
            ? false
            : true;
    };
    // Close Modal
    const handleClose = () => {
        setModal(false);
    };
    // Close Modal
    const handleOpen = () => {
        setModal(true);
    };
    //Delete Button
    const deleteButton = () => {
        return (
            <Button
                onClick={handleOpen}
                className="add-action-button outline"
                type="button"
            >
                {form?.actionLableForDel}
            </Button>
        );
    };
    // if record updated return to previous page
    if (success) {
        setTimeout(() => goBack(true), 200);
    }
    // selected center value from the list
    const [isAssign, setAssign] = useState(curRecord?.center?.id ?? false);
    const [isModalOpen, setModal] = useState(false);
    // eslint-disable-next-line react/no-multi-comp
    const showErrors = (_, value) => {
        switch (_?.type) {
            case 'required':
                return <div className="err-msg">{value?.required}</div>;
            case 'minLength':
                return <div className="err-msg">{value?.minLength}</div>;
            case 'pattern':
                return <div className="err-msg">{value?.pattern}</div>;
            default:
                break;
        }
        return !isEmpty(password) &&
            !isEmpty(confirmPassword) &&
            password !== confirmPassword ? (
            <div className="err-msg">{value?.notMatched}</div>
        ) : (
            ''
        );
    };
    useEffect(() => {
        if (isEmpty(record)) {
            getAdminRecord(pathname);
        }
    }, [pathname, getAdminRecord, record]);
    // if centerList is not availble get the centerList
    useEffect(() => {
        clearFailure();
        if (isEmpty(centers) && !centerLoading) {
            getCenterList();
        }
        if (success === true) clearFailure(true);
    }, [centers, centerLoading, getCenterList, clearFailure, success]);
    return (
        <>
            <BreadcrumbComp sections={sections} />
            <div className="edit-container">
                <div className="edit-heading">
                    <div className="edit-heading-text">
                        {details?.pageHeading}
                    </div>
                    <div className="edit-heading-subtext">
                        {details?.pageSubHeadingForEdit}
                    </div>
                    <div className="edit-close">
                        <Icon name="close" size="mini" onClick={goBack} />
                    </div>
                </div>
                {!isEmpty(curRecord) && (
                    <div className="edit-form">
                        <Form
                            unstackable
                            onSubmit={handleSubmit(onSubmit)}
                            loading={loading}
                            autoComplete="off"
                            name="adminForm"
                        >
                            <Form.Group>
                                <Form.Field width={5} className="error-field">
                                    <Input
                                        name="email"
                                        label={form?.email?.label}
                                        placeholder={form?.email?.placeholder}
                                        value={curRecord?.email}
                                        refs={{
                                            register,
                                            validation: {
                                                // pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/,
                                                maxLength: 55,
                                                minLength: 4,
                                            },
                                        }}
                                        autoComplete="email"
                                    />
                                    {/* {showErrors(errors?.email, form?.email)} */}
                                </Form.Field>
                                <Form.Field width={5}>
                                    <Input
                                        name="phoneNumber"
                                        label={form?.contact?.label}
                                        placeholder={form?.contact?.placeholder}
                                        value={curRecord?.phoneNumber}
                                        optional={details?.optional}
                                        refs={{
                                            register,
                                            validation: {
                                                pattern: /^[+-]?\d+$/,
                                                maxLength: 15,
                                                minLength: 8,
                                            },
                                        }}
                                    />
                                </Form.Field>
                            </Form.Group>
                            <Form.Group>
                                <Form.Field width={5}>
                                    <Input
                                        name="firstName"
                                        label={form?.name?.label}
                                        placeholder={form?.name?.placeholder}
                                        value={curRecord?.firstName}
                                        refs={{
                                            register,
                                            validation: {
                                                maxLength: 30,
                                                minLength: 3,
                                            },
                                        }}
                                    />
                                </Form.Field>
                                <Form.Field width={5}>
                                    <Input
                                        name="lastName"
                                        label={form?.surname?.label}
                                        placeholder={form?.surname?.placeholder}
                                        value={curRecord?.lastName}
                                        refs={{
                                            register,
                                            validation: {
                                                maxLength: 30,
                                                minLength: 3,
                                            },
                                        }}
                                    />
                                </Form.Field>
                                <Form.Field width={5}>
                                    {!loading && (
                                        <Select
                                            name="assign"
                                            label={form?.center?.label}
                                            placeholder={
                                                form?.center?.placeholder
                                            }
                                            options={centerData}
                                            loading={centerLoading}
                                            onChange={onChange}
                                            value={isAssign || ''}
                                            fluid
                                            optional={details?.optional}
                                        />
                                    )}
                                </Form.Field>
                            </Form.Group>
                            <Form.Group>
                                <Form.Field width={5} className="error-field">
                                    <Input
                                        name="password"
                                        label={form?.password?.label}
                                        placeholder={
                                            form?.password?.placeholder
                                        }
                                        refs={{
                                            register,
                                            validation: {
                                                maxLength: 15,
                                                minLength: 8,
                                            },
                                        }}
                                        type="password"
                                        optional={
                                            isEmpty(errors?.password) ^
                                            (password !== confirmPassword)
                                                ? details?.optional
                                                : ''
                                        }
                                    />
                                    {showErrors(
                                        errors?.password,
                                        form?.password
                                    )}
                                </Form.Field>
                                <Form.Field width={5} className="error-field">
                                    <Input
                                        name="confirmPassword"
                                        label={form?.repassword?.label}
                                        placeholder={
                                            form?.repassword?.placeholder
                                        }
                                        refs={{
                                            register,
                                            validation: {
                                                maxLength: 15,
                                                minLength: 8,
                                            },
                                        }}
                                        type="password"
                                        optional={
                                            isEmpty(errors?.password) ^
                                            (password !== confirmPassword)
                                                ? details?.optional
                                                : ''
                                        }
                                    />
                                    {showErrors(
                                        errors?.confirmPassword,
                                        form?.password
                                    )}
                                </Form.Field>
                            </Form.Group>
                            <Form.Group>
                                <Form.Field>
                                    <Button
                                        disabled={validateForm()}
                                        className="add-action-button"
                                        type="submit"
                                    >
                                        {form?.actionLableForEdit}
                                    </Button>
                                    {/* <ConfirmModal
                                    config={details?.modal}
                                    handleClose={handleClose}
                                    button={deleteButton}
                                    handleDelRecord={handleDelRecord}
                                    isModalOpen={isModalOpen}
                                    loading={loading}
                                /> */}
                                </Form.Field>
                            </Form.Group>
                        </Form>
                    </div>
                )}
                {!isEmpty(curRecord) && (
                    <Segment className="section-delete">
                        <div className="header-text">
                            {details?.actionLableForHead}
                        </div>
                        <ConfirmModal
                            config={details?.modal}
                            handleClose={handleClose}
                            button={deleteButton}
                            handleDelRecord={handleDelRecord}
                            isModalOpen={isModalOpen}
                            loading={loading}
                        />
                    </Segment>
                )}
            </div>
        </>
    );
};

export { AdminEdit };
