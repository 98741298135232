import { Apis, handelError, handleResponse } from './services';

const getAll = ({ pageNumber, orderBy, direction, search, promotionParticipated }) => {
    const requestOptions = {
        method: 'GET',
        params: {
            pageNumber,
            orderBy,
            direction,
            search,
            promotionParticipated
        },
    };
    return Apis()(`/promotion/list`, requestOptions)
        .then(handleResponse)
        .then((data) => ({ status: true, ...data }))
        .catch(handelError);
};
const getDefault = ({ pageNumber, orderBy, direction, search, promotionParticipated }) => {
    const requestOptions = {
        method: 'GET',
        params: {
            pageNumber,
            orderBy,
            direction,
            search,
            promotionParticipated
        },
    };
    return Apis()(`/promotions15x1/list`, requestOptions)
        .then(handleResponse)
        .then((data) => ({ status: true, ...data }))
        .catch(handelError);
};
const getBoxes = ({ userId }) => {
    const requestOptions = {
        method: 'GET',
        params: {
            userId,
        },
    };
    return Apis()(`/promotions15x1/promotion/current`, requestOptions)
        .then(handleResponse)
        .then((data) => ({ status: true, ...data }))
        .catch(handelError);
};
const getPatientRecord = ({ id, direction, orderBy, pageNumber }) => {
    const requestOptions = {
        method: 'GET',
        params: {
            direction,
            orderBy,
            pageNumber,
            userId: id,
        },
    };
    return Apis()(`/promotions15x1/ticket/list`, requestOptions)
        .then(handleResponse)
        .then((data) => ({ status: true, ...data }))
        .catch(handelError);
};
const updateTicket = (data) => {
    const { id, ...rest } = data;
    const requestOptions = {
        method: 'PUT',
        data: rest,
    };
    return Apis()(`/promotions15x1/ticket/${id}`, requestOptions)
        .then(handleResponse)
        .then(() => ({ status: true }))
        .catch(handelError);
};

const editCustomPromo = ({ id, ...data }) => {
    const requestOptions = {
        method: 'PUT',
        data,
    };
    return Apis()(`/promotion/${id}`, requestOptions)
        .then(handleResponse)
        .then(() => ({ status: true }))
        .catch(handelError);
};
const addCustomPromo = (data) => {
    const requestOptions = {
        method: 'POST',
        data,
    };
    return Apis()(`/promotion`, requestOptions)
        .then(handleResponse)
        .then(() => ({ status: true }))
        .catch(handelError);
};
const viewRecord = ({ id }) => {
    const requestOptions = {
        method: 'GET',
    };
    return Apis()(`/promotion/${id}`, requestOptions)
        .then(handleResponse)
        .then((data) => ({ status: true, ...data }))
        .catch(handelError);
};

const upCustomPromo = ({ id, ...data }) => {
    const requestOptions = {
        method: 'PUT',
        data,
    };
    return Apis()(`/promotion/${id}`, requestOptions)
        .then(handleResponse)
        .then(() => ({ status: true }))
        .catch(handelError);
};
const delCustomPromo = ({ id }) => {
    const requestOptions = {
        method: 'DELETE',
    };
    return Apis()(`/promotion/${id}`, requestOptions)
        .then(handleResponse)
        .then(() => ({ status: true }))
        .catch(handelError);
};

export const promoService = {
    getAll,
    getDefault,
    getBoxes,
    getPatientRecord,
    updateTicket,
    addCustomPromo,
    viewRecord,
    editCustomPromo,
    upCustomPromo,
    delCustomPromo,
};
