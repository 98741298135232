import { connect } from 'react-redux';
import {
    alertActions,
    tipsActions,
    userActions,
    fileUploadAction,
} from '_actions';
import { TipsPage } from '_pages';
import { getRecordId } from '_utils';
import history from '_utils/history';
function mapState(state) {
    const {
        alert,
        tips,
        loginData: { userDetails },
        files: { list: filesList },
    } = state;
    return { state: { alert, filesList, tips, userDetails } };
}

const actionCreators = (dispatch) => {
    return {
        actions: {
            clearAlerts: () => dispatch(alertActions.clear()),
            logOut: () => dispatch(userActions.logout()),
            getGenralList: (queryParams) => {
                dispatch(tipsActions.getGeranal(queryParams));
            },
            getPhysicalList: (queryParams) => {
                dispatch(tipsActions.getPhysical(queryParams));
            },
            addPyhsical: (input) => {
                dispatch(tipsActions.addPyhsical(input));
            },
            editRecord: (input) => {
                const record = getRecordId(input);
                if (record?.category === 'PHYSICAL' && record?.id)
                    dispatch(tipsActions.editPyhsical(record));
                else if (record?.category === 'GENERAL' && record?.id)
                    dispatch(tipsActions.editGen(record));
                else history.push({ pathname: `/Tips/${record?.category}` });
            },
            updatePyhsical: (input) => {
                dispatch(tipsActions.updatePyhsical(input));
            },
            delPyhsical: (input) => {
                dispatch(tipsActions.delPyhsical(input));
            },
            getRecipeslList: (queryParams) => {
                dispatch(tipsActions.getRecipes(queryParams));
            },
            addGen: (input) => {
                dispatch(tipsActions.addGen(input));
            },
            updateGen: (input) => {
                dispatch(tipsActions.updateGen(input));
            },
            delGen: (input) => {
                dispatch(tipsActions.delGen(input));
            },
            addRecipies: (input) => {
                dispatch(tipsActions.addRecipies(input));
            },
            updateRecipies: (input) => {
                dispatch(tipsActions.updateRecipies(input));
            },
            delRecipies: (input) => {
                dispatch(tipsActions.delRecipies(input));
            },
            editRecipies: (input) => {
                const data = getRecordId(input);
                data?.id
                    ? dispatch(tipsActions.editRecipies(data))
                    : history.push({ pathname: '/Tips/RECIPES' });
            },
            clearFileDetails: () => dispatch(fileUploadAction.clearFileData()),
            fileTextUpdate: (file) =>
                dispatch(fileUploadAction.fileTextUpdate(file)),
            getFoodCategories: () => {
                dispatch(tipsActions.getFoodCategories());
            },
            getFoodList: (input) => {
                dispatch(tipsActions.getFoodList(input));
            },
            getFoodUnit: () => {
                dispatch(tipsActions.getFoodUnit());
            },
            createFood: (foodObj) => {
                dispatch(tipsActions.createFood(foodObj));
            },
            updateFood: (foodObj) => {
                dispatch(tipsActions.updateFood(foodObj));
            },
            deleteFood: (id) => {
                dispatch(tipsActions.deleteFood(id));
            },
            getFoodById: (id) => {
                dispatch(tipsActions.getFoodById(id));
            },
        },
    };
};

export const TipsContainer = connect(mapState, actionCreators)(TipsPage);
