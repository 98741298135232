import React from 'react';
import { Image } from '_components/Image';
import { chatImage, chatAudioCall, chatVideoCall } from 'images';

const DefaultAction = (props) => {
    const { isAdmin, tab, config, handleMsg, calling, uploadImage } = props;
    const AudioCall = () => {
        calling({ status: true, type: 'audio' });
    };
    const VideoCall = () => {
        calling({ status: true, type: 'video' });
    };
    return (
        <>
            <div
                className={`chat-actions-input ${
                    isAdmin ? (tab === 'NUTRITIONISTS' ? '' : ' admin') : ''
                }`}
            >
                <textarea
                    placeholder={config?.msg?.placeholder}
                    className="chat-msg-box"
                    rows="3"
                    wrap="soft"
                    onKeyPress={handleMsg}
                />
            </div>
            <div className="chat-actions-button">
                <div className="img-container">
                    <Image
                        className="chat-actions-icon img img-msg"
                        src={chatImage}
                    />
                    <input
                        className={`chat-actions-img ${
                            isAdmin ? ' admin' : ''
                        }`}
                        accept="image/*"
                        type="file"
                        onChange={uploadImage}
                    />
                </div>
                {isAdmin && tab !== 'NUTRITIONISTS' && (
                    <>
                        <Image
                            className="chat-actions-icon"
                            src={chatAudioCall}
                            onClick={AudioCall}
                        />
                        <Image
                            className="chat-actions-icon"
                            src={chatVideoCall}
                            onClick={VideoCall}
                        />
                    </>
                )}
            </div>
        </>
    );
};

export default DefaultAction;
