import React from 'react';
import PropTypes from 'prop-types';
import { GridRowForAdmin } from './GridRowForAdmin';
import { GridRowForNutri } from './GridRowForNutri';
const GridRow = props => {
    const { roles } = props;
    switch (roles) {
        case 'Admin':
            return <GridRowForAdmin {...props} roles={roles} />;
        case 'Nutritionists':
            return <GridRowForNutri {...props} roles={roles} />;
        default:
            return null;
    }
};

GridRow.propTypes = {
    roles: PropTypes.string.isRequired,
};

export { GridRow };
