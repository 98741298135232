import { batch } from 'react-redux';
import MAP from '_constants';
import { mapService } from '_services';

const getAll = (params) => {
    const queryParams = {
        pageNumber: params?.pageNumber ?? 0,
        direction: params?.direction ?? 'DESC',
        orderBy: params?.orderBy ?? 'PLACE',
        search: params?.search ?? '',
    };
    return (dispatch) => {
        const onError = (error) => {
            batch(() => {
                dispatch({
                    type: MAP.MAP_LIST_FAILURE,
                    faliure: error ?? null,
                });
                dispatch({ type: MAP.ERROR, message: error });
                setTimeout(() => dispatch({ type: MAP.CLEAR }), MAP.TIMEOUT);
            });
        };
        batch(() => {
            dispatch({ type: MAP.MAP_REQUEST_START });
            dispatch({ type: MAP.CLEAR });
        });
        mapService
            .getAll(queryParams)
            .then(
                (result) => {
                    if (result && result?.status) {
                        dispatch({
                            type: MAP.MAP_LIST_SUCCESS,
                            result,
                            params: queryParams,
                        });
                    } else {
                        onError(result);
                    }
                },
                (err) => onError(err)
            )
            .catch(onError)
            .finally(() => {
                dispatch({ type: MAP.MAP_REQUEST_END });
            });
    };
};
const uploadMap = (file) => {
    return (dispatch) => {
        const uploadError = (error) => {
            batch(() => {
                dispatch({
                    type: MAP.MAP_UPLOAD_FAILURE,
                    uploadStatus: { ...error },
                });
                dispatch({ type: MAP.ERROR, message: error });
                setTimeout(() => dispatch({ type: MAP.CLEAR }), MAP.TIMEOUT);
            });
        };
        batch(() => {
            dispatch({ type: MAP.MAP_UPLOAD_REQUEST_START });
            dispatch({ type: MAP.CLEAR });
        });
        mapService
            .mapUpload(file)
            .then(
                (result) => {
                    if (result && result?.status) {
                        dispatch({
                            type: MAP.MAP_UPLOAD_SUCCESS,
                        });
                    } else {
                        uploadError(result);
                    }
                },
                (error) => {
                    uploadError(error);
                }
            )
            .catch((error) => {
                uploadError(error);
            })
            .finally(() => {
                dispatch({ type: MAP.MAP_UPLOAD_REQUEST_END });
            });
    };
};
const isUpload = () => {
    return (dispatch) => {
        dispatch({ type: MAP.MAP_UPLOAD_INIT });
    };
};
export const ketoMapActions = {
    getAll,
    uploadMap,
    isUpload,
};
