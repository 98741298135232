import MAP from '_constants';
import { INIT } from '_config/initialState';
const { mapData: initialState } = INIT;

export default (state = initialState, action = {}) => {
    const { type, result, params, uploadStatus, faliure } = action;
    switch (type) {
        case MAP.MAP_REQUEST_START:
            return { ...state, loading: true };
        case MAP.MAP_REQUEST_END:
            return { ...state, loading: false };
        case MAP.MAP_UPLOAD_REQUEST_START:
            return { ...state, uploading: true };
        case MAP.MAP_UPLOAD_REQUEST_END:
            return { ...state, uploading: false };
        case MAP.MAP_UPLOAD_INIT:
            return {
                ...state,
                uploading: false,
                uploaded: false,
                uploadStatus: false,
            };
        case MAP.MAP_LIST_SUCCESS:
            return {
                ...state,
                result: {
                    ...result,
                    list: params.pageNumber
                        ? [...state.result.list, ...result.list]
                        : [...result.list],
                },
                faliure: null,
                params: params,
            };
        case MAP.MAP_LIST_FAILURE:
            return { ...state, faliure };
        case MAP.MAP_UPLOAD_FAILURE:
            return { ...state, uploaded: false, uploadStatus };
        case MAP.MAP_UPLOAD_SUCCESS:
            return { ...state, uploaded: true, uploadStatus: false };
        default:
            return { ...state };
    }
};
export const mapDetails = (state) => state.mapData;
