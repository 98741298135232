import { batch } from 'react-redux';
import NEWS from '_constants';
import { newsService } from '_services';
import { lang } from '_config';
import { getErrorMsg } from '_utils';
const {
    menuList: {
        messages,
        error: { news },
    },
} = lang;
const getAll = (params) => {
    const queryParams = {
        pageNumber: params?.pageNumber ?? 0,
        direction: params?.direction ?? 'DESC',
        orderBy: params?.orderBy ?? 'TITLE',
        search: params?.search ?? '',
    };
    return (dispatch, getState) => {
        const {
            newsList: { loading },
        } = getState();
        if (!loading) {
            const onError = (error) =>
                batch(() => {
                    dispatch({
                        type: NEWS.ERROR,
                        message: error,
                    });
                });
            batch(() => {
                dispatch({
                    type: NEWS.CLEAR,
                });
                dispatch({ type: NEWS.NEWS_REQUEST_START });
            });
            newsService
                .getAll(queryParams)
                .then((result) => {
                    if (result && result?.status) {
                        dispatch({
                            type: NEWS.NEWS_LIST_SUCCESS,
                            result,
                            params: queryParams,
                        });
                    } else {
                        onError(result);
                    }
                })
                .catch(onError)
                .finally(() => {
                    dispatch({ type: NEWS.NEWS_REQUEST_END });
                });
        }
    };
};
const addNews = (addNewsRecord) => {
    return (dispatch) => {
        const onError = (error) => {
            batch(() => {
                dispatch({
                    type: NEWS.NEWS_ADD_FAILURE,
                    faliure: getErrorMsg(error, news?.create) ?? null,
                });
            });
        };
        batch(() => {
            dispatch({ type: NEWS.NEWS_REQUEST_START });
        });
        newsService
            .addNews(addNewsRecord)
            .then((res) => {
                if (res.status) {
                    dispatch({
                        type: NEWS.SUCCESS,
                        message: {
                            suc: news?.create[200]?.msg ?? messages?.add,
                        },
                    });
                } else onError(res);
            })
            .catch(onError)
            .finally(() => {
                batch(() => {
                    dispatch({ type: NEWS.NEWS_REQUEST_END });
                    setTimeout(() => dispatch(getAll()), NEWS.TIMEOUT);
                });
            });
    };
};
const editNews = (params) => {
    return (dispatch) => {
        const onError = (error) =>
            batch(() => {
                dispatch({
                    type: NEWS.NEWS_EDIT_FAILURE,
                    faliure: error ?? null,
                });
            });
        batch(() => {
            dispatch({ type: NEWS.NEWS_REQUEST_START });
        });
        newsService
            .editNews(params)
            .then((result) => {
                if (result && result?.status) {
                    dispatch({
                        type: NEWS.NEWS_SELECTED_ID,
                        result,
                    });
                } else {
                    onError(result);
                }
            })
            .catch(onError)
            .finally(() => dispatch({ type: NEWS.NEWS_REQUEST_END }));
    };
};
const updateNews = (updateRecord) => {
    return (dispatch) => {
        const onError = (error) =>
            batch(() => {
                dispatch({
                    type: NEWS.NEWS_EDIT_FAILURE,
                    faliure: getErrorMsg(error, news?.update) ?? null,
                    params: updateRecord,
                });
            });
        batch(() => {
            dispatch({ type: NEWS.NEWS_REQUEST_START });
        });
        newsService
            .updateNews(updateRecord)
            .then((res) => {
                if (res.status) {
                    dispatch({
                        type: NEWS.SUCCESS,
                        message: {
                            suc: news?.update[200]?.msg ?? messages?.update,
                        },
                    });
                } else onError(res);
            })
            .catch(onError)
            .finally(() => {
                batch(() => {
                    dispatch({ type: NEWS.NEWS_REQUEST_END });
                    setTimeout(() => dispatch(getAll()), NEWS.TIMEOUT);
                });
            });
    };
};
const delNews = (delRecord) => {
    return (dispatch) => {
        const onError = (error) =>
            batch(() => {
                dispatch({
                    type: NEWS.NEWS_DEL_FAILURE,
                    faliure: getErrorMsg(error, news?.delete) ?? null,
                    delRecord,
                });
            });
        batch(() => {
            dispatch({ type: NEWS.NEWS_REQUEST_START });
        });

        newsService
            .deleteNews(delRecord)
            .then((res) => {
                if (res.status) {
                    dispatch({
                        type: NEWS.SUCCESS,
                        message: {
                            suc: news?.delete[200]?.msg ?? messages?.delete,
                        },
                    });
                    setTimeout(() => dispatch(getAll()), NEWS.TIMEOUT);
                } else onError(res);
            })
            .catch(onError)
            .finally(() => {
                batch(() => {
                    dispatch({ type: NEWS.NEWS_REQUEST_END });
                });
            });
    };
};

export const newsActions = {
    getList: getAll,
    addNews,
    editNews,
    updateNews,
    delNews,
};
