import React, { useMemo } from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import { Upgrade } from './upgrade/Upgrade';
import { PatientDetails } from './PatientDetails';
const PatientRoutes = (props) => {
    const { path } = useRouteMatch();
    const UpgradeMemo = useMemo(() => <Upgrade {...props} />, [props]);
    return (
        <Switch>
            <Route exact path={`${path}`}>
                <PatientDetails {...props} />
            </Route>
            <Route exact path={`${path}/upgrade`}>
                {UpgradeMemo}
            </Route>
        </Switch>
    );
};

export { PatientRoutes };
