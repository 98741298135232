import React, { useRef } from 'react';
import { Segment, Container } from 'semantic-ui-react';
import { lang } from '_config';
import AdminRoutes from './Admin.Route';
import './AdminListPage.scss';
const AdminPage = (props) => {
    const cref = useRef('AdminListPage');
    const { admin: config } = lang;
    return (
        <Container
            cref={cref}
            fluid
            id="AdminListPage"
            className={`private-layout`}
        >
            <Segment basic>
                <AdminRoutes {...props} config={config} />
            </Segment>
        </Container>
    );
};

export { AdminPage };
