import React, { useEffect } from 'react';
import { Container, Button, Form, Segment } from 'semantic-ui-react';
import { isEmpty } from 'lodash';
import {
    BreadcrumbComp,
    Image,
    FileUpload,
    TextArea,
    Input,
} from '_components';
import { useForm } from 'react-hook-form';
import { validMinMaxInput, validMinMaxTextArea, getFileDetail } from '_utils';
import { times } from 'images';
import CONST from '_constants';

const NoticesEdit = (props) => {
    const { register, handleSubmit, watch, errors, setValue } = useForm();
    const watchAllFields = watch(['title', 'desc']);
    const {
        goBack,
        config,
        config: {
            details,
            details: { form },
        },
        location: {
            pathname,
            state: { record: defaultRecord },
        },
        state: {
            filesList,
            newsList: { selectedId, loading },
        },
        actions: {
            clearFileDetails,
            editNews,
            updateNews,
            delNews,
            fileTextUpdate,
        },
    } = props;
    const record = isEmpty(defaultRecord) ? selectedId : defaultRecord;
    const sections = [
        {
            key: 'myAdminListPage',
            content: config.pageHeading,
            onClick: goBack,
        },
        {
            key: 'myAdminAddPage',
            content: details?.breadcrumbForEdit ?? 'Edit',
            active: true,
        },
    ];
    const newsEditVidUrl = getFileDetail(filesList, 'newsEditVid', 'url');
    const newsEditImgUrl = getFileDetail(filesList, 'newsEditImg', 'url');
    const newsUploadVid = getFileDetail(
        filesList,
        'newsEditVid',
        'uploading',
        true
    );
    const newsUploadImg = getFileDetail(
        filesList,
        'newsEditImg',
        'uploading',
        true
    );
    const handelDelNews = () => {
        delNews({
            id: record?.id,
        });
        setTimeout(() => goBack(true), CONST.TIMEOUT);
    };
    const onSubmit = (data) => {
        updateNews({
            title: data.title,
            description: data.desc,
            id: record?.id,
            image: newsEditImgUrl,
            video: newsEditVidUrl
        });
        setTimeout(() => goBack(true), CONST.TIMEOUT);
    };
    const { title, desc } = watchAllFields;
    const validateForm = () => {
        return !validMinMaxInput(title) &&
            !validMinMaxTextArea(desc) &&
            !newsUploadImg &&
            !newsUploadVid &&
            isEmpty(errors)
            ? false
            : true;
    };
    useEffect(() => {
        clearFileDetails();
        if (!isEmpty(record)) {
            if (record?.image)
                fileTextUpdate({ name: 'newsEditImg', url: record?.image });
            if (record?.video)
                fileTextUpdate({ name: 'newsEditVid', url: record?.video });
        } else {
            editNews(pathname);
        }
    }, [clearFileDetails, fileTextUpdate, record, pathname, editNews]);
    return (
        <Container fluid id="InfoAdd" className={`private-layout`}>
            <Segment basic loading={loading}>
                <BreadcrumbComp sections={sections} />
                <div className="add-container">
                    <div className="add-heading">
                        <div className="add-heading-text">
                            {details?.pageHeading ?? 'Notices DATA'}
                        </div>
                        <div className="add-heading-subtext">
                            {details?.pageSubHeadingForEdit}
                        </div>
                        <div className="add-close">
                            <Image src={times} onClick={goBack} />
                        </div>
                    </div>
                    <div className="add-form">
                        <Form onSubmit={handleSubmit(onSubmit)} unstackable>
                            <Form.Field width={16}>
                                <Input
                                    name="title"
                                    label={form?.title?.label}
                                    placeholder={form?.title?.placeholder}
                                    refs={{ register }}
                                    value={record?.title}
                                    count
                                />
                            </Form.Field>
                            <Form.Group>
                                <Form.Field width={6}>
                                    <FileUpload
                                        name="newsEditImg"
                                        label={form?.img?.label}
                                        limit={form?.img?.limit}
                                        placeholder={form?.img?.placeholder}
                                        optional={details.optional}
                                    />
                                </Form.Field>
                                <Form.Field width={6}>
                                    <FileUpload
                                        name="newsEditVid"
                                        label={form?.video?.label}
                                        limit={form?.video?.limit}
                                        placeholder={form?.video?.placeholder}
                                        optional={details.optional}
                                        video
                                    />
                                </Form.Field>
                            </Form.Group>
                            <Form.Field width={16}>
                                <TextArea
                                    name="desc"
                                    label={form?.desc?.label}
                                    rows={6}
                                    placeholder={form?.desc?.placeholder}
                                    refs={{ register, setValue }}
                                    value={record?.description}
                                    editor
                                    preview
                                    content={{
                                        desc: desc ?? record?.description,
                                        title: title ?? record?.title,
                                        imageUrl: newsEditImgUrl,
                                        videoUrl: newsEditVidUrl,
                                    }}
                                    count
                                />
                            </Form.Field>
                            <Button
                                className="add-action-button"
                                type="submit"
                                disabled={validateForm()}
                            >
                                {form?.actionLableForEdit}
                            </Button>
                            <Button
                                className="add-action-button outline"
                                type="button"
                                onClick={handelDelNews}
                            >
                                {form?.actionLableForDelete}
                            </Button>
                        </Form>
                    </div>
                </div>
            </Segment>
        </Container>
    );
};

export { NoticesEdit };
