/* eslint-disable react/no-danger */
/* eslint-disable react/no-multi-comp */
import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Placeholder } from 'semantic-ui-react';
import { map, isEmpty } from 'lodash';
import './GridRow.scss';
import { Popup } from '_components/Core/Popup/Popup';
const GridRowForNutri = (props) => {
    const {
        cellData,
        rowIndex,
        listData: { cellWidth: width, columns, isHtml, loading },
    } = props;
    const loadingText = () => (
        <Placeholder>
            <Placeholder.Line />
        </Placeholder>
    );
    const createEmptyColums = () => (
        <Grid.Column>Ningún record fue encontrado</Grid.Column>
    );
    const getContent = (_) => (
        <span dangerouslySetInnerHTML={{ __html: _ ?? '' }} />
    );
    const loadData = (column, index) => {
        return loading ? (
            loadingText()
        ) : (
            <div className="text-data">
                <Popup
                    className="view-grid-popup"
                    button={
                        <label className="expire-input center-list">
                            {isHtml[index]
                                ? getContent(cellTexts[column])
                                : cellTexts[column]}
                        </label>
                    }
                    content={
                        isHtml[index]
                            ? getContent(cellTexts[column])
                            : cellTexts[column]
                    }
                    position="bottom center"
                    size="small"
                    hideOnScroll={false}
                    inverted
                    on="hover"
                />
            </div>
        );
    };
    const createColumns = () => {
        const rows = map(columns, (column, index) => {
            return (
                <Grid.Column key={`${rowIndex}-${index}`} width={width[index]}>
                    {loadData(column, index)}
                </Grid.Column>
            );
        });
        if (props.children !== undefined && loading === false) {
            rows.push(props.children(id, cellTexts));
        }
        if (loading)
            rows.push(
                <Grid.Column key={`${rowIndex}`}>{loadingText()}</Grid.Column>
            );
        return rows;
    };
    const { id, ...cellTexts } = cellData;
    return (
        <Grid.Row className={loading ? 'cell-data loading' : 'cell-data'}>
            {isEmpty(cellData) && loading === false
                ? createEmptyColums()
                : createColumns()}
        </Grid.Row>
    );
};

GridRowForNutri.propTypes = {
    cellData: PropTypes.object.isRequired,
    roles: PropTypes.string.isRequired,
    config: PropTypes.object.isRequired,
};

export { GridRowForNutri };
