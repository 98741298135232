import React, { useState } from 'react';
import { Image } from '_components/Image';
import { Button, Icon } from 'semantic-ui-react';
import { isEmpty } from 'lodash';
import { Popup } from '_components/Core/Popup/Popup';
import { ConfirmModal } from '_components/ConfirmModal/ConfirmModal';

// eslint-disable-next-line react/display-name
const ViewTicket = React.forwardRef((props, inputRef) => {
    const {
        ticketDetails,
        config,
        handelReject,
        isDisable,
        isReject,
        handelValid,
        handelSubmit,
        boxes,
        handelNote,
        handelPharmacy,
        isEnable,
        updateTicketValid,
        pharmacyText,
        visiblePharmacy,
        loading,
    } = props;
    const [isModalOpen, setModal] = useState(false);
    // Close Modal
    const handleClose = () => {
        setModal(false);
    };
    // Close Modal
    const handleOpen = () => {
        setModal(true);
    };
    return (
        <>
            <div className="ticket-validate-modal-image">
                {!isEmpty(ticketDetails?.url) && (
                    <Image wrapped size="medium" src={ticketDetails?.url} />
                )}
            </div>
            <div className="ticket-validate-modal-body">
                <div className="ticket-validate-modal-body-line defualt">
                    <label>
                        {isReject ? config?.rejectHeading : config?.heading1}
                    </label>
                    <div className="ticket-validate-modal-body-actions">
                        <Button
                            onClick={handelReject}
                            disabled={isDisable}
                            circular
                            icon="close"
                            className={`active close ${
                                isReject ? 'reject' : ''
                            }`}
                        />
                        <Button
                            onClick={handelValid}
                            disabled={isDisable}
                            circular
                            icon="check"
                            className={`check active${
                                isReject ? 'reject' : ''
                            }`}
                        />
                    </div>
                </div>
                {isReject ? (
                    <div className={`ticket-validate-modal-body-line rejected`}>
                        <label>{config?.heading3}</label>
                        <Icon
                            size="large"
                            name="angle right"
                            color="blue"
                            className="icon-link"
                            onClick={handelNote}
                        />
                    </div>
                ) : (
                    <>
                        <div
                            className={`ticket-validate-modal-body-line defualt`}
                        >
                            <label>{config?.heading2}</label>
                            <input
                                ref={inputRef}
                                onChange={handelSubmit}
                                disabled={isDisable}
                                className="expire-input"
                                maxLength="2"
                                defaultValue={boxes}
                            />
                        </div>
                        {visiblePharmacy && (
                            <div
                                className="ticket-validate-modal-body-line defualt"
                                onClick={handelPharmacy}
                            >
                                <label>{config?.heading4}</label>
                                <Popup
                                    className="view-ticket-popup"
                                    button={
                                        <label className="expire-input center-list">
                                            {isEmpty(pharmacyText)
                                                ? config?.pharmacyLabel
                                                : pharmacyText}
                                        </label>
                                    }
                                    content={pharmacyText ?? ''}
                                    position="bottom right"
                                    size="mini"
                                    inverted
                                    on={
                                        isEmpty(pharmacyText)
                                            ? 'click'
                                            : 'hover'
                                    }
                                />

                                <Icon
                                    size="large"
                                    name="angle right"
                                    color="blue"
                                    className="icon-link center-list-icon"
                                />
                            </div>
                        )}
                    </>
                )}
            </div>
            <div className="ticket-validate-modal-footer-button">
                <ConfirmModal
                    config={config?.modal?.valid}
                    handleClose={handleClose}
                    button={() => (
                        <Button
                            disabled={isEnable}
                            onClick={handleOpen}
                            size="large"
                        >
                            {isReject
                                ? config?.rejectLable
                                : config?.actionLable}
                        </Button>
                    )}
                    actionButton={updateTicketValid}
                    isModalOpen={isModalOpen}
                    loading={loading}
                />
            </div>
        </>
    );
});

export default ViewTicket;
