import { Apis, handleResponse, handelError } from './services';

const getAll = ({ pageNumber, orderBy, direction, search, category }) => {
    const requestOptions = {
        method: 'GET',
        params: {
            pageNumber,
            orderBy,
            direction,
            search,
            category,
        },
    };
    return Apis()(`/news/list`, requestOptions)
        .then(handleResponse)
        .then((res) => ({ status: true, ...res }))
        .catch(handelError);
};
const addNews = (data) => {
    const requestOptions = {
        method: 'POST',
        data,
    };
    return Apis()(`/news`, requestOptions)
        .then(handleResponse)
        .then(() => ({ status: true }))
        .catch(handelError);
};
const editNews = (data) => {
    const requestOptions = {
        method: 'GET',
    };
    const { id } = data;
    return Apis()(`/news/${id}`, requestOptions)
        .then(handleResponse)
        .then((data) => ({ status: true, ...data }))
        .catch(handelError);
};
const updateNews = (data) => {
    const { id, ...rest } = data;
    const requestOptions = {
        method: 'PUT',
        data: rest,
    };

    return Apis()(`/news/${id}`, requestOptions)
        .then(handleResponse)
        .then(() => ({ status: true }))
        .catch(handelError);
};
const deleteNews = (data) => {
    const requestOptions = {
        method: 'DELETE',
    };
    const { id } = data;
    return Apis()(`/news/${id}`, requestOptions)
        .then(handleResponse)
        .then(() => ({ status: true }))
        .catch(handelError);
};

export const newsService = {
    getAll,
    editNews,
    addNews,
    updateNews,
    deleteNews,
};
