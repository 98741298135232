import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';

import rootReducer from '_reducers';
import { INIT } from '_config/initialState';
// const loggerMiddleware = createLogger();
let middleware = [thunk];
let enhancers = [applyMiddleware(...middleware)];
let tools = compose(...enhancers);

if (process.env.NODE_ENV === 'development') {
    tools = composeWithDevTools(...enhancers);
}
export const store = createStore(rootReducer, INIT, tools);
