/* eslint-disable react/no-danger */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import './PrivacyPolicy.scss';
import { Icon, Transition } from 'semantic-ui-react';
const PrivacyPolicy = (props) => {
    const {
        desc,
        activeTab: { tab },
        history,
        activeTab: { breadcrumbHeading },
        currentGdpr,
        currentTerm,
        currentPP,
    } = props;
    const [show, setShow] = useState(false);
    const editPolicy = () => {
        history.push({
            pathname: `/Gdpr/${tab}/Add`,
            state: { record: { ...currentTerm } },
        });
    };
    const handleDesc = () => {
        setShow(!show);
    };
    const descHtml = { __html: desc };
    useEffect(() => {
        if (tab === 'TERM_CONDITION') currentGdpr();
        if (tab === 'PRIVACY_POLICY') currentPP();
    }, [currentGdpr, tab, currentPP]);
    return (
        <div id="Privacy-Policy-List">
            <div className="heading">
                <div className="heading-text">
                    {currentTerm?.title ?? breadcrumbHeading} -
                    {currentTerm?.version ?? 'V0.0.0'}
                    {tab === 'TERM_CONDITION' && (
                        <span className="heading-arrow">
                            <Icon
                                onClick={handleDesc}
                                name={`arrow ${show ? 'up' : 'down'}`}
                            />
                        </span>
                    )}
                </div>
                <div className="heading-icon">
                    <Icon onClick={editPolicy} name="add" />
                </div>
            </div>
            {tab === 'TERM_CONDITION' ? (
                <Transition
                    visible={show}
                    animation="vertical flip"
                    duration={500}
                >
                    <div className="desc" dangerouslySetInnerHTML={descHtml} />
                </Transition>
            ) : (
                <div className="desc" dangerouslySetInnerHTML={descHtml} />
            )}
        </div>
    );
};

PrivacyPolicy.propTypes = {
    heading: PropTypes.string,
    desc: PropTypes.string,
};

export { PrivacyPolicy };
