import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Segment, Grid, Form, Button } from 'semantic-ui-react';
import { BreadcrumbComp, Image, Input } from '_components';
import { times } from 'images';
import './EditPatientInfo.scss';
import { lang } from '_config';
import { ConfirmModal } from '_components/ConfirmModal/ConfirmModal';
import CONST from '_constants';
import { validMinMaxInput, getUserDetails, getFormattedPhone } from '_utils';
import { isEmpty } from 'lodash';
import INFO from '_constants';

const { details } = lang.editPatient;

const EditPatientInfo = (props) => {
    const {
        patientInfo,
        updatedInfo,
        getPatientDetail,
        profileDel,
        history,
        loading,
        match: { params },
    } = props;
    const {
        Role: [role],
    } = getUserDetails();
    const [editflg, setEdit] = useState(false);
    const [isModalOpen, setModal] = useState(false);
    const userName = patientInfo?.firstName + ' ' + patientInfo?.lastName;
    const { id } = params;
    const sections = [
        {
            key: 'myPatients',
            content: details?.breadCrumb1,
            active: true,
            link: true,
            path: '/Patients',
        },
        {
            key: 'patientsName',
            content: userName,
            active: true,
            link: true,
            path: `/Patients/${id}`,
        },
        {
            key: 'editPatient',
            content: details?.breadCrumb2,
            active: true,
        },
    ];
    const { register, handleSubmit, watch, formState, errors } = useForm({
        mode: 'onChange',
    });
    const watchAllFields = watch(['firstName', 'surName', 'phone', 'email']);
    const handelDelProfile = async () => {
        await profileDel(patientInfo.id);
        handleClose();
    };
    // open modal
    const handleOpen = () => {
        setModal(true);
    };
    // close modal
    const handleClose = () => {
        setModal(false);
    };
    const handleLink = (event) => {
        const pathname = event.target.getAttribute('path');
        if (pathname) {
            props.history.push({ pathname });
        }
    };

    const handleChange = () => {
        if (!editflg) {
            setEdit(true);
        }
    };
    useEffect(() => {
        if (isEmpty(patientInfo)) {
            getPatientDetail(id);
        }
        if (updatedInfo.status) {
            if (updatedInfo?.deleted) {
                setTimeout(() => {
                    history.push('/Patients');
                }, CONST.TIMEOUT);
            } else {
                setTimeout(history.goBack, CONST.TIMEOUT);
            }
        }
    }, [patientInfo, updatedInfo, getPatientDetail, history, id]);

    const onSubmit = (data) => {
        const formattedPhone = getFormattedPhone(data.phone);
        let obj = {
            id: patientInfo.id,
            userdata: {
                email: data.email,
                firstName: data.firstName,
                lastName: data.surName,
                phoneNumber: formattedPhone,
            },
        };
        props.updatePatientInfo(obj);
    };

    const isDisabled = () => {
        const { firstName, surName } = watchAllFields;
        let formValid = !formState.isValid;
        return editflg &&
            !validMinMaxInput(firstName) &&
            !validMinMaxInput(surName)
            ? formValid
            : true;
    };
    return (
        <div className="edit-patient-info-container">
            <Segment basic>
                <BreadcrumbComp sections={sections} handleLink={handleLink} />
                <Grid className="ui-section">
                    <Grid.Column width={16}>
                        <Segment.Group>
                            <Segment className="section-header">
                                {details?.pageHeading}
                                <Image
                                    floated="right"
                                    src={times}
                                    onClick={() => props.history.goBack()}
                                />
                            </Segment>
                            <Segment className="sub-section">
                                <div className="sub-header">
                                    {details?.subTitle}
                                </div>
                                <div className="mg-top-30">
                                    <div className="subtitle">
                                        {details?.nutriRecordId}
                                    </div>
                                    <div className="info">
                                        {patientInfo?.nutritionalRecordId ??
                                            '-'}
                                    </div>
                                </div>
                                {!isEmpty(props?.patientInfo) && (
                                    <Form
                                        onSubmit={handleSubmit(onSubmit)}
                                        autoComplete="off"
                                    >
                                        <Grid>
                                            <Grid.Row className="mg-top-30">
                                                <Grid.Column width={12}>
                                                    <Form.Group widths="equal">
                                                        <Form.Field>
                                                            <Input
                                                                name="firstName"
                                                                label={
                                                                    details?.name
                                                                }
                                                                value={
                                                                    patientInfo?.firstName
                                                                }
                                                                placeholder={
                                                                    details?.name
                                                                }
                                                                onChange={
                                                                    handleChange
                                                                }
                                                                refs={register({
                                                                    required: true,
                                                                })}
                                                            />

                                                            {/* {errors.firstName && <div className="err-msg">{errors.firstName}</div>} */}
                                                        </Form.Field>
                                                        <Form.Field>
                                                            <Input
                                                                name="surName"
                                                                label={
                                                                    details?.surName
                                                                }
                                                                placeholder={
                                                                    details?.surName
                                                                }
                                                                value={
                                                                    patientInfo?.lastName
                                                                }
                                                                onChange={
                                                                    handleChange
                                                                }
                                                                refs={register({
                                                                    required: true,
                                                                })}
                                                            />
                                                            {/* {errors.surName && <div className="err-msg">{errors.surName}</div>} */}
                                                        </Form.Field>
                                                    </Form.Group>

                                                    <Form.Group widths="equal">
                                                        <Form.Field>
                                                            <label>
                                                                {' '}
                                                                {details?.phone}
                                                            </label>
                                                            <input
                                                                name="phone"
                                                                type="text"
                                                                label={
                                                                    details?.phone
                                                                }
                                                                defaultValue={
                                                                    patientInfo?.phoneNumber
                                                                }
                                                                onChange={
                                                                    handleChange
                                                                }
                                                                placeholder={
                                                                    details?.phone
                                                                }
                                                                ref={register({
                                                                    required: true,
                                                                    pattern: /^[+]\d{8,15}$/,
                                                                    maxLength: 16,
                                                                    minLength: 9,
                                                                })}
                                                            />
                                                            {errors.phone
                                                                ?.type ===
                                                                'pattern' && (
                                                                <div className="err-msg">
                                                                    {
                                                                        details
                                                                            ?.validations
                                                                            ?.phone
                                                                            ?.pattern
                                                                    }
                                                                </div>
                                                            )}
                                                            {errors.phone
                                                                ?.type ===
                                                                'required' && (
                                                                <div className="err-msg">
                                                                    {
                                                                        details
                                                                            ?.validations
                                                                            ?.phone
                                                                            ?.required
                                                                    }
                                                                </div>
                                                            )}
                                                            {(errors.phone
                                                                ?.type ===
                                                                'minLength' ||
                                                                errors.phone
                                                                    ?.type ===
                                                                    'maxLength') && (
                                                                <div className="err-msg">
                                                                    {
                                                                        details
                                                                            ?.validations
                                                                            ?.phone
                                                                            ?.invalid
                                                                    }
                                                                </div>
                                                            )}
                                                        </Form.Field>
                                                        <Form.Field>
                                                            <label>
                                                                {' '}
                                                                {details?.email}
                                                            </label>
                                                            <input
                                                                name="email"
                                                                type="text"
                                                                label={
                                                                    details?.email
                                                                }
                                                                defaultValue={
                                                                    patientInfo?.email
                                                                }
                                                                placeholder={
                                                                    details?.email
                                                                }
                                                                onChange={
                                                                    handleChange
                                                                }
                                                                ref={register({
                                                                    pattern: /\S+@\S+\.\S+/,
                                                                })}
                                                            />
                                                            {errors.email ? (
                                                                <div className="err-msg">
                                                                    {
                                                                        details
                                                                            ?.validations
                                                                            ?.email
                                                                    }
                                                                </div>
                                                            ) : (
                                                                <div className="optional">
                                                                    {
                                                                        details?.optional
                                                                    }
                                                                </div>
                                                            )}
                                                        </Form.Field>
                                                    </Form.Group>
                                                    <Button
                                                        type="submit"
                                                        className="sq-button mt-20"
                                                        disabled={isDisabled()}
                                                        loading={
                                                            loading ?? false
                                                        }
                                                    >
                                                        {details?.save}
                                                    </Button>
                                                </Grid.Column>
                                            </Grid.Row>
                                        </Grid>
                                    </Form>
                                )}
                            </Segment>
                        </Segment.Group>
                    </Grid.Column>
                </Grid>
                {!isEmpty(props?.patientInfo) && INFO.ROLES.ADMIN === role && (
                    <Segment className="section-delete">
                        <div className="header-text">
                            {details?.deletedHeading}
                        </div>
                        <ConfirmModal
                            config={details?.popup}
                            handleClose={handleClose}
                            button={() => (
                                <Button
                                    type="submit"
                                    onClick={handleOpen}
                                    className="sq-button"
                                >
                                    {details?.deleteAction}
                                </Button>
                            )}
                            actionButton={handelDelProfile}
                            isModalOpen={isModalOpen}
                            loading={loading ?? false}
                        />
                    </Segment>
                )}
            </Segment>
        </div>
    );
};
export { EditPatientInfo };
