/* eslint-disable react/no-string-refs */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Image } from '_components/Image';
import { callEnd, callStopVideo, callMicCross, callFullView } from 'images';
class VideoCall extends Component {
    constructor() {
        super();
        this.roomJoined = this.roomJoined.bind(this);
        this.participantConnected = this.participantConnected.bind(this);
        this.participantDisconnected = this.participantDisconnected.bind(this);
        this.trackSubscribed = this.trackSubscribed.bind(this);
        this.trackUnsubscribed = this.trackUnsubscribed.bind(this);
        this.handleFullView = this.handleFullView.bind(this);
        this.handleVoice = this.handleVoice.bind(this);
        this.handleStopVideo = this.handleStopVideo.bind(this);
        this.leaveRoom = this.leaveRoom.bind(this);
    }
    state = {
        localMediaAvailable: false,
        hasJoinedRoom: false,
        fullView: false,
        isAudio: true,
        isVideo: true,
        activeRoom: '',
    };
    componentDidMount() {
        this.createConection();
    }
    componentWillUnmount() {
        this.leaveRoom();
    }
    createConection() {
        const {
            props: {
                videoRoom: { memberName },
            },
        } = this;
        this.setState(
            { identity: memberName, localMediaAvailable: true },
            this.roomJoined
        );
    }

    /// START FOR UPDATED LIB
    participantConnected(participant) {
        const {
            refs: { remoteMedia },
        } = this;
        // const curMedia = isLocal ? localMedia : remoteMedia;
        const curMedia = remoteMedia;
        const div = document.createElement('div');
        div.id = participant.sid;
        div.className = 'item-remote-user';
        // div.innerText = participant.identity;
        curMedia.appendChild(div);
        participant.on('trackSubscribed', (track) =>
            this.trackSubscribed(div, track)
        );
        participant.on('trackUnsubscribed', this.trackUnsubscribed);

        participant.tracks.forEach((publication) => {
            if (publication.isSubscribed) {
                this.trackSubscribed(div, publication.track);
            }
        });
    }

    participantDisconnected(participant) {
        const curVid = document.getElementById(participant?.sid);
        if (curVid) curVid.remove();
    }

    trackSubscribed(div, track) {
        if (track.attach) {
            div.appendChild(track.attach());
        }
    }

    trackUnsubscribed(track) {
        if (track.detach) track.detach().forEach((element) => element.remove());
    }
    roomJoined() {
        const {
            props: {
                videoRoom: { videoRoom: room },
                calling: { type },
            },
            refs: { localMedia },
        } = this;
        this.setState({
            activeRoom: room,
            localMediaAvailable: true,
            hasJoinedRoom: true,
        });
        const div = document.createElement('div');
        div.id = room.localParticipant.sid;
        div.className = 'item-local-user';
        // div.innerText = room.localParticipant.identity;
        localMedia.appendChild(div);
        // Called when a localParticipant joins a room
        room.localParticipant.audioTracks.forEach((publication) => {
            // publication.track.disable();
            publication.track.enable();
            this.trackSubscribed(div, publication.track);
        });
        if (type === 'video')
            room.localParticipant.videoTracks.forEach((publication) => {
                // publication.track.disable();
                publication.track.enable();
                this.trackSubscribed(div, publication.track);
            });
        // Called when a participant joins a room
        room.participants.forEach(this.participantConnected);
        room.on('participantConnected', this.participantConnected);

        room.on('participantDisconnected', async () => {
            await this.participantDisconnected();
            this.leaveRoom();
        });
        room.once('disconnected', () => {
            room.participants.forEach(this.participantDisconnected);
        });
    }
    leaveRoom() {
        const {
            state: { activeRoom },
            props: {
                videoRoom: { chatClient },
                calling: { type },
                endCall,
            },
        } = this;
        if (activeRoom !== '') {
            // Call when a localParticipant left a room
            if (activeRoom.localParticipant)
                activeRoom.localParticipant.audioTracks.forEach(
                    (publication) => {
                        publication.track.disable();
                        publication.track.stop();
                        publication.unpublish();
                    }
                );
            if (type === 'video')
                activeRoom.localParticipant.videoTracks.forEach(
                    (publication) => {
                        publication.track.disable();
                        publication.track.stop();
                        publication.unpublish();
                    }
                );
            activeRoom.disconnect();
            const isLocalMedia = document.getElementById(
                activeRoom.localParticipant.sid
            );
            if (isLocalMedia) isLocalMedia.remove();
            this.setState({
                hasJoinedRoom: false,
                localMediaAvailable: false,
                activeRoom: '',
            });
            chatClient.removeAllListeners();
            endCall();
        }
    }
    handleStopVideo() {
        const {
            state: { activeRoom, isVideo },
        } = this;
        if (activeRoom.localParticipant) {
            activeRoom.localParticipant.videoTracks.forEach((publication) => {
                isVideo
                    ? publication.track.disable()
                    : publication.track.enable();
            });
            this.setState({ isVideo: !isVideo });
        }
    }
    handleVoice() {
        const {
            state: { activeRoom, isAudio },
        } = this;
        if (activeRoom.localParticipant) {
            activeRoom.localParticipant.audioTracks.forEach((publication) => {
                isAudio
                    ? publication.track.disable()
                    : publication.track.enable();
            });
            this.setState({ isAudio: !isAudio });
        }
    }
    handleFullView() {
        const {
            refs: { callContent },
        } = this;
        const element = callContent;
        const requestMethod =
            element.requestFullScreen ||
            element.webkitRequestFullScreen ||
            element.mozRequestFullScreen ||
            element.msRequestFullScreen;
        if (document.fullscreenElement) {
            document.exitFullscreen();
        } else if (requestMethod) {
            // Native full screen.
            requestMethod.call(element);
        }
    }
    render() {
        const {
            state: { localMediaAvailable, isAudio, isVideo },
            props: {
                userName,
                Caller,
                calling: { type: callType },
            },
        } = this;
        return (
            <div ref="callContent" className="call-container">
                {localMediaAvailable && (
                    <div ref="localMedia" className="item-local" />
                )}
                <div className="ring-container">
                    {/* <div className="ring-text">Calling...</div> */}
                    {callType === 'audio' && (
                        <div className="ring-user">
                            <div className="ring-img">{userName.join('')}</div>
                        </div>
                    )}
                    <div
                        ref="remoteMedia"
                        className={`item-remote ${
                            callType === 'audio' ? 'hide' : ''
                        }`}
                    />
                    <div className="ring-user-name">{Caller}</div>
                    <div className="ring-user-action">
                        <Image onClick={this.leaveRoom} src={callEnd} />
                        {callType === 'video' && (
                            <Image
                                className={`${isVideo ? '' : 'audio-disable'}`}
                                onClick={this.handleStopVideo}
                                src={callStopVideo}
                            />
                        )}
                        <Image
                            className={`${isAudio ? '' : 'audio-disable'}`}
                            onClick={this.handleVoice}
                            src={callMicCross}
                        />
                        <Image
                            onClick={this.handleFullView}
                            src={callFullView}
                        />
                    </div>
                </div>
            </div>
        );
    }
}
VideoCall.propTypes = {
    videoRoom: PropTypes.object.isRequired,
};
export default VideoCall;
