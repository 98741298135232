import React, { useCallback, useEffect } from 'react';
import { Sidebar as Sides, Menu, Icon } from 'semantic-ui-react';
import {
    promotions,
    promotionsActive,
    location,
    locationActive,
    iconSetting,
    iconSettingActive,
    iconChat,
    iconChatActive,
    iconPatient,
    iconPatientActive,
    iconUpgrade,
    iconUpgradeActive,
    iconAdmin,
    iconAdminActive,
    iconInfo,
    iconInfoActive,
    iconNutri,
    iconNutriActive,
    iconNotices,
    iconNoticesActive,
    iconLogout,
    iconLogoutActive,
    iconGdpr,
    iconGdprActive,
} from 'images';
import { Image, LogoutModal } from '_components';
import './Sidebar.scss';
import { indexOf } from 'lodash';
const Sidebar = (props) => {
    const logOut = props?.actions?.logOut;
    // const getStateData = props?.history?.location?.state?.userType;
    const {
        history: {
            location: { state },
        },
        state: { userDetails, menuList: menuItems },
        actions: { selectMenuItem },
        menuList,
    } = props;
    const userType = state?.activeTab ?? state?.userType ?? '';
    const handleLogout = () => {
        try {
            logOut();
        } finally {
            props.history.push('/login');
        }
    };
    const goToPage = (name) => {
        try {
            // setMenuItem(name);
            selectMenuItem(name);
            props.history.push({
                pathname: `/${name}`,
                state: { userType },
            });
            // eslint-disable-next-line no-empty
        } catch (e) {}
    };
    const menuListForAdmin = [
        {
            name: 'Admins',
            lable: menuList[0],
            icon:
                menuItems?.selected === 'Admins' ? iconAdminActive : iconAdmin,
            isIcon: false,
            handel: goToPage,
        },
        {
            name: 'Nutritionists',
            lable: menuList[1],
            icon:
                menuItems?.selected === 'Nutritionists'
                    ? iconNutriActive
                    : iconNutri,
            isIcon: false,
            handel: goToPage,
        },
        {
            name: 'Patients',
            lable: menuList[2],
            icon:
                menuItems?.selected === 'Patients'
                    ? iconPatientActive
                    : iconPatient,
            isIcon: false,
            handel: goToPage,
        },
        {
            name: 'Activar',
            lable: menuList[3],
            icon:
                menuItems?.selected === 'Activar'
                    ? iconUpgradeActive
                    : iconUpgrade,
            isIcon: false,
            handel: goToPage,
        },
        {
            name: 'Promotions',
            lable: menuList[4],
            icon:
                menuItems?.selected === 'Promotions'
                    ? promotionsActive
                    : promotions,
            isIcon: false,
            handel: goToPage,
        },
        {
            name: 'Map',
            lable: menuList[5],
            icon: menuItems?.selected === 'Map' ? locationActive : location,
            isIcon: false,
            handel: goToPage,
        },
        {
            name: 'Chat',
            lable: menuList[6],
            icon: menuItems?.selected === 'Chat' ? iconChatActive : iconChat,
            isIcon: false,
            handel: goToPage,
        },
        {
            name: 'Tips',
            lable: menuList[7],
            icon:
                menuItems?.selected === 'Tips'
                    ? iconSettingActive
                    : iconSetting,
            isIcon: false,
            handel: goToPage,
        },
        {
            name: 'Info',
            lable: menuList[8],
            icon: menuItems?.selected === 'Info' ? iconInfoActive : iconInfo,
            isIcon: false,
            handel: goToPage,
        },
        {
            name: 'News',
            lable: menuList[9],
            icon:
                menuItems?.selected === 'News'
                    ? iconNoticesActive
                    : iconNotices,
            isIcon: false,
            handel: goToPage,
        },
        {
            name: 'Gdpr',
            lable: menuList[11],
            icon: menuItems?.selected === 'Gdpr' ? iconGdprActive : iconGdpr,
            isIcon: false,
            handel: goToPage,
        },
        {
            name: 'logout',
            lable: menuList[10],
            icon:
                menuItems?.selected === 'logout'
                    ? iconLogoutActive
                    : iconLogout,
            isIcon: false,
            handel: handleLogout,
        },
    ];
    const menuListForNutritionist = [
        {
            name: 'Patients',
            icon:
                menuItems?.selected === 'Patients'
                    ? iconPatientActive
                    : iconPatient,
            lable: menuList[0],
            isIcon: false,
            handel: goToPage,
        },
        {
            name: 'Chat',
            lable: menuList[1],
            icon: menuItems?.selected === 'Chat' ? iconChatActive : iconChat,
            isIcon: false,
            handel: goToPage,
        },
        {
            name: 'logout',
            icon:
                menuItems?.selected === 'logout'
                    ? iconLogoutActive
                    : iconLogout,
            lable: menuList[2],
            isIcon: false,
            handel: handleLogout,
        },
    ];
    const role = userDetails?.roles;
    const { selected, isAdmin } = menuItems;
    const roles = isAdmin ? menuListForAdmin : menuListForNutritionist;
    const updateMenuItem = useCallback((menuName, items, updateItem) => {
        if (items?.selected !== menuName) {
            if (indexOf(items?.items, menuName) > -1) updateItem(menuName);
        }
    }, []);
    if (props?.history.action === 'POP') {
        const { pathname } = props.location;
        const [menuName] = pathname.substring(1).split('/');
        updateMenuItem(menuName, menuItems, selectMenuItem);
    }
    useEffect(() => {
        if (selected === 'Admins' && role === 'Nutritionists') {
            selectMenuItem('Patients');
        }
    }, [selected, role, selectMenuItem]);
    return (
        <Sides
            as={Menu}
            animation="uncover"
            icon="labeled"
            vertical
            visible
            className="sidebar-container"
        >
            {roles.map((menu, index) => {
                if (menu.name === 'logout') {
                    return (
                        <LogoutModal
                            key={index}
                            config={props?.logout}
                            menu={menu}
                        />
                    );
                } else
                    return (
                        <Menu.Item
                            key={index}
                            as="a"
                            className={
                                menuItems?.selected === menu.name
                                    ? 'active'
                                    : ''
                            }
                            onClick={() => menu.handel(menu.name)}
                        >
                            {menu.isIcon ? (
                                <Icon name={menu.icon} />
                            ) : (
                                <Image src={menu.icon} />
                            )}
                            <span>{menu?.lable ?? menu?.name}</span>
                        </Menu.Item>
                    );
            })}
        </Sides>
    );
};

export { Sidebar };
