import React, { useRef, useEffect, useCallback, useMemo } from 'react';
import { Segment, Container, Divider } from 'semantic-ui-react';
import { BreadcrumbComp, Image, GridList } from '_components';
import { validTicket, pending, wrong, times } from 'images';
import { lang } from '_config';
import ActionButton from './ActionButton';
import { map, isEmpty } from 'lodash';
import './upgrade.scss';
import { dateFormat } from '_utils';
import { TicketValidateModal } from '_components/Modals/TicketValidateModal/TicketValidateModal';
const listData = {
    sorting: [false, false, false, false],
    cellWidth: [3, 3, 4, 4, 2],
    columns: ['createdDate', 'status', 'seen', 'expire'],
    isHtml: [false, true, false],
    actionList: () => {},
    filters: {},
};
const Upgrade = (props) => {
    const {
        userDetails: { roles },
        patientInfo,
        match: {
            params: { id: patientId },
        },
        patientInfo: { id, firstName, lastName },
        history,
        getPatientDetail,
        getPharmacyList,
        updateTicket,
        getTicketDetails,
        upgradeHistory,
        upgrade,
        openModal,
        closeModal,
        modalData,
        pharmaList,
        isModalLoad,
        isModalOpen,
    } = props;
    const { details } =
        roles === 'Admin' ? lang?.patients[0] : lang?.patients[1];
    const cref = useRef('receiptList');
    let sections = [
        {
            key: 'pageheading',
            content: details?.pageHeading,
            onClick: () =>
                props.history.push({
                    pathname: `/Patients/`,
                }),
        },
        {
            key: 'userName',
            content: `${firstName} ${lastName}`,
            onClick: () =>
                props.history.push({
                    pathname: `/Patients/${id}`,
                }),
        },
        {
            key: 'heading',
            content: details?.reciept?.upgradeHeading,
            active: true,
        },
    ];
    sections =
        firstName === undefined || patientInfo?.id !== parseInt(patientId)
            ? [sections[0]]
            : sections;
    const { list, ...filters } = upgrade?.result;
    const getImage = (status) => {
        let options = {};
        switch (status) {
            case 'ACCEPTED':
                options = {
                    img: validTicket,
                    text: details?.reciept?.accepted,
                };
                break;
            case 'PENDING':
                options = { img: pending, text: details?.reciept?.pending };
                break;
            case 'REJECTED':
                options = { img: wrong, text: details?.reciept?.rejected };
                break;
            default:
                break;
        }
        return `<div class='status'><img class='status-img' src='${options?.img}' /> ${options?.text}</div>`;
    };
    const pageData = map(list ?? [], (record) => ({
        ...record,
        createdDate: dateFormat(record?.createdDate),
        statuscol: record?.status,
        status: getImage(record?.status),
        seen: dateFormat(record?.reviewedDate),
        expire: dateFormat(record?.expirationDate),
    }));
    const data = {
        ...listData,
        cref,
        roles,
        params: upgrade?.params ?? {},
        loading: upgrade?.loading,
        isDisable: filters.totalElements <= pageData.length,
        actionList: upgradeHistory,
        filters,
        pageData,
    };
    const ticketData = useCallback(
        (input) => {
            updateTicket(
                {
                    id: input?.id,
                    daysToExpire: input?.daysToExpire,
                    note: input?.note,
                    status: input?.status,
                    pharmacy: input?.pharmacy,
                    upgradeParams: upgrade?.params,
                },
                true
            );
        },
        [updateTicket, upgrade]
    );
    const MemoTicketValidateModal = useMemo(() => {
        const pharmacyList = map(pharmaList, 'place');
        let { ...configData } = details?.modal;
        if (modalData?.status === 'PENDING') {
            configData.heading2 = details?.modal?.heading2;
        } else configData.heading2 = details?.modal?.headingAR;
        return (
            <TicketValidateModal
                config={configData}
                ticketDetails={{
                            url: modalData.url,
                            status: modalData?.statuscol,
                            id: modalData?.id,
                            boxes: modalData?.expire,
                            pharmacy: modalData?.pharmacy,
                            notes: modalData?.note,
                        }}
                clearData={()=>{}}
                updateTicket={ticketData}
                closeModal={closeModal}
                curOptions={{
                    modalOpen: isModalOpen,
                    loading: isModalLoad,
                }}
                pharmacyList={pharmacyList}
            />
        );
    }, [
        closeModal,
        details,
        isModalLoad,
        isModalOpen,
        modalData,
        ticketData,
        pharmaList,
    ]);
    useEffect(() => {
        upgradeHistory({ userId: patientId });
    }, [upgradeHistory, patientId]);
    useEffect(() => {
        if (isEmpty(patientInfo) || patientInfo?.id !== parseInt(patientId)) {
            getPatientDetail(patientId);
        }
        if (isEmpty(pharmaList)) getPharmacyList();
    }, [
        upgradeHistory,
        id,
        patientInfo,
        patientId,
        getPatientDetail,
        getPharmacyList,
        pharmaList,
    ]);
    return (
        <Container fluid id="upgrade" className={`private-layout`}>
            <Segment basic>
                <BreadcrumbComp sections={sections} />
                <div className="view-container">
                    <div className="view-attachment-col">
                        <div className="view-heading">
                            <div className="view-heading-text">
                                {details?.reciept?.pageHeading}
                            </div>
                            <div className="view-heading-subtext">
                                {details?.reciept?.pageSubHeading}
                            </div>
                            <div className="view-close">
                                <Image
                                    src={times}
                                    onClick={() =>
                                        props.history.push({
                                            pathname: `/Patients/${id}`,
                                        })
                                    }
                                />
                            </div>
                        </div>
                        <Divider />
                        <div id="receiptList" className="mainSection">
                            <GridList
                                config={{
                                    ...details,
                                    tableHeading:
                                        details?.reciept?.tableHeading,
                                }}
                                history={history}
                                listData={data}
                            >
                                {(...params) => {
                                    const [mId, rest] = params;
                                    return (
                                        <ActionButton
                                            key={mId}
                                            config={details}
                                            record={{ id: mId, ...rest }}
                                            openModal={openModal}
                                            getTicketDetails={getTicketDetails}
                                        />
                                    );
                                }}
                            </GridList>
                        </div>
                    </div>
                </div>
                {MemoTicketValidateModal}
            </Segment>
        </Container>
    );
};

export { Upgrade };
