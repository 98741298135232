import React, { useState, useEffect } from 'react';
import { Segment, Container, Button, Form } from 'semantic-ui-react';
import { useForm } from 'react-hook-form';
import { isEmpty, includes } from 'lodash';
import {
    BreadcrumbComp,
    Image,
    Input,
    TextArea,
    FileUpload,
    DateTimePicker,
    Select,
} from '_components';
import { validMinMaxInput, validMinMaxTextArea, getFileDetail } from '_utils';
import { times } from 'images';
import CONST from '_constants';
import './TipsAdd.scss';
const TipsAdd = (props) => {
    const {
        activeTab,
        goBack,
        config: {
            assignedList,
            details,
            details: { form, heading, subHeading, catList },
        },
        actions: { clearFileDetails, addRecipies },
        state: {
            filesList,
            tips: { loading: tipsAdding },
        },
    } = props;
    const [isAssign, setAssign] = useState(false);
    const [isCat, setCat] = useState(false);
    const pageHeading = heading[activeTab.tab] ?? '';
    const pageSubHeadingForAdd = subHeading?.add[activeTab.tab] ?? '';
    const sections = [
        {
            key: 'myAdminListPage',
            content: activeTab.breadcrumbHeading,
            onClick: goBack,
        },
        {
            key: 'myAdminEditPage',
            content: details?.breadcrumbForAdd ?? 'Add',
            active: true,
        },
    ];
    const tipsAddVidUrl = getFileDetail(filesList, 'tipsAddVid', 'url');
    const tipsAddImgUrl = getFileDetail(filesList, 'tipsAddImg', 'url');
    const tipsUploadVid = getFileDetail(
        filesList,
        'tipsAddVid',
        'uploading',
        true
    );
    const tipsUploadImg = getFileDetail(
        filesList,
        'tipsAddImg',
        'uploading',
        true
    );
    const { register, handleSubmit, watch, errors, setValue } = useForm(),
        watchAllFields = watch(['title', 'desc', 'from']),
        validation = {
            AVAILABE: {
                required: true,
            },
        },
        onChange = (_, data) => {
            setAssign(data.value);
        },
        onChangeCat = (_, data) => {
            setCat(data.value);
        },
        { title, desc, from } = watchAllFields,
        validateForm = () => {
            return !validMinMaxInput(title) &&
                !validMinMaxTextArea(desc) &&
                !isEmpty(from) &&
                !isEmpty(isAssign) &&
                !isEmpty(isCat) &&
                !tipsUploadVid &&
                !tipsUploadImg &&
                isEmpty(errors)
                ? false
                : true;
        },
        onSubmit = (data) => {
            const assignedRoles = includes(isAssign, 'TODO')
                ? ['ROLE_UNAFFILIATED', 'ROLE_CEAN', 'ROLE_KETOSTERIL']
                : isAssign;
            addRecipies({
                assignedRoles,
                availableFrom: data?.from,
                category: isCat,
                description: data?.desc,
                foodImageUrl: tipsAddImgUrl,
                title: data?.title,
                imageContent: [''],
                videoUrl: tipsAddVidUrl,
            });
            setTimeout(() => goBack(true), CONST.TIMEOUT);
        };
    useEffect(() => {
        clearFileDetails();
    }, [clearFileDetails]);
    return (
        <Container fluid id="TipsAdd" className={`private-layout`}>
            <Segment basic loading={tipsAdding}>
                <BreadcrumbComp sections={sections} />
                <div className="add-container">
                    <div className="add-heading">
                        <div className="add-heading-text">{pageHeading}</div>
                        <div className="add-heading-subtext">
                            {pageSubHeadingForAdd}
                        </div>
                        <div className="add-close">
                            <Image src={times} onClick={goBack} />
                        </div>
                    </div>
                    <div className="add-form">
                        <Form
                            autoComplete="off"
                            onSubmit={handleSubmit(onSubmit)}
                            unstackable
                        >
                            <Form.Group>
                                <Form.Field width={10}>
                                    <Input
                                        name="title"
                                        label={form?.title?.label}
                                        placeholder={form?.title?.placeholder}
                                        widths={12}
                                        refs={{ register }}
                                        count
                                    />
                                </Form.Field>
                                <Form.Field width={6}>
                                    <Select
                                        name="categories"
                                        label={form?.category?.label}
                                        placeholder={
                                            form?.category?.placeholder
                                        }
                                        options={catList ?? []}
                                        search
                                        onChange={onChangeCat}
                                        fluid
                                    />
                                </Form.Field>
                            </Form.Group>
                            <Form.Group>
                                <Form.Field width={5}>
                                    <FileUpload
                                        label={form?.imgTable?.label}
                                        limit={form?.imgTable?.limit}
                                        placeholder={
                                            form?.imgTable?.placeholder
                                        }
                                        optional={details.optional}
                                        name="tipsAddImg"
                                    />
                                </Form.Field>
                                <Form.Field width={5}>
                                    <FileUpload
                                        label={form?.video?.label}
                                        limit={form?.video?.limit}
                                        placeholder={form?.video?.placeholder}
                                        optional={details.optional}
                                        video
                                        name="tipsAddVid"
                                    />
                                </Form.Field>
                                <Form.Field width={6}>
                                    <DateTimePicker
                                        label={form?.available?.label}
                                        placeholder={
                                            form?.available?.placeholder
                                        }
                                        fluid
                                        refs={register({ ...validation })}
                                    />
                                    <Select
                                        name="assign"
                                        label={form?.assign?.label ?? 'Assign'}
                                        placeholder={form?.assign?.placeholder}
                                        options={assignedList}
                                        onChange={onChange}
                                        fluid
                                        multiple
                                    />
                                </Form.Field>
                            </Form.Group>
                            <Form.Group>
                                <Form.Field width={16}>
                                    <TextArea
                                        name="desc"
                                        label={form?.desc?.label}
                                        rows={6}
                                        placeholder={form?.desc?.placeholder}
                                        refs={{ register, setValue }}
                                        count
                                        preview
                                        content={{
                                            isAssign,
                                            availableFrom: from,
                                            category: isCat,
                                            desc,
                                            title,
                                            imageUrl: tipsAddImgUrl,
                                            videoUrl: tipsAddVidUrl,
                                        }}
                                        editor
                                    />
                                </Form.Field>
                            </Form.Group>
                            <Button
                                disabled={validateForm()}
                                className="add-action-button"
                                type="submit"
                            >
                                {form?.actionLableForAdd}
                            </Button>
                        </Form>
                    </div>
                </div>
            </Segment>
        </Container>
    );
};

export { TipsAdd };
