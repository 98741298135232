import { Apis, handleResponse, handelError } from './services';
const getToken = () => {
    const requestOptions = {};
    return Apis()(`/chat/token`, requestOptions)
        .then(handleResponse)
        .then((data) => ({ status: true, ...data }))
        .catch(handelError);
};
const sendNotificaiton = (data) => {
    const requestOptions = {
        method: 'POST',
        data,
    };
    return Apis()(`/chat/send/notification`, requestOptions)
        .then(handleResponse)
        .then((res) => ({ status: true, ...res }))
        .catch(handelError);
};

const getNutriDetails = (id, flag, isAdmin) => {
    const requestOptions = {
        method: 'GET',
    };
    const URL = flag
        ? isAdmin
            ? `/user/nutritionist/${id}`
            : `/user/admin/${id}`
        : `/patient/${id}/nutritionist`;
    return Apis()(URL, requestOptions)
        .then(handleResponse)
        .then((data) => ({ status: true, ...data }))
        .catch(handelError);
};

export const chatService = {
    getToken,
    sendNotificaiton,
    getNutriDetails,
};
