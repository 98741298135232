import { Apis, handleResponse, handelError } from './services';

const generalaAvicesList = ({ pageNumber, orderBy, direction, search }) => {
    const requestOptions = {
        method: 'GET',
        params: {
            pageNumber,
            orderBy,
            direction,
            search,
        },
    };
    return Apis()(`/generaladvices/list`, requestOptions)
        .then(handleResponse)
        .then((data) => ({ status: true, ...data }))
        .catch(handelError);
};
const physicalActivityList = ({ pageNumber, orderBy, direction, search }) => {
    const requestOptions = {
        method: 'GET',
        params: {
            pageNumber,
            orderBy,
            direction,
            search,
        },
    };
    return Apis()(`/physicalactivity/list`, requestOptions)
        .then(handleResponse)
        .then((data) => ({ status: true, ...data }))
        .catch(handelError);
};
const addPyhsical = (data) => {
    const requestOptions = {
        method: 'POST',
        data,
    };
    return Apis()(`/physicalactivity`, requestOptions)
        .then(handleResponse)
        .then((res) => ({ status: true, ...res }))
        .catch(handelError);
};

const editPhysicalactivity = (data) => {
    const requestOptions = {
        method: 'GET',
    };
    const { id } = data;
    return Apis()(`/physicalactivity/${id}`, requestOptions)
        .then(handleResponse)
        .then((res) => ({ status: true, ...res }))
        .catch(handelError);
};
const delPyhsical = ({ id }) => {
    const requestOptions = {
        method: 'DELETE',
    };
    return Apis()(`/physicalactivity/${id}`, requestOptions)
        .then(handleResponse)
        .then((res) => ({ status: true, ...res }))
        .catch(handelError);
};
const updatePyhsical = (input) => {
    const { id, ...data } = input;
    const requestOptions = {
        method: 'PUT',
        data,
    };
    return Apis()(`/physicalactivity/${id}`, requestOptions)
        .then(handleResponse)
        .then((res) => ({ status: true, ...res }))
        .catch(handelError);
};
const addGen = (data) => {
    const requestOptions = {
        method: 'POST',
        data,
    };
    return Apis()(`/generaladvices`, requestOptions)
        .then(handleResponse)
        .then((res) => ({ status: true, ...res }))
        .catch(handelError);
};
const recipesList = ({ pageNumber, orderBy, direction, search }) => {
    const requestOptions = {
        method: 'GET',
        params: {
            pageNumber,
            orderBy,
            direction,
            search,
        },
    };
    return Apis()(`/recipes/list`, requestOptions)
        .then(handleResponse)
        .then((data) => ({ status: true, ...data }))
        .catch(handelError);
};
const recipesAdd = (data) => {
    const requestOptions = {
        method: 'POST',
        data,
    };
    return Apis()(`/recipes`, requestOptions)
        .then(handleResponse)
        .then((res) => ({ status: true, ...res }))
        .catch(handelError);
};
const recipesUpdate = (input) => {
    const { id, ...data } = input;
    const requestOptions = {
        method: 'PUT',
        data,
    };
    return Apis()(`/recipes/${id}`, requestOptions)
        .then(handleResponse)
        .then((res) => ({ status: true, ...res }))
        .catch(handelError);
};
const updateGen = (input) => {
    const { id, ...data } = input;
    const requestOptions = {
        method: 'PUT',
        data,
    };
    return Apis()(`/generaladvices/${id}`, requestOptions)
        .then(handleResponse)
        .then((res) => ({ status: true, ...res }))
        .catch(handelError);
};
const delGen = ({ id }) => {
    const requestOptions = {
        method: 'DELETE',
    };
    return Apis()(`/generaladvices/${id}`, requestOptions)
        .then(handleResponse)
        .then((res) => ({ status: true, ...res }))
        .catch(handelError);
};

const recipesDeleted = ({ id }) => {
    const requestOptions = {
        method: 'DELETE',
    };
    return Apis()(`/recipes/${id}`, requestOptions)
        .then(handleResponse)
        .then((res) => ({ status: true, ...res }))
        .catch(handelError);
};
const recipesEdit = (data) => {
    const requestOptions = {
        method: 'GET',
        data,
    };
    const { id } = data;
    return Apis()(`/recipes/${id}`, requestOptions)
        .then(handleResponse)
        .then((res) => ({ status: true, ...res }))
        .catch(handelError);
};
const editGen = (data) => {
    const requestOptions = {
        method: 'GET',
        data,
    };
    const { id } = data;
    return Apis()(`/generaladvices/${id}`, requestOptions)
        .then(handleResponse)
        .then((res) => ({ status: true, ...res }))
        .catch(handelError);
};

const getFoodCategories = () => {
    let pageNumber = 0;
    const requestOptions = {
        method: 'GET',
        params: {
            pageNumber,
        },
    };
    return Apis()(`food/category/list`, requestOptions)
        .then(handleResponse)
        .then((data) => ({ status: true, ...data }))
        .catch(handelError);
};

const getFoodList = (params) => {
    const requestOptions = {
        method: 'GET',
        params: params,
    };
    return Apis()(`food/list`, requestOptions)
        .then(handleResponse)
        .then((data) => ({ status: true, ...data }))
        .catch(handelError);
};

const getFoodUnit = () => {
    let pageNumber = 0;
    const requestOptions = {
        method: 'GET',
        params: {
            pageNumber,
        },
    };
    return Apis()(`food/unit/list`, requestOptions)
        .then(handleResponse)
        .then((data) => ({ status: true, ...data }))
        .catch(handelError);
};

const createFood = (foodObj) => {
    const requestOptions = {
        method: 'POST',
        data: foodObj,
    };
    return Apis()(`food`, requestOptions)
        .then(handleResponse)
        .then((data) => ({ status: true, ...data }))
        .catch(handelError);
};

const updateFood = (data) => {
    const requestOptions = {
        method: 'PUT',
        data: data.reqObj,
    };
    return Apis()(`food/${data.id}`, requestOptions)
        .then(handleResponse)
        .then((res) => ({ status: true, ...res }))
        .catch(handelError);
};

const deleteFood = (id) => {
    const requestOptions = {
        method: 'DELETE',
    };
    return Apis()(`food/${id}`, requestOptions)
        .then(handleResponse)
        .then((data) => ({ status: true, ...data }))
        .catch(handelError);
};

const getFoodById = (id) => {
    const requestOptions = {
        method: 'GET',
    };
    return Apis()(`food/${id}`, requestOptions)
        .then(handleResponse)
        .then((data) => ({ status: true, ...data }))
        .catch(handelError);
};

export const tipsService = {
    generalaAvicesList,
    addGen,
    editGen,
    updateGen,
    delGen,

    physicalActivityList,
    editPhysicalactivity,
    addPyhsical,
    updatePyhsical,
    delPyhsical,

    recipesList,
    recipesAdd,
    recipesEdit,
    recipesUpdate,
    recipesDeleted,

    getFoodCategories,
    getFoodList,
    getFoodUnit,
    createFood,
    updateFood,
    deleteFood,
    getFoodById,
};
