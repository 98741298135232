import { combineReducers } from 'redux';
import { INIT } from '_config/initialState';
import USER from '_constants';
import loginData, { userDetails } from '_reducers/login.reducer';
import forgotData, { forgotDetails } from '_reducers/forgot.reducer';
import resetData, { resetDetails } from '_reducers/reset.reducer';
import alertData, { alertDetails } from '_reducers/alert.reducer';
import menuList, { menuDetails } from '_reducers/menu.reducer';
import mapData, { mapDetails } from '_reducers/map.reducer';
import adminList, { adminDetails } from '_reducers/admin.reducer';
import nutriList, { nutriDetails } from '_reducers/nutri.reducer';
import patientList, { patientDetails } from '_reducers/patient.reducer';
import promoList, { promoDetails } from '_reducers/promo.reducer';
import upgradeList, { upgradeDetails } from '_reducers/upgrade.reducer';
import infoList, { infoDetails } from '_reducers/info.reducer';
import gdprList, { gdprDetails } from '_reducers/gdpr.reducer';
import chatList, { chatDetails } from '_reducers/chat.reducer';
import tips, { tipsDetails } from '_reducers/tips.reducer';
import newsList, { newsDetails } from '_reducers/news.reducer';
import files, { filesDetails } from '_reducers/fileupload.reducer';
import patientMedicalRecords, {
    patientMedicalRecordsData,
} from '_reducers/patientDetails.reducer';

export default (state, action) => {
    if (action.type === USER.LOGOUT) state = INIT;
    return combineReducers({
        loginData,
        forgotData,
        resetData,
        menuList,
        alertData,
        mapData,
        adminList,
        nutriList,
        patientList,
        promoList,
        upgradeList,
        infoList,
        gdprList,
        chatList,
        newsList,
        tips,
        files,
        patientMedicalRecords,
    })(state, action);
};
export {
    userDetails,
    forgotDetails,
    resetDetails,
    alertDetails,
    menuDetails,
    mapDetails,
    adminDetails,
    nutriDetails,
    patientDetails,
    promoDetails,
    upgradeDetails,
    infoDetails,
    gdprDetails,
    chatDetails,
    newsDetails,
    tipsDetails,
    filesDetails,
    patientMedicalRecordsData,
};
