import { batch } from 'react-redux';
import FILE from '_constants';
import { fileService } from '_services';

const uploadFile = (file) => {
    return (dispatch) => {
        const uploadError = (error) => {
            batch(() => {
                dispatch({
                    type: FILE.FILE_UP_FAILURE,
                    file: { name: file.name },
                    faliure: { ...error },
                });
            });
        };
        dispatch({
            type: FILE.FILE_UP_REQUEST_START,
            file: { name: file.name },
        });
        fileService
            .fileUpload(file.fileData)
            .then(
                (result) => {
                    if (result && result?.status) {
                        dispatch({
                            type: FILE.FILE_UP_SUCCESS,
                            file: { name: file.name, url: result.data },
                        });
                    } else {
                        uploadError(result);
                    }
                },
                (error) => {
                    uploadError(error);
                }
            )
            .catch((error) => {
                uploadError(error);
            })
            .finally(() => {
                dispatch({
                    type: FILE.FILE_UP_REQUEST_END,
                    file: { name: file.name },
                });
            });
    };
};
const clearFileData = () => {
    return (dispatch) => {
        dispatch({ type: FILE.FILE_UP_INIT });
    };
};
const fileTextUpdate = ({ name, url }) => {
    return (dispatch) => {
        batch(() => {
            dispatch({
                type: FILE.FILE_UP_SUCCESS,
                file: { name, url },
            });
            dispatch({
                type: FILE.FILE_UP_REQUEST_END,
                file: { name },
            });
        });
    };
};
export const fileUploadAction = {
    uploadFile,
    clearFileData,
    fileTextUpdate,
};
