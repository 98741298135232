import React, { useRef, useEffect } from 'react';
import { Grid } from 'semantic-ui-react';
import { BreadcrumbComp, Search, GridList, StickyButton } from '_components';
import { map, isEmpty } from 'lodash';
const AdminList = (props) => {
    const { config } = props;
    const cref = useRef('AdminListPage');
    // Set Breadcrumb values
    const sections = [
        {
            key: 'myAdminListPage',
            content: config.pageHeading,
            active: true,
        },
    ];
    // get all values from container
    const {
        state: {
            userDetails: { roles },
            adminList: {
                loading,
                params,
                result: { list, ...filters },
            },
        },
        actions: { getList },
    } = props;
    // Set list data
    const pageData = map(list, (record) => ({
        Name: `${record?.firstName} ${record.lastName}`,
        Center: record?.center?.centerName ?? '--',
        Email: record?.email,
        Contact: record?.phoneNumber,
        ...record,
    }));
    // setup grid data
    const listData = {
        sorting: [
            { direction: 'DESC' },
            { direction: 'DESC' },
            false,
            false,
            false,
        ],
        cellWidth: [3, 3, 3, 5],
        columns: ['Name', 'Center', 'Contact', 'Email'],
        isHtml: [true, false, false, false],
        isDisable: filters.totalElements <= pageData.length,
        actionList: getList,
        loading,
        filters,
        params,
        cref,
        roles,
        pageData,
    };
    // Get records from db
    useEffect(() => {
        isEmpty(params) && getList();
    }, [getList, params]);
    return (
        <>
            <BreadcrumbComp sections={sections} />
            <div className="actions">
                <Search
                    config={config}
                    params={listData.params}
                    actionList={listData.actionList}
                    filters={filters}
                    width="full"
                />
            </div>
            <GridList
                history={props?.history}
                config={config}
                listData={listData}
            >
                {(id, data) => {
                    return (
                        <Grid.Column key={id} width={2}>
                            <div
                                className="text-link"
                                onClick={() =>
                                    props?.history.push({
                                        pathname: `/Admins/edit/${id}`,
                                        state: { record: { id, ...data } },
                                    })
                                }
                            >
                                {config.actionLable}
                            </div>
                        </Grid.Column>
                    );
                }}
            </GridList>
            <StickyButton
                onClick={() => props.history.push({ pathname: '/Admins/Add' })}
            />
        </>
    );
};

export { AdminList };
