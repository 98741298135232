import React from 'react';
import PropTypes from 'prop-types';
import './Notification.scss';
const Notification = (props) => {
    const { text, className } = props;
    return <div className={`dmi-notifiaction ${className}`}>{text}</div>;
};

Notification.propTypes = {
    text: PropTypes.string.isRequired,
    className: PropTypes.oneOf(['success', 'error']).isRequired,
};

export { Notification };
