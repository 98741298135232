import { useSelector, useDispatch } from 'react-redux';
import { userDetails, alertDetails, menuDetails, chatDetails } from '_reducers';
import { alertActions, userActions, chatActions } from '_actions';
import { getUserDetails } from '_utils';
const AppContainer = () => {
    const dispatch = useDispatch();
    const user = useSelector((state) => userDetails(state));
    const alert = useSelector((state) => alertDetails(state));
    const menuList = useSelector((state) => menuDetails(state));
    const chatList = useSelector((state) => chatDetails(state));
    const localUserData = getUserDetails();
    if (localUserData && user.userDetails.token === undefined) {
        userActions.loginSuccess(dispatch, {}, { success: 'force loggedin' });
    }
    return {
        state: {
            alert,
            menuList,
            userDetails: user.userDetails,
            localUserData,
            activeLang: user.lang,
            chatList,
        },
        actions: {
            clearAlerts: () => dispatch(alertActions.clear()),
            logOut: () => dispatch(userActions.logout()),
            updateLangAction: (e) => dispatch(userActions.updateLang(e)),
            selectMenuItem: (e) => dispatch(userActions.selectMenuItem(e)),
            setKabiCenter: (val) => dispatch(chatActions.setKabiCenter(val)),
        },
    };
};

export { AppContainer };
