import PATIENT from '_constants';
import { INIT } from '_config/initialState';
const { patientMedicalRecords: initialState } = INIT;

export default (state = initialState, action = {}) => {
    const { type, result, error, params, symptoms, uploadStatus } = action;
    switch (type) {
        case PATIENT.PATIENT_INFO_SUCCESS:
            return { ...state, patientInfo: result, updatedInfo: {} };
        case PATIENT.PATIENT_INFO_FAILURE:
            return { ...state, ...error };
        case PATIENT.PATIENT_DETAIL_REQUEST_START:
        case PATIENT.UPDATE_PATIENT_INFO_REQUEST_START:
        case PATIENT.PATIENT_DEL_START:
            return { ...state, loading: true };
        case PATIENT.PATIENT_HEALTH_REQUEST_START:
            return { ...state, health: { ...state.health, loading: true } };
        case PATIENT.PATIENT_HEALTH_REQUEST_END:
            return { ...state, health: { ...state.health, loading: false } };
        case PATIENT.PATIENT_HEALTH_SUCCESS:
            return {
                ...state,
                health: { ...state.health, result, params, faliure: null },
                symptoms,
            };
        case PATIENT.PATIENT_HEALTH_FAILURE:
            return {
                ...state,
                health: { ...state.health, faliure: error },
            };
        case PATIENT.PATIENT_DETAIL_REQUEST_END:
        case PATIENT.UPDATE_PATIENT_INFO_REQUEST_END:
        case PATIENT.PATIENT_DEL_END:
            return { ...state, loading: false };
        case PATIENT.PATIENT_DETAILS_UPLOAD_REQUEST_START:
            return { ...state, uploading: true };
        case PATIENT.PATIENT_DETAILS_UPLOAD_REQUEST_END:
            return { ...state, uploading: false };
        case PATIENT.PATIENT_DETAILS_UPLOAD_INIT:
            return {
                ...state,
                uploading: false,
                uploaded: false,
                uploadStatus: false,
            };    
        case PATIENT.MEDICAL_RECORD_BMI_SUCCESS:
            return { ...state, BMIRecord: result };
        case PATIENT.PATIENT_UPGRADE_LOADING:
            return { ...state, upgrade: { ...state.upgrade, loading: true } };
        case PATIENT.PATIENT_UPGRADE_LOADED:
            return { ...state, upgrade: { ...state.upgrade, loading: false } };
        case PATIENT.PATIENT_UPGRADE_SUCCESS:
            return {
                ...state,
                upgrade: {
                    ...state.upgrade,
                    result: {
                        ...result,
                        list: params.pageNumber
                            ? [...state.upgrade.result.list, ...result.list]
                            : result.list,
                    },
                    params,
                },
            };
        case PATIENT.PATIENT_UPGRADE_FAILURE:
            return {
                ...state,
                upgrade: {
                    ...state.upgrade,
                    error,
                    result: { list: [], params: {} },
                },
            };
        case PATIENT.UPGRADE_KETO_TO_CEAN_SUCCESS:
            return { ...state, upgradeSuccess: true };
        case PATIENT.UPGRADE_KETO_TO_CEAN_FAILUARE:
            return { ...state, faliure: true };
        case PATIENT.MEDICAL_RECORD_BMI_FAILURE:
            return { ...state, ...error };
        case PATIENT.MEDICAL_RECORD_BM_START:
            return {
                ...state,
                bodyMeasures: { ...state.bodyMeasures, loading: true },
            };
        case PATIENT.MEDICAL_RECORD_BM_END:
            return {
                ...state,
                bodyMeasures: { ...state.bodyMeasures, loading: false },
            };
        case PATIENT.MEDICAL_RECORD_BM_SUCCESS:
            return {
                ...state,
                bodyMeasures: { ...state.bodyMeasures, result },
            };
        case PATIENT.MEDICAL_RECORD_BM_FAILURE:
            return { ...state, ...error };
        case PATIENT.MEDICAL_RECORD_ANALYTICS_START:
            return {
                ...state,
                analytics: { ...state.analytics, loading: true },
            };
        case PATIENT.MEDICAL_RECORD_ANALYTICS_END:
            return {
                ...state,
                analytics: { ...state.analytics, loading: false },
            };
        case PATIENT.MEDICAL_RECORD_ANALYTICS_SUCCESS:
            return { ...state, analytics: { ...state.analytics, result } };
        case PATIENT.MEDICAL_RECORD_ANALYTICS_FAILURE:
            return { ...state, ...error };
        case PATIENT.UPDATE_PATIENT_INFO_SUCCESS:
        case PATIENT.PATIENT_DEL_SUCCESS:
            return { ...state, updatedInfo: result };
        case PATIENT.UPDATE_PATIENT_INFO_FAILURE:
            return { ...state, ...error };
        case PATIENT.PATIENT_DETAILS_UPLOAD_FAILURE:
            return { ...state, uploaded: false, uploadStatus };
        case PATIENT.PATIENT_DETAILS_UPLOAD_SUCCESS:
            return { ...state, uploaded: true, uploadStatus: false };    
        default:
            return { ...state };
    }
};
export const patientMedicalRecordsData = (state) => state.patientMedicalRecords;
