import React, { useEffect } from 'react';
import { Segment, Container, Button, Form } from 'semantic-ui-react';
import { isEmpty } from 'lodash';
import {
    BreadcrumbComp,
    Image,
    FileUpload,
    TextArea,
    Input,
} from '_components';
import { useForm } from 'react-hook-form';
import { validMinMaxInput, validMinMaxTextArea, getFileDetail } from '_utils';
import { times } from 'images';
import CONST from '_constants';
import './InfoAdd.scss';
const InfoAdd = (props) => {
    const {
        activeTab,
        goBack,
        config: {
            details,
            details: {
                form,
                heading: { add },
            },
        },
        actions: { addInfo, clearFileDetails },
        state: {
            filesList,
            infoList: { loading: recordAdding, tabs },
        },
    } = props;
    const infoAddVidUrl = getFileDetail(filesList, 'infoAddVid', 'url');
    const infoAddImgUrl = getFileDetail(filesList, 'infoAddImg', 'url');
    const infoUploadVid = getFileDetail(
        filesList,
        'infoAddVid',
        'uploading',
        true
    );
    const infoUploadImg = getFileDetail(
        filesList,
        'infoAddImg',
        'uploading',
        true
    );
    const { register, handleSubmit, watch, errors, setValue } = useForm();
    const watchAllFields = watch(['title', 'text']);
    const pageSubHeadingForAdd = add[activeTab.tab] ?? '';
    const sections = [
        {
            key: 'myAdminListPage',
            content: activeTab.breadcrumbHeading,
            onClick: goBack,
        },
        {
            key: 'myAdminEditPage',
            content: details?.breadcrumbForAdd ?? 'Add',
            active: true,
        },
    ];
    const onSubmit = (data) => {
        addInfo({
            ...data,
            type: activeTab?.tab,
            image: infoAddImgUrl,
            video: infoAddVidUrl,
            params: tabs[activeTab?.tab]?.params,
        });
        setTimeout(() => goBack(true), CONST.TIMEOUT);
    };
    const { title, text } = watchAllFields;
    const validateForm = () => {
        return !validMinMaxInput(title) &&
            !validMinMaxTextArea(text) &&
            !infoUploadVid &&
            !infoUploadImg &&
            isEmpty(errors)
            ? false
            : true;
    };
    useEffect(() => {
        clearFileDetails();
    }, [clearFileDetails]);
    return (
        <Container fluid id="InfoAdd" className={`private-layout`}>
            <Segment basic loading={recordAdding}>
                <BreadcrumbComp sections={sections} />
                <div className="add-container">
                    <div className="add-heading">
                        <div className="add-heading-text">
                            {details?.pageHeading ?? 'INFORMATION DATA'}
                            {activeTab.heading}
                        </div>
                        <div className="add-heading-subtext">
                            {pageSubHeadingForAdd}
                        </div>
                        <div className="add-close">
                            <Image src={times} onClick={goBack} />
                        </div>
                    </div>
                    <div className="add-form">
                        <Form onSubmit={handleSubmit(onSubmit)} unstackable>
                            <Form.Field width={16}>
                                <Input
                                    name="title"
                                    label={form?.title?.label}
                                    placeholder={form?.title?.placeholder}
                                    refs={{ register }}
                                    count
                                />
                            </Form.Field>
                            <Form.Group>
                                <Form.Field width={6}>
                                    <FileUpload
                                        label={form?.img?.label}
                                        name="infoAddImg"
                                        placeholder={form?.img?.placeholder}
                                        limit={form?.img?.limit}
                                        optional={details.optional}
                                    />
                                </Form.Field>
                                <Form.Field width={6}>
                                    <FileUpload
                                        label={form?.video?.label}
                                        placeholder={form?.video?.placeholder}
                                        limit={form?.video?.limit}
                                        optional={details.optional}
                                        name="infoAddVid"
                                        video
                                    />
                                </Form.Field>
                            </Form.Group>
                            <Form.Field width={16}>
                                <TextArea
                                    name="text"
                                    label={form?.desc?.label}
                                    rows={6}
                                    placeholder={form?.desc?.placeholder}
                                    refs={{ register, setValue }}
                                    editor
                                    preview
                                    content={{
                                        desc: text,
                                        title: title,
                                        imageUrl: infoAddImgUrl,
                                        videoUrl: infoAddVidUrl,
                                    }}
                                    count
                                />
                            </Form.Field>
                            <Button
                                className="add-action-button"
                                type="submit"
                                disabled={validateForm()}
                            >
                                {form?.actionLableForAdd ?? 'Add'}
                            </Button>
                        </Form>
                    </div>
                </div>
            </Segment>
        </Container>
    );
};

export default InfoAdd;
