import React, { useState, useEffect } from 'react';
import { Icon } from 'semantic-ui-react';
import { includes } from 'lodash';
const AudioMsg = (props) => {
    const { audioSrc } = props;
    const [ProgressContorl, setProgressControl] = useState({
        bar: 0,
        totalTime: 0.0,
        running: false,
        status: true,
        audio: new Audio(audioSrc),
    });
    const { bar, running, totalTime, audio } = ProgressContorl;
    const playAudio = () => {
        audio.play();
        setProgressControl({ ...ProgressContorl, running: true });
    };
    const pauseAudio = () => {
        audio.pause();
        setProgressControl({ ...ProgressContorl, running: false });
    };
    const updateSeekBar = (event) => {
        try {
            const curDur = event.target.value;
            audio.currentTime = curDur * 100;
            setProgressControl({
                ...ProgressContorl,
                bar: curDur,
            });
        } catch (_) {
            return false;
        }
    };
    const getDuration = () => {
        const err = ['Infinity', 'NaN'];
        const du = audio.duration.toString();
        const duration = includes(err, du) ? 30 : audio.duration;
        return parseFloat(duration / 100).toFixed(2);
    };
    audio.onstalled = () => {
        setProgressControl({
            ...ProgressContorl,
            status: false,
        });
    };
    audio.onloadeddata = () => {
        const myDur = getDuration();
        setProgressControl({ ...ProgressContorl, totalTime: myDur });
    };
    audio.ontimeupdate = () => {
        const dur = (audio.currentTime / 100).toFixed(2);
        const mytime = getDuration();
        if (!audio.ended) {
            if (dur !== ProgressContorl?.bar)
                setProgressControl({
                    ...ProgressContorl,
                    bar: dur,
                    totalTime: mytime,
                });
        } else
            setProgressControl({
                ...ProgressContorl,
                bar: 0,
                running: false,
            });
    };

    useEffect(() => {
        let isRunning = true;
        isRunning && audio.load();
        return () => {
            isRunning = false;
            audio.pause();
        };
    }, [audio]);
    return (
        <div className={`chat-msg-audio`}>
            {running ? (
                <Icon onClick={pauseAudio} name="pause" />
            ) : (
                <Icon onClick={playAudio} name="play" />
            )}

            <input
                className="progress"
                type="range"
                min={0}
                max={totalTime}
                step={0.01}
                defaultValue={bar}
                disabled
                onChange={updateSeekBar}
            />
            {bar}
        </div>
    );
};

export default AudioMsg;
