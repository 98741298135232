import React, { useState } from 'react';
import { Segment, Container } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import { keys } from 'lodash';
import { getActiveTabDetails } from '_utils';
import { lang } from '_config';

import ProRoutes from '../Pro.Routes';
import './LandingPage.scss';
const PromotionsPage = (props) => {
    const {
        state: {
            promoList: { tabs, loading },
            userDetails: { roles },
        },
        actions: { addPromo },
        history,
        location,
    } = props;
    const { promotions } = lang;
    const {
        placeholder,
        tableHeading,
        breadcrumbHeading,
        tabs: tabHeading,
        assignedList,
        ...rest
    } = promotions;
    const tabsKey = keys(tabs);
    const options = getActiveTabDetails(location?.pathname, tabsKey);
    const [activeTab, setActiveTab] = useState({
        tab: options?.curTab,
        heading: tabHeading[options?.getCurId],
        breadcrumbHeading: breadcrumbHeading[options?.getCurId],
    });
    const config = {
        tableHeading: tableHeading[activeTab.tab],
        placeholder: placeholder[activeTab.tab],
        tabs: tabHeading,
        assignedList,
        ...rest,
    };
    const tabsHeading = config?.tabs ?? [];
    return (
        <Container fluid id="Promotions" className={`private-layout`}>
            <Segment
                basic
                loading={activeTab.tab === 'CUSTOM' ? loading : false}
            >
                <div className="tabs">
                    {tabsHeading.map((tab, i) => (
                        <Link
                            to={`/Promotions/${tabsKey[i]}`}
                            key={i}
                            onClick={() => {
                                setActiveTab({
                                    tab: tabsKey[i],
                                    breadcrumbHeading: breadcrumbHeading[i],
                                    heading: tab,
                                });
                            }}
                        >
                            <div
                                className={
                                    tabsKey[i] === activeTab.tab
                                        ? 'tabs-item active'
                                        : 'tabs-item'
                                }
                            >
                                {tab}
                            </div>
                        </Link>
                    ))}
                </div>
                <div className="main">
                    <ProRoutes
                        config={config}
                        activeTab={activeTab}
                        tabs={tabs[activeTab.tab]}
                        loading={loading}
                        history={history}
                        location={location}
                        roles={roles}
                        addPromo={addPromo}
                        {...props}
                    />
                </div>
            </Segment>
        </Container>
    );
};

export { PromotionsPage };
