import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Icon } from 'semantic-ui-react';
import ViewModal from './ViewModal';

const MobilePreview = (props) => {
    const { content, loading } = props;
    const [isModalOpen, setModal] = useState(false);
    // open modal
    const handleOpen = () => {
        setModal(true);
    };
    // close modal
    const handleClose = () => {
        setModal(false);
    };
    return (
        <ViewModal
            config={{}}
            content={content}
            handleClose={handleClose}
            button={() => <Icon onClick={handleOpen} name="eye" />}
            isModalOpen={isModalOpen}
            loading={loading ?? false}
        />
    );
};

MobilePreview.propTypes = {
    content: PropTypes.object,
};

export { MobilePreview };
