import { connect } from 'react-redux';
import { alertActions, upgradeActions } from '_actions';
import { UpgradePage } from '_pages';
function mapState(state) {
    const {
        alert,
        upgradeList,
        loginData: { userDetails },
    } = state;
    return { state: { alert, upgradeList, userDetails } };
}

const actionCreators = (dispatch) => {
    return {
        actions: {
            clearAlerts: () => dispatch(alertActions.clear()),
            getAll: (i) => dispatch(upgradeActions.getAll(i)),
            enrollPatient: (i) => dispatch(upgradeActions.enrollPatient(i)),
            initModal: () => dispatch(upgradeActions.isUpload()),
            upgradeUpload: (file) => {
                dispatch(upgradeActions.uploadUpgrade(file));
            },
        },
    };
};

export const UpgradeContainer = connect(mapState, actionCreators)(UpgradePage);
