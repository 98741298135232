/* eslint-disable react/no-multi-comp */
import React from 'react';
import { Container, Icon, Button, Form } from 'semantic-ui-react';
import { isEmpty } from 'lodash';
import { BreadcrumbComp, Input } from '_components';
import { useForm } from 'react-hook-form';
import { validMinMaxInput, validMinMaxTextArea } from '_utils';

const NutritionEdit = (props) => {
    const {
        CONFIG,
        actions: { updateNutri },
        location: {
            state: { record },
        },
        state: {
            nutriList: { resetLoading },
        },
    } = props;
    const {
        pageHeading,
        details,
        details: { form },
    } = CONFIG;

    const sections = [
        {
            key: 'myAdminListPage',
            content: pageHeading,
            onClick: () => props.history.push({ pathname: '/Nutritionists' }),
        },
        {
            key: 'myAdminEditPage',
            content: details?.breadcrumbForEdit ?? 'Edit',
            active: true,
        },
    ];
    const { register, handleSubmit, watch, errors } = useForm({
        mode: 'onChange',
    });
    const watchAllFields = watch(['pwd', 'cpwd']);
    const { pwd, cpwd } = watchAllFields;
    const showErrors = (_, value) => {
        switch (_?.type) {
            case 'required':
                return <div className="err-msg">{value?.required}</div>;
            case 'minLength':
                return <div className="err-msg">{value?.minLength}</div>;
            case 'pattern':
                return <div className="err-msg">{value?.pattern}</div>;
            default:
                break;
        }
        return !isEmpty(pwd) && !isEmpty(cpwd) && pwd !== cpwd ? (
            <div className="err-msg">{value?.notMatched}</div>
        ) : (
            ''
        );
    };
    const onSubmit = (data) => {
        if (record)
            updateNutri({
                confirmPassword: data?.cpwd,
                password: data?.pwd,
                id: record?.id,
            });
    };
    const validateForm = () => {
        return !validMinMaxInput(pwd) &&
            !validMinMaxTextArea(cpwd) &&
            pwd === cpwd &&
            isEmpty(errors)
            ? false
            : true;
    };
    return (
        <Container fluid id="NutritionistEditPage" className={`private-layout`}>
            <BreadcrumbComp sections={sections} />
            <div className="edit-container">
                <div className="edit-heading">
                    <div className="edit-heading-text">
                        {details?.pageHeading}
                    </div>
                    <div className="edit-heading-subtext">
                        {details?.pageSubHeadingForEdit}
                    </div>
                    <div className="edit-close">
                        <Icon
                            name="close"
                            size="mini"
                            onClick={() => props.history.goBack()}
                        />
                    </div>
                </div>
                <div className="edit-form">
                    <Form unstackable onSubmit={handleSubmit(onSubmit)}>
                        <Form.Group>
                            <Form.Field width={5}>
                                <Input
                                    label={form?.email?.label}
                                    value={record?.email}
                                    disabled
                                />
                            </Form.Field>
                            <Form.Field width={5}>
                                <Input
                                    label={form?.contact?.label}
                                    value={record?.phone}
                                    disabled
                                />
                            </Form.Field>
                        </Form.Group>
                        <Form.Group>
                            <Form.Field width={5}>
                                <Input
                                    label={form?.userName?.label}
                                    placeholder={form?.userName?.placeholder}
                                    value={record?.fullName}
                                    disabled
                                />
                            </Form.Field>
                            <Form.Field width={5}>
                                <Input
                                    label={form?.center?.label}
                                    placeholder={form?.center?.placeholder}
                                    value={record?.clinicName}
                                    disabled
                                />
                            </Form.Field>
                        </Form.Group>
                        <Form.Group>
                            <Form.Field width={5} className="error-field">
                                <Input
                                    name="pwd"
                                    label={form?.password?.label}
                                    placeholder={form?.password?.placeholder}
                                    refs={{ register }}
                                    type="password"
                                />
                                {showErrors(errors?.pwd, form?.password)}
                            </Form.Field>
                            <Form.Field width={5} className="error-field">
                                <Input
                                    name="cpwd"
                                    label={form?.repassword?.label}
                                    placeholder={form?.repassword?.placeholder}
                                    refs={{ register }}
                                    type="password"
                                />
                                {showErrors(errors?.cpwd, form?.repassword)}
                            </Form.Field>
                        </Form.Group>
                        <Form.Group>
                            <Form.Field width={5}>
                                <Button
                                    disabled={validateForm()}
                                    loading={resetLoading}
                                    className="add-action-button"
                                    type="submit"
                                >
                                    {form?.actionLableForEdit}
                                </Button>
                            </Form.Field>
                        </Form.Group>
                    </Form>
                </div>
            </div>
        </Container>
    );
};

NutritionEdit.propTypes = {};

export { NutritionEdit };
