import React, { useState } from 'react';
import { Segment, Container } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import { keys } from 'lodash';
import { getActiveTabDetails } from '_utils';
import { lang } from '_config';

import TipsRoutes from '../Tips.Routes';
import './LandingPage.scss';
const { tip } = lang;
const {
    placeholder,
    tableHeading,
    breadcrumbHeading,
    tabs: tabHeading,
    assignedList,
    ...rest
} = tip;
const tabs = keys(tableHeading);
const TipsPage = (props) => {
    const {
        location: { pathname },
    } = props;
    const options = getActiveTabDetails(pathname, tabs);
    const [activeTab, setActiveTab] = useState({
        tab: options?.curTab,
        heading: tabHeading[options?.getCurId],
        breadcrumbHeading: breadcrumbHeading[options?.getCurId],
    });
    const config = {
        tableHeading: tableHeading[activeTab.tab],
        placeholder: placeholder[activeTab.tab],
        tabs: tabHeading,
        assignedList,
        ...rest,
    };
    const goBack = () => props.history.push(`/Tips/${activeTab.tab}`);
    const tabsHeading = config?.tabs ?? [];
    return (
        <Container fluid id="TipsPage" className={`private-layout`}>
            <Segment basic>
                <div className="tabs">
                    {tabsHeading.map((tab, i) => {
                        return (
                            <Link
                                to={`/Tips/${tabs[i]}`}
                                key={i}
                                onClick={() => {
                                    setActiveTab({
                                        tab: tabs[i],
                                        breadcrumbHeading: breadcrumbHeading[i],
                                        heading: tab,
                                    });
                                }}
                            >
                                <div
                                    className={
                                        tabs[i] === activeTab.tab
                                            ? 'tabs-item active'
                                            : 'tabs-item'
                                    }
                                >
                                    {tab}
                                </div>
                            </Link>
                        );
                    })}
                </div>

                <div className="main">
                    <TipsRoutes
                        config={config}
                        activeTab={activeTab}
                        goBack={goBack}
                        {...props}
                    />
                </div>
            </Segment>
        </Container>
    );
};

export { TipsPage };
