import { Apis, handleResponse, handelError } from './services';

const getAll = ({ pageNumber, orderBy, direction, search }) => {
    const requestOptions = {
        method: 'GET',
        params: {
            pageNumber,
            orderBy,
            direction,
            search,
        },
    };
    return Apis()(`/ketomap/list`, requestOptions)
        .then(handleResponse)
        .then((res) => ({ status: true, ...res }))
        .catch(handelError);
};

const mapUpload = (file) => {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'multipart/form-data' },
        data: file,
    };
    return Apis()(`/ketomap/upload/bo`, requestOptions)
        .then(handleResponse)
        .then(() => ({ status: true }))
        .catch(handelError);
};

export const mapService = {
    getAll,
    mapUpload,
};
