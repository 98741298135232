import { batch } from 'react-redux';
import INFO from '_constants';
import { infoService } from '_services';
import { lang } from '_config';
import { getErrorMsg } from '_utils';
const {
    menuList: {
        messages,
        error: { info },
    },
} = lang;
const getAll = (params) => {
    const queryParams = {
        pageNumber: params?.pageNumber ?? 0,
        direction: params?.direction ?? 'DESC',
        orderBy: params?.orderBy ?? 'TITLE',
        search: params?.search ?? '',
        category: params?.category ?? 'THE_KIDNEYS',
    };
    return (dispatch) => {
        const onError = (error) =>
            batch(() => {
                dispatch({
                    type: INFO.INFO_LIST_FAILURE,
                    faliure: { [queryParams?.category]: error } ?? null,
                });
                dispatch({ type: INFO.ERROR, message: error });
                setTimeout(() => dispatch({ type: INFO.CLEAR }), INFO.TIMEOUT);
            });
        batch(() => {
            dispatch({ type: INFO.INFO_REQUEST_START });
            dispatch({ type: INFO.CLEAR });
        });
        infoService
            .getAll(queryParams)
            .then((result) => {
                if (result && result?.status) {
                    dispatch({
                        type: INFO.INFO_LIST_SUCCESS,
                        result,
                        params: queryParams,
                    });
                } else {
                    onError(result);
                }
            })
            .catch(onError)
            .finally(() => {
                dispatch({ type: INFO.INFO_REQUEST_END });
            });
    };
};
const addInfo = (params) => {
    const { params: listParams, ...addInfoData } = params;
    return (dispatch) => {
        const onError = (error) => {
            batch(() => {
                dispatch({
                    type: INFO.INFO_ADD_FAILURE,
                    faliure: getErrorMsg(error, info?.create) ?? null,
                    params: addInfoData,
                });
                dispatch({
                    type: INFO.ERROR,
                    message: getErrorMsg(error, info?.create) ?? null,
                });
                setTimeout(() => dispatch({ type: INFO.CLEAR }), INFO.TIMEOUT);
            });
        };
        batch(() => {
            dispatch({ type: INFO.INFO_REQUEST_START });
            dispatch({ type: INFO.CLEAR });
        });
        infoService
            .addInfo(addInfoData)
            .catch(onError)
            .finally(() => {
                batch(() => {
                    dispatch({ type: INFO.INFO_REQUEST_END });
                    dispatch({
                        type: INFO.SUCCESS,
                        message: {
                            suc: info?.create[200]?.msg ?? messages?.add,
                        },
                    });
                    setTimeout(
                        () =>
                            dispatch(getAll({ ...listParams, pageNumber: 0 })),
                        INFO.TIMEOUT
                    );
                });
            });
    };
};
const editInfo = (params) => {
    return (dispatch) => {
        const onError = (error) =>
            batch(() => {
                dispatch({
                    type: INFO.INFO_EDIT_FAILURE,
                    faliure: error ?? null,
                    params,
                });
                dispatch({ type: INFO.ERROR, message: error ?? null });
                setTimeout(() => dispatch({ type: INFO.CLEAR }), INFO.TIMEOUT);
            });
        batch(() => {
            dispatch({ type: INFO.INFO_REQUEST_START });
            dispatch({ type: INFO.CLEAR });
        });
        infoService
            .editInfo(params)
            .then((result) => {
                if (result && result?.status) {
                    dispatch({
                        type: INFO.INFO_SELECTED_ID,
                        result,
                        params,
                    });
                } else {
                    onError(result);
                }
            })
            .catch(onError)
            .finally(() => dispatch({ type: INFO.INFO_REQUEST_END }));
    };
};
const updateInfo = (params) => {
    const { params: listParams, ...updateInfoData } = params;
    return (dispatch) => {
        const onError = (error) =>
            batch(() => {
                dispatch({
                    type: INFO.INFO_EDIT_FAILURE,
                    faliure: getErrorMsg(error, info?.put) ?? null,
                    params: updateInfoData,
                });
                dispatch({
                    type: INFO.ERROR,
                    message: getErrorMsg(error, info?.put) ?? null,
                });
                setTimeout(() => dispatch({ type: INFO.CLEAR }), INFO.TIMEOUT);
            });
        batch(() => {
            dispatch({ type: INFO.INFO_REQUEST_START });
            dispatch({ type: INFO.CLEAR });
        });
        infoService
            .updateInfo(updateInfoData)
            .catch(onError)
            .finally(() => {
                batch(() => {
                    dispatch({ type: INFO.INFO_REQUEST_END });
                    dispatch({
                        type: INFO.SUCCESS,
                        message: {
                            suc: info?.put[200]?.msg ?? messages?.update,
                        },
                    });
                    setTimeout(
                        () =>
                            dispatch(getAll({ ...listParams, pageNumber: 0 })),
                        INFO.TIMEOUT
                    );
                });
            });
    };
};
const delInfo = (params) => {
    const { params: listParams, ...delInfoData } = params;
    return (dispatch) => {
        const onError = (error) =>
            batch(() => {
                dispatch({
                    type: INFO.INFO_DEL_FAILURE,
                    faliure: getErrorMsg(error, info?.delete) ?? null,
                    delInfoData,
                });
                dispatch({
                    type: INFO.ERROR,
                    message: getErrorMsg(error, info?.delete) ?? null,
                });
                setTimeout(() => dispatch({ type: INFO.CLEAR }), INFO.TIMEOUT);
            });
        batch(() => {
            dispatch({ type: INFO.INFO_REQUEST_START });
            dispatch({ type: INFO.CLEAR });
        });
        infoService
            .deleteInfo(delInfoData)
            .then((res) => {
                res.status
                    ? batch(() => {
                          dispatch({
                              type: INFO.SUCCESS,
                              message: {
                                  suc:
                                      info?.delete[200]?.msg ??
                                      messages?.delete,
                              },
                          });
                          setTimeout(
                              () =>
                                  dispatch(
                                      getAll({ ...listParams, pageNumber: 0 })
                                  ),
                              INFO.TIMEOUT
                          );
                      })
                    : onError(res);
            })
            .catch(onError)
            .finally(() => {
                batch(() => {
                    dispatch({ type: INFO.INFO_REQUEST_END });
                });
            });
    };
};

export const infoActions = {
    getAll,
    addInfo,
    editInfo,
    updateInfo,
    delInfo,
};
