import { fetchApi, handleResponse, handelError } from './services';
const noop = () => {};
const send = (piece, start, end, size, XUniqueUploadId) => {
    return new Promise((resolve) => {
        let FD = new FormData();
        FD.append('file', piece);
        FD.append('upload_preset', 'ibamuccq');
        FD.append('cloud_name', 'fresenius-keto');
        const requestOptions = {
            method: 'POST',
            headers: {
                'X-Unique-Upload-Id': XUniqueUploadId,
                'Content-Range': 'bytes ' + start + '-' + end + '/' + size,
            },
            data: FD,
            timeout: null,
            baseURL: 'https://api.cloudinary.com',
        };
        fetchApi(`/v1_1/fresenius-keto/auto/upload`, requestOptions)
            .then(handleResponse)
            .then((result) => {
                return resolve(result);
            })
            .catch(handelError);
    });
};
const slice = (file, start, end) => {
    let slice = file.mozSlice
        ? file.mozSlice
        : file.webkitSlice
        ? file.webkitSlice
        : file.slice
        ? file.slice
        : noop;

    return slice.bind(file)(start, end);
};
const uploadVideo = (f) => {
    return new Promise((resolve) => {
        let XUniqueUploadId = +new Date();
        const sliceSize = 80000000;
        let file = f;
        let size = file.size;
        let start = 0;

        setTimeout(loop, 3);
        // let checkLoopA = null;
        async function loop() {
            let end = start + sliceSize;
            if (end > size) {
                end = size;
            }
            let s = slice(file, start, end);
            await send(s, start, end - 1, size, XUniqueUploadId).then((m) => {
                if (m?.secure_url !== undefined) return resolve(m);
            });

            if (end < size) {
                start += sliceSize;
                setTimeout(loop, 3);
            }
        }
    });
};

const fileUpload = (file) => {
    if (file.type === 'video/mp4') {
        return uploadVideo(file)
            .then((res) => {
                return {
                    status: true,
                    data: res.secure_url,
                };
            })
            .catch(handelError);
    } else {
        const fd = new FormData();
        fd.append('file', file);
        fd.append('upload_preset', 'ibamuccq');
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'multipart/form-data' },
            data: fd,
            timeout: null,
            baseURL: 'https://api.cloudinary.com',
        };
        return fetchApi(`/v1_1/fresenius-keto/upload`, requestOptions)
            .then(handleResponse)
            .then((data) => ({
                status: true,
                data: data.secure_url,
            }))
            .catch(handelError);
    }
};

export const fileService = {
    fileUpload,
};
