import React from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import { NutritionList, NutritionEdit } from './Nutrition';
const NutritionRoutes = (props) => {
    const { path } = useRouteMatch();
    return (
        <Switch>
            <Route exact path={`${path}`}>
                <NutritionList {...props} />
            </Route>
            <Route exact path={`${path}/edit/:id`}>
                <NutritionEdit {...props} />
            </Route>
        </Switch>
    );
};

export default NutritionRoutes;
