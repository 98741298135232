import React, { useState, useEffect, useCallback } from 'react';
import { Segment, Container, Button, Form } from 'semantic-ui-react';
import { useForm } from 'react-hook-form';
import { isEmpty, last, includes } from 'lodash';
import { useRouteMatch } from 'react-router-dom';
import {
    BreadcrumbComp,
    Image,
    Input,
    TextArea,
    FileUpload,
    DateTimePicker,
    Select,
} from '_components';
import { lang } from '_config';
import {
    validMinMaxInput,
    validMinMaxTextArea,
    getFileDetail,
    isNumberExist,
} from '_utils';
import { times } from 'images';
import './CustomEdit.scss';
const CustomEdit = (props) => {
    const {
        activeTab,
        tabs: { selectedId },
        location: {
            state: { record: customRecord },
        },
        config: { assignedList },
        actions: {
            clearFileDetails,
            updateCustomPromo,
            delCustomPromo,
            fileTextUpdate,
            viewPromo,
        },
        state: { filesList },
        history,
    } = props;
    const record = isEmpty(customRecord) ? selectedId : customRecord;
    const { url } = useRouteMatch();
    const [isAssign, setAssign] = useState(record?.assignedTo),
        {
            promotions: {
                details,
                details: { form },
            },
        } = lang,
        sections = [
            {
                key: 'myAdminListPage',
                content: activeTab.breadcrumbHeading,
                onClick: () =>
                    history.push({ pathname: `/Promotions/${activeTab?.tab}` }),
            },
            {
                key: 'myAdminEditPage',
                content: details?.breadcrumbForEdit ?? 'Edit',
                active: true,
            },
        ];
    const customEditImageUrl = getFileDetail(
            filesList,
            'customEditImage',
            'url'
        ),
        customUploadImg = getFileDetail(
            filesList,
            'customEditImage',
            'uploading',
            true
        );
    const customEditVideoUrl = getFileDetail(
            filesList,
            'customEditVideo',
            'url'
        ),
        customUploadVid = getFileDetail(
            filesList,
            'customEditVideo',
            'uploading',
            true
        );
    const { register, handleSubmit, watch, errors } = useForm(),
        watchAllFields = watch(['title', 'desc', 'from', 'to']),
        validation = {
            AVAILABE: {
                required: true,
                maxLength: 100,
                minLength: 4,
            },
        },
        onChange = (_, data) => {
            setAssign(data.value);
        },
        validateForm = () => {
            const { title, desc, from, to } = watchAllFields;
            return !validMinMaxInput(title) &&
                !validMinMaxTextArea(desc, undefined, 2500) &&
                !isEmpty(from) &&
                !isEmpty(to) &&
                !customUploadImg &&
                !customUploadVid &&
                !isEmpty(isAssign) &&
                isEmpty(errors)
                ? false
                : true;
        },
        handelDel = () => {
            delCustomPromo({ id: record.id, tabName: activeTab?.tab });
        },
        onSubmit = (data) => {
            const assignedRoles = includes(isAssign, 'TODO')
                ? ['ROLE_UNAFFILIATED', 'ROLE_CEAN', 'ROLE_KETOSTERIL']
                : isAssign;
            updateCustomPromo({
                title: data.title,
                description: data.desc,
                availableFrom: data.from,
                availableTo: data.to,
                image: customEditImageUrl,
                video: customEditVideoUrl,
                id: record.id,
                assignedTo: assignedRoles,
                tabName: activeTab?.tab,
            });
        };
    const isRecord = useCallback(() => {
        try {
            // navigatePage(props, alertData, '/Promotions/CUSTOM', getCustomList);
            const segmants = url.split('/');
            if (segmants.length === 5)
                return {
                    tabName: activeTab?.tab,
                    id: isNumberExist(last(segmants))
                        ? parseInt(last(segmants))
                        : 0,
                };
            else {
                return false;
            }
        } catch {
            return false;
        }
    }, [url, activeTab]);
    const formValues = record ?? null;
    useEffect(() => {
        clearFileDetails();
        if (!isEmpty(record)) {
            if (record?.image)
                fileTextUpdate({
                    name: 'customEditImage',
                    url: record?.image,
                });
            if (record?.video)
                fileTextUpdate({
                    name: 'customEditVideo',
                    url: record?.video,
                });
        } else {
            const getRecord = isRecord();
            if (!isEmpty(getRecord) && isEmpty(getRecord.id)) {
                viewPromo(getRecord);
            } else {
                history.push('/Promotions/CUSTOM');
            }
        }
    }, [
        clearFileDetails,
        fileTextUpdate,
        record,
        viewPromo,
        isRecord,
        history,
    ]);
    if (isAssign === undefined && record !== undefined)
        setAssign(record?.assignedTo);
    return (
        <Container fluid id="CustomEdit" className={`private-layout`}>
            <Segment basic>
                <BreadcrumbComp sections={sections} />
                <div className="edit-container">
                    <div className="edit-heading">
                        <div className="edit-heading-text">
                            {details?.pageHeading}
                        </div>
                        <div className="edit-heading-subtext">
                            {details?.pageSubHeadingForEdit}
                        </div>
                        <div className="edit-close">
                            <Image
                                src={times}
                                onClick={() =>
                                    history.push({
                                        pathname: `/Promotions/${activeTab?.tab}`,
                                    })
                                }
                            />
                        </div>
                    </div>
                    {!isEmpty(formValues) && (
                        <div className="edit-form">
                            <Form
                                autoComplete="off"
                                onSubmit={handleSubmit(onSubmit)}
                                unstackable
                            >
                                <Form.Field width={16}>
                                    <Input
                                        name="title"
                                        label={form?.title?.label}
                                        placeholder={form?.title?.placeholder}
                                        widths={12}
                                        refs={{ register }}
                                        value={formValues?.title}
                                        count
                                    />
                                </Form.Field>
                                <Form.Group>
                                    <Form.Field width={6}>
                                        <FileUpload
                                            label={form?.img?.label}
                                            placeholder={form?.img?.placeholder}
                                            name="customEditImage"
                                            optional={details.optional}
                                            limit={form?.img?.limit}
                                        />
                                    </Form.Field>
                                    <Form.Field width={6}>
                                        <FileUpload
                                            label={form?.vid?.label}
                                            placeholder={form?.vid?.placeholder}
                                            name="customEditVideo"
                                            optional={details.optional}
                                            limit={form?.vid?.limit}
                                            video
                                        />
                                    </Form.Field>
                                    <div className="field">
                                        {!isEmpty(formValues) && (
                                            <>
                                                <DateTimePicker
                                                    label={
                                                        form?.available
                                                            ?.label ?? ''
                                                    }
                                                    fromTo
                                                    placeholder={
                                                        form?.available
                                                            ?.placeholder
                                                    }
                                                    value={[
                                                        formValues?.availableFrom,
                                                        formValues?.availableTo,
                                                    ]}
                                                    refs={register({
                                                        ...validation.AVAILABE,
                                                    })}
                                                />
                                                <Select
                                                    name="assign"
                                                    label={
                                                        form?.assign?.label ??
                                                        ''
                                                    }
                                                    placeholder={
                                                        form?.assign
                                                            ?.placeholder
                                                    }
                                                    value={isAssign}
                                                    options={assignedList}
                                                    onChange={onChange}
                                                    multiple
                                                    fluid
                                                />
                                            </>
                                        )}
                                    </div>
                                </Form.Group>
                                <Form.Group>
                                    <Form.Field width={16}>
                                        <div className="assigned-patient">
                                            <TextArea
                                                name="desc"
                                                label={form?.desc?.label}
                                                rows={20}
                                                placeholder={
                                                    form?.desc?.placeholder
                                                }
                                                refs={{
                                                    register,
                                                    validation: {
                                                        maxLength: 2500,
                                                    },
                                                }}
                                                value={formValues?.description}
                                                count
                                            />
                                        </div>
                                    </Form.Field>
                                </Form.Group>
                                <Button
                                    disabled={validateForm()}
                                    className="add-action-button"
                                    type="submit"
                                >
                                    {form?.actionLableForEdit}
                                </Button>
                                <Button
                                    type="button"
                                    className="add-action-button outline"
                                    onClick={handelDel}
                                >
                                    {form?.actionLableForDel}
                                </Button>
                            </Form>
                        </div>
                    )}
                </div>
            </Segment>
        </Container>
    );
};

export default CustomEdit;
