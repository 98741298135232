/* eslint-disable react/jsx-no-bind */
/* eslint-disable react/destructuring-assignment */
import React, { useEffect } from 'react';
import { Redirect, Link } from 'react-router-dom';
import { Button, Form, Grid } from 'semantic-ui-react';
import { useForm } from 'react-hook-form';
import { PublicLayout as Layout, Image, Message, Input } from '_components';
import { LoginBanner, Logo, ketoLogo } from 'images';
import { validateEmail, getUserDetails } from '_utils';
import { lang } from '_config';
import { isEmpty } from 'lodash';
import './loginPage.scss';
const LoginPage = (props) => {
    const configLogin = lang.login,
        // get the labels from config
        { userField, passwordField, buttonField, language } = configLogin,
        // setup the values for form validation
        { register, handleSubmit, watch } = useForm(),
        // watching the fields
        watchAllFields = watch(['userEmail', 'password']),
        // configuration for validation
        validation = {
            required: true,
            maxLength: 60,
            minLength: 4,
        },
        // setup the form data
        frmData = {
            fields: [
                {
                    lable: userField?.lableName,
                    placeHolder: userField?.placeHolder,
                    validation: register({ ...validation }),
                    className: 'ui form-email',
                    type: 'text',
                    name: 'userEmail',
                },
                {
                    lable: passwordField?.lableName,
                    placeHolder: passwordField?.placeHolder,
                    validation: register({ ...validation }),
                    className: 'ui form-password',
                    type: 'password',
                    name: 'password',
                },
            ],
        },
        // get values from container
        {
            actions: { checkLogin, msgClear },
            state: { loginData, alertData },
        } = props,
        // check validation form
        validateForm = () => {
            let result = true;
            const { userEmail, password } = watchAllFields;
            const { maxLength, minLength } = validation;
            if (userEmail && password) {
                result =
                    userEmail.length <= maxLength &&
                    userEmail.length >= minLength;
                result = result
                    ? password.length <= maxLength &&
                      password.length >= minLength
                    : result;
                result = result ? validateEmail(userEmail) : result;
            } else result = false;
            return result;
        },
        // submittion the form
        onSubmit = (data) => {
            checkLogin(data);
        },
        checkRedirect = () => {
            if (getUserDetails()) {
                const {
                    state: {
                        loginData: { userDetails },
                    },
                    location: { state },
                } = props;
                const defaultActiveTab =
                    userDetails?.roles === 'Admin' ? 'Admins' : 'Patients';
                const stateData = {
                    userDetails,
                    userType: userDetails.roles,
                    prevState: state?.from ?? 'Admin',
                    activeTab:
                        state?.from?.pathname.substring(1) ?? defaultActiveTab,
                };
                if (userDetails.roles === 'Admin' && userDetails.loggedIn)
                    return (
                        <Redirect
                            to={{
                                pathname: stateData?.activeTab,
                                state: { ...stateData },
                            }}
                        />
                    );
                else if (
                    userDetails.roles === 'Nutritionists' &&
                    userDetails.loggedIn
                )
                    return (
                        <Redirect
                            to={{
                                pathname: stateData?.activeTab ?? '/Patients',
                                state: { ...stateData },
                            }}
                        />
                    );
            } else return <Redirect to="/login" />;
        };
    // clear messages
    useEffect(() => {
        return () => {
            msgClear();
        };
    }, [msgClear]);
    return (
        <Layout {...props} className="LoginPage" header="Login Page">
            {checkRedirect()}
            <Grid className="LoginPage-grid">
                <Grid.Row>
                    <Grid.Column width={8} className="col-form">
                        <div className="image-container">
                            <Image src={Logo} fluid />
                        </div>
                        <div className="form-container">
                            <div className="form-header">
                                <Image
                                    src={ketoLogo}
                                    className="form-header--logo"
                                />
                            </div>
                            <div className="form-header-sub">
                                {configLogin?.text?.subHeadingText}
                            </div>
                            <Form
                                autoComplete="off"
                                onSubmit={handleSubmit(onSubmit)}
                            >
                                {!isEmpty(alertData) &&
                                    alertData?.type === 'alert-danger' && (
                                        <Message
                                            alertData={alertData}
                                            loginData={loginData}
                                        />
                                    )}
                                {frmData.fields.map((field, i) => {
                                    return (
                                        <Form.Field key={i}>
                                            {i === 1 && (
                                                <Button
                                                    as={Link}
                                                    to="/forgotPwd"
                                                    className="form-forgot"
                                                >
                                                    {
                                                        configLogin?.text
                                                            ?.forgotText
                                                    }
                                                </Button>
                                            )}
                                            <Input
                                                type={field.type}
                                                label={field.lable}
                                                name={field.name}
                                                placeholder={field.placeHolder}
                                                refs={field.validation}
                                            />
                                        </Form.Field>
                                    );
                                })}
                                <Button
                                    loading={loginData.loading}
                                    type="submit"
                                    className="form-button"
                                    disabled={
                                        !validateForm() || loginData.loading
                                    }
                                >
                                    {buttonField.lableName}
                                </Button>
                            </Form>
                        </div>
                    </Grid.Column>
                    <Grid.Column
                        width={8}
                        className="col-image"
                        only="computer"
                    >
                        <div className="image-container">
                            <Image src={LoginBanner} fluid />

                            <div style={{position: 'absolute', bottom: '8px', right: '16px'}}>
                                <p style={{fontSize: '10px', color: 'white'}}>Lang: {process.env.REACT_APP_LANGUAGE}, {language.test}</p>
                            </div>

                        </div>
                    </Grid.Column>
                </Grid.Row>

            </Grid>
        </Layout>
    );
};
export { LoginPage };
