/* eslint-disable react/no-children-prop */
import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from 'semantic-ui-react';
import { GridHeading, GridRow, Pagination, GridSortHeading } from '_components';
import { map } from 'lodash';
import './GridList.scss';
const GridList = (props) => {
    const {
            children,
            config,
            listData,
            listData: {
                sorting,
                cellWidth,
                pageData,
                loading,
                filters,
                params,
                actionList,
                cref,
                columns,
                roles,
                isDisable,
                sortHeading,
            },
        } = props,
        cellHeading = config?.tableHeading ?? [];
    let pageNumber = filters?.pageNumber ?? 0;
    pageNumber += 1;
    const onLoadMore = () => {
        actionList({ ...params, pageNumber });
    };
    const handelSorting = (sortParams) => {
        const { orderBy, direction } = sortParams;
        actionList({
            ...params,
            direction,
            orderBy,
            pageNumber: 0,
        });
    };
    return (
        <div id="grid-list">
            <Grid>
                <Grid.Row className="grid-heading">
                    {cellHeading.map((heading, key) => (
                        <GridHeading
                            key={key}
                            width={cellWidth[key] ?? 3}
                            cellText={heading}
                            sorting={sorting[key]}
                            column={columns[key]?.toUpperCase() ?? ''}
                            params={params}
                            handelSorting={handelSorting}
                            loading={loading}
                        />
                    ))}
                    {sortHeading && (
                        <GridSortHeading
                            params={params}
                            loading={loading}
                            handelSorting={handelSorting}
                            column={sortHeading}
                            sorting={{ direction: 'DESC' }}
                        />
                    )}
                </Grid.Row>
                {(loading || !pageData.length) && (
                    <GridRow
                        cellData={{}}
                        config={{}}
                        loading={loading}
                        roles={roles}
                        listData={listData}
                    />
                )}
                {map(pageData, (rows, index) => {
                    return (
                        <GridRow
                            key={index}
                            rowIndex={index}
                            cellData={rows}
                            width={3}
                            loading={loading}
                            config={config}
                            roles={roles}
                            listData={listData}
                            children={children}
                        />
                    );
                })}
            </Grid>
            {filters && pageData.length && pageData.length >= 10 ? (
                <Pagination
                    cref={cref}
                    filters={filters}
                    isLoading={loading}
                    isDisable={isDisable}
                    records={pageData.length}
                    config={config}
                    onLoadMore={onLoadMore}
                />
            ) : (
                ''
            )}
        </div>
    );
};
GridList.defaultProps = {
    config: {},
    listData: {},
};
GridList.propTypes = {
    config: PropTypes.object.isRequired,
    listData: PropTypes.object.isRequired,
};

export { GridList };
