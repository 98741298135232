import NEWS from '_constants';
import { INIT } from '_config/initialState';
const { newsList: initialState } = INIT;

export default (state = initialState, action = {}) => {
    const { type, result, params, faliure } = action;
    switch (type) {
        case NEWS.NEWS_REQUEST_START:
            return { ...state, loading: true };
        case NEWS.NEWS_LIST_SUCCESS:
            return {
                ...state,
                result: {
                    ...result,
                    list: params.pageNumber
                        ? [...state.result.list, ...result.list]
                        : [...result.list],
                },
                faliure: null,
                params: params,
            };

        case NEWS.NEWS_LIST_FAILURE:
        case NEWS.NEWS_ADD_FAILURE:
        case NEWS.NEWS_EDIT_FAILURE:
        case NEWS.NEWS_DEL_FAILURE:
            return { ...state, faliure };
        case NEWS.NEWS_SELECTED_ID:
            return { ...state, selectedId: result };
        case NEWS.NEWS_REQUEST_END:
            return { ...state, loading: false };
        default:
            return { ...state };
    }
};
export const newsDetails = (state) => state.newsList;
