import { connect } from 'react-redux';
import {
    alertActions,
    infoActions,
    userActions,
    fileUploadAction,
} from '_actions';
import { getRecordId } from '_utils';
import { InfoPage } from '_pages';
import history from '_utils/history';
function mapState(state) {
    const {
        alert,
        infoList,
        loginData: { userDetails },
        files: { list: filesList },
    } = state;
    return { state: { alert, infoList, filesList, userDetails } };
}

const actionCreators = (dispatch) => {
    return {
        actions: {
            clearAlerts: () => dispatch(alertActions.clear()),
            logOut: () => dispatch(userActions.logout()),
            loginSuccess: () => dispatch(userActions.loginSuccess()),
            getList: (queryParams) => {
                dispatch(infoActions.getAll(queryParams));
            },
            addInfo: (queryParams) => {
                dispatch(infoActions.addInfo(queryParams));
            },
            editInfo: (queryParams) => {
                const data = getRecordId(queryParams);
                // dispatch(infoActions.editInfo(data));
                data?.id
                    ? dispatch(infoActions.editInfo(data))
                    : history.push({ pathname: '/Info' });
            },
            updateInfo: (queryParams) => {
                dispatch(infoActions.updateInfo(queryParams));
            },
            delInfo: (queryParams) => {
                dispatch(infoActions.delInfo(queryParams));
            },
            fileTextUpdate: (file) =>
                dispatch(fileUploadAction.fileTextUpdate(file)),
            clearFileDetails: () => dispatch(fileUploadAction.clearFileData()),
        },
    };
};

export const InfoContainer = connect(mapState, actionCreators)(InfoPage);
