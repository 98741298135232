import { Client as ChatClient } from 'twilio-chat';
import {
    connect,
    createLocalVideoTrack,
    createLocalAudioTrack,
} from 'twilio-video';
import { isEmpty, find, map, filter, omit } from 'lodash';
import AudioRecorder from 'audio-recorder-polyfill';
import { getUserDetails } from '_utils';
let chatInfo = {};
export const createRoom = async (
    section,
    identity,
    name,
    inviteUsers,
    userId
) => {
    try {
        const uniqueName =
            section === 'NUTRITIONISTS'
                ? `${section}-${identity}-${userId}`
                : `${section}-${identity}`;
        const { chatClient, rooms = [] } = chatInfo;
        const room = find(rooms, { uniqueName });
        const { Name: mName, sub: mId, roles } = getUserDetails();
        // const mRoom = await chatClient.getChannelByUniqueName(uniqueName);
        //CHECK IF ROOM NOT EXIST
        if (room === undefined) {
            return await chatClient
                .createChannel({
                    uniqueName,
                    friendlyName: identity,
                    isPrivate: true,
                    attributes: {
                        id: section === 'NUTRITIONISTS' ? userId : identity,
                        name,
                        mName,
                        mId,
                    },
                })
                // GET ROOM INFO
                .then((res) => res)
                .then(async (res) => {
                    await res.join();
                    const invitedUsers = map(
                        inviteUsers,
                        async (inviteUser) => {
                            const userList = await res.invite(inviteUser);
                            return userList;
                        }
                    );
                    return {
                        room: res,
                        invitedUsers: Promise.all(invitedUsers),
                    };
                })
                .then((res) => ({ status: true, ...res }))
                .catch((e) => ({
                    status: false,
                    err: { name: e.name, msg: e.message },
                }));
        } else {
            // ALLREADY CREATED USER IF NOT JOINED
            if (section === 'NUTRITIONISTS' && roles === 'Admin')
                room.updateAttributes({ id: userId, name, mName, mId });
            map(inviteUsers, async (inviteUser) => {
                const isExist = await room.getMemberByIdentity(inviteUser);
                if (isEmpty(isExist)) await room.invite(inviteUser);
            });
            return { status: true, ...room };
        }
    } catch (e) {
        return { status: false, err: { name: e.name, msg: e.message } };
    }
};
export const isMobileUserOnine = async (roomInfo, tab) => {
    let data = {};
    try {
        const { state } = await roomInfo.getUserDescriptors();
        const [mItem] = state.items.filter(
            (item) => item.identity === roomInfo.createdBy
        );
        if (
            mItem?.attributes?.isOnline === false ||
            (mItem?.attributes?.isOnline === true &&
                mItem?.attributes.tab !== tab)
        ) {
            data = {
                callType: 'TEXT',
                userIdList: [parseInt(roomInfo?.friendlyName)],
                callerType: tab === 'PATIENTS' ? 'NUTRITIONIST' : 'KETOCENTER',
            };
            return data;
        } else return data;
    } catch (error) {
        return data;
    }
};
export const isUserOnline = async (roomActions, tab) => {
    return await roomActions
        .getMembers()
        .then((members) => members)
        .then((members) => {
            if (tab !== 'NUTRITIONISTS') {
                return filter(members, {
                    identity: roomActions.createdBy,
                });
            } else {
                return omit(members, {
                    identity: roomActions.createdBy,
                });
            }
        })
        .then(async (users) => {
            return await users[0].getUser();
        })
        .then((res) => {
            let data = {};
            if (res?.state?.online === null || !res?.state?.online) {
                data = {
                    callType: 'TEXT',
                    userIdList: [parseInt(roomActions?.friendlyName)],
                    callerType:
                        tab === 'PATIENTS' ? 'NUTRITIONIST' : 'KETOCENTER',
                };
            }
            return data;
        });
};
const getList = async (userChannel, items) => {
    if (userChannel.hasNextPage) {
        const res = await userChannel.nextPage();
        return await getList(res, [...items, ...res.items]);
    } else return items;
};
// get all channel list
const getRoomlList = async () => {
    const { chatClient } = chatInfo;
    const userChannel = await chatClient.getSubscribedChannels();
    const myChannels = await getList(userChannel, userChannel.items);
    return [...myChannels];
};
// GET LOGS
const connetionLog = () => {
    try {
        const { chatClient, memberName, userDetails } = chatInfo;
        chatClient.user.updateAttributes({
            id: userDetails?.sub ?? null,
            name: memberName,
            ...userDetails,
        });
    } catch (e) {
        return e;
    }
};
// create connection with client
const createConnection = async () => {
    try {
        const { token, userDetails } = chatInfo;
        chatInfo.chatClient = await ChatClient.create(token, {
            preferredAudioCodecs: ['isac'],
            preferredVideoCodecs: ['H264'],
        });
        chatInfo.memberName = userDetails?.sub;
    } catch (e) {
        throw new Error('Token Expire');
    }
};
// setup chat
const initChat = async () => {
    try {
        await createConnection();
        if (!isEmpty(chatInfo)) {
            connetionLog();
            chatInfo.rooms = await getRoomlList();
        } else return { items: [] };
    } catch (e) {
        return {};
    }
};
// start chat application
export const bootChatApp = async (token, userDetails) => {
    try {
        if (!isEmpty(token)) {
            chatInfo.token = token;
            chatInfo.userDetails = userDetails;
            await initChat();
            return { chatInfo };
        }
    } catch (e) {
        return { chatInfo };
    }
};
// FOR AUDIO MSG
export const recordAudioMsg = () => {
    if (window.MediaRecorder === undefined)
        window.MediaRecorder = AudioRecorder;

    return new Promise((resolve) => {
        navigator.mediaDevices.getUserMedia({ audio: true }).then((stream) => {
            const mediaRecorder = new MediaRecorder(stream);
            const audioChunks = [];

            mediaRecorder.addEventListener('dataavailable', (event) => {
                audioChunks.push(event.data);
            });

            const start = () => {
                mediaRecorder.start();
            };

            const stop = () => {
                return new Promise((res) => {
                    mediaRecorder.addEventListener('stop', () => {
                        const audioBlob = new Blob(audioChunks, {
                            type: 'audio/mpeg',
                        });
                        const audioUrl = URL.createObjectURL(audioBlob);
                        const audio = new Audio(audioUrl);
                        const play = () => {
                            audio.play();
                        };
                        mediaRecorder.stream
                            .getTracks()
                            .forEach((track) => track.stop());
                        res({ audioBlob, audioUrl, play });
                    });

                    mediaRecorder.stop();
                });
            };

            resolve({ start, stop });
        });
    });
};
//FOR INI VIDEO CALL
export const connetRoom = async (_, roomName) => {
    const audioTrack = await createLocalAudioTrack();
    const options = {
        name: roomName,
    };
    if (_?.status && _?.type === 'video') {
        const videoTrack = await createLocalVideoTrack();
        options.tracks = [audioTrack, videoTrack];
    } else options.tracks = [audioTrack];
    // console.log(options);
    return await connect(chatInfo.token, options)
        .then(
            (room) => {
                chatInfo.videoRoom = room;
                return chatInfo;
            },
            () => {
                return false;
            }
        )
        .catch(() => false);
};
