import React, { useEffect } from 'react';
import { Segment, Container, Button, Form } from 'semantic-ui-react';
import { useForm } from 'react-hook-form';
import { isEmpty } from 'lodash';
import {
    BreadcrumbComp,
    Image,
    Input,
    TextArea,
    FileUpload,
} from '_components';
import { getFileDetail, validMinMaxInput, validMinMaxTextArea } from '_utils';
import { times } from 'images';
import CONST from '_constants';
import './GeneralEdit.scss';
const GeneralEdit = (props) => {
    const {
        activeTab,
        goBack,
        config: {
            details,
            details: { form, heading, subHeading },
        },
        location: {
            pathname,
            state: { record: selectedRecord },
        },
        actions: {
            clearFileDetails,
            updateGen,
            delGen,
            updatePyhsical,
            delPyhsical,
            fileTextUpdate,
            editRecord,
        },
        state: {
            filesList,
            tips: { loading: genEditing, selectedId },
        },
    } = props;
    const record = selectedRecord === undefined ? selectedId : selectedRecord;
    const pageHeading = heading[activeTab.tab] ?? '';
    const pageSubHeadingForAdd = subHeading?.edit[activeTab.tab] ?? '';
    const sections = [
        {
            key: 'myAdminListPage',
            content: activeTab.breadcrumbHeading,
            onClick: goBack,
        },
        {
            key: 'myAdminEditPage',
            content: details?.breadcrumbForEdit ?? 'Edit',
            active: true,
        },
    ];
    const genEditImageUrl = getFileDetail(filesList, 'genEditImage', 'url'),
        genUploadImg = getFileDetail(
            filesList,
            'genEditImage',
            'uploading',
            true
        ),
        genEditVidUrl = getFileDetail(filesList, 'genEditVid', 'url'),
        genUploadVid = getFileDetail(
            filesList,
            'genEditVid',
            'uploading',
            true
        );
    const { register, handleSubmit, watch, errors, setValue } = useForm(),
        watchAllFields = watch(['title', 'desc']),
        { title, desc } = watchAllFields,
        validateForm = () => {
            return !validMinMaxInput(title) &&
                !validMinMaxTextArea(desc) &&
                !genUploadImg &&
                !genUploadVid &&
                isEmpty(errors)
                ? false
                : true;
        },
        onSubmit = (data) => {
            const result = {
                id: record?.id,
                title: data?.title,
                description: data?.desc,
                image: genEditImageUrl,
                video: genEditVidUrl,
            };
            if (activeTab.tab === 'GENERAL') updateGen(result);
            if (activeTab.tab === 'PHYSICAL') updatePyhsical(result);
            setTimeout(() => goBack(true), CONST.TIMEOUT);
        },
        handelDel = () => {
            if (activeTab.tab === 'GENERAL') delGen({ id: record?.id });
            if (activeTab.tab === 'PHYSICAL') delPyhsical({ id: record?.id });
            setTimeout(() => goBack(true), CONST.TIMEOUT);
        };
    useEffect(() => {
        clearFileDetails();
        if (!isEmpty(record)) {
            if (record?.image)
                fileTextUpdate({ name: 'genEditImage', url: record?.image });
            if (record?.video)
                fileTextUpdate({ name: 'genEditVid', url: record?.video });
        } else {
            editRecord(pathname);
        }
    }, [clearFileDetails, fileTextUpdate, record, pathname, editRecord]);
    return (
        <Container fluid id="GeneralEdit" className={`private-layout`}>
            <Segment basic loading={genEditing}>
                <BreadcrumbComp sections={sections} />
                <div className="edit-container">
                    <div className="edit-heading">
                        <div className="edit-heading-text">{pageHeading}</div>
                        <div className="edit-heading-subtext">
                            {pageSubHeadingForAdd}
                        </div>
                        <div className="edit-close">
                            <Image src={times} onClick={goBack} />
                        </div>
                    </div>
                    {!isEmpty(record) && (
                        <div className="edit-form">
                            <Form
                                autoComplete="off"
                                onSubmit={handleSubmit(onSubmit)}
                                unstackable
                            >
                                <Form.Field width={15}>
                                    <Input
                                        name="title"
                                        label={form?.title?.label}
                                        placeholder={form?.title?.placeholder}
                                        widths={12}
                                        value={record?.title}
                                        count
                                        refs={{ register }}
                                    />
                                </Form.Field>
                                <Form.Group>
                                    <Form.Field width={5}>
                                        <FileUpload
                                            label={form?.imgGenral?.label}
                                            limit={form?.imgGenral?.limit}
                                            placeholder={
                                                form?.imgGenral?.placeholder
                                            }
                                            name="genEditImage"
                                            optional={details.optional}
                                        />
                                    </Form.Field>
                                    <Form.Field width={5}>
                                        <FileUpload
                                            label={form?.videoGenral?.label}
                                            placeholder={
                                                form?.videoGenral?.placeholder
                                            }
                                            limit={form?.videoGenral?.limit}
                                            name="genEditVid"
                                            optional={details.optional}
                                            video
                                        />
                                    </Form.Field>
                                </Form.Group>
                                <Form.Group>
                                    <Form.Field width={15}>
                                        <TextArea
                                            name="desc"
                                            label={form?.desc?.label}
                                            rows={6}
                                            placeholder={
                                                form?.desc?.placeholder
                                            }
                                            refs={{ register, setValue }}
                                            editor
                                            count
                                            preview
                                            content={{
                                                desc:
                                                    desc ?? record?.description,
                                                title: title ?? record?.title,
                                                imageUrl: genEditImageUrl,
                                                videoUrl: genEditVidUrl,
                                            }}
                                            value={record?.description}
                                        />
                                    </Form.Field>
                                </Form.Group>
                                <Button
                                    disabled={validateForm()}
                                    className="add-action-button"
                                    type="submit"
                                >
                                    {form?.actionLableForEdit}
                                </Button>
                                <Button
                                    className="add-action-button outline"
                                    onClick={handelDel}
                                    type="button"
                                >
                                    {form?.actionLableForDelete}
                                </Button>
                            </Form>
                        </div>
                    )}
                </div>
            </Segment>
        </Container>
    );
};

export { GeneralEdit };
