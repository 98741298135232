import React, { Component } from 'react';
import { Grid, Card, Dropdown, Button, Icon, Loader } from 'semantic-ui-react';
import { StickyButton, Search, Image, Pagination } from '_components';
import { lang } from '_config';
import { edit, PlaceholderImg } from 'images';
import './NutritionalTab.scss';
import { isArray } from 'lodash';

const { details, common } = lang.nutritionalTips;
const { foodTable, sortBy, categories, all } = common;
const getFormatedFoodList = (list) => {
    let updatedList = [];
    updatedList = list.map((item, index) => {
        let obj = {
            id: item?.id,
            foodName: item?.name,
            imgUrl: item?.image,
            subCategory: getTranslatedSubCat(item?.foodSubCategory.name),
            items: [
                {
                    name: foodTable[0].name,
                    value: item?.energy ? `${item?.energy} Kcal` : '-',
                },
                {
                    name: foodTable[1].name,
                    value: item?.protein ? `${item?.protein} gr` : '-',
                },
                {
                    name: foodTable[2].name,
                    value: item?.lipids ? `${item.lipids} gr` : '-',
                },
                {
                    name: foodTable[3].name,
                    value: item?.carbohydrates
                        ? `${item.carbohydrates} gr`
                        : '-',
                },
                {
                    name: foodTable[4].name,
                    value: item?.fiber ? `${item.fiber} gr` : '-',
                },
                {
                    name: foodTable[5].name,
                    value: item?.water ? `${item.water} ml` : '-',
                },
                {
                    name: foodTable[6].name,
                    value: item?.calcium ? `${item.calcium} mg` : '-',
                },
                {
                    name: foodTable[7].name,
                    value: item?.phosphorus ? `${item.phosphorus} mg` : '-',
                },
                {
                    name: foodTable[8].name,
                    value: item?.potassium ? `${item.potassium} mg` : '-',
                },
                {
                    name: foodTable[9].name,
                    value: item?.sodium ? `${item.sodium} mg` : '-',
                },
            ],
        };
        return obj;
    });
    return updatedList;
};

const getTranslatedSubCat = (nameKey) => {
    let subCatName = '';
    categories.forEach((item) => {
        item.subCategory.forEach((it) => {
            if (it.name === nameKey) {
                subCatName = it.desc;
            }
        });
    });
    return subCatName;
};

const getMatchData = (categoryList) => {
    let finalArr = [];
    categoryList.forEach((item, index) => {
        categories.forEach((it) => {
            if (it.name === item.name) {
                finalArr.push({
                    key: index + 1,
                    value: it.name,
                    text: it.desc,
                });
            }
        });
    });
    finalArr.unshift({ key: 0, value: all, text: all });
    return finalArr;
};

class NutritionalTab extends Component {
    constructor(props) {
        super(props);
        this.cref = React.createRef();
        this.cref.current = 'TipsPage';
        this.state = {
            selectedCategory: [],
            params: {
                category: [],
                pageNumber: 0,
                direction: '',
                orderBy: '',
                search: '',
                exactSearch: false,
            },
        };
    }

    componentDidMount() {
        this.props.actions.getFoodCategories();
        this.props.actions.getFoodList(this.state.params);
    }

    addRecords = () => {
        this.props.history.push({
            pathname: `/Tips/${this.props.activeTab.tab}/AddNutritionalFood`,
        });
    };

    navigateToNutriEdit = (i) => {
        this.props.history.push({
            pathname: `/Tips/${this.props.activeTab.tab}/AddNutritionalFood/${i}`,
        });
    };

    checkItemExists(index, finalCategories) {
        let exists = false;
        const { selectedCategory } = this.state;
        if (selectedCategory.length === finalCategories.length - 1) {
            exists = true;
        } else if (selectedCategory.length < finalCategories.length - 1) {
            selectedCategory.forEach((item, i) => {
                if (finalCategories[index].key === item.key) exists = true;
            });
        }
        return exists;
    }

    handleCategoryClick = (e, index, finalCategories) => {
        const { selectedCategory, params } = this.state;
        if (!this.checkItemExists(index, finalCategories)) {
            if (index === 0) {
                selectedCategory.length = 0;
            } else {
                selectedCategory.push(finalCategories[index]);
            }
            let arr = [];
            arr = selectedCategory.map((it) => {
                return it.value;
            });
            params.category = arr.join(',');
            params.pageNumber = 0;
            this.props.actions.getFoodList(params);
            if (index === 0) selectedCategory.push(finalCategories[index]);
            this.setState({
                ...this.state,
                selectedCategory,
                params: { ...params, category: arr, pageNumber: 0 },
            });
        }
    };

    removeCategory = (i) => {
        const { selectedCategory, params } = this.state;
        selectedCategory.splice(i, 1);
        let arr = [];
        arr = selectedCategory.map((it) => {
            return it.value;
        });
        params.category = arr.join(',');
        params.pageNumber = 0;
        this.props.actions.getFoodList(params);
        this.setState({
            ...this.state,
            selectedCategory,
            params: { ...params, category: arr, pageNumber: 0 },
        });
    };

    handleSort = (index) => {
        const { params: PR } = this.state;
        let params = {
            pageNumber: 0,
            direction: 'DESC',
            orderBy: '',
            search: '',
            exactSearch: false,
            category: isArray(PR?.category)
                ? PR?.category.join(',')
                : PR.category,
        };
        if (index === 0) {
            params.direction = 'ASC';
            params.orderBy = 'NAME';
        } else if (index === 1) {
            params.direction = 'DESC';
            params.orderBy = 'DATECREATED';
        } else if (index === 2) {
            params.direction = 'ASC';
            params.orderBy = 'DATECREATED';
        }

        this.setState({ ...this.state, params });
        this.props.actions.getFoodList(params);
    };

    onLoadMore = () => {
        const { params } = this.state;
        params.pageNumber = params.pageNumber + 1;
        this.setState({ ...this.state, params });
        try {
            params.category = params?.category.join(',');
        } catch (error) {
            params.category = params?.category;
        }
        this.props.actions.getFoodList(params);
    };

    renderCard = (item, i) => {
        return (
            <Grid-column key={i}>
                <Card className="food-cards">
                    <div
                        className="edit-circle"
                        onClick={() => this.navigateToNutriEdit(item.id)}
                    >
                        <Image className="food-edit" src={edit} />
                    </div>
                    <Image
                        className="food-img"
                        src={item.imgUrl ? item.imgUrl : PlaceholderImg}
                        wrapped
                        ui={false}
                    />
                    <Card.Content className="card-content">
                        <Card.Header className="food-card-header">
                            {item.foodName}
                        </Card.Header>
                        <Card.Meta>
                            <span className="card-content-subHeader">
                                {common?.valPerPortion}
                            </span>
                        </Card.Meta>
                        <Card.Description className="card-content-desc">
                            {item?.subCategory}
                        </Card.Description>
                        <Card.Description>
                            {item.items.map((obj, index) => {
                                return (
                                    <div className="items" key={index}>
                                        <div className="name">{obj.name}</div>
                                        <div className="measures">
                                            {obj.value}
                                        </div>
                                    </div>
                                );
                            })}
                        </Card.Description>
                    </Card.Content>
                </Card>
            </Grid-column>
        );
    };

    render() {
        const { selectedCategory } = this.state;
        const { config, state, actions } = this.props;
        const {
            foodCategory,
            foodList,
            loading,
        } = state?.tips?.tabs?.NUTRITIONAL;
        const catgoryList = foodCategory?.list;
        const finalCategories = catgoryList?.length
            ? getMatchData(catgoryList)
            : [];
        const formatedFoodList = foodList?.list?.length
            ? getFormatedFoodList(foodList.list)
            : [];
        const filters = { totalElements: foodList?.totalElements };
        const isDisable =
            foodList?.totalElements === foodList?.pageSize ? true : false;
        return (
            <div className="Nutritional-food">
                <div className="nutri-dropdowns">
                    <div className="actions">
                        <Search
                            config={config}
                            params={this.state.params}
                            actionList={actions?.getFoodList}
                            width="full"
                        />
                    </div>
                    <Dropdown
                        text={details?.sortBy}
                        icon={<Icon name="angle down" />}
                    >
                        <Dropdown.Menu>
                            {sortBy.map((item, index) => {
                                return (
                                    <Dropdown.Item
                                        key={index}
                                        text={item}
                                        onClick={(e) => {
                                            this.handleSort(index);
                                        }}
                                    />
                                );
                            })}
                        </Dropdown.Menu>
                    </Dropdown>
                    &nbsp;&nbsp;&nbsp;
                    <Dropdown
                        icon={<Icon name="angle down" />}
                        text={details?.category}
                    >
                        <Dropdown.Menu>
                            {finalCategories.map((item, index) => {
                                return (
                                    <Dropdown.Item
                                        key={index}
                                        text={item.text}
                                        onClick={(e) =>
                                            this.handleCategoryClick(
                                                e,
                                                index,
                                                finalCategories
                                            )
                                        }
                                    />
                                );
                            })}
                        </Dropdown.Menu>
                    </Dropdown>
                    <div className="btn-tiles">
                        {selectedCategory.map((item, i) => {
                            return (
                                <Button icon labelPosition="right" key={i}>
                                    <Icon
                                        name="times"
                                        onClick={() => this.removeCategory(i)}
                                    />
                                    {item.text}
                                </Button>
                            );
                        })}
                    </div>
                </div>
                {loading ? (
                    <Loader active inline="centered" />
                ) : (
                    <div className="food-list-section">
                        <div>
                            <Grid className="foods-section" columns={4}>
                                {formatedFoodList.map((item, index) => {
                                    return this.renderCard(item, index);
                                })}
                            </Grid>
                            <StickyButton onClick={() => this.addRecords()} />
                        </div>
                        <div>
                            <Pagination
                                cref={this.cref}
                                filters={filters}
                                isLoading={false}
                                isDisable={isDisable}
                                records={formatedFoodList?.length}
                                config={config}
                                onLoadMore={this.onLoadMore}
                            />
                        </div>
                    </div>
                )}
            </div>
        );
    }
}

export { NutritionalTab };
