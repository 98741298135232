/* eslint-disable react/jsx-no-bind */
/* eslint-disable react/destructuring-assignment */
import React, { useEffect } from 'react';
import { Button, Form, Grid, Icon } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { isEmpty } from 'lodash';
import {
    PublicLayout as Layout,
    Image,
    Message,
    Notification,
    Input,
} from '_components';
import { LoginBanner, ketoLogo, Logo } from 'images';
import { validateEmail } from '_utils';
import { lang } from '_config';
import './ForgotPage.scss';
const ForgotPage = (props) => {
    const { forgot: configLogin } = lang,
        { userField, buttonField, msg } = configLogin,
        { register, handleSubmit, watch } = useForm(),
        watchAllFields = watch(['userEmail']),
        validation = {
            required: true,
            maxLength: 60,
            minLength: 4,
        },
        frmData = {
            fields: [
                {
                    lable: userField?.lableName,
                    placeHolder: userField?.placeHolder,
                    validation: register({ ...validation }),
                    className: 'ui form-email',
                    type: 'text',
                    name: 'userEmail',
                },
            ],
        },
        {
            actions: { reqForgotPwd, msgClear },
            state: { forgotData, alertData },
        } = props,
        validateForm = () => {
            let result = true;
            const { userEmail } = watchAllFields;
            const { maxLength, minLength } = validation;
            if (userEmail) {
                result =
                    userEmail.length <= maxLength &&
                    userEmail.length >= minLength;
                result = result ? validateEmail(userEmail) : result;
            } else result = false;
            return result;
        },
        onSubmit = (data) => {
            reqForgotPwd(data);
        };
    useEffect(() => {
        return () => {
            msgClear();
        };
    }, [msgClear]);
    const Notifi = () => {
        if (alertData?.type === 'alert-success') {
            const myMsg = msg.success.replace(
                /#{email}/g,
                watchAllFields?.userEmail
            );
            return <Notification text={myMsg} className="success" />;
        }
    };
    return (
        <Layout {...props} className="forgot-page" header="Login Page">
            <Grid className="ForgotPage-grid">
                <Grid.Row>
                    <Grid.Column
                        width={8}
                        className="col-image"
                        only="computer"
                    >
                        <div className="image-container">
                            <Link to="/login">
                                <Icon
                                    circular
                                    name="arrow left"
                                    size="huge"
                                    className="back-icon"
                                />
                            </Link>
                            <Image src={LoginBanner} fluid />
                        </div>
                    </Grid.Column>
                    <Grid.Column width={8} className="col-form">
                        <div className="image-container">
                            <Image src={Logo} fluid />
                        </div>
                        <div className="form-container">
                            <div className="form-notification">{Notifi()} </div>
                            <div className="form-header">
                                <Image
                                    src={ketoLogo}
                                    className="form-header--logo"
                                />
                            </div>
                            <div className="form-content">
                                <h2 className="header">
                                    {configLogin?.text?.headingText}
                                </h2>
                                <div className="form-header-sub">
                                    {configLogin?.text?.subHeadingText}
                                </div>
                                <Form onSubmit={handleSubmit(onSubmit)}>
                                    {!isEmpty(alertData) &&
                                        alertData?.type === 'alert-danger' && (
                                            <Message
                                                alertData={alertData}
                                                loginData={forgotData}
                                            />
                                        )}
                                    {frmData.fields.map((field, i) => (
                                        <Form.Field key={i}>
                                            <Input
                                                type={field.type}
                                                label={field.lable}
                                                name={field.name}
                                                placeholder={field.placeHolder}
                                                refs={field.validation}
                                            />
                                        </Form.Field>
                                    ))}
                                    <Button
                                        loading={forgotData.loading}
                                        type="submit"
                                        className="form-button"
                                        disabled={!validateForm()}
                                    >
                                        {buttonField.lableName}
                                    </Button>
                                </Form>
                            </div>
                        </div>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </Layout>
    );
};
export { ForgotPage };
