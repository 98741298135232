import React, { useState } from 'react';
import { Grid, Icon } from 'semantic-ui-react';
import { PatientsAssigned } from '_components';
const ActionButton = (props) => {
    const {
        config,
        width,
        history,
        record,
        patientList,
        getPatientList,
    } = props;
    const { loading, result } = patientList;
    const [activeIndex, setIndex] = useState(false);
    const goToEdit = () => {
        history.push({
            pathname: `/Nutritionists/edit/${record?.id}`,
            state: { record },
        });
    };
    const getAssinedList = () => {
        if (!loading) getPatientList({ id: record.id });
        setIndex(!activeIndex);
    };
    return (
        <React.Fragment>
            <Grid.Column
                className={activeIndex ? 'cell-data-open' : 'cell-data-close'}
                width={width}
                textAlign="center"
            >
                <div
                    className={`text-button ${loading ? 'disable' : ''}`}
                    onClick={getAssinedList}
                >
                    {!activeIndex ? (
                        <Icon
                            name="angle down"
                            onClick={() => setIndex(!activeIndex)}
                        />
                    ) : (
                        <div className="text-link" onClick={goToEdit}>
                            {config?.actionLable}
                        </div>
                    )}
                </div>
            </Grid.Column>

            <PatientsAssigned
                active={activeIndex}
                setActive={setIndex}
                config={config}
                loading={loading}
                result={result}
            />
        </React.Fragment>
    );
};

export { ActionButton };
