/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-filename-extension */
import React, { useEffect } from 'react';
import { Container } from 'semantic-ui-react';
import './PublicLayout.scss';
export const PublicLayout = props => {
    useEffect(() => {
        document.title = props?.title ?? 'Welcome to login page';
    }, [props]);
    return (
        <Container className={`${props?.className}`} fluid>
            {props.children}
        </Container>
    );
};
