/* eslint-disable no-unsafe-finally */
/* eslint-disable react/no-multi-comp */
import React, { useState, useRef, useMemo, useEffect } from 'react';
import { Segment, Container } from 'semantic-ui-react';
import { lang } from '_config';
import { map, isEmpty } from 'lodash';
import { Redirect } from 'react-router-dom';
import {
    BreadcrumbComp,
    Search,
    GridList,
    ButtonGroupComp,
    TicketValidateModal,
} from '_components';
import { getRoles, isAfflido, getUserType } from '_utils';
import ActionButton from './ActionButton';
import './PatientsListPage.scss';
const PatientsListPage = (props) => {
    const cref = useRef('PatientsListPage');
    const {
        actions: {
            getList,
            logOut,
            getTicketDetails,
            updateTicket,
            closeModal,
            getPharmacyList,
        },
        state: {
            patientList: {
                loading,
                result: { list, ...filters },
                params,
                ticketDetails,
                isModalOpen,
                isModalLoad,
            },
            alertData: { message },
            userDetails: { roles },
            pharmaList,
        },
        history,
    } = props;
    const isAdmin = roles === 'Admin';
    const { details } = lang?.patients.filter((item) => {
        return item.userType === roles && item;
    })[0];
    const sections = [
        {
            key: 'myPatients',
            content: details?.pageHeading ?? 'My Patients',
            active: true,
        },
    ];
    const statusPending = ({ status, role }) => {
        return !status && role === 'CEAN'
            ? '<i aria-hidden="true" class="circle yellow link icon"></i>'
            : '';
    };
    const filterList = useMemo(() => {
        return map(list, (record) =>
            !isAdmin
                ? {
                      NAME: `${record?.firstName} ${record?.lastName}<p>${record?.id}</p>`,
                      id: record?.id,
                      nutritionalRecordId: record?.nutritionalRecordId || '--',
                      Contact: record?.phoneNumber,
                      Email: record?.email || '--',
                  }
                : {
                      NAME: `${record?.firstName} ${record?.lastName}<p>${record?.id}</p>`,
                      id: record?.id,
                      nutritionalRecordId: record?.nutritionalRecordId || '--',
                      usertype: `${getRoles(record?.roles, details?.roleDetails)} ${statusPending({
                          status: record?.tcAccepted,
                          role: getRoles(record?.roles, details?.roleDetails)[0],
                      })}`,
                      upgradeStatus: record?.upgradeStatus,
                      TICKET_PENDING: '',
                      pendingTicket: record?.upgradePendingTicket,
                      Contact: record?.phoneNumber,
                      Email: record?.email || '--',
                      role: getRoles(record?.roles, details?.roleDetails)[0] || false,
                  }
        );
    }, [list, isAdmin, details]);
    const notAuthenticate = () => {
        try {
            logOut();
        } finally {
            return (
                <Redirect
                    to={{
                        pathname: '/Login',
                    }}
                />
            );
        }
    };
    const listData = {
        sorting: !isAdmin
        ? [
              { direction: 'DESC' },
              { direction: 'DESC' },
              false,
              false,
              false,
          ]
        : [
              { direction: 'DESC' },
              { direction: 'DESC' },
              { direction: 'DESC' },
              false,
              { direction: 'DESC' },
              false,
          ],
        cellWidth: !isAdmin ? [3, 3, 3, 5] : [3, 2, 3, 3, 3],
        columns: !isAdmin
            ? ['NAME', 'nutritionalRecordId', 'Contact', 'Email']
            : [
                  'NAME',
                  'nutritionalRecordId',
                  'usertype',
                  'Contact',
                  'UPGRADE_TICKET_PENDING',
              ],
        isHtml: [true, false, true, false, true],
        actionList: getList,
        pageData: filterList ?? [],
        loading,
        filters,
        params,
        cref,
        roles,
        // sortHeading: isAdmin ? 'TICKET_PENDING' : false,
        isDisable: filters.totalElements <= filterList.length,
    };
    let activeUserType = params?.activeUserType ?? 'Todos';
    const buttonItems = details?.type.map((item, index) => ({
        label: item.label,
        value: item.value,
        active: !index,
    }));
    const [items, updateItems] = useState(buttonItems);
    const handleBtnClick = (index) => {
        updateItems(
            items.map((obj, i) => {
                if (index === i) {
                    obj.active = true;
                    activeUserType = obj.value;
                } else {
                    obj.active = false;
                }
                return obj;
            })
        );
        getList({
            ...params,
            filter: getUserType(activeUserType),
            activeUserType,
            pageNumber: 0,
        });
    };
    useEffect(() => {
        if (isEmpty(params)) getList(params);
        isEmpty(pharmaList) && getPharmacyList();
    }, [getList, params, getPharmacyList]);
    const MemoTicketValidateModal = useMemo(() => {
        const pharmacyList = map(pharmaList, 'place');
        return (
            <TicketValidateModal
                button={() => <></>}
                config={details?.modal}
                ticketDetails={{ ...ticketDetails, visiblePharmacy: false }}
                updateTicket={updateTicket}
                closeModal={closeModal}
                curOptions={{
                    modalOpen: isModalOpen,
                    loading: isModalLoad,
                }}
                pharmacyList={pharmacyList}
            />
        );
    }, [
        closeModal,
        details,
        isModalLoad,
        isModalOpen,
        ticketDetails,
        updateTicket,
        pharmaList,
    ]);
    return (
        <Container
            cref={cref}
            fluid
            id="PatientsListPage"
            className={`private-layout`}
        >
            {message?.errNo === 403 && notAuthenticate()}
            <Segment basic>
                <BreadcrumbComp sections={sections} />
                <div className="actions">
                    {isAdmin && (
                        <ButtonGroupComp
                            items={items}
                            loading={loading}
                            handleBtnClick={handleBtnClick}
                        />
                    )}
                    <Search
                        config={details}
                        params={listData.params}
                        actionList={listData.actionList}
                        filters={{}}
                        width={!isAdmin ? 'full' : ''}
                    />
                    <div className="actions-empty-block" />
                </div>
                <GridList
                    history={history}
                    config={details}
                    listData={listData}
                >
                    {(id, data) => (
                        <ActionButton
                            key={id}
                            record={{ id, ...data }}
                            details={details}
                            history={history}
                            isAdmin={isAdmin}
                            getTicketDetails={getTicketDetails}
                            isAfflido={isAfflido(
                                data?.role,
                                data?.pendingTicket
                            )}
                        />
                    )}
                </GridList>
                {MemoTicketValidateModal}
            </Segment>
        </Container>
    );
};

export { PatientsListPage };
