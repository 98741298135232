import { fetchApi, Apis, handleResponse, handelError } from './services';
export const userService = {
    login,
    logout,
    forgot,
    reset,
};
function reset(userDetails) {
    const { id, jwt, ...rest } = userDetails;
    const requestOptions = {
        url: `/sign/reset/bo/${id}`,
        method: 'POST',
        data: {
            ...rest,
            token: jwt,
        },
    };
    return fetchApi(requestOptions)
        .then(handleResponse)
        .then((data) => {
            return { status: true, msg: data };
        })
        .catch(handelError);
}
function forgot(userDetails) {
    const requestOptions = {
        url: `/sign/forgot/bo`,
        method: 'POST',
        data: {
            email: userDetails.userEmail,
        },
    };
    return fetchApi(requestOptions)
        .then(handleResponse)
        .then((data) => {
            return { status: true, msg: data };
        })
        .catch((e) => {
            const obj = { status: false, msg: '' };
            switch (e.message) {
                case 'Network Error':
                case 'timeout of 2000ms exceeded':
                    obj.msg = { errMsg: 'Network Error, Please try again.' };
                    break;
                default:
                    obj.msg = e;
                    break;
            }
            return obj;
        });
}
function login(userDetails) {
    const requestOptions = {
        url: `/session/login/bo`,
        method: 'POST',
        data: {
            username: userDetails.userEmail,
            password: userDetails.password,
        },
    };
    return fetchApi(requestOptions)
        .then(handleResponse)
        .then((user) => {
            return user?.token
                ? { ...user, status: true }
                : { status: false, msg: user };
            // if (isUser !== undefined)
            // localStorage.setItem('user', JSON.stringify(isUser));
        })
        .catch(handelError);
}

function logout() {
    // remove user from local storage to log user out
    return Apis(true)({ url: '/session/logout/bo', method: 'GET' })
        .then(handleResponse)
        .catch(handelError);
}
