import PROMO from '_constants';
import { INIT } from '_config/initialState';
const { promoList: initialState } = INIT;

export default (state = initialState, action = {}) => {
    const { type, result, params, failure, tabName, userData,modalData } = action;
    switch (type) {
        case PROMO.PROMO_REQUEST_START:
            return { ...state, loading: true };
        case PROMO.PROMO_CURRENT_REQUEST_START:
            return {
                ...state,
                boxLoading: true,
                tabs: {
                    ...state.tabs,
                    DEFAULT: { ...state.tabs.DEFAULT, boxes: {} },
                },
            };
        case PROMO.PROMO_REQUEST_END:
            return { ...state, loading: false };
        case PROMO.PROMO_CURRENT_REQUEST_END:
            return { ...state, boxLoading: false };
        case PROMO.PROMO_MODAL_OPEN:
            return { ...state, isModalOpen: true,modalData };
        case PROMO.PROMO_MODAL_CLOSE:
            return { ...state, isModalOpen: false,modalData:{} };
        case PROMO.PROMO_MODAL_LOADED:
            return { ...state, isModalLoad: false };
        case PROMO.PROMO_MODAL_LOADING:
            return { ...state, isModalLoad: true };
        case PROMO.PROMO_EDIT:
            return {
                ...state,
                tabs: {
                    ...state.tabs,
                    [tabName]: { ...state.tabs[tabName], selectedId: result },
                },
            };
        case PROMO.PROMO_PHARMA_SUCCESS:
            return {
                ...state,
                tabs: {
                    ...state.tabs,
                    [tabName]: { ...state.tabs[tabName], pharmaList: result },
                },
            };
        case PROMO.PROMO_BOXES_SUCCESS:
            return {
                ...state,
                tabs: {
                    ...state.tabs,
                    [tabName]: { ...state.tabs[tabName], boxes: result },
                },
            };
        case PROMO.PROMO_ADD:
        case PROMO.PROMO_UP:
        case PROMO.PROMO_DEL:
            return {
                ...state,
                tabs: {
                    ...state.tabs,
                    [tabName]: {
                        ...state.tabs[tabName],
                        failure: null,
                    },
                },
            };
        case PROMO.PROMO_RECORD_LIST_SUCCESS: {
            const activeTab = state.tabs[tabName];
            const { list, ...rest } = result;
            return {
                ...state,
                tabs: {
                    ...state.tabs,
                    [tabName]: {
                        ...activeTab,
                        result: {
                            ...activeTab.result,
                            receipts: params?.pageNumber
                                ? [...activeTab.result.receipts, ...list]
                                : list,
                            recordFilter: rest,
                            recordParams: params,
                            userData,
                        },
                    },
                },
            };
        }
        case PROMO.PROMO_LIST_SUCCESS: {
            const activeTab = state.tabs[tabName];
            const { list, ...filters } = result;
            const listData = list.map((item) => ({
                ...item,
                discount: true,
                edit: true,
            }));
            const setResult = () => {
                if (tabName === 'DEFAULT') {
                    return params?.pageNumber
                        ? [...activeTab.result.list, ...listData]
                        : listData;
                } else {
                    const currCountry = process.env.REACT_APP_LANGUAGE;
                    if (['es-MX', 'en', 'es-EC'].includes(currCountry)) {
                        return params?.pageNumber
                            ? [...activeTab.result.list, ...listData]
                            : [...[activeTab.result.list[0]], ...listData];
                    }
                    if (params?.pageNumber) {
                        return [...activeTab.result.list, ...listData]
                    } else {
                        return [...listData];
                    }
                }
            };
            return {
                ...state,
                tabs: {
                    ...state.tabs,
                    [tabName]: {
                        ...activeTab,
                        result: {
                            ...activeTab.result,
                            ...filters,
                            list: setResult(),
                        },
                        params: params,
                        failure: null,
                    },
                },
            };
        }
        case PROMO.PROMO_RECORD_LIST_FAILURE:
            return {
                ...state,
                tabs: {
                    ...state.tabs,
                    [tabName]: {
                        ...state.tabs[tabName],
                        failure,
                        recordParams: {},
                    },
                },
            };
        case PROMO.PROMO_ADD_FAILURE:
        case PROMO.PROMO_EDIT_FAILURE:
        case PROMO.PROMO_DEL_FAILURE:
        case PROMO.PROMO_UP_FAILURE: {
            return {
                ...state,
                tabs: {
                    ...state.tabs,
                    [tabName]: { ...state.tabs[tabName], failure },
                },
            };
        }
        case PROMO.PROMO_LIST_FAILURE:
            return { ...state, failure };
        default:
            return { ...state };
    }
};
export const promoDetails = (state) => state.promoList;
