import React, { useState, useEffect } from 'react';
import { Icon, Button, Form } from 'semantic-ui-react';
import { BreadcrumbComp, Select, Input } from '_components';
import { useForm } from 'react-hook-form';
import { validMinMaxInput, validateEmail, validateContact } from '_utils';
import { isEmpty, map, find } from 'lodash';
const AdminAdd = (props) => {
    const [isAssign, setAssign] = useState(false);
    // GET ALL VALUE FROM PROPS
    const {
        config: {
            pageHeading,
            details,
            details: { form },
        },
        state: {
            adminList: { centerList, loading, success },
        },
        actions: { getCenterList, createAdmin, clearFailure },
        goBack,
    } = props;
    // GET ALL VALUE FROM CENTERLIST
    const { result: centers, loading: centerLoading } = centerList;
    // update centerList format
    const centerData = map(centers, ({ id, centerName }) => ({
        key: id,
        value: id,
        text: centerName,
    }));
    // set the breadcrumb values
    const sections = [
        {
            key: 'myAdminList',
            content: pageHeading,
            onClick: goBack,
        },
        {
            key: 'myAdminAddPage',
            content: details?.breadcrumbForAdd ?? 'Add',
            active: true,
        },
    ];
    // get values form useForm
    const { register, handleSubmit, watch, errors } = useForm({
        mode: 'onChange',
    });
    const watchAllFields = watch([
        'email',
        'contact',
        'firsName',
        'lastName',
        'pwd',
        'cpwd',
    ]);
    const { email, contact, firsName, lastName, pwd, cpwd } = watchAllFields;
    // Get label of center list
    const getLabel = () => {
        try {
            const { text } = find(centerData, { value: isAssign });
            return text;
        } catch (e) {
            return undefined;
        }
    };
    // submition the form
    const onSubmit = (data) => {
        createAdmin({
            centerName: getLabel(),
            confirmPassword: data?.cpwd || undefined,
            email: data?.email,
            firstName: data?.firsName,
            lastName: data?.lastName,
            password: data?.pwd || undefined,
            phoneNumber: data?.contact,
        });
    };
    // if record updated return to previous page
    if (success) {
        setTimeout(() => goBack(true), 200);
    }
    // update center value
    const onChange = (_, data) => {
        setAssign(data.value);
    };
    // enable/disable action button
    const validateForm = () => {
        return !validMinMaxInput(email) &&
            validateEmail(email) &&
            !validMinMaxInput(firsName, 3, 30) &&
            !validMinMaxInput(lastName, 3, 30) &&
            !validMinMaxInput(pwd, 8, 15) &&
            !validMinMaxInput(cpwd, 8, 15) &&
            (contact.length
                ? !validMinMaxInput(contact, 8, 15) && validateContact(contact)
                : true) &&
            pwd === cpwd &&
            isEmpty(errors)
            ? false
            : true;
    };
    // eslint-disable-next-line react/no-multi-comp
    const showErrors = (_, value) => {
        switch (_?.type) {
            case 'required':
                return <div className="err-msg">{value?.required}</div>;
            case 'minLength':
                return <div className="err-msg">{value?.minLength}</div>;
            case 'pattern':
                return <div className="err-msg">{value?.pattern}</div>;
            default:
                break;
        }
        return !isEmpty(pwd) && !isEmpty(cpwd) && pwd !== cpwd ? (
            <div className="err-msg">{value?.notMatched}</div>
        ) : (
            ''
        );
    };
    // if centerList is not availble get the centerList
    useEffect(() => {
        clearFailure();
        if (isEmpty(centers) && !centerLoading) {
            getCenterList();
        }
        if (success === true) clearFailure(true);
    }, [getCenterList, clearFailure, success]);
    return (
        <>
            <BreadcrumbComp sections={sections} />
            <div className="add-container">
                <div className="add-heading">
                    <div className="add-heading-text">
                        {details?.pageHeading}
                    </div>
                    <div className="add-heading-subtext">
                        {details?.pageSubHeadingForAdd}
                    </div>
                    <div className="add-close">
                        <Icon name="close" size="mini" onClick={goBack} />
                    </div>
                </div>
                <div className="add-form">
                    <Form
                        onSubmit={handleSubmit(onSubmit)}
                        unstackable
                        loading={loading}
                        autoComplete="off"
                    >
                        <Form.Group>
                            <Form.Field width={5} className="error-field">
                                <Input
                                    name="email"
                                    label={form?.email?.label}
                                    placeholder={form?.email?.placeholder}
                                    refs={{
                                        register,
                                        validation: {
                                            maxLength: 55,
                                            minLength: 4,
                                        },
                                    }}
                                />
                                {/* {showErrors(errors.email, form?.email)} */}
                            </Form.Field>
                            <Form.Field width={5}>
                                <Input
                                    name="contact"
                                    label={form?.contact?.label}
                                    placeholder={form?.contact?.placeholder}
                                    optional={details?.optional}
                                    refs={{
                                        register,
                                        validation: {
                                            pattern: /^[+-]?\d+$/,
                                            maxLength: 15,
                                            minLength: 8,
                                        },
                                    }}
                                />
                            </Form.Field>
                        </Form.Group>
                        <Form.Group>
                            <Form.Field width={5}>
                                <Input
                                    name="firsName"
                                    label={form?.name?.label}
                                    placeholder={form?.name?.placeholder}
                                    refs={{
                                        register,
                                        validation: {
                                            maxLength: 30,
                                            minLength: 3,
                                        },
                                    }}
                                />
                            </Form.Field>
                            <Form.Field width={5}>
                                <Input
                                    name="lastName"
                                    label={form?.surname?.label}
                                    placeholder={form?.surname?.placeholder}
                                    refs={{
                                        register,
                                        validation: {
                                            maxLength: 30,
                                            minLength: 3,
                                        },
                                    }}
                                />
                            </Form.Field>
                            <Form.Field width={5}>
                                <Select
                                    name="assign"
                                    label={form?.center?.label}
                                    placeholder={form?.center?.placeholder}
                                    options={centerData}
                                    loading={centerLoading}
                                    onChange={onChange}
                                    fluid
                                    optional={details?.optional}
                                />
                            </Form.Field>
                        </Form.Group>
                        <Form.Group>
                            <Form.Field width={5} className="error-field">
                                <Input
                                    name="pwd"
                                    label={form?.password?.label}
                                    placeholder={form?.password?.placeholder}
                                    refs={{
                                        register,
                                        validation: {
                                            maxLength: 15,
                                            minLength: 8,
                                        },
                                    }}
                                    autoComplete="new-password"
                                    type="password"
                                />
                                {showErrors(errors.pwd, form?.password)}
                            </Form.Field>
                            <Form.Field width={5} className="error-field">
                                <Input
                                    name="cpwd"
                                    label={form?.repassword?.label}
                                    placeholder={form?.repassword?.placeholder}
                                    refs={{
                                        register,
                                        validation: {
                                            maxLength: 15,
                                            minLength: 8,
                                        },
                                    }}
                                    type="password"
                                />
                                {showErrors(errors.cpwd, form?.repassword)}
                            </Form.Field>
                        </Form.Group>
                        <Form.Group>
                            <Form.Field>
                                <Button
                                    disabled={validateForm()}
                                    className="add-action-button"
                                    type="submit"
                                >
                                    {form?.actionLableForAdd}
                                </Button>
                            </Form.Field>
                        </Form.Group>
                    </Form>
                </div>
            </div>
        </>
    );
};

export { AdminAdd };
