import { batch } from 'react-redux';
import NUTRI from '_constants';
import { nutriService } from '_services';
import { isEmpty } from 'lodash';
import { lang } from '_config';
import { getErrorMsg } from '_utils';
const {
    menuList: {
        messages,
        error: { nutri },
    },
} = lang;
// FOR ASSIGNED PATIENT LIST
const getPatientList = (_) => {
    return (dispatch, getState) => {
        const { alertData } = getState();
        const onError = (error) =>
            batch(() => {
                dispatch({
                    type: NUTRI.NUTRI_PATIENT_FAILURE,
                    faliure: error ?? null,
                });
                dispatch({ type: NUTRI.ERROR, message: error });
                setTimeout(
                    () => dispatch({ type: NUTRI.CLEAR }),
                    NUTRI.TIMEOUT
                );
            });
        // START REQUEST SETUP
        batch(() => {
            dispatch({ type: NUTRI.NUTRI_PATIENT_REQUEST_START });
            if (!isEmpty(alertData)) dispatch({ type: NUTRI.CLEAR });
        });
        // REQUEST START
        nutriService
            .getPatientList(_)
            .then((result) => {
                // REQUEST SUCCESS
                if (result && result?.status) {
                    dispatch({
                        type: NUTRI.NUTRI_PATIENT_SUCCESS,
                        result: { ...result.data },
                    });
                } else {
                    // REQUEST FAILURE WITH BAD PARAMS
                    onError(result);
                }
            })
            // HANDEL ANY WEIRED BEHAVIOUR(LIKE NETWORK OR ANY OTHER)
            .catch(onError)
            .finally(() => {
                // REQUEST ENDED
                dispatch({ type: NUTRI.NUTRI_PATIENT_REQUEST_END });
            });
    };
};
// FOR NUTRI LIST
const getAll = (params) => {
    const queryParams = {
        pageNumber: params?.pageNumber ?? 0,
        direction: params?.direction ?? 'DESC',
        orderBy: params?.orderBy ?? 'NAME',
        search: params?.search ?? '',
    };
    return (dispatch, getState) => {
        const { alertData } = getState();
        const onError = (error) =>
            batch(() => {
                dispatch({
                    type: NUTRI.NUTRI_LIST_FAILURE,
                    error,
                });
                dispatch({ type: NUTRI.ERROR, message: error });
                setTimeout(
                    () => dispatch({ type: NUTRI.CLEAR }),
                    NUTRI.TIMEOUT
                );
            });
        batch(() => {
            dispatch({ type: NUTRI.NUTRI_REQUEST_START });
            if (!isEmpty(alertData)) dispatch({ type: NUTRI.CLEAR });
        });
        nutriService
            .getAll(queryParams)
            .then((result) => {
                if (result && result?.status) {
                    dispatch({
                        type: NUTRI.NUTRI_LIST_SUCCESS,
                        result,
                        params: queryParams,
                    });
                } else {
                    onError(result);
                }
            })
            .catch(onError)
            .finally(() => {
                dispatch({ type: NUTRI.NUTRI_REQUEST_END });
            });
    };
};

// FOR VIEW SELECTED NUTRI RECORD
const editNutri = (params) => {
    return (dispatch, getState) => {
        const { alertData, adminList } = getState();
        const onError = (error) =>
            batch(() => {
                dispatch({
                    type: NUTRI.ADMIN_EDIT_FAILURE,
                    error,
                });
                dispatch({ type: NUTRI.ERROR, message: error ?? null });
                setTimeout(
                    () => dispatch({ type: NUTRI.CLEAR }),
                    NUTRI.TIMEOUT
                );
            });
        if (!adminList.loading) {
            // REQUEST SETUP
            batch(() => {
                dispatch({ type: NUTRI.ADMIN_REQUEST_START });
                if (!isEmpty(alertData)) dispatch({ type: NUTRI.CLEAR });
            });
            // REQUEST START
            nutriService
                .editAdmin(params)
                .then((result) => {
                    // REQUEST SUCCESS
                    if (result && result?.status) {
                        dispatch({
                            type: NUTRI.ADMIN_SELECTED_ID,
                            result,
                        });
                    }
                    // REQUEST FAILURE WITH BAD PARAMS
                    else onError(result);
                })
                // HANDEL ANY WEIRED BEHAVIOUR(LIKE NETWORK OR ANY OTHER)
                .catch(onError)
                // REQUEST END
                .finally(() => dispatch({ type: NUTRI.ADMIN_REQUEST_END }));
        }
    };
};
// FOR UPDATE NUTRI RECORD
const updateNutri = (updateData) => {
    return (dispatch, getState) => {
        const { alertData } = getState();
        const onError = (error) =>
            batch(() => {
                // dispatch({
                //     type: NUTRI.NUTRI_PWD_FAILURE,
                //     error,
                // });
                dispatch({
                    type: NUTRI.ERROR,
                    message: getErrorMsg(error, nutri?.update) ?? null,
                });
                setTimeout(
                    () => dispatch({ type: NUTRI.CLEAR }),
                    NUTRI.TIMEOUT
                );
            });
        // START REQUEST SETUP
        batch(() => {
            dispatch({ type: NUTRI.NUTRI_PWD_REQ_START });
            if (!isEmpty(alertData)) dispatch({ type: NUTRI.CLEAR });
        });
        // REQUEST START
        nutriService
            .updateNutri(updateData)
            .then((res) => {
                // REQUEST SUCCESS
                if (res?.status) {
                    batch(() => {
                        dispatch({
                            type: NUTRI.SUCCESS,
                            message: {
                                suc:
                                    nutri?.update['200']?.msg ??
                                    messages?.update,
                            },
                        });
                        // dispatch({ type: NUTRI.NUTRI_PWD_SUCCESS });
                        setTimeout(() => {
                            batch(() => {
                                dispatch({ type: NUTRI.CLEAR });
                                dispatch(getAll({ pageNumber: 0 }));
                            });
                        }, NUTRI.TIMEOUT);
                    });
                }
                // REQUEST FAILURE WITH BAD PARAMS
                else onError(res);
            })
            // HANDEL ANY WEIRED BEHAVIOUR(LIKE NETWORK OR ANY OTHER)
            .catch(onError)
            .finally(() => {
                // REQUEST ENDED
                dispatch({ type: NUTRI.NUTRI_PWD_REQ_END });
            });
    };
};
// FOR CLEAR MESSAGE IF ANY ERROR OR SUCCES IS THERE
const clear = (input) => {
    return (dispatch, getState) => {
        const {
            adminList: { faliure, success },
        } = getState();
        if (!isEmpty(faliure)) dispatch({ type: NUTRI.ADMIN_CLEAR });
        if (success) dispatch({ type: NUTRI.ADMIN_CLEAR });
        if (input === true) dispatch({ type: NUTRI.ADMIN_CLEAR });
    };
};
// FOR NUTRITIONIST UPLOAD THROUGH CSV FOR CHILE AND ECUADOR
const uploadNutri = (file) => {
    return (dispatch) => {
        const uploadError = (error) => {
            batch(() => {
                dispatch({
                    type: NUTRI.NUTRI_UPLOAD_FAILURE,
                    uploadStatus: { ...error },
                });
                dispatch({ type: NUTRI.NUTRI_UPLOAD_ERROR, message: error });
                setTimeout(() => dispatch({ type: NUTRI.NUTRI_UPLOAD_CLEAR }), NUTRI.NUTRI_UPLOAD_TIMEOUT);
            });
        };
        batch(() => {
            dispatch({ type: NUTRI.NUTRI_UPLOAD_REQUEST_START });
            dispatch({ type: NUTRI.NUTRI_UPLOAD_CLEAR });
        });
        nutriService
            .nutriUpload(file)
            .then(
                (result) => {
                    if (result && result?.status) {
                        dispatch({
                            type: NUTRI.NUTRI_UPLOAD_SUCCESS,
                        });
                    } else {
                        uploadError(result);
                    }
                },
                (error) => {
                    uploadError(error);
                }
            )
            .catch((error) => {
                uploadError(error);
            })
            .finally(() => {
                dispatch({ type: NUTRI.NUTRI_UPLOAD_REQUEST_END });
            });
    };
};
const isUpload = () => {
    return (dispatch) => {
        dispatch({ type: NUTRI.NUTRI_UPLOAD_INIT });
    };
};
export const nutriAction = {
    getPatientList,
    getAll,
    editNutri,
    updateNutri,
    clear,
    uploadNutri,
    isUpload,
};
