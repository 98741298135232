import SEARCH from '_constants';
import { INIT } from '_config/initialState';
const { menuList: initialState } = INIT;

export default (state = initialState, { type, selected, isAdmin }) => {
    switch (type) {
        case SEARCH.MENU_ITEM_SELECTED:
            return { ...state, selected, isAdmin };
        default:
            return { ...state };
    }
};
export const menuDetails = (state) => state.menuList;
