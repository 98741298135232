/* eslint-disable react/no-multi-comp */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { isEmpty, trim } from 'lodash';
import { Grid } from 'semantic-ui-react';
import { ConfirmModal } from '_components';
const ActionButton = (props) => {
    const [isModalOpen, setModal] = useState(false);
    const {
        config,
        enrollPatient,
        config: { popup },
        record,
        recordUpating,
        success,
    } = props;
    const status = !isEmpty(trim(record?.phone)) ? !record?.ketoappUser : false;
    // open modal
    const handleOpen = () => {
        setModal(status);
    };
    // close modal
    const handleClose = () => {
        setModal(false);
    };
    // action button
    const upgragePatient = () => {
        if (isEmpty(success)) {
            handleOpen();
            enrollPatient(record);
        }
    };
    // render button
    const button = () => (
        <Grid.Column width={2}>
            <div
                className={`text-link ${!status ? 'disable' : ''}`}
                onClick={handleOpen}
            >
                {config.actionLable ?? 'Edit'}
            </div>
        </Grid.Column>
    );
    if (success !== undefined && isModalOpen === true) handleClose();
    return (
        <ConfirmModal
            config={popup}
            handleClose={handleClose}
            button={button}
            actionButton={upgragePatient}
            isModalOpen={isModalOpen}
            loading={recordUpating}
        />
    );
};

ActionButton.propTypes = {
    enrollPatient: PropTypes.func.isRequired,
    config: PropTypes.object.isRequired,
    success: PropTypes.oneOfType([PropTypes.bool.isRequired]),
};

export { ActionButton };
