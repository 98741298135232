import React from 'react';
import { Button } from 'semantic-ui-react';
import './ButtonGroup.scss';

const ButtonGroupComp = (props) => {
    const { items, handleBtnClick, loading } = props;
    return (
        <div className="button-group">
            <Button.Group>
                {items.map((obj, index) => {
                    const { label, active, value } = obj;
                    return (
                        <Button
                            key={index}
                            className={active ? 'active' : ''}
                            onClick={() => handleBtnClick(index)}
                            disabled={loading}
                        >
                            {label || value}
                        </Button>
                    );
                })}
            </Button.Group>
        </div>
    );
};

export { ButtonGroupComp };
