import React, { useState, useEffect } from 'react';
import { Segment, Container, Button, Form } from 'semantic-ui-react';
import { useForm } from 'react-hook-form';
import {
    BreadcrumbComp,
    Image,
    Input,
    TextArea,
    FileUpload,
    DateTimePicker,
    Select,
} from '_components';
import { lang } from '_config';
import {
    validMinMaxInput,
    validMinMaxTextArea,
    getFileDetail,
    navigatePage,
} from '_utils';
import { isEmpty, includes } from 'lodash';
import { times } from 'images';
import './CustomAdd.scss';
const CustomAdd = (props) => {
    const [isAssign, setAssign] = useState([]),
        {
            promotions: {
                details,
                details: { form },
            },
        } = lang,
        {
            activeTab,
            addPromo,
            config: { assignedList },
            actions: { clearFileDetails, getCustomList },
            state: { filesList, alertData },
        } = props,
        sections = [
            {
                key: 'myAdminListPage',
                content: activeTab.breadcrumbHeading,
                onClick: () =>
                    props.history.push({ pathname: '/Promotions/CUSTOM' }),
            },
            {
                key: 'myAdminEditPage',
                content: details?.breadcrumbForAdd,
                active: true,
            },
        ],
        customAddImgUrl = getFileDetail(filesList, 'customAddImage', 'url'),
        customAddVideo = getFileDetail(filesList, 'customAddVideo', 'url'),
        customUploadImg = getFileDetail(
            filesList,
            'customAddImage',
            'uploading',
            true
        ),
        customUploadVideo = getFileDetail(
            filesList,
            'customAddVideo',
            'uploading',
            true
        ),
        { register, handleSubmit, watch, errors } = useForm(),
        watchAllFields = watch(['title', 'desc', 'from', 'to']),
        validation = {
            AVAILABE: {
                required: true,
                maxLength: 100,
                minLength: 4,
            },
        },
        onChange = (_, data) => {
            setAssign(data.value);
        },
        validateForm = () => {
            const { title, desc, from, to } = watchAllFields;
            return !validMinMaxInput(title) &&
                !validMinMaxTextArea(desc, undefined, 2500) &&
                !isEmpty(from) &&
                !isEmpty(to) &&
                !customUploadImg &&
                !customUploadVideo &&
                !isEmpty(isAssign) &&
                isEmpty(errors)
                ? false
                : true;
        },
        onSubmit = async (data) => {
            if (isAssign) {
                const { title, desc, from, to } = data;
                const assignedRoles = includes(isAssign, 'TODO')
                    ? ['ROLE_UNAFFILIATED', 'ROLE_CEAN', 'ROLE_KETOSTERIL']
                    : isAssign;
                await addPromo({
                    title,
                    description: desc,
                    availableFrom: from,
                    availableTo: to,
                    tabName: activeTab.tab,
                    image: customAddImgUrl,
                    video: customAddVideo,
                    assignedTo: assignedRoles,
                });
            }
        };
    navigatePage(props, alertData, '/Promotions/CUSTOM', getCustomList);
    useEffect(() => {
        clearFileDetails();
    }, [clearFileDetails]);
    return (
        <Container fluid id="CustomAdd" className={`private-layout`}>
            <Segment basic>
                <BreadcrumbComp sections={sections} />
                <div className="add-container">
                    <div className="add-heading">
                        <div className="add-heading-text">
                            {details?.pageHeading}
                        </div>
                        <div className="add-heading-subtext">
                            {details?.pageSubHeadingForAdd}
                        </div>
                        <div className="add-close">
                            <Image
                                src={times}
                                onClick={() =>
                                    props.history.push({
                                        pathname: '/Promotions/CUSTOM',
                                    })
                                }
                            />
                        </div>
                    </div>
                    <div className="add-form">
                        <Form
                            autoComplete="off"
                            onSubmit={handleSubmit(onSubmit)}
                            unstackable
                        >
                            <Form.Field width={16}>
                                <Input
                                    name="title"
                                    label={form?.title?.label}
                                    placeholder={form?.title?.placeholder}
                                    widths={12}
                                    count
                                    refs={{ register }}
                                />
                            </Form.Field>
                            <Form.Group>
                                <Form.Field width={6}>
                                    <FileUpload
                                        label={form?.img?.label}
                                        placeholder={form?.img?.placeholder}
                                        limit={form?.img?.limit}
                                        optional={details.optional}
                                        name="customAddImage"
                                    />
                                </Form.Field>
                                <Form.Field width={6}>
                                    <FileUpload
                                        label={form?.vid?.label}
                                        placeholder={form?.vid?.placeholder}
                                        limit={form?.vid?.limit}
                                        optional={details.optional}
                                        video
                                        name="customAddVideo"
                                    />
                                </Form.Field>
                                <div className="field">
                                    <DateTimePicker
                                        label={form?.available?.label}
                                        fromTo
                                        placeholder={
                                            form?.available?.placeholder
                                        }
                                        refs={register({
                                            ...validation.AVAILABE,
                                        })}
                                    />
                                    <div className="assigned-patient">
                                        <Select
                                            name="assign"
                                            label={form?.assign?.label}
                                            placeholder={
                                                form?.assign?.placeholder
                                            }
                                            value={isAssign}
                                            options={assignedList}
                                            onChange={onChange}
                                            multiple
                                            fluid
                                        />
                                    </div>
                                </div>
                            </Form.Group>
                            <Form.Group>
                                <Form.Field width={16}>
                                    <TextArea
                                        name="desc"
                                        label={form?.desc?.label}
                                        rows={20}
                                        placeholder={form?.desc?.placeholder}
                                        refs={{
                                            register,
                                            validation: { maxLength: 2500 },
                                        }}
                                        count
                                    />
                                </Form.Field>
                            </Form.Group>
                            <Button
                                disabled={validateForm()}
                                className="add-action-button"
                                type="submit"
                            >
                                {form?.actionLableForAdd}
                            </Button>
                        </Form>
                    </div>
                </div>
            </Segment>
        </Container>
    );
};

export default CustomAdd;
