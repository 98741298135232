import { Apis, handleResponse, handelError } from './services';
const currentPP = () => {
    const requestOptions = {
        method: 'GET',
    };
    return Apis()(`/dataprocessconsent/current`, requestOptions)
        .then(handleResponse)
        .then((data) => ({ status: true, ...data }))
        .catch(handelError);
};
const addPP = (data) => {
    const requestOptions = {
        method: 'POST',
        data,
    };
    return Apis()(`/dataprocessconsent`, requestOptions)
        .then(handleResponse)
        .then(() => ({ status: true }))
        .catch(handelError);
};
const getAll = ({ pageNumber, orderBy, direction, search }) => {
    const requestOptions = {
        method: 'GET',
        params: {
            pageNumber,
            orderBy,
            direction,
            search,
        },
    };
    return Apis()(`/user/list`, requestOptions)
        .then(handleResponse)
        .then((data) => ({ status: true, ...data }))
        .catch(handelError);
};
const getPList = ({ pageNumber, orderBy, direction, search }) => {
    const requestOptions = {
        method: 'GET',
        params: {
            pageNumber,
            orderBy,
            direction,
            search,
        },
    };
    return Apis()(`/user/list`, requestOptions)
        .then(handleResponse)
        .then((data) => ({ status: true, ...data }))
        .catch(handelError);
};
const marketList = ({ pageNumber, orderBy, direction, search }) => {
    const requestOptions = {
        method: 'GET',
        params: {
            pageNumber,
            orderBy,
            direction,
            search,
        },
    };
    return Apis()(`/user/marketing/list`, requestOptions)
        .then(handleResponse)
        .then((data) => ({ status: true, ...data }))
        .catch(handelError);
};
const addGDPR = (data) => {
    const requestOptions = {
        method: 'POST',
        data,
    };
    return Apis()(`/termsandconditions`, requestOptions)
        .then(handleResponse)
        .then(() => ({ status: true }))
        .catch(handelError);
};
const editGdpr = (data) => {
    const requestOptions = {
        method: 'GET',
    };
    const { id } = data;
    return Apis()(`/termsandconditions/${id}`, requestOptions)
        .then(handleResponse)
        .then((res) => ({ status: true, ...res }))
        .catch(handelError);
};
const currentGdpr = () => {
    const requestOptions = {
        method: 'GET',
    };

    return Apis()(`/termsandconditions/current`, requestOptions)
        .then(handleResponse)
        .then((data) => ({ status: true, ...data }))
        .catch(handelError);
};
const downloadList = () => {
    const requestOptions = {
        method: 'GET',
        responseType: 'blob',
    };
    return Apis()(`/patient/marketing/export`, requestOptions)
        .then((data) => data)
        .catch(handelError);
};
export const gdprService = {
    getAll,
    getPList,
    marketList,
    currentPP,
    addPP,
    editGdpr,
    addGDPR,
    currentGdpr,
    downloadList,
};
