import { batch } from 'react-redux';
import PATIENTDETAILS from '_constants';
import { patientService } from '_services';
import { alertActions } from './alert.action';
import moment from 'moment';
import 'moment/locale/es';
import { map } from 'lodash';
import { patientActions } from './patient.action';
import { lang } from '_config';
import { getErrorMsg } from '_utils';
const {
    menuList: {
        messages,
        error: {
            patient: { affiliation, info, foodDairy },
        },
    },
} = lang;
const getPatientInfo = (id) => {
    return (dispatch) => {
        const onError = (error) => {
            batch(() => {
                dispatch({
                    type: PATIENTDETAILS.PATIENT_INFO_FAILURE,
                    faliure: error ?? null,
                });
                dispatch(alertActions.error(error));
                setTimeout(
                    () => dispatch({ type: PATIENTDETAILS.CLEAR }),
                    PATIENTDETAILS.TIMEOUT
                );
            });
        };
        batch(() => {
            dispatch({ type: PATIENTDETAILS.PATIENT_DETAIL_REQUEST_START });
        });
        patientService
            .getPatientInfo(id)
            .then(
                (result) => {
                    if (result && result?.status) {
                        dispatch({
                            type: PATIENTDETAILS.PATIENT_INFO_SUCCESS,
                            result,
                            params: id,
                        });
                    } else {
                        onError(result);
                    }
                },
                (err) => onError(err)
            )
            .catch(onError)
            .finally(() => {
                dispatch({ type: PATIENTDETAILS.PATIENT_DETAIL_REQUEST_END });
            });
    };
};
const getHealthRec = (rec) => {
    const dateFormat = moment(rec?.day, 'DD-MMMM-YYYY', 'es');
    const parmas = {
        day: dateFormat.format('YYYY-MM-DD'),
        direction: 'DESC',
        orderBy: 'DAY',
        pageNumber: 0,
        userId: rec?.userId,
    };
    const curPramans = {
        day: rec?.day,
        maxDate: new Date(),
        displayDate: dateFormat.format('DD-MMMM-YYYY').replace(/-/g, ' de '),
    };
    return (dispatch) => {
        const onError = (error) => {
            batch(() => {
                dispatch({
                    type: PATIENTDETAILS.PATIENT_HEALTH_FAILURE,
                    faliure: getErrorMsg(error, foodDairy?.list) ?? null,
                });
                dispatch(
                    alertActions.error(getErrorMsg(error, foodDairy?.list))
                );
                setTimeout(
                    () => dispatch({ type: PATIENTDETAILS.CLEAR }),
                    PATIENTDETAILS.TIMEOUT
                );
            });
        };
        batch(() => {
            dispatch({ type: PATIENTDETAILS.PATIENT_HEALTH_REQUEST_START });
        });
        patientService
            .getHealthRec(parmas)
            .then(
                (result) => {
                    if (result && result?.status) {
                        dispatch({
                            type: PATIENTDETAILS.PATIENT_HEALTH_SUCCESS,
                            result: map(result.list, 'diaryFood')[0],
                            params: { ...curPramans, id: rec?.userId },
                            symptoms: result?.symptoms,
                        });
                    } else {
                        onError(result);
                    }
                },
                (err) => onError(err)
            )
            .catch(onError)
            .finally(() => {
                dispatch({ type: PATIENTDETAILS.PATIENT_HEALTH_REQUEST_END });
            });
    };
};

const getMedicalRecords = (id, type, sectionType) => {
    let medicalRecordType = '';
    if (sectionType !== undefined) {
        medicalRecordType =
            sectionType === 'BMI'
                ? 'MEDICAL_RECORD_BMI'
                : sectionType === 'BM'
                ? 'MEDICAL_RECORD_BM'
                : 'MEDICAL_RECORD_ANALYTICS';
    }
    return (dispatch) => {
        const onError = (error) => {
            batch(() => {
                dispatch({
                    type: PATIENTDETAILS[`${medicalRecordType}_FAILURE`],
                    faliure: error ?? null,
                });
                dispatch({
                    type: PATIENTDETAILS.ERROR,
                    message: error,
                });
                setTimeout(
                    () => dispatch({ type: PATIENTDETAILS.CLEAR }),
                    PATIENTDETAILS.TIMEOUT
                );
            });
        };
        batch(() => {
            dispatch({
                type: PATIENTDETAILS[`${medicalRecordType}_START`],
            });
            dispatch({
                type: PATIENTDETAILS.CLEAR,
            });
        });
        patientService
            .getMedicalRecords(id, type)
            .then(
                (result) => {
                    if (result && result?.status) {
                        dispatch({
                            type:
                                PATIENTDETAILS[`${medicalRecordType}_SUCCESS`],
                            result,
                            params: { id: id, sectionType: sectionType },
                        });
                    } else {
                        onError(result);
                    }
                },
                (err) => onError(err)
            )
            .catch(onError)
            .finally(() => {
                dispatch({
                    type: PATIENTDETAILS[`${medicalRecordType}_END`],
                });
            });
    };
};
const upgradeHistory = (params) => {
    const queryParams = {
        pageNumber: params?.pageNumber ?? 0,
        direction: params?.direction ?? 'DESC',
        orderBy: params?.orderBy ?? 'ID',
        userId: params?.userId ?? '',
    };
    return (dispatch) => {
        // const {} = getState();
        const onError = (error) => {
            batch(() => {
                dispatch({
                    type: PATIENTDETAILS.PATIENT_UPGRADE_FAILURE,
                    faliure: error ?? null,
                });
                dispatch({
                    type: PATIENTDETAILS.ERROR,
                    message: error,
                });
                setTimeout(
                    () => dispatch({ type: PATIENTDETAILS.CLEAR }),
                    PATIENTDETAILS.TIMEOUT
                );
            });
        };
        batch(() => {
            dispatch({
                type: PATIENTDETAILS.PATIENT_UPGRADE_LOADING,
            });
            dispatch({
                type: PATIENTDETAILS.CLEAR,
            });
        });
        patientService
            .upgradeHistory(queryParams)
            .then(
                (result) => {
                    if (result && result?.status) {
                        dispatch({
                            type: PATIENTDETAILS.PATIENT_UPGRADE_SUCCESS,
                            result,
                            params: queryParams,
                        });
                    } else {
                        onError(result);
                    }
                },
                (err) => onError(err)
            )
            .catch(onError)
            .finally(() => {
                dispatch({
                    type: PATIENTDETAILS.PATIENT_UPGRADE_LOADED,
                });
            });
    };
};

const updatePatientInfo = (data) => {
    return (dispatch) => {
        const onError = (error) => {
            batch(() => {
                dispatch({
                    type: PATIENTDETAILS.UPDATE_PATIENT_INFO_FAILURE,
                    faliure: error ?? null,
                });
                dispatch(alertActions.error(error));
                setTimeout(
                    () => dispatch({ type: PATIENTDETAILS.CLEAR }),
                    PATIENTDETAILS.TIMEOUT
                );
            });
        };
        batch(() => {
            dispatch({
                type: PATIENTDETAILS.UPDATE_PATIENT_INFO_REQUEST_START,
            });
        });
        patientService
            .updatePatientInfo(data)
            .then(
                (result) => {
                    if (result && result?.status) {
                        dispatch({
                            type: PATIENTDETAILS.UPDATE_PATIENT_INFO_SUCCESS,
                            result,
                            params: data.id,
                        });
                        dispatch({
                            type: PATIENTDETAILS.SUCCESS,
                            message: {
                                suc: info?.put[200]?.msg ?? messages.update,
                            },
                        });
                    } else {
                        onError(result);
                    }
                },
                (err) => onError(err)
            )
            .catch(onError)
            .finally(() => {
                dispatch({
                    type: PATIENTDETAILS.UPDATE_PATIENT_INFO_REQUEST_END,
                });
            });
    };
};
const upgradeKetoToCean = (data) => {
    return (dispatch) => {
        const onError = (error) => {
            batch(() => {
                dispatch({
                    type: PATIENTDETAILS.UPGRADE_KETO_TO_CEAN_FAILUARE,
                    error: getErrorMsg(error, affiliation) ?? null,
                });
                dispatch(alertActions.error(getErrorMsg(error, affiliation)));
                setTimeout(
                    () => dispatch({ type: PATIENTDETAILS.CLEAR }),
                    PATIENTDETAILS.TIMEOUT
                );
            });
        };
        batch(() => {
            dispatch({ type: PATIENTDETAILS.UPGRADE_REQUEST_START });
        });
        patientService
            .upgradeKetoToCean(data)
            .then(
                (result) => {
                    if (result && result?.status) {
                        batch(() => {
                            dispatch({
                                type:
                                    PATIENTDETAILS.UPGRADE_KETO_TO_CEAN_SUCCESS,
                                result,
                                params: data.id,
                            });
                            dispatch({
                                type: PATIENTDETAILS.SUCCESS,
                                message: {
                                    suc:
                                        affiliation[200]?.msg ??
                                        messages?.update,
                                },
                            });
                            setTimeout(() => {
                                dispatch({
                                    type: PATIENTDETAILS.CLEAR,
                                });
                                dispatch(getPatientInfo(data.id));
                            }, PATIENTDETAILS.TIMEOUT);
                        });
                    } else {
                        onError(result);
                    }
                },
                (err) => onError(err)
            )
            .catch(onError)
            .finally(() => {
                dispatch({ type: PATIENTDETAILS.UPGRADE_REQUEST_END });
            });
    };
};
const profileDel = (data) => {
    return (dispatch) => {
        const onError = (error) => {
            batch(() => {
                dispatch({
                    type: PATIENTDETAILS.PATIENT_DEL_FAILURE,
                    error: getErrorMsg(error, info?.delete) ?? null,
                });
                dispatch(alertActions.error(getErrorMsg(error, info?.delete)));
                setTimeout(
                    () => dispatch({ type: PATIENTDETAILS.CLEAR }),
                    PATIENTDETAILS.TIMEOUT
                );
            });
        };
        batch(() => {
            dispatch({ type: PATIENTDETAILS.PATIENT_DEL_START });
        });
        patientService
            .profileDel(data)
            .then(
                (result) => {
                    if (result && result?.status) {
                        batch(() => {
                            dispatch({
                                type: PATIENTDETAILS.PATIENT_DEL_SUCCESS,
                                result: { status: true, deleted: true },
                            });
                            dispatch({
                                type: PATIENTDETAILS.SUCCESS,
                                message: {
                                    suc:
                                        info?.delete[200].msg ??
                                        messages?.delete,
                                },
                            });
                            setTimeout(() => {
                                dispatch({
                                    type: PATIENTDETAILS.CLEAR,
                                });
                                dispatch(patientActions.getAll());
                            }, PATIENTDETAILS.TIMEOUT);
                        });
                    } else {
                        onError(result);
                    }
                },
                (err) => onError(err)
            )
            .catch(onError)
            .finally(() => {
                dispatch({ type: PATIENTDETAILS.PATIENT_DEL_END });
            });
    };
};
// FOR PATIENT DETAILS UPLOAD THROUGH CSV FOR CHILE AND ECUADOR
const uploadPatientDetails = (file, id) => {
    return (dispatch) => {
        const uploadError = (error) => {
            batch(() => {
                dispatch({
                    type: PATIENTDETAILS.PATIENT_DETAILS_UPLOAD_FAILURE,
                    uploadStatus: { ...error },
                });
                dispatch({ type: PATIENTDETAILS.PATIENT_DETAILS_UPLOAD_ERROR, message: error });
                setTimeout(() => dispatch({ type: PATIENTDETAILS.PATIENT_DETAILS_UPLOAD_CLEAR }), PATIENTDETAILS.PATIENT_DETAILS_UPLOAD_TIMEOUT);
            });
        };
        batch(() => {
            dispatch({ type: PATIENTDETAILS.PATIENT_DETAILS_UPLOAD_REQUEST_START });
            dispatch({ type: PATIENTDETAILS.PATIENT_DETAILS_UPLOAD_CLEAR });
        });
        patientService
            .patientDetailsUpload(file, id)
            .then(
                (result) => {
                    if (result && result?.status) {
                        dispatch({
                            type: PATIENTDETAILS.PATIENT_DETAILS_UPLOAD_SUCCESS,
                        });
                    } else {
                        uploadError(result);
                    }
                },
                (error) => {
                    uploadError(error);
                }
            )
            .catch((error) => {
                uploadError(error);
            })
            .finally(() => {
                dispatch({ type: PATIENTDETAILS.PATIENT_DETAILS_UPLOAD_REQUEST_END });
            });
    };
};
const isUpload = () => {
    return (dispatch) => {
        dispatch({ type: PATIENTDETAILS.PATIENT_DETAILS_UPLOAD_INIT });
    };
};
export const patientDetailActions = {
    getPatientInfo,
    getHealthRec,
    getMedicalRecords,
    upgradeHistory,
    updatePatientInfo,
    upgradeKetoToCean,
    profileDel,
    uploadPatientDetails,
    isUpload,
};
