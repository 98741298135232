import { batch } from 'react-redux';
import { isEmpty, isEqual } from 'lodash';
import UPGRADE from '_constants';
import { upgradeService } from '_services';
import { lang } from '_config';
import { getErrorMsg } from '_utils';
const {
    menuList: {
        messages,
        error: { enroll },
    },
} = lang;
// FOR ENROLL PATIENT
const enrollPatient = (enrollData) => {
    return (dispatch, getState) => {
        const { alertData } = getState();
        // ON REQUEST ERROR
        const onError = (error) =>
            batch(() => {
                dispatch({
                    type: UPGRADE.UPGRADE_RECORD_FAILURE,
                    faliure: getErrorMsg(error, enroll?.list) ?? null,
                });
                dispatch({
                    type: UPGRADE.ERROR,
                    message: getErrorMsg(error, enroll?.list) ?? null,
                });
                setTimeout(() => {
                    dispatch({ type: UPGRADE.CLEAR });
                    dispatch({
                        type: UPGRADE.UPGRADE_MSG_CLEAR,
                    });
                }, UPGRADE.TIMEOUT);
            });
        // START REQUEST SETUP
        batch(() => {
            dispatch({ type: UPGRADE.UPGRADE_RECORD_REQUEST_START });
            if (!isEmpty(alertData)) dispatch({ type: UPGRADE.CLEAR });
        });
        // REQUEST START
        upgradeService
            .enrollPatient(enrollData)
            .then((result) => {
                // REQUEST SUCCESS
                if (result && result?.status) {
                    batch(() => {
                        dispatch({
                            type: UPGRADE.UPGRADE_RECORD_SUCCESS,
                        });
                        dispatch({
                            type: UPGRADE.SUCCESS,
                            message: {
                                suc: enroll?.list[200]?.msg ?? messages?.update,
                            },
                        });
                        setTimeout(() => {
                            dispatch(getAll());
                            dispatch({
                                type: UPGRADE.UPGRADE_MSG_CLEAR,
                            });
                        }, UPGRADE.TIMEOUT);
                    });
                } else {
                    // REQUEST FAILURE WITH BAD PARAMS
                    onError(result);
                }
            })
            // HANDEL ANY WEIRED BEHAVIOUR(LIKE NETWORK OR ANY OTHER)
            .catch(onError)
            .finally(() => {
                // REQUEST ENDED
                dispatch({ type: UPGRADE.UPGRADE_RECORD_REQUEST_END });
            });
    };
};
// ENROLL LIST
const getAll = (params) => {
    const queryParams = {
        pageNumber: params?.pageNumber ?? 0,
        direction: params?.direction ?? 'DESC',
        orderBy: params?.orderBy ?? 'NAME',
        search: params?.search ?? '',
    };
    return (dispatch, getState) => {
        const { alertData, upgradeList } = getState();
        if (!upgradeList.loading) {
            // ON REQUEST ERROR
            const onError = (error) =>
                batch(() => {
                    dispatch({
                        type: UPGRADE.UPGRADE_LIST_FAILURE,
                        faliure: error ?? null,
                    });
                    dispatch({ type: UPGRADE.ERROR, message: error });
                    setTimeout(
                        () => dispatch({ type: UPGRADE.CLEAR }),
                        UPGRADE.TIMEOUT
                    );
                });
            // START REQUEST SETUP
            batch(() => {
                dispatch({ type: UPGRADE.UPGRADE_REQUEST_START });
                if (!isEmpty(alertData)) dispatch({ type: UPGRADE.CLEAR });
            });
            // REQUEST START
            upgradeService
                .getAll(queryParams)
                .then((result) => {
                    // REQUEST SUCCESS
                    if (result && result?.status) {
                        if (!isEqual(result.list, upgradeList.result.list))
                            dispatch({
                                type: UPGRADE.UPGRADE_LIST_SUCCESS,
                                result,
                                params: queryParams,
                            });
                    } else {
                        // REQUEST FAILURE WITH BAD PARAMS
                        onError(result);
                    }
                })
                // HANDEL ANY WEIRED BEHAVIOUR(LIKE NETWORK OR ANY OTHER)
                .catch(onError)
                .finally(() => {
                    // REQUEST ENDED
                    dispatch({ type: UPGRADE.UPGRADE_REQUEST_END });
                });
        }
    };
};
// FOR CLEAR MESSAGE IF ANY ERROR OR SUCCES IS THERE
const clear = (input) => {
    return (dispatch, getState) => {
        const {
            adminList: { faliure, success },
        } = getState();
        if (!isEmpty(faliure)) dispatch({ type: UPGRADE.UPGRADE_MSG_CLEAR });
        if (success) dispatch({ type: UPGRADE.UPGRADE_MSG_CLEAR });
        if (input === true) dispatch({ type: UPGRADE.UPGRADE_MSG_CLEAR });
    };
};
// FOR UPGRADE UPLOAD THROUGH CSV FOR CHILE AND ECUADOR
const uploadUpgrade = (file) => {
    return (dispatch) => {
        const uploadError = (error) => {
            batch(() => {
                dispatch({
                    type: UPGRADE.UPGRADE_UPLOAD_FAILURE,
                    uploadStatus: { ...error },
                });
                dispatch({ type: UPGRADE.UPGRADE_UPLOAD_ERROR, message: error });
                setTimeout(() => dispatch({ type: UPGRADE.UPGRADE_UPLOAD_CLEAR }), UPGRADE.UPGRADE_UPLOAD_TIMEOUT);
            });
        };
        batch(() => {
            dispatch({ type: UPGRADE.UPGRADE_UPLOAD_REQUEST_START });
            dispatch({ type: UPGRADE.UPGRADE_UPLOAD_CLEAR });
        });
        upgradeService
            .upgradeUpload(file)
            .then(
                (result) => {
                    if (result && result?.status) {
                        dispatch({
                            type: UPGRADE.UPGRADE_UPLOAD_SUCCESS,
                        });
                    } else {
                        uploadError(result);
                    }
                },
                (error) => {
                    uploadError(error);
                }
            )
            .catch((error) => {
                uploadError(error);
            })
            .finally(() => {
                dispatch({ type: UPGRADE.UPGRADE_UPLOAD_REQUEST_END });
            });
    };
};
const isUpload = () => {
    return (dispatch) => {
        dispatch({ type: UPGRADE.UPGRADE_UPLOAD_INIT });
    };
};
export const upgradeActions = {
    getAll,
    enrollPatient,
    clear,
    uploadUpgrade,
    isUpload,
};
