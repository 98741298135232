import React, { useRef } from 'react';
import { Segment, Container } from 'semantic-ui-react';
import { lang } from '_config';
import NoticesRoutes from './Notices.Routes';
import './NoticesPage.scss';
import { map } from 'lodash';
import { removeTags } from '_utils';
const NoticesPage = (props) => {
    const cref = useRef('NoticesPage');
    const { notices: CONFIG } = lang;
    const {
        state: {
            userDetails: { roles },
            newsList: {
                loading,
                result: { list, ...filters },
                params,
            },
        },
    } = props;
    const result = map(list, (data) => ({
        mytxt: removeTags(data.description),
        ...data,
    }));
    const listData = {
        sorting: [{ direction: 'DESC' }, false],
        cellWidth: [3, 10, 3],
        columns: ['title', 'mytxt'],
        isHtml: [false, false, false],
        loading: loading,
        filters: filters,
        params: params,
        cref,
        roles,
        pageData: result,
    };
    return (
        <Container
            cref={cref}
            fluid
            id="NoticesPage"
            className={`private-layout`}
        >
            <Segment basic>
                <div className="main">
                    <NoticesRoutes
                        {...props}
                        listData={listData}
                        config={CONFIG}
                    />
                </div>
            </Segment>
        </Container>
    );
};

export { NoticesPage };
