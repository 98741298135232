/* eslint-disable react/jsx-no-bind */
import React from 'react';
import { Router, Route, Switch, Redirect } from 'react-router-dom';

import { PrivateLayout as Layout } from '_components';
import {
    AppContainer,
    LoginContainer,
    ForgotContainer,
    ResetPwdContainer,
} from '_containers';
import Routes from './Routes';
import History from '_utils/history';
import 'semantic-ui-css/semantic.min.css';
import 'react-calendar/dist/Calendar.css';
import '_scss/App.scss';

const App = (_props) => {
    const {
        state,
        state: {
            localUserData,
            userDetails: { loggedIn, token },
        },
        actions,
    } = AppContainer();
    return (
        <Router history={History}>
            <Switch>
                <Route path="/login" component={LoginContainer} />
                <Route path="/forgotPwd" component={ForgotContainer} />
                <Route path="/resetpassword" component={ResetPwdContainer} />
                <Layout
                    {..._props}
                    history={History}
                    actions={actions}
                    state={state}
                    loggedIn={loggedIn}
                    title="Welcome to Fresenius"
                >
                    <Routes
                        loggedIn={loggedIn}
                        token={token ?? ''}
                        {..._props}
                        roles={localUserData.roles}
                    />
                </Layout>
                {!loggedIn && <Redirect from="*" to="/login" />}
            </Switch>
        </Router>
    );
};

export default App;
