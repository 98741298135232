import React, { useState, createRef } from 'react';
import { Modal, Dimmer, Loader } from 'semantic-ui-react';
import { validateContact } from '_utils';
import { useForm } from 'react-hook-form';
import { isEqual, isEmpty, trim } from 'lodash';
import './TicketValidateModal.scss';
import ViewTicket from './ViewTicket';
import AddNote from './AddNote';
import PharmacyList from './PharmacyList';
const TicketValidateModal = (props) => {
    const {
        config,
        curOptions,
        ticketDetails,
        updateTicket,
        closeModal,
        clearData,
        pharmacyList,
    } = props;
    const options = {
        isEnable: ticketDetails?.status !== 'PENDING',
        isReject: ticketDetails?.status === 'REJECTED',
        visiblePharmacy: ticketDetails?.visiblePharmacy ?? true ,
        isNote: false,
        loading: false,
        openPharmacy: false,
        pharmacyText: ticketDetails?.status !== 'PENDING' ? ticketDetails?.pharmacy ?? '' : '',
        isDisable: ticketDetails?.status !== 'PENDING',
        boxes: ticketDetails?.status !== 'PENDING' ? ticketDetails?.boxes ?? '' : '',
    };
    const updateOptions = { ...options, ...curOptions };
    // console.log(updateOptions);
    const inputRef = createRef();
    const [modalData, setModalData] = useState(updateOptions);
    const [showError, setShowErros] = useState({ openNotes: false });
    if (!isEqual(updateOptions, modalData) && options.isDisable) {
        setModalData(updateOptions);
    }
    const handelPharmacy = () => {
        if (!isDisable)
            setModalData({
                ...modalData,
                openPharmacy: true,
                boxes: inputRef.current.value,
            });
    };
    // CLICK ON REJECT ICON
    const handelReject = () => {
        inputRef.current.value = '';
        setModalData(() => ({
            ...modalData,
            isReject: true,
            isDisable: true,
            isEnable: true,
        }));
    };
    // CLICK ON ACCEPTED ICON
    const handelValid = () => {
        setValue('notes', '');
        setModalData(() => ({ ...modalData, isReject: false }));
    };
    // CHECK VALID BOX TYPED
    const handelSubmit = () => {
        const boxesVal = inputRef?.current?.value;
        const enabled = visiblePharmacy
            ? !isEmpty(trim(pharmacyText)) && validateContact(boxesVal)
            : validateContact(boxesVal);
        setModalData({
            ...modalData,
            isEnable: !enabled,
        });
    };
    // CLICK ON SELECT PHARMACY
    const selectPharmacy = (res) => {
        const enabled = !isEmpty(trim(res)) && validateContact(boxes);
        setModalData({
            ...modalData,
            pharmacyText: res,
            openPharmacy: false,
            isEnable: !enabled,
        });
    };
    // CLICK ON CLOSE PHARMACY LIST
    const closePharmacy = () => {
        setModalData({ ...modalData, openPharmacy: false });
    };
    // CLICK ON ERROR NOTES
    const handelNote = () => {
        setModalData({ ...modalData, isNote: !isNote });
        if (ticketDetails?.status === 'REJECTED')
            setShowErros({
                openNotes: !showError?.openNotes,
            });
    };
    const { register, watch, errors, setValue } = useForm();
    const watchAllFields = watch(['notes']);
    // UPDATE VALID TICKET
    const updateTicketValid = async () => {
        const daysToExpire = inputRef?.current?.value;
        await updateTicket({
            id: ticketDetails?.id,
            userType: ticketDetails?.recordDetails?.usertype,
            daysToExpire,
            status: 'ACCEPTED',
            note: '',
            pharmacy: pharmacyText,
        });
        // setModalData({ ...modalData, isNote: false });
    };
    // UPDATE INVALID TICKET
    const onSubmit = (data) => {
        const { notes: note } = data;
        updateTicket({
            id: ticketDetails?.id,
            userType: ticketDetails?.recordDetails?.usertype,
            daysToExpire: '',
            status: 'REJECTED',
            note,
            pharmacy: '',
        });
        setModalData({ ...modalData, isNote: false, isReject: false });
    };
    !updateOptions.modalOpen && closeModal();
    const {
        isEnable,
        isReject,
        isNote,
        openPharmacy,
        pharmacyText,
        boxes,
        visiblePharmacy,
    } = modalData;
    // console.log(modalData,ticketDetails);
    const { isDisable, modalOpen, loading } = updateOptions;
    const onClose = ()=>{
        setShowErros({
            openNotes: false,
        });
        setModalData({
            isEnable: true,
            isReject: true,
            visiblePharmacy: false,
            isNote: false,
            loading: false,
            openPharmacy: false,
            pharmacyText:'',
            isDisable: true,
            boxes: '',
        });
        clearData({});
        closeModal();
    };
    return (
        <Modal
            open={modalOpen}
            onClose={onClose}
            size="mini"
            closeIcon
        >
            {loading && (
                <Dimmer inverted active={loading}>
                    <Loader />
                </Dimmer>
            )}
            <div id="ticket-validate-modal">
                {openPharmacy ? (
                    <PharmacyList
                        selectPharmacy={selectPharmacy}
                        closePharmacy={closePharmacy}
                        pharmacyList={pharmacyList}
                        isDisable={isDisable}
                        config={config}
                        boxes={boxes}
                    />
                ) : (
                    <>
                        {isNote === false && showError?.openNotes === false ? (
                            <ViewTicket
                                ticketDetails={ticketDetails}
                                config={config}
                                handelReject={handelReject}
                                isDisable={isDisable}
                                isReject={isReject}
                                handelValid={handelValid}
                                handelSubmit={handelSubmit}
                                boxes={boxes}
                                handelNote={handelNote}
                                handelPharmacy={handelPharmacy}
                                isEnable={isEnable}
                                updateTicketValid={updateTicketValid}
                                visiblePharmacy={visiblePharmacy}
                                pharmacyText={pharmacyText}
                                ref={inputRef}
                                loading={loading}
                            />
                        ) : (
                            <AddNote
                                onSubmit={onSubmit}
                                handelNote={handelNote}
                                loading={loading}
                                config={config}
                                register={register}
                                watchAllFields={watchAllFields}
                                isEnable={isEnable}
                                errors={errors}
                                showError={showError}
                                notesDefault={ticketDetails?.notes}
                            />
                        )}{' '}
                    </>
                )}
            </div>
        </Modal>
    );
};

export { TicketValidateModal };
