import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import CONFIG from '_constants';
import { Image, Dropzone } from '_components';
import { imgPlaceholder } from 'images';
import { Loader } from 'semantic-ui-react';
import { hasExtensions, getFileDetail } from '_utils';
import { useFileUpload } from '_containers';
import { isEmpty } from 'lodash';
import './FileUpload.scss';

const FileUpload = (props) => {
    const {
        VALIDATION: {
            FILE: { VID, IMG },
        },
    } = CONFIG;
    const { label, placeholder, optional, video, limit, name } = props;
    const {
        state: { getFileDetails },
        actions: { isFileUpload },
    } = useFileUpload();
    const [curFileError, setError] = useState(false);
    const { list } = getFileDetails;
    const onDrop = useCallback(
        ([file]) => {
            if (!isEmpty(file)) {
                isFileUpload({ fileData: file, name });
                setError(false);
            }
        },
        [isFileUpload, name]
    );
    const uploading = getFileDetail(list, name, 'uploading', true);
    const isError = getFileDetail(list, name, 'faliure', true);
    const src = getFileDetail(list, name, 'url') ?? '';
    const onDropRejected = useCallback(
        (input) => {
            const err = { name, msg: '' };
            const [file] = input;
            if (video) {
                if (file.size > VID.maxSize)
                    err.msg = 'El video no debe tener más de 400 MB';
                if (!hasExtensions(file.name, ['.mp4']))
                    err.msg = 'File is not supported. Allowed types: MP4';
            } else {
                if (file.size > IMG.maxSize)
                    err.msg = 'Tamaño de imagen no mayor a 5Mb';
                if (!hasExtensions(file.name, ['.png']))
                    err.msg = 'File is not supported. Allowed types: PNG';
            }
            setError(() => err);
        },
        [setError, video, VID, IMG, name]
    );
    if (isError && isEmpty(curFileError)) {
        setError(() => ({
            msg: isError?.errMsg,
        }));
    }
    const defaultOptions = { onDropRejected, onDrop };
    const curOptions = video
        ? { ...VID, ...defaultOptions }
        : { ...IMG, ...defaultOptions };
    const { isDragActive, ...fileDropzone } = Dropzone({ ...curOptions });
    const upCN = src === '' ? '' : 'uploaded';
    const vidCN = video ? 'video' : '';
    const limitText = limit ?? `Tamaño de video no mayor a 400MB`;
    const errClass = isEmpty(curFileError) ? '' : 'error';
    return (
        <div className={`file-upload ${errClass}`}>
            <label>{label}</label>
            <div
                {...fileDropzone.getRootProps()}
                className={`drag-container ${
                    isDragActive ? 'active' : uploading ? 'uploading' : ''
                } ${upCN}`}
            >
                <Loader active={uploading} />
                <input {...fileDropzone.getInputProps()} />
                <div className={`drag-area ${vidCN}`}>
                    {!video && src !== '' && (
                        <Image src={src} className="upload-img" />
                    )}
                    {video && src !== '' && (
                        <video
                            className="upload-vid"
                            src={src}
                            canplay="false"
                        />
                    )}
                    <Image
                        className={`default-img  ${upCN}`}
                        src={imgPlaceholder}
                    />
                    <p className={`drag-file-draggable ${upCN}`}>
                        {placeholder}
                        <span>{limitText}</span>
                    </p>
                </div>
            </div>
            {!isEmpty(curFileError) ? (
                <label className="optional">{curFileError?.msg}</label>
            ) : (
                optional.trim() !== '' && (
                    <label className="optional">{optional}</label>
                )
            )}
        </div>
    );
};
// "Tamaño de imagen no mayor a 400MB",
FileUpload.defaultProps = {
    label: '',
    placeholder: '',
    type: 'image',
    optional: '',
    limit: 'Tamaño de imagen no mayor a 5MB',
    video: false,
};
FileUpload.propTypes = {
    label: PropTypes.string,
    placeholder: PropTypes.string,
    type: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    optional: PropTypes.string,
    limit: PropTypes.string,
    video: PropTypes.bool,
};

export { FileUpload };
