import React, { useEffect } from 'react';
import { map, isEmpty, trim } from 'lodash';
import { BreadcrumbComp, Search, GridList, UploadCsvModal } from '_components';
import { ActionButton } from './ActionButton';
const NutritionList = (props) => {
    const {
        CONFIG,
        cref,
        state: {
            userDetails: { roles },
            nutriList: {
                loading,
                params,
                result: { list, ...filters },
                assignePatientList,
                csvLoading,
                csvParams,
                uploaded,
                uploading,
                uploadStatus,
            },
        },
        actions: { getAll, getPatientList, nutriUpload, initModal },
        history,
    } = props;
    const sections = [
        {
            key: 'myNutritionists',
            content: CONFIG.pageHeading,
            active: true,
        },
    ];
    const pageData = map(list, (record) => ({
        ...record,
        Name: record.fullName ?? '--',
        CENTER: record?.clinicName ?? '--',
        email: record?.email || '--',
        phone: trim(record?.phone).length ? record?.phone : '--' ?? '--',
    }));
    const listData = {
        sorting: [
            { direction: 'DESC' },
            { direction: 'ASC' },
            false,
            false,
            false,
        ],
        cellWidth: [3, 3, 3, 5],
        columns: ['Name', 'CENTER', 'phone', 'email'],
        isHtml: [false, false, false, false],
        actionList: getAll,
        loading,
        filters,
        params,
        cref,
        roles,
        pageData,
        isDisable: filters.totalElements <= pageData.length,
    };
    useEffect(() => {
        if (isEmpty(params)) getAll();
    }, [params, getAll]);
    return (
        <>
            <BreadcrumbComp sections={sections} />
            <div className="actions">
                <Search
                    config={CONFIG}
                    params={params}
                    actionList={listData.actionList}
                    filters={filters}
                    width="full"
                />
            </div>
            <GridList history={history} config={CONFIG} listData={listData}>
                {(id, data) => (
                    <ActionButton
                        key={id}
                        record={{ id, ...data }}
                        width={1}
                        config={CONFIG}
                        history={history}
                        patientList={assignePatientList}
                        getPatientList={getPatientList}
                    />
                )}
            </GridList>
            {CONFIG?.uploadRequired ? <UploadCsvModal
                lang={CONFIG.uploadModal}
                uploaded={uploaded}
                uploading={uploading}
                uploadFile={nutriUpload}
                initModal={initModal}
                loading={csvLoading}
                actionList={getAll}
                params={csvParams}
                uploadStatus={uploadStatus}
                withInfoIcon
                buttonInfoText={CONFIG?.uploadButtonInfoText}
            /> : ''}
        </>
    );
};

export { NutritionList };
