import USER from '_constants';
import { INIT } from '_config/initialState';
const { loginData: initialState } = INIT;
const expireTokenAt = () => {
    const dt = new Date();
    dt.setHours(dt.getHours() + 1).getTime();
    return dt;
};
export default (state = initialState, action = {}) => {
    switch (action.type) {
        case USER.UPDATE_LANG:
            return {
                ...state,
                lang: action.lang,
            };
        case USER.LOGIN_REQUEST:
            return {
                ...state,
                loading: true,
                userDetails: {
                    logging: true,
                    loggedIn: false,
                    userName: action.userName,
                    expireTokenAt,
                },
            };
        case USER.LOGIN_SUCCESS:
            return {
                ...state,
                loading: false,
                userDetails: { logging: false, loggedIn: true, ...action.user },
            };
        case USER.LOGIN_FAILURE:
            return {
                ...state,
                loading: false,
            };
        case USER.LOGOUT_FAILURE:
            return { ...initialState };
        default:
            return state;
    }
};
export const userDetails = (state) => state.loginData;
