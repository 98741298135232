// import React from 'react';
import PropTypes from 'prop-types';
import { useDropzone } from 'react-dropzone';
const Dropzone = (props) => {
    const { accept, multiple, maxSize, onDropRejected, onDrop } = props;
    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        accept: accept ?? '', //'image/*',
        multiple: multiple ?? false,
        maxSize: maxSize ?? 20000000,
        onDropRejected,
        onDrop,
    });
    return { getRootProps, getInputProps, isDragActive };
};
Dropzone.defaultProps = {
    multiple: false,
    accept: '',
    maxSize: 20000000,
    extensionAllowed: [],
};
Dropzone.propTypes = {
    multiple: PropTypes.bool.isRequired,
    accept: PropTypes.string.isRequired,
    maxSize: PropTypes.number.isRequired,
    onDropRejected: PropTypes.func.isRequired,
    extensionAllowed: PropTypes.array.isRequired,
};

export { Dropzone };
