import LocalizedStrings from 'react-localization';
import {
    loginEN,
    nutritionistEN,
    patientsEN,
    ketoMapEN,
    adminEN,
    infoEN,
    gdprEN,
    chatEN,
    tipEN,
    forgotEN,
    menuEN,
    resetPwdEn,
    medicalRecordEN,
    editPatientEN,
    upgradeEN,
    promotionsEN,
    nutritionalTipsEN,
    noticesEN,
} from '_config/locale/en';
import {
    loginSP,
    nutritionistSP,
    patientsSP,
    ketoMapSp,
    adminSp,
    infoSp,
    gdprSp,
    chatSp,
    tipSp,
    forgotSP,
    menuSP,
    resetPwdSp,
    medicalRecordSP,
    editPatientSP,
    upgradeSP,
    promotionsSP,
    nutritionalTipsSP,
    noticesSP,
} from '_config/locale/sp';
import {
    loginCL,
    nutritionistCL,
    patientsCL,
    ketoMapCL,
    adminCL,
    infoCL,
    gdprCL,
    chatCL,
    tipCL,
    forgotCL,
    menuCL,
    resetPwdCL,
    medicalRecordCL,
    editPatientCL,
    upgradeCL,
    promotionsCL,
    nutritionalTipsCL,
    noticesCL,
} from '_config/locale/cl';
import {
    loginCO,
    nutritionistCO,
    patientsCO,
    ketoMapCO,
    adminCO,
    infoCO,
    gdprCO,
    chatCO,
    tipCO,
    forgotCO,
    menuCO,
    resetPwdCO,
    medicalRecordCO,
    editPatientCO,
    upgradeCO,
    promotionsCO,
    nutritionalTipsCO,
    noticesCO,
} from '_config/locale/co';
import {
    loginEC,
    nutritionistEC,
    patientsEC,
    ketoMapEC,
    adminEC,
    infoEC,
    gdprEC,
    chatEC,
    tipEC,
    forgotEC,
    menuEC,
    resetPwdEC,
    medicalRecordEC,
    editPatientEC,
    upgradeEC,
    promotionsEC,
    nutritionalTipsEC,
    noticesEC,
} from '_config/locale/ec';
import {
    loginPE,
    nutritionistPE,
    patientsPE,
    ketoMapPE,
    adminPE,
    infoPE,
    gdprPE,
    chatPE,
    tipPE,
    forgotPE,
    menuPE,
    resetPwdPE,
    medicalRecordPE,
    editPatientPE,
    upgradePE,
    promotionsPE,
    nutritionalTipsPE,
    noticesPE,
} from '_config/locale/pe';
// const updateLang = (res) => {
//     strings.setLanguage(res);
//     return strings.translations;
// };
const strings = new LocalizedStrings({
    en: {
        translations: {
            login: loginEN,
            forgot: forgotEN,
            nutritionist: nutritionistEN,
            patients: patientsEN,
            ketoMap: ketoMapEN,
            admin: adminEN,
            info: infoEN,
            gdpr: gdprEN,
            chat: chatEN,
            tip: tipEN,
            menuList: menuEN,
            resetPwd: resetPwdEn,
            medicalRecord: medicalRecordEN,
            editPatient: editPatientEN,
            upgrade: upgradeEN,
            promotions: promotionsEN,
            nutritionalTips: nutritionalTipsEN,
            notices: noticesEN,
        },
    },
    'es-MX': {
        translations: {
            login: loginSP,
            forgot: forgotSP,
            nutritionist: nutritionistSP,
            patients: patientsSP,
            ketoMap: ketoMapSp,
            admin: adminSp,
            info: infoSp,
            gdpr: gdprSp,
            chat: chatSp,
            tip: tipSp,
            menuList: menuSP,
            resetPwd: resetPwdSp,
            medicalRecord: medicalRecordSP,
            editPatient: editPatientSP,
            upgrade: upgradeSP,
            promotions: promotionsSP,
            nutritionalTips: nutritionalTipsSP,
            notices: noticesSP,
        },
    },
    'es-CL': {
        translations: {
            login: loginCL,
            forgot: forgotCL,
            nutritionist: nutritionistCL,
            patients: patientsCL,
            ketoMap: ketoMapCL,
            admin: adminCL,
            info: infoCL,
            gdpr: gdprCL,
            chat: chatCL,
            tip: tipCL,
            menuList: menuCL,
            resetPwd: resetPwdCL,
            medicalRecord: medicalRecordCL,
            editPatient: editPatientCL,
            upgrade: upgradeCL,
            promotions: promotionsCL,
            nutritionalTips: nutritionalTipsCL,
            notices: noticesCL,
        },
    },
    'es-CO': {
        translations: {
            login: loginCO,
            forgot: forgotCO,
            nutritionist: nutritionistCO,
            patients: patientsCO,
            ketoMap: ketoMapCO,
            admin: adminCO,
            info: infoCO,
            gdpr: gdprCO,
            chat: chatCO,
            tip: tipCO,
            menuList: menuCO,
            resetPwd: resetPwdCO,
            medicalRecord: medicalRecordCO,
            editPatient: editPatientCO,
            upgrade: upgradeCO,
            promotions: promotionsCO,
            nutritionalTips: nutritionalTipsCO,
            notices: noticesCO,
        },
    },
    'es-EC': {
        translations: {
            login: loginEC,
            forgot: forgotEC,
            nutritionist: nutritionistEC,
            patients: patientsEC,
            ketoMap: ketoMapEC,
            admin: adminEC,
            info: infoEC,
            gdpr: gdprEC,
            chat: chatEC,
            tip: tipEC,
            menuList: menuEC,
            resetPwd: resetPwdEC,
            medicalRecord: medicalRecordEC,
            editPatient: editPatientEC,
            upgrade: upgradeEC,
            promotions: promotionsEC,
            nutritionalTips: nutritionalTipsEC,
            notices: noticesEC,
        },
    },
    'es-PE': {
        translations: {
            login: loginPE,
            forgot: forgotPE,
            nutritionist: nutritionistPE,
            patients: patientsPE,
            ketoMap: ketoMapPE,
            admin: adminPE,
            info: infoPE,
            gdpr: gdprPE,
            chat: chatPE,
            tip: tipPE,
            menuList: menuPE,
            resetPwd: resetPwdPE,
            medicalRecord: medicalRecordPE,
            editPatient: editPatientPE,
            upgrade: upgradePE,
            promotions: promotionsPE,
            nutritionalTips: nutritionalTipsPE,
            notices: noticesPE,
        },
    },
});
/* This is fix because some key was missing in all the translations */
// const lang = strings.translations;
const lang = strings._props[process.env.REACT_APP_LANGUAGE].translations;
export { lang };
