import { connect } from 'react-redux';
import { alertActions, nutriAction } from '_actions';
import { NutritionistPage } from '_pages';
function mapState(state) {
    const {
        alert,
        nutriList,
        loginData: { userDetails },
    } = state;
    return { state: { alert, nutriList, userDetails } };
}

const actionCreators = (dispatch) => {
    return {
        actions: {
            clearAlerts: () => dispatch(alertActions.clear()),
            getAll: (_) => dispatch(nutriAction.getAll(_)),
            getPatientList: (_) => dispatch(nutriAction.getPatientList(_)),
            updateNutri: (_) => dispatch(nutriAction.updateNutri(_)),
            initModal: () => dispatch(nutriAction.isUpload()),
            nutriUpload: (file) => {
                dispatch(nutriAction.uploadNutri(file));
            },
        },
    };
};

export const NutritionistContainer = connect(
    mapState,
    actionCreators
)(NutritionistPage);
