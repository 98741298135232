import React, { useRef, useEffect, useMemo } from 'react';
import { GridList, StickyButton, Search } from '_components';
import { Grid } from 'semantic-ui-react';
import { isEmpty, map, filter } from 'lodash';
import { dateFormat, removeTags } from '_utils';
const TipsList = (props) => {
    const {
        activeTab: { tab },
        actions: { getGenralList, getPhysicalList, getRecipeslList },
        state: {
            tips: { loading, tabs },
            userDetails: { roles },
        },
        config: {
            details: { catList },
        },
        config,
        history,
    } = props;
    const {
        params,
        result: { list, ...filters },
    } = tabs[tab];
    let data = {};
    const cref = useRef('TipsPage');
    const listData = {
        sorting: [{ direction: 'DESC' }, false, false, false],
        isDisable: filters.totalElements <= list.length,
        columns: ['title', 'CREATED_DATE', 'category'],
        actionList: getRecipeslList,
        isHtml: [false, false],
        cellWidth: [4, 5, 5, 2],
        pageData: list,
        loading,
        filters,
        params,
        cref,
        roles,
    };
    const filterList = useMemo(
        () =>
            map(list, (record) => {
                return {
                    ...record,
                    TITLE: record?.title || '--',
                    CREATED_DATE: dateFormat(record?.availableFrom),
                    description: record?.description || '--',
                    mytxt: removeTags(record?.description),
                    cat: record?.category,
                    category:
                        filter(catList, { value: record?.category })[0]?.text ??
                        record?.category,
                };
            }),
        [list, catList]
    );
    // DEFAULT LOAD DATA
    switch (tab) {
        case 'RECIPES':
            data = {
                ...listData,
                sorting: [
                    { direction: 'DESC' },
                    { direction: 'DESC' },
                    { direction: 'DESC' },
                    false,
                ],
                isHtml: [false, false, true],
                pageData: filterList,
            };
            break;
        case 'PHYSICAL':
        case 'GENERAL':
            data = {
                ...listData,
                sorting: [{ direction: 'DESC' }, false, false],
                isHtml: [false, true],
                columns: ['title', 'mytxt'],
                cellWidth: [4, 10, 2],
                pageData: filterList,
                actionList: tab === 'GENERAL' ? getGenralList : getPhysicalList,
            };
            break;
        default:
            data = {};
            break;
    }
    // CHECK WHICH ACTION IS CALLED AND GET UPDATED RECORD
    useEffect(() => {
        if (isEmpty(params))
            switch (tab) {
                case 'RECIPES':
                    getRecipeslList({ ...params, category: tab });
                    break;
                case 'GENERAL':
                    getGenralList({ ...params, category: tab });
                    break;
                case 'PHYSICAL':
                    getPhysicalList({ ...params, category: tab });
                    break;
                default:
                    break;
            }
    }, [getGenralList, getPhysicalList, getRecipeslList, params, tab]);
    return (
        <>
            <div className="actions">
                <Search
                    params={data?.params}
                    actionList={data?.actionList}
                    config={config}
                    width="full"
                />
            </div>
            <GridList config={config} history={history} listData={data}>
                {(id, record) => (
                    <Grid.Column key={id} width={2}>
                        <div
                            className="text-link"
                            onClick={() =>
                                history.push({
                                    pathname: `/Tips/${tab}/id/${id}`,
                                    state: {
                                        record: {
                                            ...record,
                                            id,
                                            category: record?.cat,
                                        },
                                    },
                                })
                            }
                        >
                            {config.actionLable ?? 'Edit'}
                        </div>
                    </Grid.Column>
                )}
            </GridList>
            <StickyButton
                onClick={() =>
                    props.history.push({
                        pathname: `/Tips/${tab}/Add`,
                    })
                }
            />
        </>
    );
};

export { TipsList };
