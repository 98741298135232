import React, { useEffect } from 'react';
import { Segment, Container, Button, Form } from 'semantic-ui-react';
import CONST from '_constants';
import { isEmpty } from 'lodash';
import {
    BreadcrumbComp,
    Image,
    FileUpload,
    Input,
    TextArea,
} from '_components';
import { useForm } from 'react-hook-form';
import {
    getFileDetail,
    validMinMaxInput,
    validMinMaxTextArea,
    getRecordId,
} from '_utils';
import { times } from 'images';
import './InfoEdit.scss';
const InfoEdit = (props) => {
    const {
        activeTab,
        goBack,
        config: {
            details,
            details: {
                form,
                heading: { edit },
            },
        },
        location: {
            pathname,
            state: { record: selectedRecord },
        },
        actions: {
            clearFileDetails,
            fileTextUpdate,
            updateInfo,
            editInfo,
            delInfo,
        },
        state: {
            filesList,
            infoList: { loading: recordEditing, selectedId, tabs },
        },
    } = props;
    const record = selectedRecord === undefined ? selectedId : selectedRecord;
    const { category } = getRecordId(pathname);
    if (isEmpty(tabs[category]?.faliure)) {
        // redirect to list page
    }
    const infoEditVidUrl = getFileDetail(filesList, 'infoEditVid', 'url');
    const infoEditImgUrl = getFileDetail(filesList, 'infoEditImg', 'url');
    const infoUploadVid = getFileDetail(
        filesList,
        'infoEditVid',
        'uploading',
        true
    );
    const infoUploadImg = getFileDetail(
        filesList,
        'infoEditImg',
        'uploading',
        true
    );
    const pageSubHeadingForEdit = edit[activeTab.tab] ?? '';
    const { register, handleSubmit, watch, errors, setValue } = useForm();
    const watchAllFields = watch(['title', 'text']);
    const sections = [
        {
            key: 'myAdminListPage',
            content: activeTab.breadcrumbHeading,
            onClick: goBack,
        },
        {
            key: 'myAdminEditPage',
            content: details?.breadcrumbForEdit ?? 'Edit',
            active: true,
        },
    ];

    const onSubmit = (data) => {
        updateInfo({
            ...data,
            id: record?.id,
            type: activeTab?.tab,
            image: infoEditImgUrl,
            video: infoEditVidUrl,
            params: tabs[activeTab?.tab]?.params,
        });
        setTimeout(() => goBack(true), CONST.TIMEOUT);
    };
    const { title, text } = watchAllFields;
    const validateForm = () => {
        return !validMinMaxInput(title) &&
            !validMinMaxTextArea(text) &&
            !infoUploadVid &&
            !infoUploadImg &&
            isEmpty(errors)
            ? false
            : true;
    };
    const handelDelInfo = () => {
        delInfo({
            id: record?.id,
            params: tabs[activeTab?.tab]?.params,
        });
        setTimeout(() => goBack(true), CONST.TIMEOUT);
    };
    useEffect(() => {
        clearFileDetails();
        if (!isEmpty(record)) {
            if (record?.image)
                fileTextUpdate({ name: 'infoEditImg', url: record?.image });
            if (record?.video)
                fileTextUpdate({ name: 'infoEditVid', url: record?.video });
        } else {
            editInfo(pathname);
        }
    }, [clearFileDetails, fileTextUpdate, record, pathname, editInfo]);
    return (
        <Container fluid id="InfoEdit" className={`private-layout`}>
            <Segment basic loading={recordEditing}>
                <BreadcrumbComp sections={sections} />
                <div className="edit-container">
                    <div className="edit-heading">
                        <div className="edit-heading-text">
                            {details?.pageHeading}
                            {activeTab.heading}
                        </div>
                        <div className="edit-heading-subtext">
                            {pageSubHeadingForEdit}
                        </div>
                        <div className="edit-close">
                            <Image src={times} onClick={goBack} />
                        </div>
                    </div>
                    {!isEmpty(record) && (
                        <div className="edit-form">
                            <Form onSubmit={handleSubmit(onSubmit)} unstackable>
                                <Form.Field width={16}>
                                    <Input
                                        name="title"
                                        label={form?.title?.label}
                                        placeholder={form?.title?.placeholder}
                                        refs={{ register }}
                                        value={record?.title ?? ''}
                                        count
                                    />
                                </Form.Field>
                                <Form.Group>
                                    <Form.Field width={6}>
                                        <FileUpload
                                            name="infoEditImg"
                                            label={form?.img?.label}
                                            limit={form?.img?.limit}
                                            placeholder={form?.img?.placeholder}
                                            optional={details.optional}
                                        />
                                    </Form.Field>
                                    <Form.Field width={6}>
                                        <FileUpload
                                            name="infoEditVid"
                                            label={form?.video?.label}
                                            limit={form?.video?.limit}
                                            placeholder={
                                                form?.video?.placeholder
                                            }
                                            optional={details.optional}
                                            video
                                        />
                                    </Form.Field>
                                </Form.Group>
                                <Form.Field width={16}>
                                    <TextArea
                                        name="text"
                                        label={form?.desc?.label}
                                        rows={6}
                                        placeholder={form?.desc?.placeholder}
                                        refs={{ register, setValue }}
                                        value={record?.text ?? ''}
                                        count
                                        preview
                                        content={{
                                            desc: text ?? record?.text,
                                            title: title ?? record?.title,
                                            imageUrl: infoEditImgUrl,
                                            videoUrl: infoEditVidUrl,
                                        }}
                                        editor
                                    />
                                </Form.Field>
                                <Button
                                    className="edit-action-button"
                                    type="submit"
                                    disabled={validateForm()}
                                >
                                    {form?.actionLableForEdit}
                                </Button>
                                <Button
                                    className="edit-action-button outline"
                                    type="button"
                                    onClick={handelDelInfo}
                                >
                                    {form?.actionLableForDelete}
                                </Button>
                            </Form>
                        </div>
                    )}
                </div>
            </Segment>
        </Container>
    );
};

export default InfoEdit;
