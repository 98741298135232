import { connect } from 'react-redux';
import {
    alertActions,
    userActions,
    chatActions,
    promoActions as PA,
} from '_actions';
import CONST from '_constants';
import { ChatPage } from '_pages';
function mapState(state) {
    const {
        chatList,
        loginData: { userDetails },
    } = state;
    const boxes = state.promoList.tabs?.DEFAULT?.boxes??{};
    return { state: { chatList, userDetails, boxes } };
}

const actionCreators = (dispatch) => {
    return {
        actions: {
            updateMsgHistory: (msg) =>
                dispatch(chatActions.updateMsgHistory(msg)),
            updatePrevMsgHistory: (msg) =>
                dispatch(chatActions.updatePrevMsgHistory(msg)),
            setRoomInfo: (info) => dispatch(chatActions.setRoomInfo(info)),
            getToken: (_) => dispatch(chatActions.getToken(_)),
            callingAction: (_) => dispatch(chatActions.calling(_)),
            notifAction: (_) => dispatch(chatActions.notificaion(_)),
            endCallAction: () => dispatch(chatActions.endCall()),
            startAudioMsg: (tab) => dispatch(chatActions.startAudioMsg(tab)),
            stopAudioMsg: (_) => dispatch(chatActions.stopAudioMsg(_)),
            getBoxes: (userId) => dispatch(PA.getBoxes(userId)),
            getNutri: (userId, flag, isAdmin) => {
                dispatch(chatActions.getNutri(userId, flag, isAdmin));
                setTimeout(
                    () => dispatch(alertActions.clear()),
                    CONST.TIMEOUT + 2000
                );
            },
            errorAlerts: (_) => {
                dispatch(alertActions.error(_));
                setTimeout(
                    () => dispatch(alertActions.clear()),
                    CONST.TIMEOUT + 2000
                );
            },
            clearAlerts: () => dispatch(alertActions.clear()),
            logOut: () => dispatch(userActions.logout()),
            loginSuccess: () => dispatch(userActions.loginSuccess()),
            setKabiCenter: (val) => dispatch(chatActions.setKabiCenter(val)),
            updateChatOnCenterChange: () => dispatch(chatActions.updateChatOnCenterChange()),
        },
    };
};

export const ChatContainer = connect(mapState, actionCreators)(ChatPage);
