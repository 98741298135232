import React, { useRef, useEffect, useMemo, useCallback } from 'react';
import { map, filter, isEmpty } from 'lodash';
import { Segment, Container, Divider } from 'semantic-ui-react';
import {
    BreadcrumbComp,
    Image,
    GridList,
    StatusHistory,
    TicketValidateModal,
} from '_components';
import { validTicket, pending, wrong, times } from 'images';
import ActionButton from './ActionButton';
import { dateFormat, getRecordId } from '_utils';
import './ProView.scss';
const listData = {
    sorting: [
        { direction: 'DESC' },
        { direction: 'DESC' },
        { direction: 'DESC' },
        false,
    ],
    cellWidth: [3, 3, 4, 2],
    columns: ['created_Date', 'status', 'pharmacy', 'boxes'],
    isHtml: [false, true, false, false],
};
const ProView = (props) => {
    const cref = useRef('receiptList');
    const {
        actions: {
            getPatientRecord,
            closeModal,
            updateTicket,
            openModal,
            getPharmacyList,
            getBoxes,
        },
        config: { default: details },
        activeTab,
        location: {
            pathname,
            state: { record },
        },
        tabs: {
            result: { receipts, recordParams, recordFilter, userData },
            pharmaList,
            boxes,
        },
        state: {
            promoList: { isModalOpen, isModalLoad, boxLoading,modalData },
        },
        roles,
        config,
        loading,
        history,
    } = props;
    const { id: recordId } = getRecordId(pathname);
    const breadCrumbName = isEmpty(record) ? userData : record;
    const sections = [
        {
            key: 'myProList',
            content: activeTab.breadcrumbHeading,
            onClick: () =>
                props.history.push({
                    pathname: `/Promotions/${activeTab.tab}`,
                }),
        },
        {
            key: 'myProView',
            content: breadCrumbName
                ? breadCrumbName?.firstName + ' ' + breadCrumbName?.lastName
                : '',
            active: true,
        },
    ];
    const getImage = (status) => {
        let options = {};
        switch (status) {
            case 'ACCEPTED':
                options = {
                    img: validTicket,
                    text: details?.reciept?.accepted,
                };
                break;
            case 'PENDING':
                options = { img: pending, text: details?.reciept?.pending };
                break;
            case 'REJECTED':
                options = { img: wrong, text: details?.reciept?.rejected };
                break;
            default:
                break;
        }
        return `<div class='status'><img class='status-img' src='${options?.img}' /> ${options?.text}</div>`;
    };
    const pageData = map(receipts, (reciept) => ({
        ...reciept,
        statuscol: reciept?.status,
        pharmacy: reciept?.pharmacy,
        status: getImage(reciept?.status),
        created_Date: dateFormat(reciept?.createdDate),
    }));
    const data = {
        ...listData,
        cref,
        roles,
        filters: recordFilter ?? {},
        params: recordParams ?? {},
        loading,
        actionList: getPatientRecord,
        pageData,
    };
    const ticketData = useCallback(
        (input) => {
            updateTicket({
                id: input?.id,
                boxes: input?.daysToExpire,
                notes: input?.note,
                status: input?.status,
                pharmacy: input?.pharmacy,
                params: recordParams,
            });
        },
        [updateTicket, recordParams]
    );
    const MemoTicketValidateModal = useMemo(() => {
        if(isEmpty(modalData)) return false;
        const pharmacyList = map(pharmaList, 'place');
        return (
            <TicketValidateModal
                config={details?.modal}
                ticketDetails={{
                    url: modalData?.imageUrl,
                    status: modalData?.statuscol,
                    id: modalData?.id,
                    boxes: modalData?.boxes,
                    pharmacy: modalData?.pharmacy,
                    notes: modalData?.note,
                }}
                updateTicket={ticketData}
                clearData={()=>{}}
                closeModal={closeModal}
                curOptions={{
                    modalOpen: isModalOpen,
                    loading: isModalLoad,
                }}
                pharmacyList={pharmacyList}
            />
        );
    }, [
        closeModal,
        details,
        isModalLoad,
        isModalOpen,
        modalData,
        ticketData,
        pharmaList,
    ]);
    useEffect(() => {
        getBoxes(record?.id ?? recordId);
        getPatientRecord({ id: record?.id ?? recordId });
    }, [getBoxes, record, recordId, getPatientRecord]);
    useEffect(() => {
        isEmpty(pharmaList) && getPharmacyList();
    }, [getPharmacyList, pharmaList]);
    const { boxhistory } = details;
    // console.log(filter(pageData, { statuscol: 'PENDING' }));
    const items = {
        free: boxes?.totalGiftBoxes,
        rejectedItems: filter(pageData, { statuscol: 'REJECTED' }).length,
        acceptedtems: boxes?.validBoxes,
    };
    return (
        <Container fluid id="ProView" className={`private-layout`}>
            <Segment basic>
                <BreadcrumbComp sections={sections} />
                <div className="view-container">
                    <div className="view-attachment-col">
                        <div className="view-heading">
                            <div className="view-heading-text">
                                {details?.pageHeading}
                            </div>
                            <div className="view-heading-subtext">
                                {details?.pageSubHeadingForView}
                            </div>
                            <div className="view-close">
                                <Image
                                    src={times}
                                    onClick={() =>
                                        props.history.push({
                                            pathname: `/Promotions/${activeTab.tab}`,
                                        })
                                    }
                                />
                            </div>
                        </div>
                        <Divider />
                        {
                            <div id="receiptList" className="mainSection">
                                <GridList
                                    config={{
                                        ...config,
                                        tableHeading:
                                            details?.reciept?.tableHeading,
                                        pagination: config?.details?.pagination,
                                    }}
                                    history={history}
                                    listData={data}
                                >
                                    {(id, rest) => (
                                        <ActionButton
                                            config={config}
                                            key={id}
                                            record={{ id, ...rest }}
                                            openModal={openModal}
                                        />
                                    )}
                                </GridList>
                            </div>
                        }
                    </div>
                    <div className="view-history-col">
                        <StatusHistory
                            isRejected={false}
                            isPending={false}
                            items={items}
                            config={boxhistory}
                            boxLoading={boxLoading}
                        />
                    </div>
                </div>
                {MemoTicketValidateModal}
            </Segment>
        </Container>
    );
};

export { ProView };
