/* eslint-disable react/no-multi-comp */
import React from 'react';
import { Switch, Route, useRouteMatch, Redirect } from 'react-router-dom';
import { GdprList, GdprEdit, PrivacyPolicy } from './Gdpr';
const GdprRoutes = (props) => {
    const { path } = useRouteMatch();
    const {
        config,
        activeTab,
        actions: { currentGdpr, currentPP },
        state: {
            gdprList: { currentTerm, currentPrivacy },
        },
    } = props;

    const getPage = () => {
        switch (activeTab?.tab) {
            case 'PRIVACY_POLICY':
                return (
                    <PrivacyPolicy
                        {...props}
                        config={{ ...config, activeTab }}
                        desc={currentPrivacy?.content}
                        currentPP={currentPP}
                        currentTerm={currentPrivacy}
                    />
                );
            case 'TERM_CONDITION':
                return (
                    <>
                        <PrivacyPolicy
                            {...props}
                            config={{ ...config, activeTab }}
                            desc={currentTerm?.content}
                            currentTerm={currentTerm}
                            currentGdpr={currentGdpr}
                        />
                        <GdprList
                            {...props}
                            config={{ ...config, activeTab }}
                        />
                    </>
                );
            case 'MARKET':
                return (
                    <GdprList {...props} config={{ ...config, activeTab }} />
                );
            default:
                return false;
        }
    };

    return (
        <Switch>
            <Route exact path={path}>
                <Redirect to={`${path}/${activeTab.tab}`} />
            </Route>
            <Route exact path={`${path}/:tabID`}>
                {getPage()}
            </Route>
            <Route exact path={`${path}/:tabID/id/:id`}>
                <GdprEdit {...props} />
            </Route>
            <Route exact path={`${path}/:tabID/Add`}>
                <GdprEdit {...props} />
            </Route>
        </Switch>
    );
};

export default GdprRoutes;
