import React, { useState } from 'react';
import { Button, Modal, Icon, Item } from 'semantic-ui-react';
import { map, isEmpty, trim } from 'lodash';

const PharmacyList = (props) => {
    const {
        selectPharmacy,
        closePharmacy,
        pharmacyList,
        isDisable,
        config,
    } = props;
    const [settings, updateSettings] = useState({
        addNewOne: false,
        disable: true,
    });
    const { addNewOne, disable } = settings;
    const addNew = () => updateSettings({ ...settings, addNewOne: !addNewOne });
    const updateVal = (event) => {
        const value = event.target.value;
        if (isEmpty(trim(value))) {
            if (!disable) updateSettings({ ...settings, disable: true });
        } else {
            if (disable) updateSettings({ ...settings, disable: false });
        }
    };
    const addNewPharmacy = () => {
        const inputEle = document.querySelector(
            '.add-new-pharmacy>input.input'
        );
        selectPharmacy(inputEle?.value);
    };
    return (
        <div
            className={
                isDisable ? 'pharmacy-list disableList' : 'pharmacy-list'
            }
        >
            <Modal.Header>
                <Icon
                    size="large"
                    name="arrow left"
                    color="blue"
                    className="icon-link"
                    onClick={closePharmacy}
                />
                <div className="pharmacy-header">
                    {config?.pharmacy?.headingText}
                </div>
            </Modal.Header>
            <Modal.Content>
                <div className="pharmacy-container">
                    <Item.Group divided>
                        {map(pharmacyList, (_, index) => {
                            return (
                                <Item
                                    onClick={() => selectPharmacy(_)}
                                    key={index}
                                >
                                    {_}
                                </Item>
                            );
                        })}
                    </Item.Group>
                    {addNewOne ? (
                        <Item className="add-new-pharmacy">
                            <input
                                onChange={updateVal}
                                className="input"
                                type="text"
                            />
                            <Icon onClick={addNew} name="close" />
                        </Item>
                    ) : (
                        <Item className="click-new-pharmacy" onClick={addNew}>
                            {config?.pharmacy?.addNew}
                        </Item>
                    )}
                </div>
                <div className="pharmacy-container-button">
                    <Button
                        disabled={disable}
                        size="large"
                        onClick={addNewPharmacy}
                    >
                        {config?.pharmacy?.actionLable}
                    </Button>
                </div>
            </Modal.Content>
        </div>
    );
};

export default PharmacyList;
