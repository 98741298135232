import UPGRADE from '_constants';
import { INIT } from '_config/initialState';
const { upgradeList: initialState } = INIT;

export default (state = initialState, action = {}) => {
    const { type, result, params, faliure, uploadStatus } = action;
    const { success, ...res } = state;
    switch (type) {
        case UPGRADE.UPGRADE_REQUEST_START:
            return { ...state, loading: true };
        case UPGRADE.UPGRADE_REQUEST_END:
            return { ...state, loading: false };
        case UPGRADE.UPGRADE_RECORD_REQUEST_START:
            return { ...state, recordUpating: true };
        case UPGRADE.UPGRADE_RECORD_REQUEST_END:
            return { ...state, recordUpating: false };
        case UPGRADE.UPGRADE_LIST_SUCCESS:
            return {
                ...state,
                result: {
                    ...result,
                    list: params.pageNumber
                        ? [...state.result.list, ...result.list]
                        : [...result.list],
                },
                params: params,
                faliure: null,
            };
        case UPGRADE.UPGRADE_UPLOAD_REQUEST_START:
            return { ...state, uploading: true };
        case UPGRADE.UPGRADE_UPLOAD_REQUEST_END:
            return { ...state, uploading: false };
        case UPGRADE.UPGRADE_UPLOAD_INIT:
            return {
                ...state,
                uploading: false,
                uploaded: false,
                uploadStatus: false,
            };    
        case UPGRADE.UPGRADE_LIST_FAILURE:
            return { ...state, faliure };
        case UPGRADE.UPGRADE_RECORD_FAILURE:
            return { ...state, faliure, success: false };
        case UPGRADE.UPGRADE_RECORD_SUCCESS:
            return { ...state, faliure, success: true };
        case UPGRADE.UPGRADE_MSG_CLEAR:
            return { ...res, faliure: null };
        case UPGRADE.UPGRADE_UPLOAD_FAILURE:
            return { ...state, uploaded: false, uploadStatus };
        case UPGRADE.UPGRADE_UPLOAD_SUCCESS:
            return { ...state, uploaded: true, uploadStatus: false };    
        default:
            return { ...state };
    }
};
export const upgradeDetails = (state) => state.upgradeList;
