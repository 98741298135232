import React from 'react';
import { Breadcrumb } from 'semantic-ui-react';
import './BreadcrumbComp.scss';

const BreadcrumbComp = (props) => {
    const { sections, handleLink } = props;
    const handleClick = (e) => {
        if (handleLink) handleLink(e);
    };

    return (
        <div className="breadcrumb-name">
            <Breadcrumb
                icon="right angle"
                sections={sections}
                onClick={(e) => handleClick(e)}
            />
        </div>
    );
};

export { BreadcrumbComp };
