export default {
    //ALERT
    SUCCESS: 'ALERT_SUCCESS',
    ERROR: 'ALERT_ERROR',
    CLEAR: 'ALERT_CLEAR',

    REQUEST_START: 'REQUEST_START',
    REQUEST_END: 'REQUEST_END',
    //LANGUAGE
    UPDATE_LANG: 'UPDATE_LANG',
    //LOGIN
    LOGIN_REQUEST: 'LOGIN_REQUEST',
    LOGIN_SUCCESS: 'LOGIN_SUCCESS',
    LOGIN_FAILURE: 'LOGIN_FAILURE',

    // LOGOUT
    LOGOUT: 'LOGOUT',
    LOGOUT_FAILURE: 'LOGOUT_FAILURE',

    // FORGOT
    FORGOT_REQUEST: 'FORGOT_REQUEST',
    FORGOT_SUCCESS: 'FORGOT_SUCCESS',
    FORGOT_FAILURE: 'FORGOT_FAILURE',

    //RESET
    RESET_REQUEST_START: 'RESET_REQUEST_START',
    RESET_REQUEST_END: 'RESET_REQUEST_END',
    RESET_SUCCESS: 'RESET_SUCCESS',
    RESET_FAILURE: 'RESET_FAILURE',

    // ADMIN
    ADMIN_REQUEST_START: 'ADMIN_REQUEST_START',
    ADMIN_REQUEST_END: 'ADMIN_REQUEST_END',
    ADMIN_LIST_SUCCESS: 'ADMIN_LIST_SUCCESS',
    ADMIN_LIST_FAILURE: 'ADMIN_LIST_FAILURE',
    ADMIN_SELECTED_ID: 'ADMIN_SELECTED_ID',
    ADMIN_ADD_FAILURE: 'ADMIN_ADD_FAILURE',
    ADMIN_EDIT_FAILURE: 'ADMIN_EDIT_FAILURE',
    ADMIN_DEL_FAILURE: 'ADMIN_DEL_FAILURE',
    ADMIN_CLEAR: 'ADMIN_CLEAR',
    ADMIN_RECORD_SUCCESS: 'ADMIN_RECORD_SUCCESS',
    CENTER_REQUEST_START: 'CENTER_REQUEST_START',
    CENTER_REQUEST_END: 'CENTER_REQUEST_END',
    CENTER_LIST_SUCCESS: 'CENTER_LIST_SUCCESS',
    CENTER_LIST_FAILURE: 'CENTER_LIST_FAILURE',

    // NUTRI
    NUTRI_REQUEST_START: 'NUTRI_REQUEST_START',
    NUTRI_REQUEST_END: 'NUTRI_REQUEST_END',
    NUTRI_PWD_REQ_START: 'NUTRI_PWD_REQ_START',
    NUTRI_PWD_REQ_END: 'NUTRI_PWD_REQ_END',
    NUTRI_PWD_SUCCESS: 'NUTRI_PWD_SUCCESS',
    NUTRI_PWD_FAILURE: 'NUTRI_PWD_FAILURE',
    NUTRI_PATIENT_REQUEST_START: 'NUTRI_PATIENT_REQUEST_START',
    NUTRI_PATIENT_REQUEST_END: 'NUTRI_PATIENT_REQUEST_END',
    NUTRI_PATIENT_SUCCESS: 'NUTRI_PATIENT_SUCCESS',
    NUTRI_PATIENT_FAILURE: 'NUTRI_PATIENT_FAILURE',
    NUTRI_LIST_SUCCESS: 'NUTRI_LIST_SUCCESS',
    NUTRI_LIST_FAILURE: 'NUTRI_LIST_FAILURE',
    NUTRI_SELECTED_ID: 'NUTRI_SELECTED_ID',
    NUTRI_EDIT_FAILURE: 'NUTRI_EDIT_FAILURE',
    NUTRI_CLEAR: 'NUTRI_CLEAR',
    NUTRI_RECORD_SUCCESS: 'NUTRI_RECORD_SUCCESS',
    NUTRI_UPLOAD_REQUEST_START: 'NUTRI_UPLOAD_REQUEST_START',
    NUTRI_UPLOAD_REQUEST_END: 'NUTRI_UPLOAD_REQUEST_END',
    NUTRI_UPLOAD_INIT: 'NUTRI_UPLOAD_INIT',
    NUTRI_UPLOAD_FAILURE: 'NUTRI_UPLOAD_FAILURE',
    NUTRI_UPLOAD_SUCCESS: 'NUTRI_UPLOAD_SUCCESS',
    NUTRI_UPLOAD_ERROR: 'NUTRI_UPLOAD_ERROR',
    NUTRI_UPLOAD_CLEAR: 'NUTRI_UPLOAD_CLEAR',
    NUTRI_UPLOAD_TIMEOUT: 'NUTRI_UPLOAD_TIMEOUT',

    // PATIENT
    PATIENT_REQUEST_START: 'PATIENT_REQUEST_START',
    PATIENT_REQUEST_END: 'PATIENT_REQUEST_END',
    PATIENT_LIST_SUCCESS: 'PATIENT_LIST_SUCCESS',
    PATIENT_LIST_FAILURE: 'PATIENT_LIST_FAILURE',
    PATIENT_TICKET_SUCCESS: 'PATIENT_TICKET_SUCCESS',
    PATIENT_TICKET_FAILURE: 'PATIENT_TICKET_FAILURE',
    PATIENT_UPGRADE_LOADING: 'PATIENT_UPGRADE_LOADING',
    PATIENT_UPGRADE_LOADED: 'PATIENT_UPGRADE_LOADED',
    PATIENT_UPGRADE_SUCCESS: 'PATIENT_UPGRADE_SUCCESS',
    PATIENT_UPGRADE_FAILURE: 'PATIENT_UPGRADE_FAILURE',
    PATIENT_MODAL_OPEN: 'PATIENT_MODAL_OPEN',
    PATIENT_MODAL_CLOSE: 'PATIENT_MODAL_CLOSE',
    PATIENT_MODAL_LOADED: 'PATIENT_MODAL_LOADED',
    PATIENT_MODAL_LOADING: 'PATIENT_MODAL_LOADING',

    //FILE_UP
    FILE_UP_REQUEST_START: 'FILE_UP_REQUEST_START',
    FILE_UP_REQUEST_END: 'FILE_UP_REQUEST_END',
    FILE_UP_FAILURE: 'FILE_UP_FAILURE',
    FILE_UP_SUCCESS: 'FILE_UP_SUCCESS',
    FILE_UP_INIT: 'FILE_UP_INIT',

    // KETO MAP
    MAP_REQUEST_START: 'MAP_REQUEST_START',
    MAP_REQUEST_END: 'MAP_REQUEST_END',
    MAP_LIST_SUCCESS: 'MAP_LIST_SUCCESS',
    MAP_LIST_FAILURE: 'MAP_LIST_FAILURE',
    MAP_UPLOAD_REQUEST_START: 'MAP_UPLOAD_REQUEST_START',
    MAP_UPLOAD_REQUEST_END: 'MAP_UPLOAD_REQUEST_END',
    MAP_UPLOAD_INIT: 'MAP_UPLOAD_INIT',
    MAP_UPLOAD_SUCCESS: 'MAP_UPLOAD_SUCCESS',
    MAP_UPLOAD_FAILURE: 'MAP_UPLOAD_FAILURE',
    NUTR_UPDATEFOOD_FAILURE: 'NUTR_UPDATEFOOD_FAILURE',
    NUTR_UPDATEFOOD_SUCCESS: 'NUTR_UPDATEFOOD_SUCCESS',
    NUTR_DELETEFOOD_SUCCESS: 'NUTR_DELETEFOOD_SUCCESS',
    NUTR_DELETEFOOD_FAILURE: 'NUTR_DELETEFOOD_FAILURE',

    // PROMOTIONS
    PROMO_REQUEST_START: 'PROMO_REQUEST_START',
    PROMO_REQUEST_END: 'PROMO_REQUEST_END',
    PROMO_CURRENT_REQUEST_START: 'PROMO_CUURENT_REQUEST_START',
    PROMO_CURRENT_REQUEST_END: 'PROMO_CUURENT_REQUEST_END',
    PROMO_LIST_SUCCESS: 'PROMO_LIST_SUCCESS',
    PROMO_LIST_FAILURE: 'PROMO_LIST_FAILURE',
    PROMO_RECORD_LIST_SUCCESS: 'PROMO_RECORD_LIST_SUCCESS',
    PROMO_RECORD_LIST_FAILURE: 'PROMO_RECORD_LIST_FAILURE',
    PROMO_ADD: 'PROMO_ADD',
    PROMO_EDIT: 'PROMO_EDIT',
    PROMO_UP: 'PROMO_UP',
    PROMO_DEL: 'PROMO_DEL',
    PROMO_ADD_FAILURE: 'PROMO_ADD_FAILURE',
    PROMO_EDIT_FAILURE: 'PROMO_EDIT_FAILURE',
    PROMO_DEL_FAILURE: 'PROMO_DEL_FAILURE',
    PROMO_UP_FAILURE: 'PROMO_UP_FAILURE',
    PROMO_MODAL_OPEN: 'PROMO_MODAL_OPEN',
    PROMO_MODAL_CLOSE: 'PROMO_MODAL_CLOSE',
    PROMO_MODAL_LOADING: 'PROMO_MODAL_LOADING',
    PROMO_MODAL_LOADED: 'PROMO_MODAL_LOADED',
    PROMO_TICKET_FAILURE: 'PROMO_TICKET_FAILURE',
    PROMO_PHARMA_FAILURE: 'PROMO_PHARMA_FAILURE',
    PROMO_PHARMA_SUCCESS: 'PROMO_PHARMA_SUCCESS',
    PROMO_BOXES_FAILURE: 'PROMO_BOXES_FAILURE',
    PROMO_BOXES_SUCCESS: 'PROMO_BOXES_SUCCESS',

    // INFO
    INFO_REQUEST_START: 'INFO_REQUEST_START',
    INFO_REQUEST_END: 'INFO_REQUEST_END',
    INFO_LIST_SUCCESS: 'INFO_LIST_SUCCESS',
    INFO_CLEAR_FAILURE: 'INFO_CLEAR_FAILURE',
    INFO_LIST_FAILURE: 'INFO_LIST_FAILURE',
    // INFO ADD
    INFO_ADD_FAILURE: 'INFO_ADD_FAILURE',
    // INFO EDIT
    INFO_EDIT_FAILURE: 'INFO_EDIT_FAILURE',
    // INFO DELETE
    INFO_DEL_FAILURE: 'INFO_DEL_FAILURE',
    // INFO SELECT
    INFO_SELECTED_ID: 'INFO_SELECTED_ID',

    // GDPR
    GDPR_REQUEST_START: 'GDPR_REQUEST_START',
    GDPR_REQUEST_END: 'GDPR_REQUEST_END',
    GDPR_LIST_SUCCESS: 'GDPR_LIST_SUCCESS',
    GDPR_CURRENT_SUCCESS: 'GDPR_CURRENT_SUCCESS',
    GDPR_CLEAR_FAILURE: 'GDPR_CLEAR_FAILURE',
    GDPR_LIST_FAILURE: 'GDPR_LIST_FAILURE',
    // GDPR ADD
    GDPR_ADD_FAILURE: 'GDPR_ADD_FAILURE',
    // GDPR EDIT
    GDPR_EDIT_FAILURE: 'GDPR_EDIT_FAILURE',
    // GDPR DELETE
    GDPR_DEL_FAILURE: 'GDPR_DEL_FAILURE',
    // GDPR SELECT
    GDPR_SELECTED_ID: 'GDPR_SELECTED_ID',

    // CHAT
    CHAT_REQUEST_START: 'CHAT_REQUEST_START',
    CHAT_REQUEST_END: 'CHAT_REQUEST_END',
    CHAT_NOTIF_START: 'CHAT_NOTIF_START',
    CHAT_NOTIF_END: 'CHAT_NOTIF_END',
    CHAT_NOTIF_FAIL: 'CHAT_NOTIF_FAIL',
    CHAT_NOTIF_SUCCESS: 'CHAT_NOTIF_SUCCESS',
    CHAT_TOKEN_SET: 'CHAT_TOKEN_SET',
    CHAT_TOKEN_FAIL: 'CHAT_TOKEN_FAIL',
    CHAT_MSGS: 'CHAT_MSGS',
    CHAT_LIST_SUCCESS: 'CHAT_LIST_SUCCESS',
    CHAT_CLEAR_FAILURE: 'CHAT_CLEAR_FAILURE',
    CHAT_LIST_FAILURE: 'CHAT_LIST_FAILURE',
    CHAT_RECORDING_START: 'CHAT_RECORDING_START',
    CHAT_RECORDING_STOP: 'CHAT_RECORDING_STOP',
    CHAT_CALLING_START: 'CHAT_CALLING_START',
    CHAT_CALLING_STOP: 'CHAT_CALLING_STOP',
    CHAT_CALLING_END: 'CHAT_CALLING_END',
    // CHAT ADD
    CHAT_ADD_FAILURE: 'CHAT_ADD_FAILURE',
    // CHAT EDIT
    CHAT_EDIT_FAILURE: 'CHAT_EDIT_FAILURE',
    // CHAT DELETE
    CHAT_DEL_FAILURE: 'CHAT_DEL_FAILURE',
    // CHAT SELECT
    CHAT_SELECTED_ID: 'CHAT_SELECTED_ID',
    CHAT_UPDATE_MSG_HISTORY: 'CHAT_UPDATE_MSG_HISTORY',
    CHAT_UPDATE_PREV_MSG_HISTORY: 'CHAT_UPDATE_PREV_MSG_HISTORY',
    // NUTRI
    CHAT_NUTRI_START: 'CHAT_NUTRI_START',
    CHAT_NUTRI_END: 'CHAT_NUTRI_END',
    CHAT_NUTRI_LOADING: 'CHAT_NUTRI_LOADING',
    CHAT_NUTRI_SUCCESS: 'CHAT_NUTRI_SUCCESS',
    CHAT_NUTRI_FAILURE: 'CHAT_NUTRI_FAILURE',
    CHAT_SET_KABICENTER: 'CHAT_SET_KABICENTER',
    CHAT_CHANGE_CENTER: 'CHAT_CHANGE_CENTER',
    // TIPS
    TIPS_REQUEST_START: 'TIPS_REQUEST_START',
    TIPS_REQUEST_END: 'TIPS_REQUEST_END',
    TIPS_RECORD_REQUEST_START: 'TIPS_RECORD_REQUEST_START',
    TIPS_RECORD_REQUEST_END: 'TIPS_RECORD_REQUEST_END',
    TIPS_LIST_SUCCESS: 'TIPS_LIST_SUCCESS',
    TIPS_CLEAR_FAILURE: 'TIPS_CLEAR_FAILURE',
    TIPS_LIST_FAILURE: 'TIPS_LIST_FAILURE',
    // TIPS ADD
    TIPS_ADD_FAILURE: 'TIPS_ADD_FAILURE',
    // TIPS EDIT
    TIPS_EDIT_FAILURE: 'TIPS_EDIT_FAILURE',
    // TIPS DELETE
    TIPS_DEL_FAILURE: 'TIPS_DEL_FAILURE',
    // TIPS SELECT
    TIPS_SELECTED_ID: 'TIPS_SELECTED_ID',

    //NUTRITINAL TIPS
    NUTR_FOODLIST_FAILURE: 'NUTR_FOODLIST_FAILURE',
    NUTR_FOODLIST_SUCCESS: 'NUTR_FOODLIST_SUCCESS',
    NUTR_FOODCAT_SUCCESS: 'NUTR_FOODCAT_SUCCESS',
    NUTR_FOODCAT_FAILURE: 'NUTR_FOODCAT_FAILURE',
    NUTR_ERROR: 'NUTR_ERROR',
    NUTR_REQUEST_START: 'NUTR_REQUEST_START',
    NUTR_REQUEST_END: 'NUTR_REQUEST_END',
    NUTR_CLEAR: 'NUTR_CLEAR',
    NUTR_FAILURE: 'NUTR_FAILURE',
    NUTR_FOODUNIT_SUCCESS: 'NUTR_FOODUNIT_SUCCESS',
    NUTR_FOODUNIT_FAILURE: 'NUTR_FOODUNIT_FAILURE',
    NUTR_FOODCREATE_FAILURE: 'NUTR_FOODCREATE_FAILURE',
    NUTR_FOODCREATE_SUCCESS: 'NUTR_FOODCREATE_SUCCESS',
    NUTR_GETFOOD_SUCCESS: 'NUTR_GETFOOD_SUCCESS',
    NUTR_GETFOOD_FAILURE: 'NUTR_GETFOOD_FAILURE',
    // UPGRADE
    UPGRADE_REQUEST_START: 'UPGRADE_REQUEST_START',
    UPGRADE_REQUEST_END: 'UPGRADE_REQUEST_END',
    UPGRADE_RECORD_REQUEST_START: 'UPGRADE_RECORD_REQUEST_START',
    UPGRADE_RECORD_REQUEST_END: 'UPGRADE_RECORD_REQUEST_END',
    UPGRADE_LIST_SUCCESS: 'UPGRADE_LIST_SUCCESS',
    UPGRADE_LIST_FAILURE: 'UPGRADE_LIST_FAILURE',
    UPGRADE_RECORD_FAILURE: 'UPGRADE_RECORD_FAILURE',
    UPGRADE_RECORD_SUCCESS: 'UPGRADE_RECORD_SUCCESS',
    UPGRADE_MSG_CLEAR: 'UPGRADE_MSG_CLEAR',

    UPGRADE_KETO_TO_CEAN_FAILUARE: 'UPGRADE_KETO_TO_CEAN_FAILUARE',
    UPGRADE_KETO_TO_CEAN_SUCCESS: 'UPGRADE_KETO_TO_CEAN_SUCCESS',
    UPGRADE_UPLOAD_REQUEST_START: 'UPGRADE_UPLOAD_REQUEST_START',
    UPGRADE_UPLOAD_REQUEST_END: 'UPGRADE_UPLOAD_REQUEST_END',
    UPGRADE_UPLOAD_INIT: 'UPGRADE_UPLOAD_INIT',
    UPGRADE_UPLOAD_FAILURE: 'UPGRADE_UPLOAD_FAILURE',
    UPGRADE_UPLOAD_SUCCESS: 'UPGRADE_UPLOAD_SUCCESS',
    UPGRADE_UPLOAD_ERROR: 'UPGRADE_UPLOAD_ERROR',
    UPGRADE_UPLOAD_CLEAR: 'UPGRADE_UPLOAD_CLEAR',
    UPGRADE_UPLOAD_TIMEOUT: 'UPGRADE_UPLOAD_TIMEOUT',
    // DELETE PATIENT PROFILE
    PATIENT_DEL_FAILURE: 'PATIENT_DEL_FAILURE',
    PATIENT_DEL_SUCCESS: 'PATIENT_DEL_SUCCESS',
    PATIENT_DEL_START: 'PATIENT_DEL_START',
    PATIENT_DEL_END: 'PATIENT_DEL_END',
    // SEARCH
    GETALL_REQUEST: 'SEARCH_GETALL_REQUEST',
    GETALL_SUCCESS: 'SEARCH_GETALL_SUCCESS',
    GETALL_FAILURE: 'SEARCH_GETALL_FAILURE',
    MENU_ITEM_SELECTED: 'MENU_ITEM_SELECTED',

    // NEWS
    NEWS_REQUEST_START: 'NEWS_REQUEST_START',
    NEWS_REQUEST_END: 'NEWS_REQUEST_END',
    NEWS_LIST_SUCCESS: 'NEWS_LIST_SUCCESS',
    NEWS_CLEAR_FAILURE: 'NEWS_CLEAR_FAILURE',
    NEWS_LIST_FAILURE: 'NEWS_LIST_FAILURE',
    // NEWS ADD
    NEWS_ADD_FAILURE: 'NEWS_ADD_FAILURE',
    // NEWS EDIT
    NEWS_EDIT_FAILURE: 'NEWS_EDIT_FAILURE',
    // NEWS DELETE
    NEWS_DEL_FAILURE: 'NEWS_DEL_FAILURE',
    // NEWS SELECT
    NEWS_SELECTED_ID: 'NEWS_SELECTED_ID',

    //VALIDATION
    VALIDATION: {
        MIN: 4,
        MAXINPUT: 55,
        MAXTEXTAREA: 25000,
        FILE: {
            VID: { accept: 'video/mp4', maxSize: 424673280 },
            IMG: { accept: 'image/png', maxSize: 20971520 },
        },
    },

    // PATIENT_DETAIL
    PATIENT_DETAIL_REQUEST_START: 'PATIENT_DETAIL_REQUEST_START',
    PATIENT_DETAIL_REQUEST_END: 'PATIENT_DETAIL_REQUEST_END',
    PATIENT_INFO_SUCCESS: 'PATIENT_INFO_SUCCESS',
    PATIENT_INFO_FAILURE: 'PATIENT_INFO_FAILURE',
    // PATIENT DETAIL UPLOAD
    PATIENT_DETAILS_UPLOAD_REQUEST_START: 'PATIENT_DETAILS_UPLOAD_REQUEST_START',
    PATIENT_DETAILS_UPLOAD_REQUEST_END: 'PATIENT_DETAILS_UPLOAD_REQUEST_END',
    PATIENT_DETAILS_UPLOAD_INIT: 'PATIENT_DETAILS_UPLOAD_INIT',
    PATIENT_DETAILS_UPLOAD_FAILURE: 'PATIENT_DETAILS_UPLOAD_FAILURE',
    PATIENT_DETAILS_UPLOAD_SUCCESS: 'PATIENT_DETAILS_UPLOAD_SUCCESS',
    PATIENT_DETAILS_UPLOAD_ERROR: 'PATIENT_DETAILS_UPLOAD_ERROR',
    PATIENT_DETAILS_UPLOAD_CLEAR: 'PATIENT_DETAILS_UPLOAD_CLEAR',
    PATIENT_DETAILS_UPLOAD_TIMEOUT: 'PATIENT_DETAILS_UPLOAD_TIMEOUT',
    // PATIENT HEALTH
    PATIENT_HEALTH_REQUEST_START: 'PATIENT_HEALTH_REQUEST_START',
    PATIENT_HEALTH_REQUEST_END: 'PATIENT_HEALTH_REQUEST_END',
    PATIENT_HEALTH_SUCCESS: 'PATIENT_HEALTH_SUCCESS',
    PATIENT_HEALTH_FAILURE: 'PATIENT_HEALTH_FAILURE',

    UPDATE_PATIENT_INFO_SUCCESS: 'UPDATE_PATIENT_INFO_SUCCESS',
    UPDATE_PATIENT_INFO_FAILURE: 'UPDATE_PATIENT_INFO_FAILURE',
    UPDATE_PATIENT_INFO_REQUEST_START: 'UPDATE_PATIENT_INFO_REQUEST_START',
    UPDATE_PATIENT_INFO_REQUEST_END: 'UPDATE_PATIENT_INFO_REQUEST_END',

    //MEDICAL_RECORD
    MEDICAL_RECORD_FAILURE: 'MEDICAL_RECORD_FAILURE',
    MEDICAL_RECORD_SUCCESS: 'MEDICAL_RECORD_SUCCESS',
    MEDICAL_RECORD_BMI_FAILURE: 'MEDICAL_RECORD_BMI_FAILURE',
    MEDICAL_RECORD_BMI_SUCCESS: 'MEDICAL_RECORD_BMI_SUCCESS',
    MEDICAL_RECORD_BM_FAILURE: 'MEDICAL_RECORD_BM_FAILURE',
    MEDICAL_RECORD_BM_SUCCESS: 'MEDICAL_RECORD_BM_SUCCESS',
    MEDICAL_RECORD_ANALYTICS_SUCCESS: 'MEDICAL_RECORD_ANALYTICS_SUCCESS',
    MEDICAL_RECORD_ANALYTICS_FAILURE: 'MEDICAL_RECORD_ANALYTICS_FAILURE',
    MEDICAL_RECORD_REQUEST_START: 'MEDICAL_RECORD_REQUEST_START',
    MEDICAL_RECORD_REQUEST_END: 'MEDICAL_RECORD_REQUEST_END',
    MEDICAL_RECORD_BMI_START: 'MEDICAL_RECORD_BMI_START',
    MEDICAL_RECORD_BMI_END: 'MEDICAL_RECORD_BMI_END',
    MEDICAL_RECORD_BM_START: 'MEDICAL_RECORD_BM_START',
    MEDICAL_RECORD_BM_END: 'MEDICAL_RECORD_BM_END',
    MEDICAL_RECORD_ANALYTICS_START: 'MEDICAL_RECORD_ANALYTICS_START',
    MEDICAL_RECORD_ANALYTICS_END: 'MEDICAL_RECORD_ANALYTICS_END',
    TIMEOUT: 5000,

    ROLES: {
        UNAFFILIATED: 'ROLE_UNAFFILIATED',
        CEAN: 'ROLE_CEAN',
        KETO: 'ROLE_KETOSTERIL',
        ADMIN: 'ROLE_ADMINISTRATOR',
    },
};
