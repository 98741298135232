/* eslint-disable react/no-multi-comp */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Modal, Button, Menu } from 'semantic-ui-react';
import { Image } from '_components';
import './LogoutModal.scss';
const LogoutModal = (props) => {
    const { menu, config, modalOpen } = props;
    const [isModalOpen, setModal] = useState(modalOpen);
    const handleOpen = () => {
        setModal(true);
    };
    const handleClose = () => {
        setModal(false);
    };
    const button = () => (
        <Menu.Item as="a" onClick={handleOpen}>
            <Image src={menu.icon} />
            <span>{menu?.lable ?? menu?.name}</span>
        </Menu.Item>
    );
    return (
        <Modal
            open={isModalOpen}
            onClose={handleClose}
            size="large"
            trigger={button()}
            className="log-out-modal"
        >
            <div className="log-out-main">
                <label>{config?.heading}</label>
            </div>
            <div className="log-out-footer-button">
                <Button
                    className="action-logout"
                    onClick={menu.handel}
                    size="large"
                >
                    {config?.actionOk ?? 'Yes'}
                </Button>
                <Button
                    className="action-cancel"
                    onClick={handleClose}
                    size="large"
                >
                    {config?.actionCancel ?? 'Cancel'}
                </Button>
            </div>
        </Modal>
    );
};

LogoutModal.propTypes = {
    menu: PropTypes.object.isRequired,
    config: PropTypes.object,
};

export { LogoutModal };
