import { Apis, handleResponse, handelError } from './services';
// GET CENTER LIST
const getCenterList = () => {
    const requestOptions = {
        method: 'GET',
    };
    return Apis()(`/user/admin/centerlist`, requestOptions)
        .then(handleResponse)
        .then((data) => ({ status: true, data }))
        .catch(handelError);
};
// GET ADMIN LIST
const getAll = ({ pageNumber, orderBy, direction, search, category }) => {
    const requestOptions = {
        method: 'GET',
        params: {
            pageNumber,
            orderBy,
            direction,
            search,
            category,
        },
    };
    return Apis()(`/user/admin/list`, requestOptions)
        .then(handleResponse)
        .then((data) => ({ status: true, ...data }))
        .catch(handelError);
};
// ADD NEW ADMIN
const addAdmin = (data) => {
    const requestOptions = {
        method: 'POST',
        data,
    };
    return Apis()(`/user/admin/`, requestOptions)
        .then(handleResponse)
        .then(() => ({ status: true }))
        .catch(handelError);
};
// GET ADMIN RECORD
const editAdmin = (data) => {
    const requestOptions = {
        method: 'GET',
    };
    const { id } = data;
    return Apis()(`/user/admin/${id}`, requestOptions)
        .then(handleResponse)
        .then((res) => ({ status: true, ...res }))
        .catch(handelError);
};
// UPDATE ADMIN RECORD
const updateAdmin = (data) => {
    const { id, ...rest } = data;
    const requestOptions = {
        method: 'PUT',
        data: rest,
    };

    return Apis()(`/user/admin/${id}`, requestOptions)
        .then(handleResponse)
        .then(() => ({ status: true }))
        .catch(handelError);
};
// DELETE ADMIN RECORD
const deleteAdmin = (data) => {
    const requestOptions = {
        method: 'DELETE',
    };
    const { id } = data;
    return Apis()(`/user/admin/${id}`, requestOptions)
        .then(handleResponse)
        .then(() => ({ status: true }))
        .catch(handelError);
};

export const adminService = {
    getCenterList,
    getAll,
    editAdmin,
    addAdmin,
    updateAdmin,
    deleteAdmin,
};
