import React from 'react';
import PropTypes from 'prop-types';
import './ChatHistory.scss';
import ChatContent from './ChatContent';
import { Dimmer, Loader } from 'semantic-ui-react';
import { map, debounce } from 'lodash';
import { ChatHistoryHooks } from './ChatHistoryHooks';
import { chatDate } from '_utils';

const ChatHistory = (props) => {
    const {
        chatClient,
        roomHistory,
        selectedId,
        updateMsgHistory,
        updatePrevMsgHistory,
        tab,
        isAdmin,
        config,
        userName,
    } = props;
    const { handelScrollHook, chatMsgContentRef } = ChatHistoryHooks({
        selectedId,
        chatClient,
        tab,
        updateMsgHistory,
        roomHistory,
    });
    const handelScroll = () => {
        handelScrollHook(updatePrevMsgHistory);
    };
    return (
        <div
            onScroll={debounce(handelScroll, 200)}
            ref={chatMsgContentRef}
            className="chat-history"
        >
            <div className="chat-msg-content">
                {chatClient !== undefined &&
                    selectedId?.curIndex === undefined && (
                        <div className="no-msg">
                            <p>{config?.selectUser}</p>
                        </div>
                    )}
                {!roomHistory.length && selectedId?.curIndex !== undefined ? (
                    <div className="no-msg">
                        <p>{config?.noMsg}</p>
                    </div>
                ) : (
                    ''
                )}
                {chatClient === undefined && (
                    <Dimmer active inverted>
                        <Loader />
                    </Dimmer>
                )}
                {selectedId?.roomInfo &&
                    map(roomHistory, (item, key) => {
                        const formatDate = chatDate(item, roomHistory, key);
                        return (
                            <ChatContent
                                curMember={
                                    selectedId?.memberInfo?.memberName ??
                                    selectedId?.memberInfo
                                }
                                senderName={
                                    tab === 'NUTRITIONISTS'
                                        ? props?.selectedId?.roomInfo
                                              ?.attributes
                                        : props?.selectedId?.roomInfo
                                              ?.attributes?.name
                                }
                                key={key}
                                isAdmin={isAdmin}
                                item={item}
                                formatDate={formatDate}
                                userName={userName}
                            />
                        );
                    })}
            </div>
        </div>
    );
};

ChatHistory.propTypes = {
    chatApp: PropTypes.object,
};

export { ChatHistory };
