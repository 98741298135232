import React, { useRef } from 'react';
import { Segment, Container } from 'semantic-ui-react';
import NutritionRoutes from './Nutrition.Routes';
import { lang } from '_config';
import './NutritionistPage.scss';
const NutritionistPage = (props) => {
    const cref = useRef('NutritionistPage');
    const { nutritionist: CONFIG } = lang;

    return (
        <Container
            cref={cref}
            fluid
            id="NutritionistPage"
            className={`private-layout`}
        >
            <Segment basic>
                <NutritionRoutes cref={cref} CONFIG={CONFIG} {...props} />
            </Segment>
        </Container>
    );
};

export { NutritionistPage };
