import FORGOT from '_constants';
import { INIT } from '_config/initialState';
const { forgotData: initialState } = INIT;
export default (state = initialState, action = {}) => {
    switch (action.type) {
        case FORGOT.FORGOT_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case FORGOT.FORGOT_SUCCESS:
        case FORGOT.FORGOT_FAILURE:
            return {
                ...state,
                loading: false,
            };
        default:
            return state;
    }
};
export const forgotDetails = (state) => state.forgotData;
