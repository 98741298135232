import React, { useEffect, useCallback, useReducer } from 'react';
import { Image } from '_components/Image';
import './ChatRinging.scss';
import { Ring } from '_resources';
import { isEmpty } from 'lodash';
import { connetRoom } from '_utils/chat';
import { initialState, reducer } from './ChatRing.Hooks';
//import VideoCall from './VideoCall';
import VideoCall from './VideoCall';
import { callEnd } from 'images';
const ChatRinging = (props) => {
    const { calling, endCallAction, notifAction, roomInfo, tab } = props;
    const [chatData, dispatch] = useReducer(reducer, initialState);
    const { videoData } = chatData;
    const {
        createdBy: roomName,
        attributes: { name: Caller },
    } = roomInfo;
    const [fName, lName] = Caller.split(' ');
    const userName =
        lName === undefined
            ? [fName.charAt(0) ?? '']
            : [fName.charAt(0), lName.charAt(0) ?? ''];
    const initVideoApp = useCallback(async () => {
        connetRoom(calling, roomName)
            .then((res) => {
                const [userId] = roomName.split('_');
                const data = {
                    callType: calling?.type.toUpperCase(),
                    userIdList: [parseInt(userId)],
                    callerType:
                        tab === 'PATIENTS' ? 'NUTRITIONIST' : 'KETOCENTER',
                };
                notifAction(data);
                if (res) dispatch({ type: 'roomData', payload: { ...res } });
            })
            .catch(() => {
                dispatch({ type: 'roomData', payload: {} });
            });
    }, [calling, roomName, notifAction, tab]);
    useEffect(() => {
        const myAudio = new Audio(Ring);
        // myAudio.loop = true;
        myAudio.play();
        initVideoApp(myAudio);
        return () => {
            myAudio.pause();
            !isEmpty(calling) && endCallAction();
        };
    }, [calling, endCallAction, initVideoApp]);
    return (
        <div className="main-section" id="chat-ringing">
            {isEmpty(videoData) ? (
                <div className="ring-container">
                    <div className="ring-text">Calling...</div>
                    <div className="ring-user">
                        <div className="ring-img">{userName.join('')}</div>
                    </div>
                    {/* <div className="ring-user-name">{Caller}</div> */}
                    <div className="ring-user-calling">
                        <Image src={callEnd} />
                    </div>
                </div>
            ) : (
                <VideoCall
                    userName={userName}
                    Caller={Caller}
                    calling={calling}
                    videoRoom={videoData}
                    endCall={endCallAction}
                />
            )}
        </div>
    );
};

export { ChatRinging };
