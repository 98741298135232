import React, { useRef, useEffect } from 'react';
import { Segment, Container } from 'semantic-ui-react';
import { Search, GridList, UploadCsvModal } from '_components';
import { lang } from '_config';
import { map, isEmpty } from 'lodash';
import { ActionButton } from './ActionButton';
import './UpgradePage.scss';
const UpgradePage = (props) => {
    const cref = useRef('UpgradePage');
    const { upgrade: CONFIG } = lang;
    // get all the values from the container
    const {
        state: {
            userDetails: { roles },
            upgradeList: {
                params,
                loading,
                success,
                recordUpating,
                result: { list, ...filters },
                csvLoading,
                csvParams,
                uploaded,
                uploading,
                uploadStatus,
            },
        },
        actions: { getAll, enrollPatient, upgradeUpload, initModal },
    } = props;
    // setup list data from db
    const pageData = map(list, (item) => ({
        ...item,
        Name: `${item?.fullName ?? '---'}`,
        Cean: item?.id ?? '---',
        Contact: item?.phone ?? '---',
        Email: item?.email ?? '---',
        ID: item?.id ?? '---',
    }));
    // setup the patient list
    const listData = {
        sorting: [
            { direction: 'DESC' },
            { direction: 'DESC' },
            false,
            false,
            false,
        ],
        cellWidth: [3, 3, 3, 5, 2],
        columns: ['Name', 'ID', 'Contact', 'Email'],
        isHtml: [true, false, false, false],
        isDisable: filters.totalElements <= pageData.length,
        actionList: getAll,
        loading,
        filters,
        params,
        cref,
        roles,
        pageData,
    };
    useEffect(() => {
        if (isEmpty(params)) getAll();
    }, [getAll, params]);
    return (
        <Container
            cref={cref}
            fluid
            id="UpgradePage"
            className={`private-layout`}
        >
            <Segment basic>
                <div className="heading">
                    {CONFIG.pageHeading} <sup>®</sup>
                </div>
                <div className="actions">
                    <Search
                        config={CONFIG}
                        params={params}
                        actionList={listData?.actionList}
                        filters={filters}
                        width="full"
                    />
                </div>
                <GridList
                    history={props?.history}
                    config={CONFIG}
                    listData={listData}
                >
                    {(id, ...[data]) => (
                        <ActionButton
                            enrollPatient={enrollPatient}
                            key={id}
                            config={CONFIG}
                            success={success}
                            recordUpating={recordUpating}
                            record={{
                                id: data?.Cean.replace('/', '-'),
                                ...data,
                            }}
                        />
                    )}
                </GridList>
                {CONFIG?.uploadRequired ? <UploadCsvModal
                    lang={CONFIG.uploadModal}
                    uploaded={uploaded}
                    uploading={uploading}
                    uploadFile={upgradeUpload}
                    initModal={initModal}
                    loading={csvLoading}
                    actionList={getAll}
                    params={csvParams}
                    uploadStatus={uploadStatus}
                    withInfoIcon
                    buttonInfoText={CONFIG?.uploadButtonInfoText}
                /> : ''}
            </Segment>
        </Container>
    );
};

export { UpgradePage };
