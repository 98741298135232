import React, { useEffect } from 'react';
import { Container, Button, Form } from 'semantic-ui-react';
import { isEmpty } from 'lodash';
import {
    BreadcrumbComp,
    Image,
    FileUpload,
    TextArea,
    Input,
} from '_components';
import { useForm } from 'react-hook-form';
import { validMinMaxInput, validMinMaxTextArea, getFileDetail } from '_utils';
import { times } from 'images';
import CONST from '_constants';

const NoticesAdd = (props) => {
    const { register, handleSubmit, watch, errors, setValue } = useForm();
    const watchAllFields = watch(['title', 'desc']);
    const {
        goBack,
        config,
        config: {
            details,
            details: { form },
        },
        state: { filesList },
        actions: { clearFileDetails, addNews },
    } = props;
    const sections = [
        {
            key: 'myAdminListPage',
            content: config.pageHeading,
            onClick: goBack,
        },
        {
            key: 'myAdminAddPage',
            content: details?.breadcrumbForAdd ?? 'Add',
            active: true,
        },
    ];
    const newsAddVidUrl = getFileDetail(filesList, 'newsAddVid', 'url');
    const newsAddImgUrl = getFileDetail(filesList, 'newsAddImg', 'url');
    const newsUploadVid = getFileDetail(
        filesList,
        'newsAddVid',
        'uploading',
        true
    );
    const newsUploadImg = getFileDetail(
        filesList,
        'newsAddImg',
        'uploading',
        true
    );
    const onSubmit = (data) => {
        addNews({
            title: data?.title,
            description: data?.desc,
            image: newsAddImgUrl,
            video: newsAddVidUrl,
        });
        setTimeout(() => goBack(true), CONST.TIMEOUT);
    };
    const { title, desc } = watchAllFields;
    const validateForm = () => {
        return !validMinMaxInput(title) &&
            !validMinMaxTextArea(desc) &&
            !newsUploadVid &&
            !newsUploadImg &&
            isEmpty(errors)
            ? false
            : true;
    };
    useEffect(() => {
        clearFileDetails();
    }, [clearFileDetails]);
    return (
        <Container fluid id="InfoAdd" className={`private-layout`}>
            <BreadcrumbComp sections={sections} />
            <div className="add-container">
                <div className="add-heading">
                    <div className="add-heading-text">
                        {details?.pageHeading ?? 'INFORMATION DATA'}
                    </div>
                    <div className="add-heading-subtext">
                        {details?.pageSubHeadingForAdd}
                    </div>
                    <div className="add-close">
                        <Image src={times} onClick={goBack} />
                    </div>
                </div>
                <div className="add-form">
                    <Form onSubmit={handleSubmit(onSubmit)} unstackable>
                        <Form.Field width={16}>
                            <Input
                                name="title"
                                label={form?.title?.label}
                                placeholder={form?.title?.placeholder}
                                refs={{ register }}
                                count
                            />
                        </Form.Field>
                        <Form.Group>
                            <Form.Field width={6}>
                                <FileUpload
                                    label={form?.img?.label}
                                    placeholder={form?.img?.placeholder}
                                    limit={form?.img?.limit}
                                    optional={details.optional}
                                    name="newsAddImg"
                                />
                            </Form.Field>
                            <Form.Field width={6}>
                                <FileUpload
                                    label={form?.video?.label}
                                    placeholder={form?.video?.placeholder}
                                    limit={form?.video?.limit}
                                    optional={details.optional}
                                    video
                                    name="newsAddVid"
                                />
                            </Form.Field>
                        </Form.Group>
                        <Form.Field width={16}>
                            <TextArea
                                name="desc"
                                label={form?.desc?.label}
                                rows={6}
                                placeholder={form?.desc?.placeholder}
                                refs={{ register, setValue }}
                                count
                                preview
                                content={{
                                    desc,
                                    title,
                                    imageUrl: newsAddImgUrl,
                                    videoUrl: newsAddVidUrl,
                                }}
                                editor
                            />
                        </Form.Field>
                        <Button
                            className="add-action-button"
                            type="submit"
                            disabled={validateForm()}
                        >
                            {form?.actionLableForAdd ?? 'Add'}
                        </Button>
                    </Form>
                </div>
            </div>
        </Container>
    );
};

export { NoticesAdd };
