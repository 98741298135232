/* eslint-disable react/no-multi-comp */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
// import { Fraction } from 'fractional';
import { Grid, Segment } from 'semantic-ui-react';
import Calendar from 'react-calendar';
import { filter, map, isEmpty } from 'lodash';
import { getQuantity } from '_utils';
const FoodItems = (props) => {
    const { item, symptomLabel } = props;
    const curLabel = symptomLabel
        ? symptomLabel[item?.food?.foodUnit?.name]
        : item?.food?.foodUnit?.name;
    const getPackets = (input) => {
        return `${getQuantity(input)} ${curLabel}`;
    };
    return (
        <div className="col-item">
            <div className="col-item-heading">{item?.food?.name}</div>
            <div className="col-item-qty">{getPackets(item?.quantity)}</div>
        </div>
    );
};
const SectionHealth = (props) => {
    const {
        healthDiary,
        getHealthRec,
        health: healthData,
        id,
        symptoms,
        symp,
        symptomLabel,
    } = props;
    const curDate = new Date();
    const onChange = (res) => {
        getHealthRec({ day: res, userId: id });
    };
    const { loading, params, result } = healthData;
    const breakFast = filter(result, { category: 'BREAKFAST' });
    const mrgSnack = filter(result, { category: 'MORNING_SNACK' });
    const eveSnack = filter(result, { category: 'EVENING_SNACK' });
    const lunch = filter(result, { category: 'LUNCH' });
    const dinner = filter(result, { category: 'DINNER' });
    const nightSnack = filter(result, { category: 'NIGHT_SNACK' });
    // console.log(breakFast, lunch, dinner);
    useEffect(() => {
        if (
            (params === undefined && !loading) ^
            (params !== undefined && !loading && params?.id !== id)
        )
            getHealthRec({ day: curDate, userId: id });
    }, [getHealthRec, id, params, curDate, loading]);
    return (
        <Segment.Group className="section-health">
            <Segment className="section-header">{healthDiary?.title}</Segment>
            <Segment loading={loading} className="sub-section">
                <Grid columns={2}>
                    <Grid.Column className="section-calendar" width={5}>
                        <div>
                            {params !== undefined && (
                                <Calendar
                                    locale={healthDiary?.locale}
                                    maxDate={params?.maxDate}
                                    onChange={onChange}
                                    value={params?.date}
                                />
                            )}
                        </div>
                    </Grid.Column>
                    <Grid.Column className="section-details" width={11}>
                        <Grid.Row>
                            <div className="date-header">
                                {params?.displayDate}
                            </div>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid
                                celled="internally"
                                className="health-diary-section"
                            >
                                <div className="health-diary-section-values heading-border">
                                    <div className="col-head">
                                        <div>{healthDiary?.breakfast}</div>
                                    </div>
                                    <div className="col-head">
                                        <div>{healthDiary?.mrgSnack}</div>
                                    </div>
                                    <div className="col-head">
                                        <div>{healthDiary?.lunch}</div>
                                    </div>
                                    <div className="col-head">
                                        <div>{healthDiary?.eveSnack}</div>
                                    </div>
                                    <div className="col-head">
                                        <div>{healthDiary?.dinner}</div>
                                    </div>
                                    {healthDiary?.nightSnack && <div className="col-head">
                                        <div>{healthDiary?.nightSnack}</div>
                                    </div>}
                                </div>
                                <div className="health-diary-section-values">
                                    <div className="col break-fast">
                                        {isEmpty(breakFast) ? (
                                            <div className="mg-top-20">
                                                {healthDiary?.noRecord}
                                            </div>
                                        ) : (
                                            map(breakFast, (item, index) => {
                                                return (
                                                    <FoodItems
                                                        key={index}
                                                        item={item}
                                                        symptomLabel={
                                                            symptomLabel
                                                        }
                                                    />
                                                );
                                            })
                                        )}
                                    </div>
                                    <div className="col break-fast">
                                        {isEmpty(mrgSnack) ? (
                                            <div className="mg-top-20">
                                                {healthDiary?.noRecord}
                                            </div>
                                        ) : (
                                            map(mrgSnack, (item, index) => {
                                                return (
                                                    <FoodItems
                                                        key={index}
                                                        item={item}
                                                        symptomLabel={
                                                            symptomLabel
                                                        }
                                                    />
                                                );
                                            })
                                        )}
                                    </div>
                                    <div className="col lunch">
                                        {isEmpty(lunch) ? (
                                            <div className="mg-top-20">
                                                {healthDiary?.noRecord}
                                            </div>
                                        ) : (
                                            map(lunch, (item, index) => {
                                                return (
                                                    <FoodItems
                                                        key={index}
                                                        item={item}
                                                        symptomLabel={
                                                            symptomLabel
                                                        }
                                                    />
                                                );
                                            })
                                        )}
                                    </div>
                                    <div className="col break-fast">
                                        {isEmpty(eveSnack) ? (
                                            <div className="mg-top-20">
                                                {healthDiary?.noRecord}
                                            </div>
                                        ) : (
                                            map(eveSnack, (item, index) => {
                                                return (
                                                    <FoodItems
                                                        key={index}
                                                        item={item}
                                                        symptomLabel={
                                                            symptomLabel
                                                        }
                                                    />
                                                );
                                            })
                                        )}
                                    </div>
                                    <div className="col dinner">
                                        {isEmpty(dinner) ? (
                                            <div className="mg-top-20">
                                                {healthDiary?.noRecord}
                                            </div>
                                        ) : (
                                            map(dinner, (item, index) => {
                                                return (
                                                    <FoodItems
                                                        key={index}
                                                        item={item}
                                                        symptomLabel={
                                                            symptomLabel
                                                        }
                                                    />
                                                );
                                            })
                                        )}
                                    </div>
                                    {healthDiary?.nightSnack && <div className="col night-snack">
                                        {isEmpty(nightSnack) ? (
                                            <div className="mg-top-20">
                                                {healthDiary?.noRecord}
                                            </div>
                                        ) : (
                                            map(nightSnack, (item, index) => {
                                                return (
                                                    <FoodItems
                                                        key={index}
                                                        item={item}
                                                        symptomLabel={
                                                            symptomLabel
                                                        }
                                                    />
                                                );
                                            })
                                        )}
                                    </div>}
                                </div>
                            </Grid>
                        </Grid.Row>
                        <Grid.Row>
                            <div className="symptoms">
                                <div className="hd-header">
                                    {healthDiary?.symptoms}
                                </div>
                                {isEmpty(symptoms) ? (
                                    <div className="mg-top-20  pl-1">
                                        {healthDiary?.noRecord}
                                    </div>
                                ) : (
                                    map(symptoms, (symptom, index) => {
                                        return (
                                            <div
                                                key={index}
                                                className="mg-top-20"
                                            >
                                                <div className="symptoms-subtitle">
                                                    {
                                                        symp[
                                                            symptom?.symptoms
                                                                ?.name
                                                        ]
                                                    }
                                                </div>
                                                <div className="note">
                                                    {symptom?.notes}
                                                </div>
                                            </div>
                                        );
                                    })
                                )}
                            </div>
                        </Grid.Row>
                    </Grid.Column>
                </Grid>
            </Segment>
        </Segment.Group>
    );
};

SectionHealth.propTypes = {
    healthDiary: PropTypes.object.isRequired,
};

export default SectionHealth;
