import { connect } from 'react-redux';
import { alertActions, ketoMapActions, userActions } from '_actions';
import { KetomapPage } from '_pages';
function mapState(state) {
    const {
        alertData,
        mapData,
        loginData: { userDetails },
    } = state;
    return { state: { alertData, mapData, userDetails } };
}

const actionCreators = (dispatch) => {
    return {
        actions: {
            clearAlerts: () => dispatch(alertActions.clear()),
            getKetoList: (queryParams) => {
                dispatch(ketoMapActions.getAll(queryParams));
            },
            initModal: () => dispatch(ketoMapActions.isUpload()),
            mapUpload: (file) => {
                dispatch(ketoMapActions.uploadMap(file));
            },
            logOut: () => dispatch(userActions.logout()),
        },
    };
};

export const KetomapContainer = connect(mapState, actionCreators)(KetomapPage);
