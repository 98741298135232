/* eslint-disable no-unsafe-finally */
import React, { useEffect, useRef, useMemo } from 'react';
import { Segment, Container } from 'semantic-ui-react';
import { map, isEmpty } from 'lodash';
import { BreadcrumbComp, Search, GridList, UploadCsvModal } from '_components';
import { lang } from '_config';
import './KetomapPage.scss';
import { Redirect } from 'react-router-dom';
const KetomapPage = (props) => {
    const cref = useRef('KetomapPage');
    const { ketoMap: CONFIG } = lang;
    const sections = [
        {
            key: 'myNutritionists',
            content: CONFIG?.pageHeading,
            active: true,
        },
    ];
    const {
        actions: { getKetoList, logOut, mapUpload, initModal },
        state: {
            mapData: {
                loading,
                result: { list, ...filters },
                params,
                uploaded,
                uploading,
                uploadStatus,
            },
            userDetails: { roles },
            alertData: { message },
        },
    } = props;
    const filterList = useMemo(() => {
        return map(list, (record) => {
            return {
                ...record,
                cordinate: `${record['latitude']} <br/> ${record['longitude']}`,
            };
        });
    }, [list]);
    const notAuthenticate = async () => {
        try {
            await logOut();
            return (
                <Redirect
                    to={{
                        pathname: '/Login',
                    }}
                />
            );
        } catch (error) {
            return null;
        }
    };
    const listData = {
        sorting: [{ direction: 'DESC' }, { direction: 'ASC' }, false, false],
        cellWidth: [3, 3, 6, 3],
        columns: ['place', 'category', 'address', 'cordinate'],
        isHtml: [false, false, false, true],
        pageData: filterList ?? [],
        actionList: getKetoList,
        isDisable: filters.totalElements <= filterList.length,
        loading,
        filters,
        params,
        cref,
        roles,
    };
    useEffect(() => {
        if (isEmpty(params)) getKetoList(params);
    }, [getKetoList, params]);
    return (
        <Container
            cref={cref}
            fluid
            id="KetomapPage"
            className={`private-layout`}
        >
            {message?.errNo === 403 && notAuthenticate()}
            <Segment basic>
                <BreadcrumbComp sections={sections} />
                <div className="actions">
                    <Search
                        config={CONFIG}
                        params={params}
                        actionList={getKetoList}
                        filters={filters}
                        width="full"
                    />
                </div>
                <GridList {...props} config={CONFIG} listData={listData} />
                <UploadCsvModal
                    lang={CONFIG.modal}
                    uploaded={uploaded}
                    uploading={uploading}
                    uploadFile={mapUpload}
                    initModal={initModal}
                    loading={loading}
                    actionList={getKetoList}
                    params={params}
                    uploadStatus={uploadStatus}
                />
            </Segment>
        </Container>
    );
};

export { KetomapPage };
