import PROMO from '_constants';
import { batch } from 'react-redux';
import { promoService, patientService } from '_services';
import { isEmpty } from 'lodash';
import history from '_utils/history';
import { lang } from '_config';
import moment from 'moment';
import { getErrorMsg } from '_utils';
const {
    menuList: {
        messages,
        error: {
            promotions: { custom, pro15 },
        },
    },
} = lang;
// FOR VIEW SELECTED CUSTOM PROMO RECORD
const customRecordPromo = (record) => {
    const { tabName, ...input } = record;
    return (dispatch, getState) => {
        const { alertData, promoList } = getState();
        const onError = (error) =>
            batch(() => {
                dispatch({
                    type: PROMO.PROMO_EDIT_FAILURE,
                    error,
                    tabName,
                });
                dispatch({ type: PROMO.ERROR, message: error ?? null });
                setTimeout(
                    () => dispatch({ type: PROMO.CLEAR }),
                    PROMO.TIMEOUT
                );
            });
        if (!promoList.loading) {
            // REQUEST SETUP
            batch(() => {
                dispatch({ type: PROMO.PROMO_REQUEST_START });
                if (!isEmpty(alertData)) dispatch({ type: PROMO.CLEAR });
            });
            // REQUEST START
            promoService
                .viewRecord(input)
                .then((result) => {
                    // REQUEST SUCCESS
                    if (result && result?.status) {
                        dispatch({
                            type: PROMO.PROMO_EDIT,
                            result,
                            tabName,
                        });
                    }
                    // REQUEST FAILURE WITH BAD PARAMS
                    else onError(result);
                })
                // HANDEL ANY WEIRED BEHAVIOUR(LIKE NETWORK OR ANY OTHER)
                .catch(onError)
                // REQUEST END
                .finally(() => dispatch({ type: PROMO.PROMO_REQUEST_END }));
        }
    };
};
const customListPromo = (params) => {
    const queryParams = {
        pageNumber: params?.pageNumber ?? 0,
        direction: params?.direction ?? 'DESC',
        orderBy: params?.orderBy ?? 'ID',
        search: params?.search ?? '',
        category: params?.category ?? 'CUSTOM',
        promotionParticipated: 'true',
    };
    return (dispatch, getState) => {
        const {
            promoList: { loading },
        } = getState();
        if (!loading) {
            const onError = (error) => {
                batch(() => {
                    dispatch({
                        type: PROMO.PROMO_LIST_FAILURE,
                        failure: error ?? null,
                    });
                    dispatch({
                        type: PROMO.ERROR,
                        message: error ?? null,
                    });
                    setTimeout(
                        () => dispatch({ type: PROMO.CLEAR }),
                        PROMO.TIMEOUT
                    );
                });
            };
            batch(() => {
                dispatch({ type: PROMO.PROMO_REQUEST_START });
                dispatch({ type: PROMO.CLEAR });
            });
            promoService
                .getAll(queryParams)
                .then((result) => {
                    if (result && result?.status) {
                        dispatch({
                            type: PROMO.PROMO_LIST_SUCCESS,
                            result,
                            params: queryParams,
                            tabName: queryParams.category,
                        });
                    } else {
                        onError(result);
                    }
                })
                .catch(onError)
                .finally(() => {
                    dispatch({ type: PROMO.PROMO_REQUEST_END });
                });
        }
    };
};
const getList = (params) => {
    const queryParams = {
        pageNumber: params?.pageNumber ?? 0,
        direction: params?.direction ?? 'DESC',
        orderBy: params?.orderBy ?? 'NAME',
        search: params?.search ?? '',
        category: params?.category ?? 'DEFAULT',
        promotionParticipated: true,
    };
    return (dispatch, getState) => {
        const {
            promoList: { loading },
        } = getState();
        if (!loading) {
            const onError = (error) => {
                batch(() => {
                    dispatch({
                        type: PROMO.PROMO_LIST_FAILURE,
                        failure: error ?? null,
                    });
                    dispatch({
                        type: PROMO.ERROR,
                        message: error ?? null,
                    });
                    setTimeout(
                        () => dispatch({ type: PROMO.CLEAR }),
                        PROMO.TIMEOUT
                    );
                });
            };
            batch(() => {
                dispatch({ type: PROMO.PROMO_REQUEST_START });
                dispatch({ type: PROMO.CLEAR });
            });
            patientService
                .getAll(queryParams)
                .then((result) => {
                    if (result && result?.status) {
                        dispatch({
                            type: PROMO.PROMO_LIST_SUCCESS,
                            result,
                            params: queryParams,
                            tabName: queryParams.category,
                        });
                    } else {
                        onError(result);
                    }
                })
                .catch(onError)
                .finally(() => {
                    dispatch({ type: PROMO.PROMO_REQUEST_END });
                });
        }
    };
};
const addPromo = (record) => {
    const { tabName, ...newRecord } = record;
    const { availableFrom, availableTo } = newRecord;
    newRecord.availableFrom = moment(availableFrom, 'DD-MM-YYYY').format(
        'YYYY-MM-DD'
    );
    newRecord.availableTo = moment(availableTo, 'DD-MM-YYYY').format(
        'YYYY-MM-DD'
    );
    return (dispatch) => {
        const onError = (error) => {
            batch(() => {
                dispatch({
                    type: PROMO.PROMO_ADD_FAILURE,
                    failure: getErrorMsg(error, custom?.create) ?? null,
                    params: newRecord,
                    tabName,
                });
                dispatch({
                    type: PROMO.ERROR,
                    message: getErrorMsg(error, custom?.create) ?? null,
                });
                setTimeout(
                    () => dispatch({ type: PROMO.CLEAR }),
                    PROMO.TIMEOUT
                );
            });
        };
        batch(() => {
            dispatch({ type: PROMO.PROMO_REQUEST_START });
            dispatch({ type: PROMO.CLEAR });
        });
        promoService
            .addCustomPromo(newRecord)
            .then((res) => {
                res.status
                    ? batch(() => {
                          dispatch({
                              type: PROMO.PROMO_ADD,
                              tabName,
                          });
                          dispatch({
                              type: PROMO.SUCCESS,
                              message: {
                                  suc:
                                      custom?.create[200]?.msg ?? messages?.add,
                              },
                          });
                      })
                    : onError(res);
            })
            .catch(onError)
            .finally(() => {
                batch(() => {
                    dispatch({ type: PROMO.PROMO_REQUEST_END });
                });
            });
    };
};
const delCustomPromo = (record) => {
    const { tabName, ...recordId } = record;
    return (dispatch) => {
        const onError = (error) => {
            batch(() => {
                dispatch({
                    type: PROMO.PROMO_DEL_FAILURE,
                    failure: getErrorMsg(error, custom?.delete) ?? null,
                    tabName,
                });
                dispatch({
                    type: PROMO.ERROR,
                    message: getErrorMsg(error, custom?.delete) ?? null,
                });
                setTimeout(
                    () => dispatch({ type: PROMO.CLEAR }),
                    PROMO.TIMEOUT
                );
            });
        };
        batch(() => {
            dispatch({ type: PROMO.PROMO_REQUEST_START });
            dispatch({ type: PROMO.CLEAR });
        });
        promoService
            .delCustomPromo(recordId)
            .then((res) => {
                res.status
                    ? batch(() => {
                          dispatch({
                              type: PROMO.PROMO_DEL,
                              tabName,
                          });
                          dispatch({
                              type: PROMO.SUCCESS,
                              message: {
                                  suc:
                                      custom?.delete[200]?.msg ??
                                      messages?.delete,
                              },
                          });

                          setTimeout(() => {
                              dispatch(
                                  customListPromo({
                                      pageNumber: 0,
                                      direction: 'DESC',
                                      orderBy: 'ID',
                                      search: '',
                                      category: 'CUSTOM',
                                  })
                              );
                              history.push('/Promotions/CUSTOM');
                          }, PROMO.TIMEOUT);
                      })
                    : onError(res);
            })
            .catch(onError)
            .finally(() => {
                batch(() => {
                    dispatch({ type: PROMO.PROMO_REQUEST_END });
                });
            });
    };
};
const updateCustomPromo = (record) => {
    const { tabName, ...recordUpdate } = record;
    const { availableFrom, availableTo } = recordUpdate;
    recordUpdate.availableFrom = moment(availableFrom, 'DD-MM-YYYY').format(
        'YYYY-MM-DD'
    );
    recordUpdate.availableTo = moment(availableTo, 'DD-MM-YYYY').format(
        'YYYY-MM-DD'
    );
    return (dispatch) => {
        const onError = (error) => {
            batch(() => {
                dispatch({
                    type: PROMO.PROMO_UP_FAILURE,
                    failure: getErrorMsg(error, custom?.update) ?? null,
                    tabName,
                });
                dispatch({
                    type: PROMO.ERROR,
                    message: getErrorMsg(error, custom?.update) ?? null,
                });
                setTimeout(
                    () => dispatch({ type: PROMO.CLEAR }),
                    PROMO.TIMEOUT
                );
            });
        };
        batch(() => {
            dispatch({ type: PROMO.PROMO_REQUEST_START });
            dispatch({ type: PROMO.CLEAR });
        });
        promoService
            .upCustomPromo(recordUpdate)
            .then((res) => {
                res.status
                    ? batch(() => {
                          dispatch({
                              type: PROMO.PROMO_UP,
                              tabName,
                          });
                          dispatch({
                              type: PROMO.SUCCESS,
                              message: {
                                  suc:
                                      custom?.update[200]?.msg ??
                                      messages?.update,
                              },
                          });
                          setTimeout(() => {
                              dispatch(
                                  customListPromo({
                                      pageNumber: 0,
                                      direction: 'DESC',
                                      orderBy: 'ID',
                                      search: '',
                                      category: 'CUSTOM',
                                  })
                              );
                              history.push('/Promotions/CUSTOM');
                          }, PROMO.TIMEOUT);
                      })
                    : onError(res);
            })
            .catch(onError)
            .finally(() => {
                batch(() => {
                    dispatch({ type: PROMO.PROMO_REQUEST_END });
                });
            });
    };
};
const updateTicket = (data) => {
    return (dispatch, getState) => {
        const {
            patientList: { loading, isModalOpen },
            alertData,
        } = getState();
        const { params, ...record } = data;
        // if allready fetching the record
        if (!loading) {
            const onError = (faliure) => {
                batch(() => {
                    dispatch({
                        type: PROMO.PROMO_TICKET_FAILURE,
                        faliure,
                    });
                    dispatch({
                        type: PROMO.ERROR,
                        message: faliure,
                    });
                    setTimeout(
                        () => dispatch({ type: PROMO.CLEAR }),
                        PROMO.TIMEOUT
                    );
                });
            };
            // START REQUEST SETUP
            batch(() => {
                if (!isEmpty(alertData)) dispatch({ type: PROMO.CLEAR });
                if (!isModalOpen) dispatch({ type: PROMO.PROMO_MODAL_OPEN });
                dispatch({ type: PROMO.PROMO_MODAL_LOADING });
            });
            // REQUEST START
            promoService
                .updateTicket(record)
                .then(
                    (result) => {
                        // REQUEST SUCCESS
                        if (result && result?.status) {
                            batch(() => {
                                dispatch({
                                    type: PROMO.SUCCESS,
                                    message: {
                                        suc:
                                            pro15?.update[200]?.msg ??
                                            messages?.update,
                                    },
                                });
                                setTimeout(() => {
                                    batch(() => {
                                        params.pageNumber = 0;
                                        dispatch(getPatientRecord(params));
                                        dispatch(getBoxes(params.id));
                                    });
                                }, PROMO.TIMEOUT);
                            });
                        } else {
                            // REQUEST FAILURE WITH BAD PARAMS
                            onError(result);
                        }
                    },
                    (err) => onError(err)
                )
                // HANDEL ANY WEIRED BEHAVIOUR(LIKE NETWORK OR ANY OTHER)
                .catch(onError)
                .finally(() => {
                    // REQUEST ENDED
                    batch(() => {
                        dispatch({ type: PROMO.PROMO_MODAL_LOADED });
                        dispatch({ type: PROMO.PROMO_MODAL_CLOSE });
                    });
                });
        }
    };
};

const getPatientRecord = (params = {}) => {
    params.category = params?.category ?? 'DEFAULT';
    params.id = params?.id ?? '2';
    params.pageNumber = params?.pageNumber ?? 0;
    params.direction = params?.direction ?? 'DESC';
    params.orderBy = params?.orderBy ?? 'ID';
    params.search = params?.search ?? '';
    return (dispatch, getState) => {
        const {
            promoList: { loading },
            alertData,
        } = getState();
        if (!loading) {
            const onError = (error) => {
                batch(() => {
                    dispatch({
                        type: PROMO.PROMO_RECORD_LIST_FAILURE,
                        failure: error ?? null,
                        tabName: params.category,
                    });
                    dispatch({
                        type: PROMO.ERROR,
                        message: error ?? null,
                    });
                    setTimeout(
                        () => dispatch({ type: PROMO.CLEAR }),
                        PROMO.TIMEOUT
                    );
                });
            };
            batch(() => {
                dispatch({ type: PROMO.PROMO_REQUEST_START });
                if (!isEmpty(alertData)) dispatch({ type: PROMO.CLEAR });
            });
            promoService
                .getPatientRecord(params)
                .then((result) => {
                    if (result && result?.status) {
                        dispatch({
                            type: PROMO.PROMO_RECORD_LIST_SUCCESS,
                            result: { ...result?.resultList },
                            userData: result?.appUserData,
                            params: params,
                            tabName: params?.category,
                        });
                    } else {
                        onError(result);
                    }
                })
                .catch(onError)
                .finally(() => {
                    dispatch({ type: PROMO.PROMO_REQUEST_END });
                });
        }
    };
};
//OPEN MODAL
const openModal = (modalData) => {
    return (dispatch, getState) => {
        const {
            promoList: { isModalOpen },
        } = getState();
        if (!isModalOpen) dispatch({ type: PROMO.PROMO_MODAL_OPEN,modalData });
    };
};
//close MODAL
const closeModal = () => {
    return (dispatch, getState) => {
        const {
            promoList: { isModalOpen },
        } = getState();
        if (isModalOpen) dispatch({ type: PROMO.PROMO_MODAL_CLOSE });
    };
};
const getPharmacyList = () => {
    const queryParams = {
        type: 'PHARMACY',
    };
    return (dispatch) => {
        const onError = (error) => {
            batch(() => {
                dispatch({
                    type: PROMO.PROMO_PHARMA_FAILURE,
                    failure: error ?? null,
                });
                dispatch({
                    type: PROMO.ERROR,
                    message: error ?? null,
                });
                setTimeout(
                    () => dispatch({ type: PROMO.CLEAR }),
                    PROMO.TIMEOUT
                );
            });
        };
        batch(() => {
            dispatch({ type: PROMO.PROMO_REQUEST_START });
            dispatch({ type: PROMO.CLEAR });
        });
        patientService
            .getPharmacyList(queryParams)
            .then((result) => {
                if (result && result?.status) {
                    dispatch({
                        type: PROMO.PROMO_PHARMA_SUCCESS,
                        tabName: 'DEFAULT',
                        result,
                    });
                } else {
                    onError(result);
                }
            })
            .catch(onError)
            .finally(() => {
                dispatch({ type: PROMO.PROMO_REQUEST_END });
            });
    };
};
const getBoxes = (userId) => {
    const queryParams = {
        userId,
    };
    return (dispatch) => {
        const onError = (error) => {
            const { errNo, errMsg } = error;
            if (
                errNo !== 404 &&
                errMsg !== "User doesn't have any promotion active"
            )
                batch(() => {
                    dispatch({
                        type: PROMO.PROMO_BOXES_FAILURE,
                        failure: error ?? null,
                    });
                    dispatch({
                        type: PROMO.ERROR,
                        message: error ?? null,
                    });
                    setTimeout(
                        () => dispatch({ type: PROMO.CLEAR }),
                        PROMO.TIMEOUT
                    );
                });
        };
        batch(() => {
            dispatch({ type: PROMO.PROMO_CURRENT_REQUEST_START });
            dispatch({ type: PROMO.CLEAR });
        });
        promoService
            .getBoxes(queryParams)
            .then((result) => {
                if (result && result?.status) {
                    dispatch({
                        type: PROMO.PROMO_BOXES_SUCCESS,
                        tabName: 'DEFAULT',
                        result: {
                            totalGiftBoxes: result?.totalGiftBoxes,
                            validBoxes: result?.validBoxes,
                            pendingBoxes: result?.appUser?.pendingTicket,
                        },
                    });
                } else {
                    onError(result);
                }
            })
            .catch(onError)
            .finally(() => {
                dispatch({ type: PROMO.PROMO_CURRENT_REQUEST_END });
            });
    };
};
export const promoActions = {
    getList,
    getPharmacyList,
    getBoxes,
    getPatientRecord,
    updateTicket,
    addPromo,
    delCustomPromo,
    updateCustomPromo,
    customRecordPromo,
    customListPromo,
    openModal,
    closeModal,
};
