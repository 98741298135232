import React, { useEffect } from 'react';
import { Search, GridList, StickyButton, BreadcrumbComp } from '_components';
import { isEmpty } from 'lodash';
import { Grid } from 'semantic-ui-react';
const NoticesList = (props) => {
    const {
        config,
        listData,
        history,
        actions: { getList },
        state: {
            newsList: { params, loading },
        },
    } = props;
    const sections = [
        {
            key: 'myNoticesPage',
            content: config?.pageHeading,
            active: true,
        },
    ];
    listData.actionList = getList;
    useEffect(() => {
        if (isEmpty(params) && !loading) getList(params);
    }, [getList, params, loading]);
    return (
        <>
            <BreadcrumbComp sections={sections} />
            <div className="actions">
                <Search
                    config={config}
                    params={{}}
                    actionList={listData.actionList}
                    filters={{}}
                    width="full"
                />
            </div>
            <GridList history={history} config={config} listData={listData}>
                {(id, data) => (
                    <Grid.Column key={id} width={2}>
                        <div
                            className="text-link"
                            onClick={() =>
                                history.push({
                                    pathname: `/News/Edit/${id}`,
                                    state: { record: { id, ...data } },
                                })
                            }
                        >
                            {config?.actionLable ?? 'Edit'}
                        </div>
                    </Grid.Column>
                )}
            </GridList>
            <StickyButton
                onClick={() => history.push({ pathname: '/News/Add' })}
            />
        </>
    );
};

export { NoticesList };
