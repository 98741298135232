/* eslint-disable react/no-multi-comp */
import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Modal, Button, Icon } from 'semantic-ui-react';
import { isEmpty } from 'lodash';
import { hasExtensions } from '_utils';
import { Image, StickyButton, Dropzone } from '_components';
import { excelIcon, uploadErr, uploadOk } from 'images';
import './upload-csv-modal.scss';
const UploadCsvModal = (props) => {
    const {
        lang,
        uploaded,
        uploading,
        loading,
        uploadFile,
        initModal,
        actionList,
        uploadStatus,
        withInfoIcon,
        buttonInfoText,
        patientId,
    } = props;
    const [modalOpen, setModal] = useState(false);
    const [isMsg, setMsg] = useState(false);
    const [isImport, setImport] = useState(false);
    const handleOpen = () => {
        setModal(true);
    };
    const handleClose = useCallback(async () => {
        await initModal();
        const { ...isDataLoaded } = isImport;
        if (!isEmpty(isDataLoaded) && isDataLoaded?.success) {
            actionList();
        }
        setModal(false);
        setMsg(false);
        setImport(false);
    }, [initModal, isImport, actionList]);
    const onDrop = useCallback(
        (acceptedFiles) => {
            acceptedFiles.forEach((file) => {
                if (hasExtensions(file.name)) {
                    let formData = new FormData();
                    formData.append('file', file);
                    setMsg({
                        ...isMsg,
                        err: false,
                        success: true,
                        formData,
                    });
                } else {
                    setMsg({
                        ...isMsg,
                        success: false,
                        err: true,
                        msg: 'Invalid format type',
                    });
                }
            });
        },
        [isMsg]
    );
    const { getRootProps, getInputProps, isDragActive } = Dropzone({
        accept: '.csv',
        multiple: false,
        onDropRejected: (input) => {
            const [file] = input;
            let msg = file.size > 1048576 && 'File not more than 1mb';
            msg = !hasExtensions(file.name) && 'Invalid format type';
            setMsg({ ...isMsg, err: true, msg, success: false });
        },
        onDrop,
    });
    const button = () => {
        return (
            <StickyButton
                icon="cloud upload"
                size="huge"
                onClick={() => handleOpen()}
                disabled={loading}
                withInfoIcon={withInfoIcon}
                buttonInfoText={buttonInfoText}
            />
        );
    };
    const upLoadData = () => {
        if (isMsg?.success) {
            patientId ? uploadFile(isMsg.formData, patientId) : uploadFile(isMsg.formData);
        }
    };

    if (uploadStatus && !isImport) {
        setImport({ success: false, err: true });
    }
    if (uploaded && !uploading && !isImport) {
        setImport({ ...isMsg });
    }
    return (
        <Modal
            open={modalOpen}
            onClose={handleClose}
            size="tiny"
            trigger={button()}
        >
            <div id="upload-csv-modal">
                <div className="modal-heading">
                    <div className="modal-close">
                        <Icon onClick={handleClose} name="close" />
                    </div>
                    <label className={isImport ? 'hide' : ''}>
                        {lang?.heading ?? 'IMPORT CSV'}
                    </label>
                </div>
                <div className="modal-content">
                    {!isImport ? (
                        <div {...getRootProps()} className="drag-container">
                            <input {...getInputProps()} />
                            <div
                                className={`drag-area ${
                                    isDragActive ? 'active' : ''
                                }`}
                            >
                                <Image src={excelIcon} />
                                <p className="drag-file-draggable">
                                    {lang?.dropText ??
                                        'Drag and drop your CSV file here'}
                                </p>
                                <p className="drag-file-computer">
                                    {lang?.uploadText ??
                                        'Or select file from your computer'}
                                </p>
                            </div>
                        </div>
                    ) : (
                        <div className="modal-message-image">
                            {!uploaded && <Image src={uploadErr} />}
                            {uploaded && <Image src={uploadOk} />}
                        </div>
                    )}
                </div>
                <div className="modal-action">
                    {!isImport ? (
                        <Button
                            disabled={!isMsg?.success ?? true}
                            onClick={upLoadData}
                            size="small"
                            loading={uploading}
                        >
                            {lang?.actionButton ?? 'Import'}
                        </Button>
                    ) : (
                        <div className="modal-message-text">
                            {!uploaded &&
                                (lang?.errMsg ??
                                    'The data did not load correctly.')}
                            {uploaded &&
                                (lang?.sucMsg ??
                                    'The data has been loaded succesfully.')}
                        </div>
                    )}
                </div>
            </div>
        </Modal>
    );
};

UploadCsvModal.propTypes = {
    lang: PropTypes.object.isRequired,
    uploadFile: PropTypes.func.isRequired,
    initModal: PropTypes.func.isRequired,
    actionList: PropTypes.func.isRequired,
    uploadStatus: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
    params: PropTypes.object.isRequired,
};

export { UploadCsvModal };
