import React from 'react';
import PropTypes from 'prop-types';
import { Select as SelectSemiantic } from 'semantic-ui-react';
import './Select.scss';
const Select = (props) => {
    const {
        placeholder,
        options,
        loading,
        search,
        fluid,
        selection,
        multiple,
        label,
        onChange,
        value,
        optional,
    } = props;
    return (
        <div className="dmi-select">
            <label>{label}</label>
            <SelectSemiantic
                fluid={fluid}
                search={search}
                selection={selection}
                multiple={multiple}
                placeholder={placeholder}
                options={options}
                loading={loading}
                onChange={onChange}
                onLabelClick={onChange}
                defaultValue={value}
            />
            {optional !== '' && <label className="optinal">{optional}</label>}
        </div>
    );
};
Select.defaultProps = {
    options: [],
    placeholder: '',
    label: '',
    loading: false,
    fluid: false,
    search: false,
    selection: false,
    multiple: false,
    value: '',
    optional: '',
    onChange: () => {},
};
Select.propTypes = {
    loading: PropTypes.bool,
    fluid: PropTypes.bool,
    search: PropTypes.bool,
    selection: PropTypes.bool,
    multiple: PropTypes.bool,
    options: PropTypes.array,
    onChange: PropTypes.func,
    placeholder: PropTypes.string,
    label: PropTypes.string,
    optional: PropTypes.string,
    value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.array,
    ]),
};

export { Select };
